import { object, string } from 'yup';

const rulesMessage = {
  min: (number, field) => `В поле "${field}" не должно быть меньше ${number} символов`,
  max: (number, field) => `В поле "${field}" не должно быть больше ${number} символов`,
  required: 'Поле обязательно для заполнения',
  email: 'Поле "Почта" введено некорректно',
};

const schemaTeacher = object({
  first_name: string()
    .min(2, rulesMessage.min(2, 'Имя'))
    .max(150, rulesMessage.max(150, 'Имя'))
    .required(rulesMessage.required),
  last_name: string()
    .min(2, rulesMessage.min(2, 'Фамилия'))
    .max(150, rulesMessage.max(150, 'Фамилия'))
    .required(rulesMessage.required),
  middle_name: string()
    .min(2, rulesMessage.min(2, 'Отчество'))
    .max(150, rulesMessage.max(150, 'Отчество'))
    .required(rulesMessage.required),
  email: string().email(rulesMessage.email).required(rulesMessage.required),
  profession_info: string().required(rulesMessage.required),
  education_info: string().required(rulesMessage.required),
});

async function clientValidateForm(form) {
  let errors;
  try {
    await schemaTeacher.validate(form, { abortEarly: false });
  } catch (validationError) {
    errors = {};
    validationError.inner.forEach((error) => {
      const { path, message } = error;
      errors[path] = message;
    });
  }
  return errors;
}

async function clientValidateField(field, value) {
  let errors;

  try {
    await schemaTeacher.validateAt(field, value, { abortEarly: false });
  } catch (validationError) {
    errors = {};
    validationError.inner.forEach((error) => {
      const { path, message } = error;
      errors[path] = message;
    });
  }
  return errors;
}

export default { clientValidateForm, clientValidateField };

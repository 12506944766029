/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import joi from "joi";
import { observer } from "mobx-react-lite";
import "bootstrap/dist/css/bootstrap.css";
import { useHistory } from "react-router";
import { Button, FormGroup, Label, Input, Form, InputGroup } from "reactstrap";
import * as style from "./style/Login.module.scss";
import authApi from "../../api/endPoints/login";
import { useStore } from "../../store";

import { ReactComponent as EyeOn } from "../../assets/img/eyeOn.svg";
import { ReactComponent as EyeOff } from "../../assets/img/eyeOff.svg";

const emailSchema = joi
  .string()
  .email({ tlds: { allow: false } })
  .messages({
    "string.empty": "Это поле обязательное",
    "string.email": "Введите верный формат данных",
  });

const passwordSchema = joi.string().messages({
  "string.empty": "Это поле обязательное",
});

function Login() {
  const history = useHistory();
  const oldPath = localStorage.getItem("path");
  const [formErrors, setFormErrors] = React.useState({
    email: "",
    password: "",
  });
  const [isLogInPressed, setIsLogInPressed] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);

  const formNames = {
    email: "email",
    password: "password",
  };

  const [serverErrors, setServerErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    userStore: { setIsAuthorized },
  } = useStore();

  useEffect(() => {
    const emailErrorMessage = validateField(formNames.email, email);
    const passwordErrorMessage = validateField(formNames.password, password);
    setFormErrors({ email: emailErrorMessage, password: passwordErrorMessage });
  }, [email, password]);

  useEffect(() => {
    if (localStorage.getItem("access_token") && localStorage.getItem("role")) {
      history.push("/admin");
    }
  }, [history]);

  function handeSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setIsLogInPressed(true);
    const emailErrorMessage = validateField(formNames.email, email, true);
    const passwordErrorMessage = validateField(
      formNames.password,
      password,
      true
    );
    setFormErrors({ email: emailErrorMessage, password: passwordErrorMessage });
    if (!(emailErrorMessage || passwordErrorMessage)) {
      logIn();
    }
    if (emailErrorMessage || passwordErrorMessage) {
      setLoading(false);
    }
  }

  const logIn = () => {
    authApi
      .signIn(email, password)
      .then(() => {
        setLoading(false);
        if (oldPath) {
          history.push(oldPath);
        } else {
          history.push("/admin");
        }
        setServerErrors("");
        setIsAuthorized(true);
      })
      .catch((error) => {
        setServerErrors(null);
        setLoading(false);
        if (error.status === 422) {
          if (error.data.error.advanced.email != null) {
            setServerErrors(error.data.error.advanced.email);
          } else {
            setServerErrors(error.data.error.message);
          }
        }
      });
  };

  function validateField(name, value, checkForValid) {
    if (!(isLogInPressed || checkForValid)) {
      return "";
    }
    let message;
    switch (name) {
      case formNames.email:
        message = emailSchema.validate(value).error?.message;
        break;
      case formNames.password:
        message = passwordSchema.validate(value).error?.message;
        break;
    }
    return message ?? "";
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case formNames.email:
        setEmail(value);
        break;
      case formNames.password:
        setPassword(value);
        break;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className={`col-md-3 offset-md-1 ${style.inputForm}`}>
          <p className={style.authTxt}>Авторизация</p>
          <Form>
            <FormGroup>
              <Label for="Email" className={style.inputLable}>
                E-mail
              </Label>
              <Input
                type="email"
                className={
                  formErrors.email ? `${style.wrongPassword}` : `${style.email}`
                }
                id="Email"
                placeholder="Введите e-mail"
                name={formNames.email}
                value={email}
                onChange={handleChange}
                autoComplete="off"
              />
              <small
                className={
                  formErrors.email ? `${style.visible}` : `${style.unvisible}`
                }
              >
                {formErrors.email}
              </small>
              <Label for="password" className={style.inputLable}>
                Пароль
              </Label>
              <InputGroup>
                <Input
                  className={
                    formErrors.password ? `${style.wrongPassword}` : ""
                  }
                  type={checkPassword ? "text" : "password"}
                  id="password"
                  placeholder="Введите пароль"
                  name={formNames.password}
                  value={password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <Button
                  color="inline"
                  className={style.changeEye}
                  onClick={(e) => {
                    e.preventDefault();
                    setCheckPassword(!checkPassword);
                  }}
                >
                  {checkPassword ? <EyeOn /> : <EyeOff />}
                </Button>
              </InputGroup>
              <small
                className={
                  formErrors.password
                    ? `${style.visible}`
                    : `${style.unvisible}`
                }
              >
                {formErrors.password}
              </small>
              <small
                className={
                  serverErrors
                    ? `${style.visibleServerErrors}`
                    : `${style.unvisibleServerErrors}`
                }
              >
                {serverErrors}
              </small>

              {loading ? (
                <Button
                  disabled
                  type="submit"
                  color="primary"
                  className={style.submitBtn}
                  onClick={handeSubmit}
                >
                  <div
                    className={`spinner-border text-light ${style.loading}`}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </Button>
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  className={style.submitBtn}
                  onClick={handeSubmit}
                >
                  Войти
                </Button>
              )}
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default observer(Login);

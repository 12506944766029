import React, { useState } from "react";
import { useStore } from "store";

import * as style from "./style/AddResources.module.scss";

import { observer } from "mobx-react-lite";
import AddFileResources from "./ResourcesTabs/AddFileResources";
import AddLinkResources from "./ResourcesTabs/AddLinkResources";
import AddKinescopeResources from "./ResourcesTabs/AddKinescopeResources";

function AddResources({ setModalLoading }) {
    const [activeTab, setActiveTab] = useState("FILE");

    function handleClick(e, tab) {
        e.preventDefault();
        setActiveTab(tab);
    }

    const activeTabInput = (
        <div className={style.tabs}>
            {activeTab === "FILE" && <AddFileResources setModalLoading={setModalLoading} />}
            {activeTab === "LINK" && <AddLinkResources setModalLoading={setModalLoading} />}
            {activeTab === "KINESCOPE" && (
                <AddKinescopeResources setModalLoading={setModalLoading} />
            )}
        </div>
    );

    return (
        <div className={style.modal}>
            <div className={style.modalOverlay}>
                <div className={style.modalWindow}>
                    <div className={style.modalHeader}>
                        <p className={style.popUpTitle}>Загрузить ресурс</p>
                    </div>
                    <div>
                        <div className={style.tab_wrapper}>
                            <div
                                onClick={(e) => handleClick(e, "FILE")}
                                className={
                                    activeTab === "FILE"
                                        ? `${style.tab_active} ${style.tab}`
                                        : style.tab
                                }>
                                Загрузить файл
                            </div>

                            <div
                                onClick={(e) => handleClick(e, "LINK")}
                                className={
                                    activeTab === "LINK"
                                        ? `${style.tab_active} ${style.tab}`
                                        : style.tab
                                }>
                                Добавить ссылку
                            </div>

                            <div
                                onClick={(e) => handleClick(e, "KINESCOPE")}
                                className={
                                    activeTab === "KINESCOPE"
                                        ? `${style.tab_active} ${style.tab}`
                                        : style.tab
                                }>
                                Добавить kinescope
                            </div>
                            <div className={style.emptytab}></div>
                        </div>
                    </div>
                    {activeTabInput}
                </div>
            </div>
        </div>
    );
}

export default observer(AddResources);

import React from "react";
import PropTypes from "prop-types";
import { UncontrolledPopover } from "reactstrap";

import styles from "./styles.module.scss";

function PopoverMenu({ target, placement, actions }) {
    return (
        <UncontrolledPopover target={target} placement={placement} trigger="legacy">
            <div className={styles.popover}>
                {actions.map((action) => (
                    <>
                        {!action.disabled && (
                            <button
                                type="button"
                                className={styles.popover__menu__item}
                                style={{ marginRight: "20px" }}
                                onClick={() => {
                                    action.onClick?.();
                                    document.body.click();
                                }}
                                key={action.name}>
                                <action.icon />
                                {action.name}
                            </button>
                        )}
                    </>
                ))}
            </div>
        </UncontrolledPopover>
    );
}

PopoverMenu.propTypes = {
    target: PropTypes.string.isRequired,
    placement: PropTypes.string,
    actions: PropTypes.array.isRequired,
};

PopoverMenu.defaultProps = {
    placement: "right",
};

export default PopoverMenu;

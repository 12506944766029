import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useParams } from "react-router";
import foldersApi from "../../../../../api/endPoints/folders";
import { toast } from "react-toastify";

import { Button, FormText, Input, FormFeedback, Form, FormGroup, Label } from "reactstrap";
import * as style from "./style/AddLinkResources.module.scss";
import validationResources from "components/Resources/utils/validationResources";

function AddLinkResources({ setModalLoading }) {
    const [link, setLink] = useState({
        name: "",
        src_url: "",
        type: "LINK",
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const {
        modalWindowStore: { setAddResource },
    } = useStore();

    const { id } = useParams();

    const onBlur = async (e) => {
        const validationErrors = await validationResources.clientValidateField(e.target.name, {
            ...link,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        clearErrField(name);
        setLink({ ...link, [name]: value });
    };

    const apiAddLink = () => {
        setLoading(true);
        setModalLoading(true);
        foldersApi
            .addLinkResource(link, id)
            .then(() => {
                setAddResource(false);
                setLoading(false);
                setModalLoading(false);
                toast.success("Ресурс успешно добавлен");
            })
            .catch((error) => {
                setErrors({ ...errors, ...error.data.error.advanced });
                setModalLoading(false);
                setLoading(false);
            });
    };

    return (
        <Form>
            <FormGroup>
                <div className={style.titleName}>
                    <Label>Название:*</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        value={link.name}
                        invalid={!!errors["name"] || !!errors?.name}
                        onBlur={(e) => onBlur(e, link.name)}
                        onChange={handleInputChange}
                        placeholder="Название материала"></Input>
                    <FormFeedback>{errors["name"] || errors?.name}</FormFeedback>
                    <FormText color="muted" className={style.displayHint}>
                        Введите от 2 до 150 символов
                    </FormText>
                </div>
            </FormGroup>

            <FormGroup>
                <div className={style.resourceLink}>
                    <Label>Ссылка на ресурс:*</Label>
                    <Input
                        type="text"
                        id="src_url"
                        name="src_url"
                        className={style.uploadFile}
                        value={link.src_url}
                        invalid={!!errors["src_url"] || !!errors?.src_url}
                        onBlur={(e) => onBlur(e, link.name)}
                        onChange={handleInputChange}
                        placeholder="Вставьте URL"></Input>
                    <FormFeedback>{errors["src_url"] || errors?.src_url}</FormFeedback>
                </div>
            </FormGroup>

            <div className={style.displayButton}>
                <Button
                    color="primary"
                    outline
                    className={style.deleteLesson}
                    onClick={() => setAddResource(false)}>
                    Отмена
                </Button>

                {loading ? (
                    <Button disabled color="primary" outline className={style.deleteLesson}>
                        <div
                            className={`spinner-border text-primary ${style.loading}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        disabled={!link.src_url}
                        className={style.canselChange}
                        onClick={() => apiAddLink()}>
                        Добавить
                    </Button>
                )}
            </div>
        </Form>
    );
}

export default observer(AddLinkResources);

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import purchases from "api/endPoints/purchases";
import FolderTree from "components/FolderTree/FolderTree";
import Preloader from "components/Preloader/Preloader";
import * as style from "../style/UserDetail.module.scss";
import BreadCrumbs from "components/Breadcrumbs/Breadcrumbs";

const recursiveAddCustomId = (data) => {
  data.forEach((el) => {
    el.custom_id = el.id + el.foreign_type;
    if(el.children.length > 0) {
      return recursiveAddCustomId(el.children);
    }
  })

  return data;
}
function UserMaterialPurchase() {
  const [data, setData] = useState();
  const params = useParams();
  const { pathname } = useLocation();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [requestData, setRequestData] = useState({
    no_countable_element_id: params.materialId,
    subject_id: params.subjId,
  });
  useEffect(() => {
    purchases.getPurchasesTreeForUser(params.id, requestData).then((res) => {
      setBreadCrumbs(res.breadcrumbs);
      setData(recursiveAddCustomId(res.data));
    });
  }, []);

  const breadcrumbsNew = () => {
    if (params.subjId) {
      let firstItem = {
        name: "Личные данные ученика",
        path: `/user-detail/${params.id}`,
      };
      let secondItem = {
        name: breadCrumbs[1].name,
        path: `/user-detail/${params.id}/material/${params.materialId}`,
      };
      let thirdItem = {
        name: breadCrumbs[0].name,
        path: `/user-detail/${params.id}/material/${params.materialId}/subject/${params.subjId}`,
      };
      return [firstItem, secondItem,thirdItem];
    } else {
      let firstItem = {
        name: "Личные данные ученика",
        path: `/user-detail/${params.id}`,
      };
      let secondItem = {
        name: breadCrumbs[0].name,
        path: `/user-detail/${params.id}/materials/${params.materialId}`,
      };
      return [firstItem, secondItem];
    }
  };
  return (
    <>
      {data ? (
        <div>
          <div className={style.material_header}>
            <BreadCrumbs breadCrumbs={breadcrumbsNew()} pathname={pathname} />
            <p className={style.material_title}>{breadCrumbs[0].name}</p>
          </div>
          {data.length > 0 ? (
            <FolderTree data={data} purchases />
          ) : (
            <p>Нет купленных продуктов</p>
          )}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default UserMaterialPurchase;

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useStore } from "store";
import itemsManagement from "api/endPoints/itemsManagement";
import * as style from "./style/DeleteItem.module.scss";

function DeleteItem({ loading, setLoading }) {
  const {
    modalWindowStore: { isDeleteItemModalOpen, setIsDeleteItemModalOpen },
  } = useStore();

  const [errors, setErrors] = useState({});

  const deleteSubject = () => {
    setLoading(true);
    itemsManagement
      .deleteSubject(isDeleteItemModalOpen.id)
      .then(() => {
        setLoading(false);
        toast.info("Изменения сохранены");
        setIsDeleteItemModalOpen(false);
      })
      .catch((error) => {
        setErrors(error);
        setLoading(false);
      });
  };

  return (
    <>
      {errors.status === 400 ? (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.error_title}>
                  Невозможно удалить предмет
                  <br />
                  {isDeleteItemModalOpen.name}
                </p>
                <p className={style.error_text}>
                 {errors.data.error.message}
                </p>
              </div>
              <div className={style.displayButton}>
                <Button
                  color="primary"
                  className={style.deleteError}
                  onClick={() => setIsDeleteItemModalOpen(false)}
                >
                  Ок
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.popUpTitle}>
                  Вы уверены, что хотите <br />
                  удалить предмет {isDeleteItemModalOpen.name}?
                </p>
              </div>
              <p className={style.popUpTxt}>
                Предмет нельзя будет выбрать при настройке формы обучения.
              </p>
              <div>
                <Button
                  color="primary"
                  outline
                  className={style.deleteLesson}
                  onClick={() => setIsDeleteItemModalOpen(false)}
                >
                  Отменить
                </Button>
                {loading ? (
                  <Button
                    disabled
                    color="primary"
                    className={style.canselChange}
                  >
                    <div
                      className={`spinner-border text-light ${style.loading}`}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className={style.canselChange}
                    onClick={() => deleteSubject()}
                  >
                    Удалить
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(DeleteItem);

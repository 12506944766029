import moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

function detectedAgeFromDate(date) {
  const diff = moment().diff(date, 'years');
  return diff ? moment.duration(diff, 'years').humanize() : '-';
}

function detectedAgeFromNumber(numb) {
  return moment.duration(numb, 'years').humanize();
}

export default {
  detectedAgeFromDate,
  detectedAgeFromNumber,
};

import React, { useState, useEffect, useReducer, useMemo } from "react";
import { useParams, useHistory } from "react-router";
import paths from "../../../../constants/paths";
import { useStore } from "store";
import { observer } from "mobx-react-lite";

import "cropperjs/dist/cropper.css";

import { FormSelect } from "react-bootstrap";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import {
    Input,
    Button,
    Form,
    FormGroup,
    Label,
    FormText,
    InputGroup,
    Row,
    Col,
    FormFeedback,
} from "reactstrap";
import * as style from "./style/EditStudent.module.scss";
import { ReactComponent as ArrowBack } from "../../../../assets/img/arrowBack.svg";

import studentApi from "../../../../api/endPoints/users";

import { userActions, userTypes, userReducer, userInitialValue } from "./reducers/userReducer.js";
import validation from "./utils/validation";

import AsyncSelect from "react-select/async";
import searchCity from "api/endPoints/profileEndpoint";
import searchTimezone from "api/endPoints/profileEndpoint";

import downloadLogo from "../../../../assets/img/downloadLogo.svg";
import imgIcon from "../../../../assets/img/imgIcon.svg";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";
import LeavePage from "components/Popup/LeavePage";
import { toast } from "react-toastify";
import Preloader from "components/Preloader/Preloader";
import { compareData } from "utils/compareData";
import age from "utils/detectAge";
import CropperModal from "components/Popup/CropperModal";

function EditStudent() {
    const {
        modalWindowStore: { isCloseModalOpen, setIsCloseModalOpen },
        modalWindowStore: { cropperModalOpen, seCropperModalOpen },
        userStore: { setUserListTabs },
    } = useStore();
    const { id } = useParams();
    const history = useHistory();

    const [studentInfo, setStudentInfo] = useState();

    const [user, userDispatch] = useReducer(userReducer, {});

    const [loading, setLoading] = useState(false);
    const [divergencies, setDivergencies] = useState([]);
    const [studentClasses, setStudentClasses] = useState([]);
    const [studentEducationForm, setStudentEducationForm] = useState([]);
    const [adultRole, setAdultRole] = useState([]);

    const [image, setImage] = useState();
    const [cropper, setCropper] = useState();

    const [buttonStatus, setButtonStatus] = useState(false);
    const imageElement = document.getElementById("avatar");
    const [deleteAvatar, setDeleteAvatar] = useState(false);
    const [errors, setErrors] = useState({});

    const [avatarName, setAvatarName] = useState("");

    useEffect(() => {
        studentApi.getStudent(id).then((response) => {
            setStudentInfo({
                student: {
                    ...response?.profile?.student,
                    divergency: response?.profile?.student?.divergency?.id || null,
                    education_class: response?.profile?.student?.education_class?.id || null,
                    education_format: response?.profile?.student?.education_format?.id || null,
                    ...response?.user,
                },
                adult: {
                    ...response?.profile?.adult,
                    role: response?.profile?.adult?.role?.id || null,
                },
            });
            if (!!response?.profile?.student?.avatar) {
                setButtonStatus(true);
                setImage(response?.profile.student.avatar);
                setAvatarName(response?.profile.student.avatar_name);
            }
            userDispatch({
                type: userActions.setValues,
                payload: {
                    student: {
                        ...response?.profile?.student,
                        divergency:
                            response?.profile?.student?.divergency?.id ||
                            response?.profile?.student?.divergency,
                        education_class:
                            response?.profile?.student?.education_class?.id ||
                            response?.profile?.student?.education_class,
                        education_format:
                            response?.profile?.student?.education_format?.id ||
                            response?.profile?.student?.education_format,
                        ...response?.user,
                    },
                    adult: {
                        ...response?.profile?.adult,
                        role: response?.profile?.adult?.role?.id || response?.profile?.adult?.role,
                    },
                },
            });
        });

        studentApi.getStudentDevergency().then((response) => {
            setDivergencies(response);
        });
        studentApi.getStudentClass().then((response) => {
            setStudentClasses(response);
        });
        studentApi.getStudentEducationForm().then((response) => {
            setStudentEducationForm(response);
        });
        studentApi.getAdultRole().then((response) => {
            setAdultRole(response);
        });
    }, []);

    const isPreloader = useMemo(
        () => !!(studentInfo && divergencies && studentClasses && studentEducationForm),
        [studentInfo, divergencies, studentClasses, studentEducationForm],
    );

    const SIZE_LIMIT = 10485760;

    const formNames = {
        first_name: "first_name",
        last_name: "last_name",
        email: "email",
        birthdayStudent: "birthday",
        phone: "phone",
        education_class: "education_class",
        education_format: "education_format",
        divergency: "divergency",
        fio: "fio",
        birthdayAdult: "birthday",
        role: "role",
        location: "location",
        other_divergency: "other_divergency",
    };

    const userTypes = {
        student: "student",
        adult: "adult",
    };

    const onBlur = async (e, userType) => {
        const { name } = e.target;
        const validationErrors = await validation.clientValidateField(`${userType}.${name}`, {
            [userType]: { ...user[userType] },
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (obj, field) => {
        const checkError = { ...errors };

        if (checkError[`${obj}.${field}`]) {
            delete checkError[`${obj}.${field}`];
        }
        if (checkError[obj] && checkError[obj][field]) {
            delete checkError[obj][field];
        }

        setErrors({ ...checkError });
    };

    const changeInput = (e, userType) => {
        const { name, value } = e.target;
        if (name === "birthday" && value === "") {
            const value = null;
            clearErrField(userType, name);
            userDispatch({
                type: userActions.setValue,
                payload: { userType, name, value },
            });
        } else if (name === "education_class" && value === "Выберите группу") {
            const value = null;
            clearErrField(userType, name);
            userDispatch({
                type: userActions.setValue,
                payload: { userType, name, value },
            });
        } else {
            clearErrField(userType, name);
            userDispatch({
                type: userActions.setValue,
                payload: { userType, name, value },
            });
        }
    };

    const uploadImage = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        const avatar = e.target.files[0];
        if (avatar.size <= SIZE_LIMIT) {
            reader.onload = () => {
                setImage(reader.result);
                setAvatarName(avatar.name);
            };
            reader.readAsDataURL(files[0]);
        } else {
            toast.success("Размер файла не должен превышать 10 МБ", options);
        }
    };

    const getCropData = (e, userType) => {
        e.preventDefault();
        setButtonStatus(true);
        setDeleteAvatar(false);
        seCropperModalOpen(false);

        if (typeof cropper !== "undefined") {
            setImage(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
            fetch(cropper.getCroppedCanvas().toDataURL("image/jpeg"))
                .then((res) => res.blob())
                .then((file) =>
                    userDispatch({
                        type: userActions.setValue,
                        payload: { userType, name: "avatar", value: file },
                    }),
                );
        }
    };

    const options = {
        style: { width: "400px" },
    };

    const deleteImage = (userType) => {
        imageElement.value = "";
        setImage(null);
        setAvatarName(null);
        setButtonStatus(false);
        setDeleteAvatar(true);
        seCropperModalOpen(false);

        userDispatch({
            type: userActions.deletePhoto,
            payload: { userType, name: user.student.avatar, value: "" },
        });
    };

    const prepareData = () => {
        const prepareStudent = {
            ...compareData(user.student, studentInfo.student),
        };
        const prepareAdult = { ...compareData(user.adult, studentInfo.adult) };

        return {
            student: { ...prepareStudent },
            adult: { ...prepareAdult },
        };
    };

    const checkForExitModal = () => {
        const prepareStudent = {
            ...compareData(user.student, studentInfo.student),
        };
        const prepareAdult = { ...compareData(user.adult, studentInfo.adult) };
        if (Object.keys(prepareStudent).length === 0 && Object.keys(prepareAdult).length === 0) {
            return false;
        } else {
            return true;
        }
    };

    let locationLoadTimeout;
    const [modeChangeLocation, setModeChangeLocation] = useState(false);

    const locationLoadOptions = (inputValue, callback) => {
        clearTimeout(locationLoadTimeout);
        locationLoadTimeout = setTimeout(() => {
            searchCity.searchCity(inputValue).then((response) => {
                const options = response.data.items.map((item) => ({
                    value: item,
                    label: item.name,
                }));
                callback(options);
            });
        }, 1000);
    };

    const handleAdminChange = (e) => {
        const { name, value } = e.target;
        clearErrField("teacher", name);
        setStudentInfo({ ...studentInfo, [name]: value });
    };

    const locationSelectOnChange = (selectedItem) => {
        const { data } = selectedItem.value;

        searchTimezone.searchTimezone(data.city, data.geo_lat, data.geo_lon).then((response) => {
            const timezone = response.data.items.pop();
            if (timezone) {
                user.adult.timezone = timezone;
                user.adult.timezone_id = timezone.id;
                const location = [data.city_type, data.city].join(" ");

                // handleAdultChange({ target: { name: 'location', value: location } });
                user.adult.location = location;

                user.student.timezone = timezone;
                user.student.timezone_id = timezone.id;

                user.timezone_id = timezone.id;
                user.timezone = timezone;
                user.location = location;
                handleAdminChange({ target: { name: "timezone_id", value: timezone.id } });
            }
        });

        setModeChangeLocation(false);
    };

    const submitButton = async () => {
        setLoading(true);

        const validateErrors = await validation.clientValidateForm({
            student: { ...user.student },
            adult: { ...user.adult },
        });

        if (validateErrors) {
            setErrors({ ...validateErrors });
            setLoading(false);
        } else {
            setErrors({});
            studentApi
                .editStudent(prepareData(), id, deleteAvatar, avatarName)
                .then(() => {
                    setLoading(false);
                    setErrors({});
                    setUserListTabs("tab2");
                    history.push(paths.userDetail(id));
                    toast.success("Пользователь успешно отредактирован", options);
                })
                .catch((error) => {
                    const { student, adult } = error.data.error.advanced;
                    setErrors({ student, adult });
                    setLoading(false);
                });
        }
    };

    const backButtonClick = () => {
        if (checkForExitModal()) {
            setIsCloseModalOpen(true);
        } else {
            history.push(paths.userDetail(id));
        }
    };

    const textArea = document.getElementById("other_divergency");
    textArea?.addEventListener("keyup", function () {
        if (this.scrollTop > 0) {
            this.style.height = this.scrollHeight + "px";
        }
    });

    const defaultTimezone = useMemo(() => {
        if (user?.student?.timezone) {
            return `${user.student.timezone.identifier} ${user.student.timezone.offset}`;
        }
        if (studentInfo?.student?.timezone) {
            return `${studentInfo.student.timezone.identifier} ${studentInfo.student.timezone.offset}`;
        }
        return null;
    }, [studentInfo?.student?.timezone, user?.student?.timezone]);

    const defaultLocation = useMemo(() => {
        if (user?.adult?.location) {
            return user?.adult?.location;
        }
        if (studentInfo?.adult?.location) {
            return studentInfo?.adult?.location;
        }
        return null;
    }, [studentInfo?.adult?.location, user?.adult?.location]);

    return (
        <div>
            <>
                {isPreloader ? (
                    <div>
                        <div className={style.wrapper}>
                            <Button
                                className={style.btn_link}
                                color="link"
                                onClick={backButtonClick}>
                                <ArrowBack />
                                <p className={style.btn_link_text}>Назад</p>
                            </Button>

                            <div className={style.listHeader}>
                                <p className={style.listTopTxt}>Редактировать пользователя</p>
                            </div>
                        </div>
                        <div>
                            <Form>
                                <Row>
                                    <Col md={5}>
                                        <p className={style.title}>Ученик </p>
                                        <FormGroup>
                                            <Label for="firstName">Имя ученика: *</Label>
                                            <Input
                                                type="text"
                                                id="firstName"
                                                name={formNames.first_name}
                                                value={user?.student?.first_name}
                                                onChange={(e) => changeInput(e, userTypes.student)}
                                                invalid={!!errors["student.first_name"]}
                                                onBlur={(e) => onBlur(e, userTypes.student)}
                                            />
                                            <FormFeedback>
                                                {errors["student.first_name"]}
                                            </FormFeedback>
                                            <FormText color="muted">
                                                Введите от 2 до 150 символов
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <p className={style.title}>Взрослый </p>
                                        <FormGroup>
                                            <Label for="fio">ФИО родителя: *</Label>
                                            <Input
                                                type="text"
                                                id="fio"
                                                name={formNames.fio}
                                                value={user?.adult?.fio}
                                                onChange={(e) => changeInput(e, userTypes.adult)}
                                                invalid={!!errors["adult.fio"]}
                                                onBlur={(e) => onBlur(e, userTypes.adult)}
                                            />
                                            <FormFeedback>{errors["adult.fio"]}</FormFeedback>
                                            <FormText color="muted">
                                                Введите от 2 до 150 символов
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="lastName">Фамилия ученика: *</Label>
                                            <Input
                                                type="text"
                                                id="lastName"
                                                name={formNames.last_name}
                                                value={user?.student?.last_name}
                                                onChange={(e) => changeInput(e, userTypes.student)}
                                                invalid={!!errors["student.last_name"]}
                                                onBlur={(e) => onBlur(e, userTypes.student)}
                                            />
                                            <FormFeedback>
                                                {errors["student.last_name"]}
                                            </FormFeedback>
                                            <FormText color="muted">
                                                Введите от 2 до 150 символов
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="birthday">Дата рождения:</Label>
                                            <Input
                                                type="date"
                                                max="9999-12-31"
                                                id="birthday"
                                                placeholder="01.02.2003"
                                                name={formNames.birthdayAdult}
                                                value={
                                                    user?.adult?.birthday
                                                        ? moment(user?.adult?.birthday).format(
                                                              "YYYY-MM-DD",
                                                          )
                                                        : null
                                                }
                                                onChange={(e) => changeInput(e, userTypes.adult)}
                                            />
                                            <FormFeedback>
                                                {errors["adult.birthday"] ||
                                                    errors?.adult?.birthday}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="birthday">Дата рождения: * </Label>
                                            <Input
                                                type="date"
                                                max="9999-12-31"
                                                id="birthday"
                                                name={formNames.birthdayStudent}
                                                value={moment(user?.student?.birthday).format(
                                                    "YYYY-MM-DD",
                                                )}
                                                onChange={(e) => changeInput(e, userTypes.student)}
                                                invalid={
                                                    !!errors["student.birthday"] ||
                                                    !!errors?.student?.birthday
                                                }
                                                onBlur={(e) => onBlur(e, userTypes.student)}
                                            />
                                            <FormFeedback>
                                                {errors["student.birthday"] ||
                                                    errors?.student?.birthday}
                                            </FormFeedback>
                                            <div className={style.studentAge}>
                                                Возраст:{" "}
                                                {age.detectedAgeFromDate(user?.student?.birthday)}
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="role">Роль: *</Label>
                                            <Input
                                                type="select"
                                                id="role"
                                                name={formNames.role}
                                                value={user?.adult?.role}
                                                onChange={(e) => changeInput(e, userTypes.adult)}
                                                invalid={!!errors["adult.role"]}
                                                onBlur={(e) => onBlur(e, userTypes.adult)}>
                                                <option value="" selected disabled hidden>
                                                    Выберите роль
                                                </option>
                                                {adultRole?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Input>
                                            <FormFeedback>{errors["adult.role"]}</FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="divergency">Особенность: *</Label>
                                            <Input
                                                type="select"
                                                id="divergency"
                                                name={formNames.divergency}
                                                value={user?.student?.divergency}
                                                onChange={(e) => changeInput(e, userTypes.student)}
                                                invalid={
                                                    !!errors["student.divergency"] ||
                                                    !!errors?.student?.divergency
                                                }
                                                onBlur={(e) => onBlur(e, userTypes.student)}>
                                                <option value="" selected disabled hidden>
                                                    Выберите особенность
                                                </option>
                                                {divergencies?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </Input>
                                            <FormFeedback>
                                                {errors["student.divergency"] ||
                                                    errors?.student?.divergency}
                                            </FormFeedback>
                                        </FormGroup>
                                        {(user?.student?.divergency?.id == 7 ||
                                            user?.student?.divergency == 7) && (
                                            <InputGroup className={style.otherDivergency}>
                                                <Input
                                                    type="textarea"
                                                    id="other_divergency"
                                                    name={formNames.other_divergency}
                                                    value={user?.student?.other_divergency}
                                                    placeholder="Опишите вашу особенность"
                                                    onChange={(e) =>
                                                        changeInput(e, userTypes.student)
                                                    }
                                                    invalid={!!errors["student.other_divergency"]}
                                                    onBlur={(e) => onBlur(e, userTypes.student)}
                                                    autoComplete="off"
                                                    className={style.otherDivergency_input}
                                                />
                                                <FormFeedback>
                                                    {errors["student.other_divergency"]}
                                                </FormFeedback>
                                                <FormText color="muted">
                                                    Введите от 2 до 200 символов
                                                </FormText>
                                            </InputGroup>
                                        )}
                                    </Col>

                                    <Col md={5}>
                                        <FormGroup>
                                            <Label htmlFor="timezone">Место проживания: *</Label>
                                            {modeChangeLocation && (
                                                <div>
                                                    <AsyncSelect
                                                        placeholder=""
                                                        loadOptions={locationLoadOptions}
                                                        noOptionsMessage={() =>
                                                            "Начните вводить название города"
                                                        }
                                                        loadingMessage={() => "Загружается..."}
                                                        onChange={locationSelectOnChange}
                                                        onBlur={() => setModeChangeLocation(false)}
                                                        autoFocus
                                                        cacheOptions
                                                    />
                                                    <FormText>
                                                        Введите название города и выберите из списка
                                                    </FormText>
                                                    <br />
                                                </div>
                                            )}
                                            {!modeChangeLocation && (
                                                <div>
                                                    <Input
                                                        id="timezone"
                                                        name="timezone"
                                                        value={defaultLocation}
                                                        onFocus={() => setModeChangeLocation(true)}
                                                        className={style.passwordInput}
                                                        placeholder="Введите страну и город"
                                                        invalid={
                                                            !!errors["teacher.timezone_id"] ||
                                                            !!errors?.teacher?.timezone_id
                                                        }
                                                    />
                                                    {defaultTimezone && (
                                                        <FormText>
                                                            Часовой пояс: {defaultTimezone}
                                                        </FormText>
                                                    )}
                                                </div>
                                            )}
                                            <FormFeedback
                                                className={
                                                    (!!errors["teacher.timezone_id"] ||
                                                        !!errors?.teacher?.timezone_id) &&
                                                    "d-block"
                                                }>
                                                {errors["teacher.timezone_id"] ||
                                                    errors?.teacher?.timezone_id}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="educationClass">Группа обучения:</Label>
                                            <FormSelect
                                                type="text"
                                                id="educationClass"
                                                name={formNames.education_class}
                                                value={user?.student?.education_class}
                                                onChange={(e) => changeInput(e, userTypes.student)}>
                                                <option>Выберите группу</option>
                                                {studentClasses?.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </FormSelect>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="phone">Телефон:</Label>
                                            <Input
                                                className={style.raz}
                                                type="tel"
                                                id="phone"
                                                name={formNames.phone}
                                                value={user?.student?.phone}
                                                isValid={() =>
                                                    !errors["student.phone"] ||
                                                    !errors?.student?.phone
                                                }
                                                onChange={(e) => changeInput(e, userTypes.student)}
                                            />
                                            <FormFeedback
                                                className={
                                                    (!!errors["student.phone"] ||
                                                        !!errors?.student?.phone) &&
                                                    "d-block"
                                                }>
                                                {errors["student.phone"] || errors?.student?.phone}
                                            </FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Почта:*</Label>
                                            <Input
                                                type="text"
                                                id="email"
                                                name={formNames.email}
                                                disabled={user?.student?.email}
                                                value={user?.student?.email}
                                                onChange={(e) => changeInput(e, userTypes.student)}
                                                invalid={!!errors["student.email"]}
                                                onBlur={(e) => onBlur(e, userTypes.student)}
                                            />
                                            <FormFeedback>{errors["student.email"]}</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="firstFile">Фото</Label>

                                            <div className={style.blockPhoto}>
                                                <input
                                                    id="avatar"
                                                    type="file"
                                                    onChange={uploadImage}
                                                    style={{ display: "none" }}
                                                    accept="image/*"
                                                />
                                            </div>
                                            {buttonStatus ? (
                                                <>
                                                    <div className={style.inputImgWrapper}>
                                                        <img
                                                            className={style.inputImgWrapperImg}
                                                            src={imgIcon}
                                                            alt="icon"
                                                        />
                                                        <p className={style.inputImgWrapperTxt}>
                                                            {avatarName}
                                                        </p>
                                                        <Button
                                                            className={style.inputImgWrapperBtn}
                                                            outline
                                                            color="inherit"
                                                            onClick={(e) =>
                                                                deleteImage(e, userTypes.student)
                                                            }>
                                                            <img src={deleteIcon} alt="icon" />
                                                        </Button>
                                                    </div>
                                                    <img
                                                        className={style.croppedPhoto}
                                                        src={image}
                                                        alt=""
                                                    />
                                                </>
                                            ) : (
                                                <Button
                                                    color="inherit"
                                                    className={style.fileLable}
                                                    onClick={() => seCropperModalOpen(true)}>
                                                    <img
                                                        className={style.addImageButton}
                                                        color="inherit"
                                                        src={downloadLogo}
                                                        alt=""
                                                    />
                                                    Прикрепить изображение
                                                </Button>
                                            )}

                                            <FormText color="muted">
                                                Размер изображения не должен превышать 10мб
                                            </FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        {loading ? (
                            <Button
                                disabled
                                color="primary"
                                className={style.createLesson}
                                onClick={() => submitButton()}>
                                <div
                                    className={`spinner-border text-light ${style.loading}`}
                                    role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                className={style.createLesson}
                                onClick={() => submitButton()}>
                                Сохранить
                            </Button>
                        )}
                        {isCloseModalOpen && <LeavePage page={paths.usersList} />}
                    </div>
                ) : (
                    <Preloader />
                )}
            </>

            {cropperModalOpen && (
                <CropperModal
                    image={image}
                    setCropper={setCropper}
                    getCropData={getCropData}
                    userTypes={userTypes}
                    cancelClick={deleteImage}
                    lableFor={"avatar"}
                    croppSize={10}
                    hint="Выберите область, которая будет отображаться в вашем профиле"
                    ratio={1}
                />
            )}
        </div>
    );
}

export default observer(EditStudent);

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { Input, Button, Form, FormGroup, Label, FormText, FormFeedback } from 'reactstrap';

import createTeacher from '../../../../api/endPoints/users';
import paths from '../../../../constants/paths';
import * as style from './style/AddTeacher.module.scss';
import downloadLogo from '../../../../assets/img/downloadLogo.svg';
import imgIcon from '../../../../assets/img/imgIcon.svg';
import deleteIcon from '../../../../assets/img/deleteIcon.svg';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { toast } from 'react-toastify';
import validationTeacher from './utils/validationTeacher';
import { compareData } from 'utils/compareData';
import CropperModal from '../../../Popup/CropperModal';

function AddTeacher({ setTeacherData }) {
  const {
    userStore: { setUserListTabs, setOpenModalLeaveOpen },
    modalWindowStore: { cropperModalOpen, seCropperModalOpen },
  } = useStore();
  const history = useHistory();
  const [userTeacher, setUserTeacher] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    profession_info: '',
    education_info: '',
    avatar: null,
  });

  const [isSubmitBtnPressed, setIsSubmitBtnPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCropperButtonPressed, setIsCropperButtonPressed] = useState(false);

  const [image, setImage] = useState();
  const [cropper, setCropper] = useState();
  const [buttonStatus, setButtonStatus] = useState(false);
  const imageElement = document.getElementById('avatar');
  const [cropPhoto, setCropPhoto] = useState();
  const [errors, setErrors] = useState({});
  const [avatarName, setAvatarName] = useState('');

  const SIZE_LIMIT = 10485760;

  const uploadPhoto = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    const avatar = e.target.files[0];
    if (avatar.size <= SIZE_LIMIT) {
      reader.onload = () => {
        setImage(reader.result);
        setAvatarName(avatar.name);
      };
      reader.readAsDataURL(files[0]);
    } else {
      toast.success('Размер файла не должен превышать 10 МБ', options);
    }
  };

  const getCropData = (e) => {
    e.preventDefault();
    setButtonStatus(true);
    setIsCropperButtonPressed(true);
    seCropperModalOpen(false);

    if (typeof cropper !== 'undefined') {
      setCropPhoto(cropper.getCroppedCanvas().toDataURL());
      fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((file) => {
          setUserTeacher({ ...userTeacher, avatar: file });
        });
    }
  };

  const formNames = {
    first_name: 'first_name',
    last_name: 'last_name',
    middle_name: 'middle_name',
    email: 'email',
    profession_info: 'profession_info',
    education_info: 'education_info',
    avatar: 'avatar',
  };

  const onBlur = async (e) => {
    const validationErrors = await validationTeacher.clientValidateField(e.target.name, {
      ...userTeacher,
    });

    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const clearErrField = (field) => {
    const checkError = { ...errors };

    if (checkError[field]) {
      delete checkError[field];
    }
    setErrors({ ...checkError });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    clearErrField(name);

    setUserTeacher({ ...userTeacher, [name]: value });
    setTeacherData({ ...userTeacher, [name]: value });
  };

  const submitButton = async () => {
    setIsSubmitBtnPressed(true);
    setLoading(true);

    const validateErrors = await validationTeacher.clientValidateForm({ ...userTeacher });

    if (validateErrors) {
      setErrors({ ...validateErrors });
      setLoading(false);
    } else {
      setErrors({});
      createTeacher
        .createTeacher(userTeacher, avatarName)
        .then(() => {
          setLoading(false);
          setErrors({});
          setUserListTabs('tab1');
          toast.success('Пользователь успешно создан', options);
          history.push(paths.usersList);
        })
        .catch((error) => {
          setErrors({ ...errors, ...error.data.error.advanced });
          setLoading(false);
        });
    }
  };

  const options = {
    style: { width: '400px' },
  };

  const deleteImage = () => {
    imageElement.value = '';
    setImage(null);
    setAvatarName(null);
    setCropPhoto(null);
    setButtonStatus(false);
    setIsCropperButtonPressed(false);
    seCropperModalOpen(false);
    setUserTeacher({ ...userTeacher, avatar: null });
  };

  return (
    <div>
      <Form className={style.addTeacherFields}>
        <FormGroup>
          <Label for="firstName">Имя: *</Label>
          <Input
            type="text"
            id="firstName"
            name={formNames.first_name}
            value={userTeacher.first_name}
            onChange={handleChange}
            invalid={!!errors.first_name}
            onBlur={(e) => onBlur(e, userTeacher.first_name)}
            placeholder="Введите имя"
          />
          <FormFeedback>{errors.first_name}</FormFeedback>
          <FormText color="muted">Введите от 2 до 150 символов</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Фамилия: *</Label>
          <Input
            type="text"
            id="lastName"
            name={formNames.last_name}
            value={userTeacher.last_name}
            onChange={handleChange}
            placeholder="Введите фамилию"
            invalid={!!errors.last_name}
            onBlur={(e) => onBlur(e, userTeacher.last_name)}
          />
          <FormFeedback>{errors.last_name}</FormFeedback>
          <FormText color="muted">Введите от 2 до 150 символов</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="middleName">Отчество: *</Label>
          <Input
            type="text"
            id="middleName"
            name={formNames.middle_name}
            value={userTeacher.middle_name}
            onChange={handleChange}
            placeholder="Введите отчество"
            invalid={!!errors.middle_name}
            onBlur={(e) => onBlur(e, userTeacher.middle_name)}
          />
          <FormFeedback>{errors.middle_name}</FormFeedback>
          <FormText color="muted">Введите от 2 до 150 символов</FormText>
        </FormGroup>
        <FormGroup>
          <Label for="email">Почта: *</Label>
          <Input
            type="text"
            id="email"
            name={formNames.email}
            value={userTeacher.email}
            onChange={handleChange}
            placeholder="Введите почту"
            invalid={!!errors.email}
            onBlur={(e) => onBlur(e, userTeacher.email)}
          />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="professionInfo">Проф. информация (должность, стаж, специализация): *</Label>
          <Input
            type="textarea"
            className={style.textArea}
            id="professionInfo"
            name={formNames.profession_info}
            value={userTeacher.profession_info}
            onChange={handleChange}
            placeholder="Введите информацию"
            invalid={!!errors.profession_info}
            onBlur={(e) => onBlur(e, userTeacher.profession_info)}
          />
          <FormFeedback>{errors.profession_info}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="educationInfo">Образование: *</Label>
          <Input
            type="textarea"
            className={style.textArea}
            id="educationInfo"
            name={formNames.education_info}
            value={userTeacher.education_info}
            onChange={handleChange}
            placeholder="Введите информацию"
            invalid={!!errors.education_info}
            onBlur={(e) => onBlur(e, userTeacher.education_info)}
          />
          <FormFeedback>{errors.education_info}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Фото:</Label>
          {buttonStatus ? (
            <>
              <div className={style.inputImgWrapper}>
                <img className={style.inputImgWrapperImg} src={imgIcon} alt="icon" />
                <p className={style.inputImgWrapperTxt}>{avatarName}</p>
                <Button
                  className={style.inputImgWrapperBtn}
                  outline
                  color="inherit"
                  name="firstImgDeleteBtn"
                  onClick={() => deleteImage()}>
                  <img src={deleteIcon} alt="icon" />
                </Button>
              </div>
              <img src={cropPhoto} alt="" className={style.inputImage} />
            </>
          ) : (
            <Button
              color="inherit"
              className={style.fileLable}
              onClick={() => seCropperModalOpen(true)}>
              <img className={style.addImageButton} color="inherit" src={downloadLogo} alt="" />
              Прикрепить изображение
            </Button>
          )}
          <div className={style.blockPhoto}>
            <input
              id="avatar"
              type="file"
              onChange={uploadPhoto}
              style={{ display: 'none' }}
              accept="image/*"
            />
          </div>
          <Label className={style.inputImgWrapperLable}>
            Размер файла не должен превышать 10 МБ
          </Label>
        </FormGroup>
      </Form>

      {loading ? (
        <Button
          disabled
          color="primary"
          className={style.createLesson}
          onClick={() => submitButton()}>
          <div className={`spinner-border text-light ${style.loading}`} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </Button>
      ) : (
        <Button color="primary" className={style.createLesson} onClick={() => submitButton()}>
          Добавить
        </Button>
      )}

      {cropperModalOpen && (
        <CropperModal
          image={image}
          setCropper={setCropper}
          getCropData={getCropData}
          cancelClick={deleteImage}
          lableFor={"avatar"}
          croppSize={10}
          hint="Выберите область, которая будет отображаться в вашем профиле"
          ratio={1}
        />
      )}
    </div>
  );
}

export default observer(AddTeacher);

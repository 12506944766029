import { number, object, string, addMethod } from "yup";
import yup from "yup";

const rulesMessage = {
    min: (number, field) => `В поле "${field}" не должно быть меньше ${number} символов`,
    max: (number, field) => `В поле "${field}" не должно быть больше ${number} символов`,
    required: "Поле обязательно для заполнения",
    email: 'Поле "Почта" введено некорректно',
};

addMethod(string, "stripEmptyString", function () {
    return this.transform((value) => (value === "" ? null : value));
});

const schemaLesson = object({
    name: string()
        .min(2, rulesMessage.min(2, "Тема урока"))
        .max(100, rulesMessage.max(100, "Тема урока"))
        .nullable()
        .required(rulesMessage.required),
    start_at: string().nullable().required(rulesMessage.required),
    button_1_name: string()
        .stripEmptyString()
        .nullable(true)
        .min(2, rulesMessage.min(2, "Название кнопки"))
        .max(50, rulesMessage.max(50, "Название кнопки")),
    button_2_name: string()
        .stripEmptyString()
        .nullable(true)
        .min(2, rulesMessage.min(2, "Название кнопки"))
        .max(50, rulesMessage.max(50, "Название кнопки")),
    teacher_id: number()
        .typeError(rulesMessage.required)
        .nullable()
        .required(rulesMessage.required),
});

async function clientValidateForm(form) {
    let errors;

    try {
        await schemaLesson.validate(form, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

async function clientValidateField(field, value) {
    let errors;
    try {
        await schemaLesson.validateAt(field, value, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

export default { clientValidateForm, clientValidateField };

import React, { useEffect, useState } from "react";
import { useStore } from "store";
import { Button } from "reactstrap";

import plusIcon from "../../../assets/img/plusIcon.svg";
import * as style from "./style/FoldersComponent.module.scss";
import Pagination from "../../Pagination/Pagination";
import Menu from "../../Menu/Menu";
import getFolders from "../../../api/endPoints/folders";
import history from "../../../utils/history/history";
import paths from "../../../constants/paths";
import AddFolder from "./AddFolder/AddFolder";
import DeleteFolder from "./DeleteFolder/DeleteFolder";

import { observer } from "mobx-react-lite";
import Preloader from "components/Preloader/Preloader";

import ResourcesComponent from "../ResourcesComponent/ResourcesComponent";
import EditFolder from "./EditFolder/EditFolder";
import { useRef } from "react";

function FoldersComponent() {
  const [foldersList, setFoldersList] = useState();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [resources, setResources] = useState();
  const [newFolderId, setNewFolderId] = useState(null);
  const ref = useRef();

  const {
    modalWindowStore: {
      setAddFolder,
      addFolderOpen,
      setDeleteFolder,
      deleteFolderOpen,
      setEditFolder,
      editFolderOpen,
    },
  } = useStore();

  useEffect(() => {
    setLoading(true);
    getFolders.getFolders(page, newFolderId).then((response) => {
      setFoldersList(response.items);
      setLastPage(response.last_page);
      setPaginationLoading(false);
      setNewFolderId(null);
      setLoading(false);
      if (ref.current && page > 1) {
        ref.current.scrollIntoView();
      }
      if (response.current_page > response.last_page) {
        setPage(response.last_page);
      }
    });
  }, [page, modalLoading]);

  const addFolder = () => {
    setModalLoading(true);
  };

  const deleteFolder = () => {
    setModalLoading(true);
  };

  const editFolder = () => {
    setModalLoading(true);
  };

  const prevPage = (number) => {
    setPaginationLoading(true);
    setPage(page - number);
    setNewFolderId(null);
  };

  const nextPage = (number) => {
    setPaginationLoading(true);
    setPage(page + number);
    setNewFolderId(null);
  };

  const lastPageFunction = (number) => {
    setPaginationLoading(true);
    setPage(number);
    setNewFolderId(null);
  };

  return (
    <>
      {foldersList ? (
        <div ref={ref}>
          <p>Папки</p>
          <div>
            <div className={style.training_program_blocks}>
              <div className={style.training_program_blocks__block}>
                <div className={style.blocks__titles}>
                  <h3
                    className={`title-h3 ${style.training_program_blocks__title}`}
                  >
                    <Button
                      color="inherit"
                      className={style.addNewFolder}
                      onClick={() => setAddFolder(true)}
                    >
                      <img
                        className={style.plusIcon}
                        color="inherit"
                        src={plusIcon}
                        alt=""
                      />
                      Добавить папку
                    </Button>
                  </h3>
                </div>
              </div>

              {paginationLoading || loading ? (
                <div className={`row ${style.lessonsPreloader}`}>
                  <Preloader />
                </div>
              ) : (
                <>
                  {foldersList &&
                    foldersList.map((item) => (
                      <div
                        key={item.id}
                        className={style.training_program_blocks__link}
                        title={item.name}
                      >
                        <div className={style.training_program_blocks__block}>
                          <div
                            className={style.blocks__titles}
                            onClick={() =>
                              history.push(paths.folderDetail(item.id))
                            }
                          >
                            <p
                              title={item.name}
                              className={`title-h3 ${style.training_program_blocks__title}`}
                            >
                              {item.name}
                            </p>
                          </div>
                          <div className={style.menuContainer}>
                            <Menu key={item.id}>
                              <Button
                                color="inherit"
                                className={style.menuButton}
                                onClick={() => setEditFolder(true, item)}
                              >
                                Редактировать
                              </Button>
                              <Button
                                color="inherit"
                                className={style.menuButton}
                                onClick={() => setDeleteFolder(true, item)}
                              >
                                Удалить
                              </Button>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
          <div className={style.foldersPagination}>
            <Pagination
              page={page}
              nextPage={nextPage}
              prevPage={prevPage}
              lastPage={lastPage}
              lastPageFunction={lastPageFunction}
            />
          </div>

          {addFolderOpen && (
            <AddFolder
              addFolder={addFolder}
              loading={modalLoading}
              setModalLoading={setModalLoading}
              setNewFolderId={setNewFolderId}
            />
          )}

          {deleteFolderOpen && (
            <DeleteFolder
              deleteFolder={deleteFolder}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}

          {editFolderOpen && (
            <EditFolder
              editFolder={editFolder}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}
        </div>
      ) : (
        <div className="row">
          <Preloader />
        </div>
      )}
      <hr />
      <ResourcesComponent resources={resources} />
    </>
  );
}

export default observer(FoldersComponent);

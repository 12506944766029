import React from "react";
import styles from "./styles.module.scss";

const View = (props) => {
    const { time } = props;

    return (
        <td className={styles.time}>
            <div className={styles.time__hour}>{time}</div>
        </td>
    );
};

export default View;

import React from "react";
import { Button } from "reactstrap";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import * as style from "./style/LeavePage.module.scss";
import { adultDefault, studentDefault } from "constants/defaultCreateField";

function ChangeCreateUsersTabs({setTeacherData,setCreateStudentData}) {
  const {
    modalWindowStore: { setIsChangeTabsModal, isChangeTabsModal },
    userStore: { setCreateUserTabs, setOpenModalLeaveOpen },
  } = useStore();

  const leaveSubmit = () => {
    setCreateUserTabs(isChangeTabsModal.page);
    setOpenModalLeaveOpen(false);
    setIsChangeTabsModal(false);
    setTeacherData({});
    setCreateStudentData({
      student: studentDefault,
      adult: adultDefault,
    })
  };

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.title}>
              Вы уверены, что хотите
              <br /> покинуть страницу
              <br /> без сохранения?
            </p>
          </div>
          <p className={style.leavePageTxt}>
            Все заполненные данные будут стерты без возможности восстановления
          </p>
          <div className={style.btnWrapper}>
            <Button
              className={style.leaveBtn}
              outline
              color="primary"
              onClick={leaveSubmit}
            >
              Покинуть
            </Button>
            <Button
              className={style.stayBtn}
              color="primary"
              onClick={() => setIsChangeTabsModal(false)}
            >
              Отменить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ChangeCreateUsersTabs);

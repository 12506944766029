import React from "react";
import getEventList from "../../../api/endPoints/educationEvents";
import getEventTimeList from "../../../api/endPoints/educationEvents";
import createEducationEvent from "../../../api/endPoints/educationEvents";
import editEducationEvent from "../../../api/endPoints/educationEvents";
import getSubjectsForSelect from "../../../api/endPoints/productsEndpoint";
import getStudentClasses from "../../../api/endPoints/profileEndpoint";
import getStudentsFilter from "../../../api/endPoints/users";
import { formatUserName, getTimeZone } from "../helpers";
import { toast } from "react-toastify";
import { useStore } from "store";
import Controller from "./Controller.jsx";

const Connector = (props) => {
    const { onClose, isUpdate, title, buttonText } = props;

    const {
        createLessonStore: { type_id, setTypeId, lesson_id },
    } = useStore();

    const formatItemNameId = (itemList) =>
        itemList.map((item) => ({ name: item.name, selectedCode: item.id }));

    const formatTime = (itemList) =>
        itemList.map((item) => ({
            name: item.local_time,
            selectedCode: item.time,
        }));

    const getType = (setAll, setCurrent, setSelect, setNoSelect, setLoading) => {
        getEventList
            .getEventList()
            .then((response) => {
                const result = formatItemNameId(response);
                setAll(result);
                setCurrent(result);
                if (type_id) {
                    setSelect(result.filter((item) => item.selectedCode === type_id));
                    setNoSelect(result.filter((item) => item.selectedCode !== type_id));
                    setTypeId(null);
                }
            })
            .finally(() => setLoading(false));
    };

    const getClass = (setAll, setCurrent, setLoading) => {
        getStudentClasses
            .getStudentClasses()
            .then((response) => {
                const result = formatItemNameId(response);
                setAll(result);
                setCurrent(result);
            })
            .finally(() => setLoading(false));
    };

    const getSubject = (search, page, setAll, setTotal, setLoading) => {
        getSubjectsForSelect
            .getSubjectsForSelect({ name: search }, page)
            .then((response) => {
                setAll([
                    ...response.items.map((el) => ({
                        name: el.name,
                        selectedCode: el.id,
                    })),
                ]);
                setTotal(response.total);
            })
            .finally(() => setLoading(false));
    };

    const getSubjectAdd = (search, page, setAll, setTotal, setLoading, allList) => {
        getSubjectsForSelect
            .getSubjectsForSelect({ name: search }, page)
            .then((response) => {
                setAll([
                    ...allList,
                    ...response.items.map((el) => ({
                        name: el.name,
                        selectedCode: el.id,
                    })),
                ]);
                setTotal(response.total);
            })
            .finally(() => setLoading(false));
    };

    const getStudent = (search, page, setAll, setTotal, setLoading) => {
        getStudentsFilter
            .getStudentsFilter({ name: search }, page)
            .then((response) => {
                setAll([
                    ...response.items.map((el) => ({
                        name: formatUserName(el?.user),
                        selectedCode: el?.user?.id,
                    })),
                ]);
                setTotal(response.total);
            })
            .finally(() => setLoading(false));
    };

    const getStudentAdd = (search, page, setAll, setTotal, setLoading, allSelect) => {
        getStudentsFilter
            .getStudentsFilter({ name: search }, page)
            .then((response) => {
                setAll([
                    ...allSelect,
                    ...response.items.map((el) => ({
                        name: formatUserName(el?.user),
                        selectedCode: el?.user?.id,
                    })),
                ]);
                setTotal(response.total);
            })
            .finally(() => setLoading(false));
    };

    const getTime = (
        student,
        teacher_id,
        start_at,
        type_id,
        setAll,
        setSelect,
        setCurrent,
        setLoading,
        selectStartTime,
    ) => {
        getEventTimeList
            .getEventTimeList({
                students: student,
                teacher_id: teacher_id,
                start_date: start_at,
                timezone: getTimeZone(),
                event_type_id: type_id,
            })
            .then((response) => {
                setAll(formatTime(response));
                setSelect(formatTime(response).filter((item) => item.name === selectStartTime));
                setCurrent(formatTime(response).filter((item) => item.name !== selectStartTime));
            })
            .finally(() => setLoading(false));
    };

    const createEvent = (data, setErrors) => {
        if (isUpdate) {
            editEducationEvent
                .editEducationEvent(lesson_id, data)
                .then(() => {
                    onClose();
                    window.location.reload();
                    toast.info("Событие обновлено");
                })
                .catch((error) => setErrors({ ...error.data.error.advanced }));
        } else {
            createEducationEvent
                .createEducationEvent(data)
                .then(() => {
                    onClose();
                    window.location.reload();
                    toast.info("Событие создано");
                })
                .catch((error) => setErrors({ ...error.data.error.advanced }));
        }
    };

    return (
        <Controller
            title={title}
            buttonText={buttonText}
            createEvent={createEvent}
            onClose={onClose}
            getType={getType}
            getClass={getClass}
            getSubject={getSubject}
            getSubjectAdd={getSubjectAdd}
            getStudent={getStudent}
            getStudentAdd={getStudentAdd}
            getTime={getTime}
        />
    );
};

export default Connector;

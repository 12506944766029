import React, { useState } from "react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import folders from "api/endPoints/folders";
import { toast } from "react-toastify";

import * as style from "./style/DeleteFolder.module.scss";

function DeleteFolder({ setModalLoading }) {
  const {
    modalWindowStore: { setDeleteFolder, deleteFolderOpen },
  } = useStore();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const apiDeleteFolder = (id) => {
    setLoading(true);
    setModalLoading(true);
    folders
      .deleteFolder(id)
      .then(() => {
        setDeleteFolder(false);
        setModalLoading(false);
        toast.success("Папка успешно удалена");
        setLoading(false);
      })
      .catch((error) => {
        setErrors(error);
        setModalLoading(false);
        setLoading(false);
      });
  };

  return (
    <>
      {errors.status === 400 ? (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.popUpTitle}>
                  Невозможно удалить папку <br />
                  {deleteFolderOpen.item.name}
                </p>
                <p className={style.displayError}>
                  {errors?.data?.error?.message}
                </p>
              </div>
              <div className={style.displayButton}>
                <Button
                  color="primary"
                  className={style.deleteError}
                  onClick={() => setDeleteFolder(false)}
                >
                  Ок
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.popUpTitle}>
                  Вы уверены,что хотите <br />
                  удалить папку {deleteFolderOpen.item.name}?
                </p>
                <p className={style.popUpTxt}>Ресурсы и папки внутри данной папки также будут удалены.</p>
              </div>
              <div className={style.displayButton}>
                <Button
                  color="primary"
                  outline
                  className={style.deleteLesson}
                  onClick={() => setDeleteFolder(false)}
                >
                  Отмена
                </Button>

                {loading ? (
                  <Button
                    disabled
                    color="primary"
                    outline
                    className={style.deleteLesson}
                  >
                    <div
                      className={`spinner-border text-primary ${style.loading}`}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className={style.canselChange}
                    onClick={() => apiDeleteFolder(deleteFolderOpen.item.id)}
                  >
                    Удалить
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(DeleteFolder);

import React from 'react';
import * as style from './style/Pagination.module.scss';
import { ReactComponent as ArrowBack } from '../../assets/img/arrowBack.svg';

export default function Pagination({ prevPage, nextPage, page, lastPage, lastPageFunction }) {
  
  if (lastPage === 1) {
    return null;
  }

  if (lastPage === 2) {
    return (
      <div className={style.wrapper}>
        <button
          disabled={page === 1}
          style={page === 1 ? { opacity: '0.65' } : null}
          className={style.lessonsPrevPage}
          color="link"
          onClick={() => prevPage(1)}>
          <ArrowBack className={style.arrowPrev} />
          Предыдущая
        </button>
        {page === lastPage && (
          <button
            disabled={page === 1}
            className={style.lessonNextPage}
            color="link"
            onClick={() => prevPage(1)}>
            1
          </button>
        )}
        <button color="link" style={{ background: '#F5F5F5' }} className={style.lessonNextPage}>
          {page}
        </button>
        {page === 1 && (
          <button className={style.lessonNextPage} color="link" onClick={() => nextPage(1)}>
            2
          </button>
        )}
        <button
          disabled={page === lastPage}
          className={style.lessonsPrevPage}
          onClick={() => nextPage(1)}
          style={page === lastPage ? { opacity: '0.65' } : null}
          color="link">
          Следующая <ArrowBack className={style.arrowNext} />
        </button>
      </div>
    );
  }

  if (lastPage === 3) {
    return (
      <div className={style.wrapper}>
        <button
          disabled={page === 1}
          style={page === 1 ? { opacity: '0.65' } : null}
          className={style.lessonsPrevPage}
          color="link"
          onClick={() => prevPage(1)}>
          {'< '}Предыдущая
        </button>
        {page === lastPage && (
          <>
            <button
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(2)}>
              1
            </button>
            <button
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(1)}>
              2
            </button>
          </>
        )}
        {page === 2 && (
          <button className={style.lessonNextPage} color="link" onClick={() => prevPage(1)}>
            1
          </button>
        )}
        <button color="link" style={{ background: '#F5F5F5' }} className={style.lessonNextPage}>
          {page}
        </button>
        {page === 2 && (
          <button className={style.lessonNextPage} color="link" onClick={() => nextPage(1)}>
            3
          </button>
        )}
        {page === 1 && (
          <>
            <button className={style.lessonNextPage} color="link" onClick={() => nextPage(1)}>
              2
            </button>
            <button className={style.lessonNextPage} color="link" onClick={() => nextPage(2)}>
              3
            </button>
          </>
        )}
        <button
          disabled={page === lastPage}
          className={style.lessonsPrevPage}
          onClick={() => nextPage(1)}
          style={page === lastPage ? { opacity: '0.65' } : null}
          color="link">
          Следующая {'>'}
        </button>
      </div>
    );
  }

  if (lastPage === 4) {
    return (
      <div className={style.wrapper}>
        <button
          type="button"
          disabled={page === 1}
          style={page === 1 ? { opacity: '0.65' } : null}
          className={style.lessonsPrevPage}
          color="link"
          onClick={() => prevPage(1)}>
          {'< '}Предыдущая
        </button>
        {page === lastPage && (
          <>
            <button
              type="button"
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(3)}>
              1
            </button>
            <button
              type="button"
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(2)}>
              2
            </button>
            <button
              type="button"
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(1)}>
              3
            </button>
          </>
        )}
        {page === 2 && (
          <button
            type="button"
            className={style.lessonNextPage}
            color="link"
            onClick={() => prevPage(1)}>
            1
          </button>
        )}
        {page === 3 && (
          <>
            <button
              type="button"
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(2)}>
              1
            </button>
            <button
              type="button"
              disabled={page === 1}
              className={style.lessonNextPage}
              color="link"
              onClick={() => prevPage(1)}>
              2
            </button>
          </>
        )}
        <button
          type="button"
          color="link"
          style={{ background: '#F5F5F5' }}
          className={style.lessonNextPage}>
          {page}
        </button>
        {page === 3 && (
          <button
            type="button"
            className={style.lessonNextPage}
            color="link"
            onClick={() => nextPage(1)}>
            4
          </button>
        )}
        {page === 2 && (
          <>
            {' '}
            <button
              type="button"
              className={style.lessonNextPage}
              color="link"
              onClick={() => nextPage(1)}>
              3
            </button>
            <button
              type="button"
              className={style.lessonNextPage}
              color="link"
              onClick={() => nextPage(2)}>
              4
            </button>
          </>
        )}
        {page === 1 && (
          <>
            <button
              type="button"
              className={style.lessonNextPage}
              color="link"
              onClick={() => nextPage(1)}>
              2
            </button>
            <button
              type="button"
              className={style.lessonNextPage}
              color="link"
              onClick={() => nextPage(2)}>
              3
            </button>
            <button
              type="button"
              className={style.lessonNextPage}
              color="link"
              onClick={() => nextPage(3)}>
              4
            </button>
          </>
        )}
        <button
          type="button"
          disabled={page === lastPage}
          className={style.lessonsPrevPage}
          onClick={() => nextPage(1)}
          style={page === lastPage ? { opacity: '0.65' } : null}
          color="link">
          Следующая {'>'}
        </button>
      </div>
    );
  }

  return (
    <div className={style.wrapper}>
      <button
        disabled={page === 1}
        className={style.lessonsPrevPage}
        color="link"
        style={page === 1 ? { opacity: '0.65' } : null}
        onClick={() => prevPage(1)}>
        {'< '}Предыдущая
      </button>
      {page === 3 && (
        <button color="link" onClick={() => lastPageFunction(1)} className={style.lessonNextPage}>
          1
        </button>
      )}
      {page > 3 && (
        <>
          <button color="link" onClick={() => lastPageFunction(1)} className={style.lessonNextPage}>
            1
          </button>
          ...
        </>
      )}
      {page === lastPage && (
        <button color="link" onClick={() => prevPage(2)} className={style.lessonNextPage}>
          {page - 2}
        </button>
      )}
      {page !== 1 && (
        <button color="link" onClick={() => prevPage(1)} className={style.lessonNextPage}>
          {page - 1}
        </button>
      )}
      <button color="link" style={{ background: '#F5F5F5' }} className={style.lessonNextPage}>
        {page}
      </button>
      {page !== lastPage && (
        <button color="link" onClick={() => nextPage(1)} className={style.lessonNextPage}>
          {page + 1}
        </button>
      )}
      {page === 1 && (
        <button color="link" onClick={() => nextPage(2)} className={style.lessonNextPage}>
          3
        </button>
      )}
      {lastPage - page >= 3 && (
        <>
          ...
          <button
            color="link"
            onClick={() => lastPageFunction(lastPage)}
            className={style.lessonNextPage}>
            {lastPage}
          </button>
        </>
      )}
      {lastPage - 2 === page && (
        <>
          <button
            color="link"
            onClick={() => lastPageFunction(lastPage)}
            className={style.lessonNextPage}>
            {lastPage}
          </button>
        </>
      )}
      <button
        disabled={page === lastPage}
        style={page === lastPage ? { opacity: '0.65' } : null}
        className={style.lessonsPrevPage}
        onClick={() => nextPage(1)}
        color="link">
        Следующая {'>'}
      </button>
    </div>
  );
}

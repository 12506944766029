import axiosInstance from "..";
import apiList from "../../constants/apiList";

export default {
  async lessons(page) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.lessons,
        params: {
          page: page,
          per_page: "25",
        },
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },
  async lessonChat(url) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deleteLesson(id) {
    try {
      const response = await axiosInstance({
        method: "delete",
        url: apiList.deleteLesson(id),
      });
    } catch (error) {
      throw error.response;
    }
  },
};

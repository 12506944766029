import React from "react";
import File from "./File/File";
import Folder from "./Folder/Folder";
import { NODE_TYPES } from "./state/constants";

function FolderTreeRecursive(props) {
  const {
    data,
    level,
    isFirstChild,
    isLastChild,
    hasChild,
    prevFolderHasChild,
    isPrevFolderOpen,
    lastElementsList,
    setOpen,
    setChecked,
    fullData,
    radio,
    toggleChange,
    purchases,
  } = props;

  const { id, name, checked, isOpen, type, src_url, custom_id } = data ?? {};
  if (data) {
    const { foreign_type } = data;
    switch (foreign_type) {
      case NODE_TYPES.FILE:
        return (
          <File
            key={`${custom_id}`}
            id={id}
            customId={custom_id}
            path={data.path}
            level={level}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            isPrevFolderOpen={isPrevFolderOpen}
            lastElementsList={lastElementsList}
            name={name}
            checked={checked}
            setChecked={setChecked}
            fullData={fullData}
            radio={radio}
            toggleChange={toggleChange}
            purchases={purchases}
            type={type}
            foreign_type={foreign_type}
            src_url={src_url}
          />
        );
      case NODE_TYPES.FOLDER:
        return (
          <Folder
            key={`${custom_id}${type}`}
            id={id}
            customId={custom_id}
            path={data.path}
            level={level}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            isPrevFolderOpen={isPrevFolderOpen}
            hasChild={hasChild}
            prevFolderHasChild={prevFolderHasChild}
            lastElementsList={lastElementsList}
            name={name}
            foreign_type={foreign_type}
            checked={checked}
            isOpen={isOpen}
            setOpen={setOpen}
            setChecked={setChecked}
            fullData={fullData}
            children={data.children}
            radio={radio}
            toggleChange={toggleChange}
            purchases={purchases}
          >
            {data.children.map((child, index) => {
              const { id: childId } = child;
              const isFirstChildren = index === 0;
              const isLastChildren = index === data.children.length - 1;
              let isPrevFolderOpen = false;
              let hasChildren = false;
              let prevFolderHasChildren = false;
              const newLastElementsList = [...lastElementsList, isLastChildren];

              if (child.type === NODE_TYPES.FOLDER) {
                hasChildren = !!child.children.length;
              }

              if (index > 0) {
                const prevFolderTreeItem = data.children[index - 1];
                if (prevFolderTreeItem.type === NODE_TYPES.FOLDER) {
                  isPrevFolderOpen = prevFolderTreeItem.isOpen;
                  prevFolderHasChildren = !!prevFolderTreeItem.children.length;
                }
              }
              return (
                <FolderTreeRecursive
                  key={childId}
                  level={level + 1}
                  isFirstChild={isFirstChildren}
                  isLastChild={isLastChildren}
                  isInLastFolder={isLastChildren}
                  isPrevFolderOpen={isPrevFolderOpen}
                  hasChild={hasChildren}
                  prevFolderHasChild={prevFolderHasChildren}
                  lastElementsList={newLastElementsList}
                  data={child}
                  setOpen={setOpen}
                  setChecked={setChecked}
                  fullData={fullData}
                  radio={radio}
                  toggleChange={toggleChange}
                  purchases={purchases}
                />
              );
            })}
          </Folder>
        );
    }
  }

  return null;
}

export default FolderTreeRecursive;

import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useStore } from "store";
import * as style from "./style/DeletePopUp.module.scss";
import educationForms from "api/endPoints/educationForms";

function DeleteEducationForm({ loading, setLoading }) {
  const {
    modalWindowStore: { isDeleteFormModalOpen, setIsDeleteFormModalOpen },
  } = useStore();
  const deleteForm = () => {
    setLoading(true);
    educationForms
      .deleteFormForUser(isDeleteFormModalOpen.id)
      .then(() => {
        setIsDeleteFormModalOpen(false);
        setLoading(false);
        toast.info(
          `Форма обучения ${isDeleteFormModalOpen.name} больше не доступна для пользователя`
        );
      })
      .catch(() => setLoading(false));
  };
  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>
              Вы уверены, что хотите <br />
              удалить форму обучения
              <br /> {isDeleteFormModalOpen.name}?
            </p>
          </div>
          <p className={style.popUpTxt}>
            Данные не сохранятся. Форма станет недоступной для пользователя.
          </p>
          <div>
            <Button
              outline
              color="primary"
              className={style.deleteLesson}
              onClick={() => setIsDeleteFormModalOpen(false)}
            >
              Отменить
            </Button>
            {loading ? (
              <Button disabled color="primary" className={style.canselChange}>
                <div
                  className={`spinner-border text-light ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                className={style.canselChange}
                onClick={() => deleteForm()}
              >
                Удалить
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DeleteEducationForm);

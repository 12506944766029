import React, { useState } from "react";
import { toast } from "react-toastify";
import changeEventStatus from "../../../api/endPoints/educationEvents";
import Controller from "./Controller.jsx";

const Connector = (props) => {
    const { lesson } = props;

    const [updateTable, setUpdateTable] = useState(false);

    const onChangeStatusClick = (item) => {
        changeEventStatus.changeEventStatus(item.id, { status: "COMPLETED" }).then(() => {
            setUpdateTable(!updateTable);
            window.location.reload();
            toast.info("Изменения сохранены");
        });
    };

    return <Controller lesson={lesson} onChangeStatusClick={(item) => onChangeStatusClick(item)} />;
};

export default Connector;

import React from "react";
import ButtonLink from "../ButtonLink";
import TimeTable from "../TimeTable";
import WeekSlider from "../WeekSlider";
import ButtonAdd from "../ButtonAdd";
import ButtonDownload from "../ButtonDownload";
import DropdownSelect from "../Dropdown";
import styles from "./styles.module.scss";

const View = (props) => {
    const {
        selectDate,
        setSelectDate,
        studentsSelect,
        setStudentsSelect,
        noStudentsSelect,
        setNoStudentsSelect,
        allStudentsSelect,
        studentsLoading,
        onStudentsScroll,
        onStudentsSearch,
        studentsInput,
        studentsAllTitle,
        statusAllTitle,
        statusSelect,
        setStatusSelect,
        noStatusSelect,
        setNoStatusSelect,
        allStatusSelect,
        statusInput,
    } = props;

    const WeekSliderData = { selectDate: selectDate, setSelectDate: setSelectDate };

    const dropdownSelectStudentData = {
        selectAllTitle: studentsAllTitle,
        selectList: studentsSelect,
        setSelectList: setStudentsSelect,
        noSelectList: noStudentsSelect,
        setNoSelectList: setNoStudentsSelect,
        allSelectList: allStudentsSelect,
        loading: studentsLoading,
        onScroll: onStudentsScroll,
        onSearch: onStudentsSearch,
        inputValue: studentsInput,
    };

    const dropdownSelectStatusData = {
        selectAllTitle: statusAllTitle,
        selectList: statusSelect,
        setSelectList: setStatusSelect,
        noSelectList: noStatusSelect,
        setNoSelectList: setNoStatusSelect,
        allSelectList: allStatusSelect,
        inputValue: statusInput,
    };

    const timetableData = {
        selectDate: selectDate,
        students: studentsSelect,
        statuses: statusSelect,
    };

    return (
        <div className={styles.wrapper}>
            <ButtonLink />
            <div className={styles.menu}>
                <WeekSlider {...WeekSliderData} />
                <div className={styles.menu__buttonContainer}>
                    <ButtonAdd />
                    <ButtonDownload />
                </div>
            </div>
            <div className={styles.dropdown}>
                <div style={{ marginRight: "20px" }}>
                    <DropdownSelect {...dropdownSelectStudentData} />
                </div>
                <DropdownSelect {...dropdownSelectStatusData} />
            </div>
            <TimeTable {...timetableData} />
        </div>
    );
};

export default View;

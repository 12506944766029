import React, { useMemo, useState } from "react";
import { lessonCardColors, cardFormatTime } from "./helpers";
import { useStore } from "store";
import individualAvatar from "../assets/individualAvatar.svg";
import groupAvatar from "../assets/groupAvatar.svg";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { ReactComponent as UpdateIcon } from "../assets/update.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import { formatUserName } from "../helpers";
import View from "./View.jsx";

const Controller = (props) => {
    const { lesson, onChangeStatusClick } = props;

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const colors = useMemo(() => lessonCardColors(lesson), [lesson]);

    const time = useMemo(() => cardFormatTime(lesson), [lesson]);

    const {
        createLessonStore: {
            setTeacherId,
            setName,
            setTypeId,
            setTypeName,
            setLessonUrl,
            setClassId,
            setSubjectId,
            setStudents,
            setStartAt,
            setStartTime,
            setIsUpdate,
            setLessonId,
            setClearAll,
        },
    } = useStore();

    const styleTop = useMemo(() => `${time.split(" - ")[0].split(":")[1][0] * 34}px`, [time]);

    const colorsStyle = {
        backgroundColor: colors.main,
        borderColor: colors.border,
        color: colors.text,
        top: styleTop,
    };

    const childLesson = useMemo(() => lesson.type.id !== 4 && lesson.type.id !== 5, [lesson]);

    const visibleStudent = useMemo(() => {
        if (lesson.type.id === 2) return lesson.students[0];
        return lesson.students[0];
    }, [lesson]);

    const childName = useMemo(
        () =>
            childLesson
                ? `${visibleStudent?.user?.last_name || "Неизвестный"} 
${visibleStudent?.user?.first_name[0] || "П"}.`
                : visibleStudent?.profile?.adult?.fio || "Неизвестный П.",
        [childLesson, visibleStudent],
    );

    const lessonType = useMemo(() => lesson.type.id === 1 || lesson.type.id === 2, [lesson]);

    const srcImg = useMemo(() => {
        if (lesson.type.id === 1) return individualAvatar;
        if (lesson.type.id === 2) return groupAvatar;
        return null;
    }, [lesson]);

    const mentorActions = [
        {
            disabled: lesson.status.original_name === "COMPLETED",
            icon: EditIcon,
            name: "Редактировать",
            onClick: () => {
                setIsUpdate(true);
                setIsEditModalOpen(true);
                setLessonId(lesson.id);
                setTeacherId(lesson.teacher.user.id);
                setName(lesson.name);
                setTypeId(lesson.type.id);
                setTypeName([{ name: lesson.type.name, selectedCode: lesson.type.id }]);
                setLessonUrl(lesson.lesson_url);
                setClassId(lesson?.class_id || "");
                setSubjectId(lesson.subject);
                const v = lesson.students.map((el) => ({
                    name: formatUserName(el?.user),
                    selectedCode: el?.user?.id,
                }));
                setStudents(v);
                setStartAt(lesson.start_at + "T00:00");
                setStartTime(lesson.start_time);
            },
        },
        {
            disabled: lesson.status.original_name !== "PLANNED",
            icon: UpdateIcon,
            name: "Проведено",
            onClick: () => onChangeStatusClick?.(lesson),
        },
        {
            disabled: false,
            icon: DeleteIcon,
            name: "Удалить",
            onClick: () => setIsDeleteModalOpen(true),
        },
    ];

    const modalTitle = "Редактировать событие";
    const modalButton = "Сохранить";

    return (
        <View
            lesson={lesson}
            colorsStyle={colorsStyle}
            childName={childName}
            visibleStudent={visibleStudent}
            lessonType={lessonType}
            srcImg={srcImg}
            time={time}
            mentorActions={mentorActions}
            closeDeleteModalOpen={() => setIsDeleteModalOpen(false)}
            isDeleteModalOpen={isDeleteModalOpen}
            isEditModalOpen={isEditModalOpen}
            closeEditModalOpen={() => {
                setIsEditModalOpen(false);
                setClearAll();
            }}
            modalTitle={modalTitle}
            modalButton={modalButton}
        />
    );
};

export default Controller;

import axiosInstance from "..";
import apiList from "../../constants/apiList";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getStudentClasses() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.educationClasses,
            });
            return response.data.classes;
        } catch (error) {
            throw error.response;
        }
    },

    async putTimezone(data, role) {
        try {
            const response = await axiosInstance({
                method: "PUT",
                url: role === 'ADMIN' ? apiList.timezoneAdmin : apiList.timezoneMentor,
                data: {jsonBody: JSON.stringify(data)}
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async searchCity(city_name) {
        try {
          const response = await axiosInstance({
            method: 'GET',
            url: apiList.searchCity(city_name),
          });
          return response.data;
        } catch (error) {
          throw error.response;
        }
    },


    async searchTimezone(city_name, city_latitude, city_longitude) {
        try {
          const response = await axiosInstance({
            method: 'GET',
            url: apiList.searchTimezone(city_name, city_latitude, city_longitude),
          });
          return response.data;
        } catch (error) {
          throw error.response;
        }
      },
};

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import history from "utils/history/history";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import paths from "constants/paths";
import * as style from "./style/AdditionalPurchases.module.scss";
import DetailMaterials from "components/EducationForms/DetailMaterials/DetailMaterials";
import { useStore } from "store";
import ChangePrice from "../Modal/ChangePrice";
import purchases from "api/endPoints/purchases";
import Preloader from "components/Preloader/Preloader";
import PurchaseMaterial from "./PurchaseMaterial/PurchaseMaterial";

function AdditionalPurchases() {
  const {
    modalWindowStore: { isAdditionalPruchasePriceOpen },
  } = useStore();
  const [data, setData] = useState();
  const [dataMaterials, setDatamaterials] = useState();
  const [activeTab, setActiveTab] = useState("tab1");
  const [loading, setLoading] = useState(false);
  const handleClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  useEffect(() => {
    purchases
      .getCountableProductsList()
      .then((responce) => setData(responce.data.items));
    purchases
      .getNoCountableProductsList()
      .then((responce) => setDatamaterials(responce.data.items));
  }, [loading]);

  return (
    <>
      {data ? (
        <div>
          <Button
            className={style.btn_link}
            color="link"
            onClick={() => history.push(paths.adminPanel)}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
          <p className={style.title}>Магазин дополнительных продуктов</p>
          <div className={style.tab_wrapper}>
            <a
              href="/"
              onClick={(e) => handleClick(e, "tab1")}
              className={
                activeTab === "tab1"
                  ? `${style.tab_active} ${style.tab}`
                  : style.tab
              }
            >
              Элементы
            </a>
            <a
              href="/"
              onClick={(e) => handleClick(e, "tab2")}
              className={
                activeTab === "tab2"
                  ? `${style.tab_active} ${style.tab}`
                  : style.tab
              }
            >
              Материалы
            </a>
            <div className={style.emptytab}></div>
          </div>
          {activeTab === "tab1" ? (
            <DetailMaterials
              type="elements"
              data={data}
              setLoading={setLoading}
              isEditable
            />
          ) : (
            <PurchaseMaterial
              type="materials"
              data={dataMaterials}
              setLoading={setLoading}
              isClickable="/additional-purchases"
            />
          )}
          {isAdditionalPruchasePriceOpen && (
            <ChangePrice loading={loading} setLoading={setLoading} />
          )}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default observer(AdditionalPurchases);

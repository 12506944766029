import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import * as style from "./style/TrainingProgram.module.scss";
import programsApi from "api/endPoints/programs";
import Preloader from "../Preloader/Preloader";
import { useStore } from "../../store";

function TrainingProgram() {
	const [data, setData] = useState([]);

	useEffect(() => {
		programsApi
			.programs()
			.then((d) => {
				setData(d.items.map((v) => ({ ...v, name: v.name.split("/") })));
			})
			.catch();
	}, []);

	return (
		<>
			{data.length !== 0 ? (
				<div className={style.training_program}>
					<a href="/" className={style.training_program__back_link}>
						&#8592; Назад
					</a>
					<h1 className={`title-h1 ${style.training_program__header__margin}`}>
						Учебная программа дополнительного образования
					</h1>
					<div className={style.training_program_blocks}>
						{data.map((v) => (
							<a
								href={`/training/${v.id}`}
								key={v.id}
								className={style.training_program_blocks__link}
							>
								<div className={style.training_program_blocks__block}>
									<div className={style.blocks__titles}>
										<h3
											className={`title-h3 ${style.training_program_blocks__title}`}
										>{`${v.name[0]}${v.name[1] ? "/" : ""}`}</h3>
										<h3
											className={`title-h3 ${style.training_program_blocks__title2}`}
										>
											{v.name[1]}
										</h3>
									</div>
								</div>
							</a>
						))}
					</div>
				</div>
			) : (
				<div className="row">
					<Preloader />
				</div>
			)}
		</>
	);
}

export default observer(TrainingProgram);

import React from "react";
import ModalDownload from "../ModalDownload";
import { ReactComponent as DownloadIcon } from "../assets/download.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const { title, isOpenModal, onOpen, onClose, createEvent } = props;

    const addModal = isOpenModal && <ModalDownload onClose={onClose} createEvent={createEvent} />;

    return (
        <>
            <button type="button" className={styles.button} onClick={onOpen}>
                {title}
                <DownloadIcon />
            </button>
            {addModal}
        </>
    );
};

export default View;

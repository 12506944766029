import React from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { useStore } from "store";
import educationForms from "api/endPoints/educationForms";
import * as style from "./style/PublicFormModal.module.scss";

function ActivationEducationForm({ loading, setLoading }) {
  const {
    modalWindowStore: {
      isActivationFormModalOpen,
      setIsActivationFormModalOpen,
    },
  } = useStore();

  const cardInfo = isActivationFormModalOpen.status ? (
    <>
      Вы хотите деактивировать
      <br />
      форму обучения {isActivationFormModalOpen.name}?
    </>
  ) : (
    <>
      Вы хотите активировать форму
      <br />
      обучения {isActivationFormModalOpen.name}?
    </>
  );

  const submitClick = () => {
    setLoading(true);
    if (isActivationFormModalOpen.status) {
      educationForms
        .deactivateFormForUser(isActivationFormModalOpen.id)
        .then(() => {
          setIsActivationFormModalOpen(false);
          setLoading(false);
          toast.info(
            `Форма обучения ${isActivationFormModalOpen.name} больше не доступна для пользователя`
          );
        })
        .catch(() => setLoading(false));
    } else {
      educationForms
        .activateFormForUser(isActivationFormModalOpen.id)
        .then(() => {
          setIsActivationFormModalOpen(false);
          setLoading(false);
          toast.info(
            `Форма обучения ${isActivationFormModalOpen.name} доступна для пользователя`
          );
        })
        .catch(() => setLoading(false));
    }
  };
  return (
    <div>
      <div className={style.modal}>
        <div className={style.modalOverlay}>
          <div className={style.modalWindow}>
            <div className={style.modalHeader}>
              <p className={style.popUpTitle}>{cardInfo}</p>
            </div>
            <p className={style.popUpTxt}>
              {isActivationFormModalOpen.status
                ? "Форма станет недоступной для пользователя."
                : "Если вы активируете данную форму, все остальные автоматически станут недоступными для пользователя."}
            </p>
            <div>
              <Button
                color="outline"
                className={style.canselChange}
                onClick={() => setIsActivationFormModalOpen(false)}
              >
                Отменить
              </Button>
              {loading ? (
                <Button
                  disabled
                  color="primary"
                  className={style.delete}
                  onClick={() => submitClick()}
                >
                  <div
                    className={`spinner-border text-light ${style.loading}`}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </Button>
              ) : (
                <Button
                  color="primary"
                  className={style.delete}
                  onClick={() => submitClick()}
                >
                  {isActivationFormModalOpen.status
                    ? "Деактивировать"
                    : "Активировать"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ActivationEducationForm);

/* eslint-disable import/no-anonymous-default-export */
import axiosInstance from "..";
import apiList from "../../constants/apiList";

export default {
  async getOrders(filter, page, type) {
    const formatterFilter = { ...filter };
    let newType;
    if (formatterFilter.users.name === "") {
      delete formatterFilter.users.name;
    }
    if (type) {
      newType = "asc";
    } else {
      newType = "desc";
    }
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.orders,
        params: {
          sort_by: "bought_at",
          sort_type: newType,
          per_page: "25",
          filter: formatterFilter,
          page,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async getCountableProductsList() {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getCountableElements,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async editCountableElements(id, price) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.editCountableElements(id),
        params: {
          _method: "PUT",
        },
        data: { price },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async publishCountableElements(id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.publishCountableElements(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async unpublishCountableElements(id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.unpublishCountableElements(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async publishNoCountableElements(id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.publishNoCountableElements(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async unpublishNoCountableElements(id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.unpublishNoCountableElements(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getNoCountableProductsList() {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getNoCountableElements,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getCurrentProductsList(filter, id, page, subjId) {
    const formatterFilter = { ...filter };
    if (formatterFilter.name === "") {
      delete formatterFilter.name;
    }
    if (formatterFilter.is_active === "") {
      delete formatterFilter.is_active;
    }
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getCurrentProductList,
        params: {
          filter: formatterFilter,
          education_format_no_countable_element_id: id,
          page,
          per_page: "25",
          subject_id: subjId,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getCurrentOrder(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.currentOrder(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getProductsTree(product_id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getProductsTree,
        params: { product_id },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async addProduct(data) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.addProduct,
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async deleteProduct(id) {
    try {
      const response = await axiosInstance({
        method: "DELETE",
        url: apiList.editProduct(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async editProduct(id, data) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.editProduct(id),
        params: { _method: "PUT" },
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getCurrentProduct(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.editProduct(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getPurchasesTreeForUser(id, data) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getPurchasesTreeForUser(id),
        params: data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";

import { Button } from "reactstrap";
import Preloader from "components/Preloader/Preloader";
import users from "../../../api/endPoints/users";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import _trim from "lodash";

import * as style from "./style/Teacher.module.scss";
import Menu from "./Menu";
import { useStore } from "store";
import UnblockUser from "../UnblockUser/UnblockUser";
import DeleteUsers from "../DeleteUsers/DeleteUsers";
import BlockUser from "../BlockUsers/BlockUser";
import paths from "constants/paths";
import { toast } from "react-toastify";

import Pagination from "components/Pagination/Pagination";
import UserLinkWrapper from "../UserLinkWrapper/UserLinkWrapper";

function TeacherComponent({ filter }) {
    const history = useHistory();
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [teachersList, setTeachersList] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const {
        modalWindowStore: {
            isDeleteModalOpen,
            isBlockModalOpen,
            isUnBlockModalOpen,
            setIsDeleteModalOpen,
            setIsBlockModalOpen,
            setIsUnBlockModalOpen,
        },
    } = useStore();

    useEffect(() => {
        setLoading(true);
        users.getTeachersList(page, filter).then((response) => {
            setTeachersList(response.items);
            setLastPage(response.last_page);
            setPaginationLoading(false);
            setLoading(false);
        });
    }, [page, filter, isUnBlockModalOpen, isDeleteModalOpen, isBlockModalOpen]);

    const deleteTeacher = () => {
        apiDeleteUser(isDeleteModalOpen.id);
        setModalLoading(true);
    };

    const clickBlockTeacher = () => {
        setModalLoading(true);
        apiBlockTeacher(isBlockModalOpen.id);
    };

    const apiDeleteUser = (id) => {
        users.deleteUser(id).then(() => {
            setModalLoading(false);
            setIsDeleteModalOpen(false);
            toast.success("Пользователь удален");
        });
    };

    const apiBlockTeacher = (id) => {
        users.blockTeacher(id).then(() => {
            setIsBlockModalOpen(false);
            toast.success("Пользователь заблокирован");
            setModalLoading(false);
        });
    };

    const apiUnBlockTeacher = (id) => {
        setModalLoading(true);
        users.unblockUser(id).then(() => {
            setIsUnBlockModalOpen(false);
            toast.success("Пользователь разблокирован");
            setModalLoading(false);
        });
    };

    const prevPage = (number) => {
        setPaginationLoading(true);
        setPage(page - number);
    };

    const nextPage = (number) => {
        setPaginationLoading(true);
        setPage(page + number);
    };

    const lastPageFunction = (number) => {
        setPaginationLoading(true);
        setPage(number);
    };

    return (
        <>
            {teachersList ? (
                <div>
                    {loading ? (
                        <div className={`row ${style.lessonsPreloader}`}>
                            <Preloader />
                        </div>
                    ) : (
                        <>
                            <table className={style.tableWidth}>
                                {teachersList?.length === 0 ? (
                                    <div>
                                        <p className={style.lessonsListNull}>
                                            Ничего не найдено...
                                        </p>
                                    </div>
                                ) : (
                                    <thead>
                                        <tr className={style.tableBorder}>
                                            <th className={style.tableHeader}>Номер </th>
                                            <th className={style.tableHeader}>ФИО</th>
                                            <th className={style.tableHeader}></th>
                                        </tr>
                                    </thead>
                                )}

                                <tbody>
                                    {teachersList.map((item, index) => (
                                        <tr key={item.user.id} className={style.tableBorder}>
                                            <td>{(page - 1) * 25 + index + 1}</td>
                                            <td
                                                className={
                                                    item.user.is_blocked
                                                        ? style.userNameBlocked
                                                        : style.userName
                                                }>
                                                <UserLinkWrapper
                                                    link={paths.teacherDetail(item.user.id)}
                                                    blocked={item.user.is_blocked}>
                                                    {item.user.first_name}&nbsp;
                                                    {item.user.middle_name}&nbsp;
                                                    {item.user.last_name}
                                                </UserLinkWrapper>
                                            </td>

                                            <td className={style.buttonDisplay}>
                                                <Button
                                                    color="inherit"
                                                    className={style.tableDeleteBtn}
                                                    onClick={() =>
                                                        setIsDeleteModalOpen(true, item.user.id)
                                                    }>
                                                    <img src={deleteIcon} alt="Delete" />
                                                </Button>

                                                <Menu
                                                    id={item.user.id}
                                                    blockTeacher={clickBlockTeacher}
                                                    loading={loading}
                                                    isBlocked={item.user.is_blocked}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={style.lessonPagination}>
                                <Pagination
                                    page={page}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                    lastPage={lastPage}
                                    lastPageFunction={lastPageFunction}
                                />
                            </div>
                        </>
                    )}

                    {isDeleteModalOpen && (
                        <DeleteUsers deleteUser={deleteTeacher} loading={modalLoading} />
                    )}

                    {isBlockModalOpen && (
                        <BlockUser blockUser={clickBlockTeacher} loading={modalLoading} />
                    )}
                    {isUnBlockModalOpen && (
                        <UnblockUser apiUnBlockTeacher={apiUnBlockTeacher} loading={modalLoading} />
                    )}
                </div>
            ) : (
                <div className="row">
                    <Preloader />
                </div>
            )}
        </>
    );
}
export default observer(TeacherComponent);

import React, { useState, useRef } from 'react';
import editDots from '../../../assets/img/3dots.svg';
import { useHistory } from 'react-router';
import paths from '../../../constants/paths';
import { Button } from 'reactstrap';

import * as styleMenu from './style/Menu.module.scss';
import * as style from '../TeachersList/style/Teacher.module.scss';
import { useClickOutside } from 'utils/useClickOutside';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import role from 'constants/role';

function Menu({ id, isBlocked }) {
  const isAdmin = localStorage.getItem('role');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const {
    modalWindowStore: { setIsUnBlockModalOpen, setIsBlockModalOpen },
  } = useStore();

  const history = useHistory();
  const m = useRef();

  useClickOutside(m, () => {
    if (isMenuOpen) setMenuOpen(false);
  });

  return (
    <div className={styleMenu.chat_bubble__menu_container} ref={m}>
      {isMenuOpen && (
        <>
          {isBlocked ? (
            <div className={styleMenu.chat_bubble__menu_list}>
              <button
                type="button"
                className={styleMenu.chat_bubble__menu_list_item}
                onClick={() => setIsUnBlockModalOpen(true, id)}>
                Разблокировать
              </button>
            </div>
          ) : (
            <div className={styleMenu.chat_bubble__menu_list}>
              <button
                type="button"
                className={styleMenu.chat_bubble__menu_list_item}
                onClick={() => history.push(paths.editStudent(id))}>
                Редактировать
              </button>
              {isAdmin === role.admin && (
                <button
                  type="button"
                  className={styleMenu.chat_bubble__menu_list_item}
                  onClick={() => setIsBlockModalOpen(true, id)}>
                  Заблокировать
                </button>
              )}
            </div>
          )}
        </>
      )}

      <Button
        className={style.tableEditBtn}
        outline
        color="inherit"
        disabled={isAdmin !== role.admin && isBlocked}
        onClick={() => setMenuOpen(true)}>
        <img className={style.tableEditBtnSvg} src={editDots} alt="Edit" />
      </Button>
    </div>
  );
}

export default observer(Menu);

import React from "react";

import * as style from "./style/CropperModal.module.scss";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import downloadLogo from "../../assets/img/downloadLogo.svg";
import cancel from "../../assets/img/closeIcon.svg";

import { Button, Label } from "reactstrap";
function CropperModal({
  image,
  setCropper,
  getCropData,
  userTypes,
  cancelClick,
  lableFor,
  croppSize,
  hint,
  ratio = NaN,
}) {
  const checkUserTypes = (e) => {
    if (userTypes) {
      getCropData(e, userTypes.student);
    } else {
      getCropData(e);
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <h4 className={style.titleMessage}>Прикрепите изображение</h4>
            <Button
              color="outline"
              name={lableFor}
              className={style.closeButton}
              onClick={() => cancelClick(lableFor)}
            >
              <img
                className={style.closeIcon}
                color="inherit"
                src={cancel}
                alt=""
              />
            </Button>
          </div>
          <p className={style.titleChoose}>{hint}</p>
          <div className={style.displayCropper}>
            {image ? (
              <>
                <Cropper
                  style={{ height: 300, width: "75%" }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  aspectRatio={ratio}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={croppSize}
                  minCropBoxWidth={croppSize}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
                <div className={style.displayButton}>
                  <Button
                    color="inherit"
                    name={lableFor}
                    className={`${style.cancelButton} m-2`}
                    onClick={() => cancelClick(lableFor)}
                  >
                    Отменить
                  </Button>

                  <Button
                    color="primary"
                    className={`${style.createLesson} m-2`}
                    onClick={checkUserTypes}
                  >
                    Обрезать изображение
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={style.modalWrapper}>
                  <Label
                    color="inherit"
                    className={style.fileLable}
                    for={lableFor}
                  >
                    <img
                      className={style.addImageButton}
                      color="inherit"
                      src={downloadLogo}
                      alt=""
                    />
                    <span>Выбрать файл</span>
                  </Label>
                </div>
                <div>
                  <Label className={style.footerMessage}>
                    Размер файла не должен превышать 10 МБ
                  </Label>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CropperModal;

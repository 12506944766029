import React, { useState, useRef } from 'react';
import * as styleMenu from './style/Menu.module.scss';
import editDots from '../../assets/img/3dots.svg';
import lessonsApi from '../../api/endPoints/lessonList';
import { useHistory } from 'react-router';
import paths from '../../constants/paths';
import { Button } from 'reactstrap';
import * as style from './style/LessonsList.module.scss';
import { useClickOutside } from 'utils/useClickOutside';

export default function Menu({ id, link, status }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const m = useRef();

  useClickOutside(m, () => {
    if (isMenuOpen) setMenuOpen(false);
  });

  const downloadChat = (url) => {
    lessonsApi
      .lessonChat(url)
      .then((response) => {
        const a = document.createElement('a');
        a.href = response.url;
        a.download = `chat-${id}.txt`;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        new Error(error);
      });
  };

  return (
    <div className={styleMenu.chat_bubble__menu_container} ref={m}>
      {isMenuOpen && (
        <div className={styleMenu.chat_bubble__menu_list}>
          <button
            type="button"
            disabled={status === 'planned'}
            className={styleMenu.chat_bubble__menu_list_item}
            onClick={() => downloadChat(link)}>
            Скачать чат
          </button>
          <button
            type="button"
            className={styleMenu.chat_bubble__menu_list_item}
            onClick={() => history.push(paths.editLesson(id))}>
            Редактировать
          </button>
        </div>
      )}
      <Button
        outline
        color="inherit"
        className={style.tableEditBtn}
        onClick={() => setMenuOpen(true)}>
        <img className={style.tableEditBtnSvg} src={editDots} alt="Edit" />
      </Button>
    </div>
  );
}

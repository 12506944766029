const actions = {
  setValue: 'setValue',
  reset: 'reset',
  deletePhoto: 'deletePhoto',
};

const userTypes = {
  student: 'student',
  adult: 'adult',
};

const initialValue = {
  [userTypes.student]: {
    first_name: '',
    last_name: '',
    email: '',
    birthday: '',
    phone: '',
    education_class: null,
    education_format: null,
    divergency: '',
    avatar: '',
    other_divergency: '',
  },
  [userTypes.adult]: {
    fio: '',
    birthday: null,
    role: 2,
    location: '',
  },
};

function reducer(state, action) {
  const { type, payload } = action;
  const { name, value, userType } = payload;
  switch (type) {
    case actions.setValue: {
      return {
        ...state,
        [userTypes[userType]]: {
          ...state[userTypes[userType]],
          [name]: value,
        },
      };
    }

    case actions.deletePhoto: {
      return {
        ...state,
        [userTypes[userType]]: {
          ...state[userTypes[userType]],
          avatar: initialValue.student.avatar,
        },
      };
    }

    case actions.reset:
      return initialValue;
    default:
      return state;
  }
}

export {
  userTypes,
  actions as userActions,
  reducer as userReducer,
  initialValue as userInitialValue,
};

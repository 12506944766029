export const mapRadioTree = (customId, tree) => {
  return mapTree(customId, clearRadioTree(tree), 0, searchPath(customId, tree, 0));
}

export const mapRadioNode = (customId, node) => mapRadioTree(customId, [node])[0];

function searchPath(customId, tree, level) {
  for (const idx in tree) {
    if (tree[idx].custom_id === customId) return { [level]: idx };
    else if (tree[idx].children.length) {
      const segment = searchPath(customId, tree[idx].children, level + 1);
      if (segment)
        return {
          [level]: idx,
          ...segment,
        };
    }
  }
  return null;
}

const mapTree = (customId, tree, level, path) =>
  tree.map((node, idx) =>
    +path?.[level] === +idx
      ? {
          ...node,
          checked: node.custom_id === customId ? 1 : 0.5,
          children: mapTree(customId, node.children, level + 1, path),
        }
      : node
  );

export function clearRadioTree(tree) {
  return tree.map((node) => ({
    ...node,
    checked: 0,
    children: clearRadioTree(node.children),
  }));
}

import React from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useStore } from '../../store';
import putTimezone from "api/endPoints/profileEndpoint";
import paths from "constants/paths";
import View from './View';

const Connector = () => {
  const history = useHistory();
  const {
    userStore: { setUpdateFromLocalStorage },
  } = useStore();

  const onSubmit = (formData, setErrors, setLoading) => {
    setLoading(true);
    putTimezone.putTimezone(formData, "ADMIN")
      .then(() => {
        toast.success('Данные успешно изменены');
        localStorage.timezone = JSON.stringify(formData.timezone);
        setUpdateFromLocalStorage();
        setLoading(false);
        history.push(paths.adminPanel);
      })
      .catch(async error => {
        const { advanced } = error.data.error;
        setErrors(advanced);
        setLoading(false);
      });
  };

  return <View onSubmit={onSubmit} />;
};

export default Connector;

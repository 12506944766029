import React from "react";
import * as style from "./style/LessonsList.module.scss";

export default function LessonStatus({ status }) {
	if (status === "planned") {
		return <p className={style.tableStatusPlanned}>Запланирован</p>;
	}

	if (status === "active") {
		return <p className={style.tableStatusActive}>Идет</p>;
	}
	return <p className={style.tableStatusDisabled}>Завершен</p>;
}

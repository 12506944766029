import React from 'react';
import history from 'utils/history/history';
import * as style from './style/BreadCrumbs.module.scss';
import breadcrumb from '../../assets/img/breadcrumb.svg';

function BreadCrumbs({ breadCrumbs, pathname }) {
  return (
    <div className={style.breadCrumbs}>
      {breadCrumbs &&
        breadCrumbs.map((item, index) => {
          return (
            <>
              <a
                key={index}
                onClick={() => history.push(item?.path)}
                className={item.path === pathname ? style.color : ''}>
                {item.name}
                {breadCrumbs.length - 1 !== index ? (
                  <img src={breadcrumb} className={style.imageBreadCrumb} />
                ) : null}
              </a>
            </>
          );
        })}
    </div>
  );
}

export default BreadCrumbs;

import { makeAutoObservable } from "mobx";

class DownLoadTimeSheetStore {
    constructor() {
        this.start_week = null;
        this.end_week = null;
        this.start_month = null;
        this.end_month = null;
        this.students = [];
        this.statuses = [];
        makeAutoObservable(this);
    }

    setWeekDate = (value) => {
        this.start_week = value.start;
        this.end_week = value.end;
    };

    setMonthDate = (value) => {
        this.start_month = value.start;
        this.end_month = value.end;
    };

    setStudents = (value) => {
        this.students = value.map((item) => item.selectedCode);
    };

    setStatuses = (value) => {
        this.statuses = value.map((item) => item.selectedCode);
    };

    setClearAll = () => {
        this.start_week = null;
        this.end_week = null;
        this.start_month = null;
        this.end_month = null;
        this.students = [];
        this.statuses = [];
    };
}

export default DownLoadTimeSheetStore;

/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import * as style from "./style/EditFormModal.module.scss";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useStore } from "store";
import { ReactComponent as Price } from "../../assets/img/price.svg";
import validation from "components/EducationForms/utils/validate";
import educationForms from "../../api/endPoints/educationForms";
import { useParams } from "react-router";
import { toast } from "react-toastify";

function EditFormModal({ data, setLoading, loading }) {
  const params = useParams();
  const {
    modalWindowStore: { setIsEditFormModal },
  } = useStore();
  const [form, setForm] = useState({
    name: data.name,
    duration: data.duration?.original_name || data.duration,
    price: Number.parseInt(data.price),
    description: data.description,
  });

  const [errors, setErrors] = useState({});
  const [duration, setDuration] = useState([]);
  useEffect(() => {
    educationForms
      .duration()
      .then((response) => setDuration(response.data.items));
  }, []);

  const closeModal = (e) => {
    e.preventDefault();
    setIsEditFormModal(false);
  };

  const clearErrField = (field) => {
    const checkError = { ...errors };

    if (checkError[field]) {
      delete checkError[field];
    }
    setErrors({ ...checkError });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    clearErrField(name);
    switch (name) {
      case "name":
        setForm({ ...form, name: value });
        break;
      case "description":
        setForm({ ...form, description: value });
        break;
      case "price":
        setForm({ ...form, price: Number(value.replace(/\D+/, "")) });

        break;
      case "duration":
        setForm({ ...form, duration: value });
        break;
    }
  };

  const onBlur = async (e) => {
    const { name } = e.target;
    const validationErrors = await validation.clientValidateField(name, {
      ...form,
    });
    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = await validation.clientValidateForm(form);
    if (errors) {
      setLoading(false);
      setErrors({ ...errors });
    } else {
      setErrors({});
      educationForms
        .editForm(params.id, form, data.education_format_type.name)
        .then(() => {
          setLoading(false);
          setIsEditFormModal(false);
          toast.info("Изменения сохранены");
        })
        .catch((errors) => {
          setLoading(false);
          setErrors(errors.data.error.advanced);
        });
    }
  };

  return (
    <>
      {duration && (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.popUpTitle}>Редактировать шаблон</p>
              </div>
              <div>
                <Form onSubmit={onSubmit} noValidate>
                  <FormGroup className="mb-4">
                    <Label className={style.form_first_lable} htmlFor="name">
                      Название: *
                    </Label>
                    <Input
                      name="name"
                      id="name"
                      value={form.name}
                      placeholder="Введите название"
                      onChange={handleChange}
                      onBlur={onBlur}
                      invalid={!!errors?.name}
                    />
                    <FormFeedback>{errors?.name}</FormFeedback>
                    <Label className={style.form_second_lable}>
                      Введите от 2 до 150 символов
                    </Label>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label
                      className={style.form_first_lable}
                      htmlFor="description"
                    >
                      Описание: *
                    </Label>
                    <Input
                      name="description"
                      id="description"
                      type="textarea"
                      value={form.description}
                      placeholder="Введите описание"
                      onChange={handleChange}
                      onBlur={onBlur}
                      invalid={!!errors?.description}
                    />
                    <FormFeedback>{errors?.description}</FormFeedback>
                    <Label className={style.form_second_lable}>
                      Введите от 2 до 300 символов
                    </Label>
                  </FormGroup>
                  {data.education_format_type.name !== "FREE" && (
                    <FormGroup className="mb-4">
                      <Label htmlFor="price" className={style.form_first_lable}>
                        Цена: *
                      </Label>
                      <div className={style.form_price_wrapper}>
                        <Input
                          // type="number"
                          id="price"
                          name="price"
                          min={0}
                          pattern="^[0-9]"
                          value={form.price}
                          placeholder="Введите цену"
                          className={style.form_price}
                          onChange={handleChange}
                          onBlur={onBlur}
                          invalid={!!errors?.price}
                        />
                        <Price className={style.form_price_img} />
                        <FormFeedback>{errors?.price}</FormFeedback>
                      </div>

                      <Label className={style.form_second_lable}>
                        Введите от 1 до 6 символов
                      </Label>
                    </FormGroup>
                  )}
                  {form.duration && (
                    <FormGroup className="mb-4">
                      <Label
                        htmlFor="duration"
                        className={style.form_first_lable}
                      >
                        Длительность: *
                      </Label>
                      <Input
                        id="duration"
                        name="duration"
                        type="select"
                        value={form.duration}
                        onChange={handleChange}
                        onBlur={onBlur}
                        invalid={!!errors?.duration}
                      >
                        {duration?.map((item, index) => (
                          <option key={index} value={item.original_name}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>{errors?.duration}</FormFeedback>
                    </FormGroup>
                  )}
                  <Button
                    color="primary"
                    outline
                    className={style.form_cancel}
                    onClick={(e) => closeModal(e)}
                  >
                    Отмена
                  </Button>
                  {loading ? (
                    <Button
                      disabled
                      color="primary"
                      className={style.form_submit}
                    >
                      <div
                        className={`spinner-border text-light ${style.loading}`}
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </Button>
                  ) : (
                    <Button color="primary" className={style.form_submit}>
                      Сохранить
                    </Button>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(EditFormModal);

import React from "react";
import preloader from "../../assets/img/preloader.svg";
import * as style from "./style/Preloader.module.scss";

export default function Preloader({ type }) {
    if (type === "small") {
        return (
            <div
                className={style.wrapper}
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "5px",
                    marginBottom: "5px",
                }}>
                <div className={`spinner-border text-primary ${style.loading}`} role="status" />
            </div>
        );
    }

    return (
        <div>
            <div className={style.wrapper}>
                <div className={`spinner-border text-primary ${style.loading}`} role="status">
                    <span className="sr-only">Идет загрузка...</span>
                </div>
            </div>
            <p className={style.loadingText}>Идет загрузка...</p>
        </div>
    );
}

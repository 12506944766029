import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import Preloader from "components/Preloader/Preloader";
import itemsManagement from "api/endPoints/itemsManagement";
import FolderTree from "components/FolderTree/FolderTree";
import * as style from "./style/MaterialTree.module.scss";
import BreadCrumbs from "components/Breadcrumbs/Breadcrumbs";
import users from "api/endPoints/users";


const recursiveAddCustomId = (data) => {
  data.forEach((el) => {
    el.custom_id = el.id + el.foreign_type;
    if(el.children.length > 0) {
      return recursiveAddCustomId(el.children);
    }
  })

  return data;
}

function MaterialTree() {
  const [data, setData] = useState();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const params = useParams();
  const { pathname } = useLocation();

  const [userName, setUserName] = useState();

  useEffect(() => {
    itemsManagement
      .getSubjectTree(params.formId, params.subjId, params.materialId)
      .then((res) => {
        setBreadCrumbs(res.breadcrumbs);
        setData(recursiveAddCustomId(res.data));
      });
    users.getStudent(params.id).then((response) => {
      setUserName(`${response.user.last_name || "Неизвестный"} 
        ${response.user.first_name || "пользователь"}`);
    });
  }, []);

  const breadcrumbsNew = () => {
    let firstItem = {
      name: userName,
      path: `/user-detail/${params.id}`,
    };
    let secondItem = {
      name: breadCrumbs[0].name,
      path: `/user/${params.id}/edit-form/${breadCrumbs[0].id}`,
    };
    let thirdItem = {
      name: breadCrumbs[1].name,
      path: `/user/${params.id}/edit-form/${breadCrumbs[0].id}/materials/${breadCrumbs[1].id}`,
    };
    let fourthItem = {
      name: breadCrumbs[2].name,
      path: `/user/${params.id}/edit-form/${breadCrumbs[0].id}/materials/${breadCrumbs[1].id}/subject/${breadCrumbs[2].id}`,
    };
    return [firstItem, secondItem, thirdItem, fourthItem];
  };

  return (
    <>
      {data ? (
        <div>
          <div className={style.header}>
            <BreadCrumbs breadCrumbs={breadcrumbsNew()} pathname={pathname} />
            <p className={style.title}>{breadCrumbs[2].name}</p>
          </div>
          <FolderTree data={data} />
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default MaterialTree;

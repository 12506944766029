import moment from "moment";
import { DATE_FORMAT } from "./utils";

export const weekDates = (selectDate) => {
    const currentMoment = moment(selectDate);
    let currentWeekDay = currentMoment.day();
    if (!currentWeekDay) currentWeekDay = 7;
    const result = [];
    for (let i = 1; i <= 7; i++) {
        const nextMoment = currentMoment.clone().add(i - currentWeekDay, "days");
        result.push(nextMoment.toDate());
    }
    return result;
};

export const monthDates = (selectDate) => {
    const currentMoment = moment(selectDate);
    let currentMonthDay = selectDate.getDate();

    const date1 = new Date(selectDate.getFullYear(), selectDate.getMonth(), 1);
    const date2 = new Date(selectDate.getFullYear(), selectDate.getMonth() + 1, 1);

    const numberDays = Math.round((date2 - date1) / 1000 / 3600 / 24);

    if (!currentMonthDay) currentMonthDay = numberDays;
    const result = [];
    for (let i = 1; i <= numberDays; i++) {
        const nextMoment = currentMoment.clone().add(i - currentMonthDay, "days");
        result.push(nextMoment.toDate());
    }
    return result;
};

export const shiftWeekDates = (direction, selectDate) => {
    const nextWeek = moment(selectDate[0]).add(7, "days").toDate();
    const previousWeek = moment(selectDate[0]).add(-7, "days").toDate();
    if (direction === "right") return weekDates(nextWeek);
    return weekDates(previousWeek);
};

export const isCurrentWeek = (selectDate) => {
    const today = new Date().toLocaleDateString();
    return !!selectDate.find((val) => val.toLocaleDateString() === today);
};

export const dateFormat = (currentDate) => {
    return moment(currentDate).format(DATE_FORMAT);
};

export const formatTime = (hours, minuts = 0) => {
    return `${hours < 10 ? "0" : ""}${hours}:${minuts < 10 ? "0" : ""}${minuts}`;
};

export const formatInputValue = (mas) => {
    if (mas.length === 1) {
        return mas[0].name;
    }
    if (mas.length === 2) {
        return `${mas[0].name}, ${mas[1].name}`;
    }
    return `${mas[0].name}, ${mas[1].name}, ${mas[2].name}`;
};

export const formatUserName = (userName) => {
    if (userName?.first_name && userName?.last_name)
        return `${userName.last_name} ${userName.first_name}`;
    if (userName?.first_name) return `${userName.first_name}`;
    if (userName?.last_name) return `${userName.last_name}`;
    return "Неизвестный пользователь";
};

export const getTimeZone = () => {
    const date = new Date().toString().match(/([-+][0-9]+)\s/)[1];
    return `${date[0]}${date[1]}${date[2]}:${date[3]}${date[4]}`;
};

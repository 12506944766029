import React from "react";
import * as style from "./style/Modal.module.scss";

export default function Modal({children}) {
	return (
		<div className={style.modal}>
			<div className={style.modalOverlay}>
				<div className={style.modalWindow}>
					{children}
				</div>
			</div>
		</div>
	);
}

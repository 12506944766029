import React, { useState } from "react";
import View from "./View";

const Controller = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const title = "Скачать расписание";
    const onOpen = () => setIsOpenModal(true);
    const onClose = () => setIsOpenModal(false);
    const createEvent = () => {
        console.log("create");
        setIsOpenModal(false);
    };

    return (
        <View
            title={title}
            isOpenModal={isOpenModal}
            onOpen={onOpen}
            onClose={onClose}
            createEvent={createEvent}
        />
    );
};

export default Controller;

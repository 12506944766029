import React, { useMemo } from "react";
import styles from "./styles.module.scss";

const View = (props) => {
    const { dayOfMonth, dayOfWeek } = props;

    return (
        <td key={dayOfMonth} className={styles.date}>
            <div className={styles.date__month}>{dayOfMonth}</div>
            <div className={styles.date__week}>{dayOfWeek}</div>
        </td>
    );
};

export default View;

import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import * as style from "./style/Purchases.module.scss";
import { ReactComponent as ArrowBack } from "../../assets/img/arrowBack.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/search.svg";
import { ReactComponent as UpRightArrow } from "../../assets/img/upRightArrow.svg";
import { ReactComponent as UpDown } from "../../assets/img/upDown.svg";
import history from "utils/history/history";
import paths from "constants/paths";
import purchases from "api/endPoints/purchases";
import Preloader from "components/Preloader/Preloader";
import Pagination from "components/Pagination/Pagination";
import numberOfProduct from "utils/numberOfProduct";

function Purchases() {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    users: {
      name: "",
    },
  });
  const [filterType, setFilterType] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.removeItem("order");
    purchases.getOrders(filters, page, filterType).then((response) => {
      setData(response.data.items);
      setLastPage(response.data.last_page);
      setLoading(false);
      setPaginationLoading(false);
    });
  }, [page, filters, filterType]);

  const prevPage = (number) => {
    setPaginationLoading(true);
    setPage(page - number);
  };

  const nextPage = (number) => {
    setPaginationLoading(true);
    setPage(page + number);
  };

  const lastPageFunction = (number) => {
    setPaginationLoading(true);
    setPage(number);
  };

  const changeSort = () => {
    setFilterType(!filterType);
    setPaginationLoading(true);
  };

  const hangleChange = (e) => {
    setFilters({ users: { name: e.target.value } });
    setPaginationLoading(true);
  };

  const savePurchase = (order, id, type) => {
    if (type === "EDUCATION_FORMAT_TEMPLATE_ORDER") {
      localStorage.setItem("order", order);
    }
    localStorage.setItem("purchase", true);
    history.push(paths.userDetail(id));
  };

  return (
    <>
      {!loading ? (
        <div>
          <Button
            className={style.btn_link}
            color="link"
            onClick={() => history.push(paths.adminPanel)}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
          <p className={style.title}>Покупки</p>
          <div>
            <p className={style.title_debounce}>Поиск:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                className={style.findUsers}
                placeholder="Введите ФИО пользователя"
                value={filters.users.name}
                minLength={1}
                debounceTimeout={800}
                onChange={(e) => hangleChange(e)}
              />
              <SearchIcon className={style.searchImg} />
            </div>
          </div>
          <div className={style.table}>
            {paginationLoading ? (
              <Preloader />
            ) : (
              <>
                {data.length === 0 ? (
                  <div className={style.table_width}>
                    <p className={style.nothing_find}>Ничего не найдено...</p>
                  </div>
                ) : (
                  <table className={style.table_width}>
                    <thead>
                      <tr className={style.table_header}>
                        <th className={style.table_number_header}>Номер</th>
                        <th className={style.table_user_header}>
                          Пользователь
                        </th>
                        <th className={style.table_count_header}>Количество</th>
                        <th className={style.table_pruchase_header}>Покупка</th>
                        <th className={style.table_date_header}>
                          Дата
                          <button
                            onClick={() => changeSort()}
                            className={style.table_date_header_btn}
                          >
                            <UpDown />
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={item.id} className={style.table_body}>
                          <td className={style.table_number}>
                            {numberOfProduct(index, page)}
                          </td>
                          <td
                            className={style.table_user}
                            onClick={() =>
                              savePurchase(item.id, item.user.id, item.type)
                            }
                          >
                            {item.user.last_name || "Неизвестный"} &nbsp;
                            {item.user.first_name || "пользователь"}
                            <UpRightArrow className={style.table_user_arrow} />
                          </td>
                          <td className={style.table_count}>{item.quantity}</td>
                          <td className={style.table_pruchase}>{item.name}</td>
                          <td className={style.table_date}>{item.bought_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </>
            )}
          </div>
          <Pagination
            page={page}
            nextPage={nextPage}
            prevPage={prevPage}
            lastPage={lastPage}
            lastPageFunction={lastPageFunction}
          />
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default Purchases;

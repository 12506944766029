import axiosInstance from "..";
import apiList from "../../constants/apiList";
import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getEducationEventStatuses() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getEducationEventStatuses,
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getEventList() {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getEventList,
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getTeacherEducationEvents(from, to, students, statuses) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getTeacherEducationEvents,
                params: {
                    date_from: moment(from).format("YYYY-MM-DD"),
                    date_to: moment(to).format("YYYY-MM-DD"),
                    students: students.map((item) => item.selectedCode),
                    statuses: statuses.map((item) => item.selectedCode),
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getEventTimeList(params) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getEventTimeList,
                params,
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async createEducationEvent(data) {
        const filterData = { ...data };
        if (filterData.subject_id === "") {
            delete filterData.subject_id;
        }

        if (filterData?.student.length > 0) {
            delete filterData.student;
        }

        if (filterData.class_id === "") {
            delete filterData.class_id;
        }
        if (
            (filterData.type_id !== 1 || filterData.type_id !== 2 || filterData.type_id !== 6) &&
            (filterData.name === "" || filterData.name === null)
        ) {
            delete filterData.name;
        }

        if (filterData.description === null) {
            delete filterData.description;
        }

        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.createEducationEvent,
                data: filterData,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async downloadTeacherEvents(params) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.downloadTeacherEvents,
                responseType: "blob",
                params,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editEducationEvent(id, data) {
        const filterData = { ...data };
        if (filterData.subject_id === "") {
            delete filterData.subject_id;
        }

        if (filterData.class_id === "") {
            delete filterData.class_id;
        }
        if (
            (filterData.type_id !== 1 || filterData.type_id !== 2 || filterData.type_id !== 6) &&
            (filterData.name === "" || filterData.name === null)
        ) {
            delete filterData.name;
        }
        if (filterData.description === null) {
            delete filterData.description;
        }
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editEducationEvent(id),
                data: filterData,
                params: {
                    _method: "PUT",
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async changeEventStatus(id, data) {
        const filterData = { ...data };
        if (filterData.start_at === "") {
            delete filterData.start_at;
        }

        if (filterData.start_time === "") {
            delete filterData.start_time;
        }
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.changeEventStatus(id),
                data: filterData,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async deleteEvent(id) {
        try {
            const response = await axiosInstance({
                method: "DELETE",
                url: apiList.deleteEvent(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },
};

import React, { useEffect, useState } from "react";
import useTreeState from "use-tree-state";
import FolderTreeRecursive from "./FolderTreeRecursive";
import { mapRadioNode } from "./utils/mapRadioTree";

function RootNode(props) {
  const [lastElementsList, setLastElementsList] = useState([]);
  const {
    data,
    isPrevFolderOpen,
    hasChild,
    prevFolderHasChild,
    isFirstElement,
    isLastElement,
    onTreeChange,
    fullData,
    radio,
    toggleChange,
    purchases,
  } = props;

  useEffect(() => {
    setLastElementsList([isLastElement]);
  }, []);

  const { treeState, reducers } = useTreeState({
    data,
    options: { initCheckedStatus: "custom", initOpenStatus: "closed" },
    onChange: (newState, event) =>
      onTreeChange(data.custom_id, newState, event),
  });

  const { toggleOpenByProp, checkNodeByProp, setTreeState } = reducers;

  useEffect(() => {
    if (radio) setTreeState(data);
  }, [data.checked, radio]);

  const handleRadioToggle = (e, type) => {
    toggleChange(e, type);
    setTreeState(mapRadioNode(e.target.value, treeState));
  };

  return (
    <FolderTreeRecursive
      key={
        data.type
          ? `${data.foreign_type}${data.id}${data.type}`
          : `${data.foreign_type}${data.id}`
      }
      level={0}
      isFirstChild={isFirstElement}
      isLastChild={isLastElement}
      isPrevFolderOpen={isPrevFolderOpen}
      hasChild={hasChild}
      prevFolderHasChild={prevFolderHasChild}
      lastElementsList={lastElementsList}
      data={treeState}
      setOpen={toggleOpenByProp}
      setChecked={checkNodeByProp}
      fullData={fullData}
      radio={radio}
      toggleChange={handleRadioToggle}
      purchases={purchases}
    />
  );
}

export default RootNode;

import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import View from "./View";

const Controller = (props) => {
    const {
        selectAllTitle,
        selectList,
        setSelectList,
        noSelectList,
        setNoSelectList,
        allSelectList,
        loading,
        onScroll,
        onSearch,
        inputValue,
        isNotWork,
        isError,
    } = props;

    const [isOnList, setIsOnList] = useState(false);
    const [isAll, setIsAll] = useState(true);
    const [searchValue, setSearchValue] = useState("");

    const selectRef = useRef();

    useEffect(() => {
        if (isOnList) {
            setSearchValue("");
            return;
        }
        setSearchValue(inputValue);
        return;
    }, [inputValue, isOnList]);

    useEffect(() => {
        function onClickOutside(event) {
            if (!selectRef?.current?.contains(event.target)) {
                setIsOnList(false);
            }
        }
        document.addEventListener("mousedown", onClickOutside);
        return () => document.removeEventListener("mousedown", onClickOutside);
    }, []);

    const setSelect = (item) => {
        if (selectAllTitle) setSelectList([...selectList, item]);
        else setSelectList([item]);
        setIsAll(false);
    };
    const setNoSelect = (item) => {
        setSelectList(selectList.filter((el) => el.selectedCode !== item.selectedCode));
    };

    const selectAll = () => {
        setSelectList([]);
        setIsAll(true);
    };

    useEffect(() => {
        setNoSelectList(allSelectList.filter((el) => !selectList.includes(el)));
        if (selectList.length === 0) setIsAll(true);
    }, [allSelectList, selectList, setNoSelectList]);

    const { ref: lastItemRef, inView } = useInView({ threshold: 0 });

    useEffect(() => {
        if (inView) {
            onScroll?.();
        }
    }, [inView]);

    const refObject = { selectRef: selectRef, lastItemRef: lastItemRef };

    const setInputValue = (value) => {
        setSearchValue(value);
        onSearch(value);
    };

    const noFound = selectList.length === 0 && noSelectList.length === 0;

    return (
        <View
            selectAllTitle={selectAllTitle}
            isOnList={isOnList}
            setShowList={() => setIsOnList(true)}
            setHideList={() => setIsOnList(false)}
            inputValue={searchValue}
            setInputValue={(value) => setInputValue(value)}
            selectList={selectList}
            setSelect={(item) => setSelect(item)}
            noSelectList={noSelectList}
            setNoSelect={(item) => setNoSelect(item)}
            selectAll={() => selectAll()}
            isAll={isAll}
            loading={loading}
            ref={refObject}
            noFound={noFound}
            isNotWork={isNotWork}
            isError={isError}
        />
    );
};

export default Controller;

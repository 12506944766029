import { useEffect, useCallback } from 'react';

/**
 * @param {HTMLElement} ref - элемент, клик вне которого запускает callback
 * @param {function} callback - функция, срабатывающая при клике по внешним ref элементам
 * @ignore
 * Пример использования:
 *
 * - создается ref на некий HTML элемент
 * - создается некое событие callback, которое должно сработать только при клике/нажатии вне данного ref
 *
 * @example
 * useClickOutside(myRef, myCallback);
 */

export const useClickOutside = (ref, callback) => {
  const handleClick = useCallback(
    e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);
};

import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";

import { Button } from "reactstrap";
import Preloader from "components/Preloader/Preloader";
import getStudentsList from "../../../api/endPoints/users";
import users from "../../../api/endPoints/users";

import deleteIcon from "../../../assets/img/deleteIcon.svg";

import * as style from "./style/StudentsList.module.scss";
import Pagination from "components/Pagination/Pagination";
import Menu from "./Menu.jsx";
import DeleteUsers from "../DeleteUsers/DeleteUsers";
import { useStore } from "store";
import UnblockUser from "../UnblockUser/UnblockUser";
import BlockUser from "../BlockUsers/BlockUser";
import paths from "constants/paths";
import role from "constants/role";
import { toast } from "react-toastify";
import UserLinkWrapper from "../UserLinkWrapper/UserLinkWrapper";

function StudentComponent({ filter }) {
    const history = useHistory();
    const [studentsList, setStudentsList] = useState();
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const isAdmin = useMemo(() => localStorage.getItem("role") === role.admin, []);

    const {
        modalWindowStore: {
            isDeleteModalOpen,
            isBlockModalOpen,
            isUnBlockModalOpen,
            setIsDeleteModalOpen,
            setIsBlockModalOpen,
            setIsUnBlockModalOpen,
        },
    } = useStore();

    useEffect(() => {
        setLoading(true);
        getStudentsList
            .getStudentsList(filter, page)
            .then((response) => {
                setStudentsList(response.items);
                setLastPage(response.last_page);
                setPaginationLoading(false);
                setLoading(false);
            })
            .catch();
    }, [page, filter, isDeleteModalOpen, isBlockModalOpen, isUnBlockModalOpen]);

    const deleteStudent = () => {
        setModalLoading(true);
        apiDeleteUser(isDeleteModalOpen.id);
    };

    const apiDeleteUser = (id) => {
        users.deleteUser(id).then(() => {
            setModalLoading(false);
            setIsDeleteModalOpen(false);
            toast.success("Пользователь удален");
        });
    };

    const prevPage = (number) => {
        setPaginationLoading(true);
        setPage(page - number);
    };

    const nextPage = (number) => {
        setPaginationLoading(true);
        setPage(page + number);
    };

    const lastPageFunction = (number) => {
        setPaginationLoading(true);
        setPage(number);
    };

    const clickBlockStudent = () => {
        setModalLoading(true);
        apiBlockStudent(isBlockModalOpen.id);
    };

    const apiBlockStudent = (id) => {
        users.blockTeacher(id).then(() => {
            setIsBlockModalOpen(false);
            setModalLoading(false);
            toast.success("Пользователь заблокирован");
        });
    };

    const apiUnBlockStudent = (id) => {
        setModalLoading(true);
        users.unblockUser(id).then(() => {
            setIsUnBlockModalOpen(false);
            setModalLoading(false);
            toast.success("Пользователь разблокирован");
        });
    };

    return (
        <>
            {studentsList ? (
                <div>
                    <div>
                        {paginationLoading || loading ? (
                            <div className={`row ${style.lessonsPreloader}`}>
                                <Preloader />
                            </div>
                        ) : (
                            <>
                                <table className={style.tableWidth}>
                                    {studentsList.length === 0 ? (
                                        <div>
                                            <p className={style.lessonsListNull}>
                                                Ничего не найдено...
                                            </p>
                                        </div>
                                    ) : (
                                        <thead>
                                            <tr className={style.tableBorder}>
                                                <th className={style.tableHeaderNumber}>Номер</th>
                                                <th className={style.tableHeaderFio}>ФИО</th>
                                                <th className={style.tableHeaderEducationForm}>
                                                    Форма обучения
                                                </th>
                                                <th className={style.tableHeaderDevergency}>
                                                    Email
                                                </th>
                                                <th className={style.tableHeaderClass}>Группа</th>
                                                <th className={style.tableHeaderAge}>Возраст</th>
                                                <th className={style.tableHeader}></th>
                                            </tr>
                                        </thead>
                                    )}

                                    <tbody>
                                        {studentsList
                                            ? studentsList.map((item, index) => (
                                                  <tr className={style.tableBorder} key={index}>
                                                      <td>{(page - 1) * 25 + index + 1}</td>
                                                      <td
                                                          className={
                                                              item.user.is_blocked
                                                                  ? style.userNameBlocked
                                                                  : style.userName
                                                          }>
                                                          <UserLinkWrapper
                                                              link={paths.userDetail(item.user.id)}
                                                              blocked={item.user.is_blocked}>
                                                              {item.user.first_name ||
                                                                  "Неизвестный"}{" "}
                                                              &nbsp;
                                                              {item.user.last_name ||
                                                                  "пользователь"}
                                                              &nbsp;
                                                              {/* {item.user.middle_name} */}
                                                          </UserLinkWrapper>
                                                      </td>
                                                      <td
                                                          className={
                                                              item.user.is_blocked
                                                                  ? style.userNameBlocked
                                                                  : style.userEducationFormat
                                                          }>
                                                          <UserLinkWrapper
                                                              link={paths.userDetail(item.user.id)}
                                                              blocked={item.user.is_blocked}>
                                                              {item?.profile?.student
                                                                  ?.education_format === null ? (
                                                                  <>&#8212;</>
                                                              ) : (
                                                                  item?.profile?.student
                                                                      ?.education_format?.name
                                                              )}
                                                          </UserLinkWrapper>
                                                      </td>
                                                      <td
                                                          className={
                                                              item.user.is_blocked
                                                                  ? style.userNameBlocked
                                                                  : style.userName
                                                          }>
                                                          <UserLinkWrapper
                                                              link={paths.userDetail(item.user.id)}
                                                              blocked={item.user.is_blocked}>
                                                              {item?.user?.email === null ? (
                                                                  <>&#8212;</>
                                                              ) : (
                                                                  <>{item?.user?.email}</>
                                                              )}
                                                          </UserLinkWrapper>
                                                      </td>
                                                      <td
                                                          className={
                                                              item.user.is_blocked
                                                                  ? style.userNameBlocked
                                                                  : style.userEducationFormat
                                                          }>
                                                          <UserLinkWrapper
                                                              link={paths.userDetail(item.user.id)}
                                                              blocked={item.user.is_blocked}>
                                                              {item?.profile?.student
                                                                  ?.education_class === null ? (
                                                                  <>&#8212;</>
                                                              ) : (
                                                                  item?.profile?.student
                                                                      ?.education_class?.name
                                                              )}
                                                          </UserLinkWrapper>
                                                      </td>
                                                      <td
                                                          className={
                                                              item.user.is_blocked
                                                                  ? style.userNameBlocked
                                                                  : style.userName
                                                          }>
                                                          <UserLinkWrapper
                                                              link={paths.userDetail(item.user.id)}
                                                              blocked={item.user.is_blocked}>
                                                              {item?.profile?.student?.age ===
                                                              null ? (
                                                                  <>&#8212;</>
                                                              ) : (
                                                                  item?.profile?.student?.age
                                                              )}
                                                          </UserLinkWrapper>
                                                      </td>

                                                      <td className={style.buttonCol}>
                                                          {isAdmin && (
                                                              <Button
                                                                  color="inherit"
                                                                  className={style.tableDeleteBtn}
                                                                  onClick={() => {
                                                                      setIsDeleteModalOpen(
                                                                          true,
                                                                          item.user.id,
                                                                      );
                                                                  }}>
                                                                  <img
                                                                      src={deleteIcon}
                                                                      alt="Delete"
                                                                  />
                                                              </Button>
                                                          )}

                                                          <Menu
                                                              id={item.user.id}
                                                              isBlocked={item.user.is_blocked}
                                                              loading={loading}
                                                          />
                                                      </td>
                                                  </tr>
                                              ))
                                            : ""}
                                    </tbody>
                                </table>
                                <div className={style.lessonPagination}>
                                    <Pagination
                                        page={page}
                                        nextPage={nextPage}
                                        prevPage={prevPage}
                                        lastPage={lastPage}
                                        lastPageFunction={lastPageFunction}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {isDeleteModalOpen && (
                        <DeleteUsers deleteUser={deleteStudent} loading={modalLoading} />
                    )}

                    {isBlockModalOpen && (
                        <BlockUser blockUser={clickBlockStudent} loading={modalLoading} />
                    )}
                    {isUnBlockModalOpen && (
                        <UnblockUser apiUnBlockTeacher={apiUnBlockStudent} loading={modalLoading} />
                    )}
                </div>
            ) : (
                <div className="row">
                    <Preloader />
                </div>
            )}
        </>
    );
}
export default observer(StudentComponent);

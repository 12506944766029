import React, { useMemo } from "react";
import PopoverMenu from "./PopoverMenu";
import ModalDeleteEvent from "../ModalDeleteEvent";
import bell from "assets/img/bell.png";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const {
        notification,
        colorsStyle,
        time,
        mentorActions,
        isDeleteModalOpen,
        closeDeleteModalOpen,
    } = props;

    const lessonAvatar = useMemo(() => {
        return (
            <div className={styles.lesson__content__top__icon}>
                <DeleteIcon />
                <PopoverMenu
                    key={`lesson-${notification.id}`}
                    target={`lesson-${notification.id}`}
                    actions={mentorActions}
                />
            </div>
        );
    }, []);

    const deleteModal = isDeleteModalOpen && (
        <ModalDeleteEvent onClose={closeDeleteModalOpen} eventId={notification.id} />
    );

    return (
        <>
            {deleteModal}
            <div
                key={`lesson-${notification.id}`}
                className={styles.lesson}
                style={colorsStyle}
                id={`lesson-${notification.id}`}>
                <div className={styles.lesson__content}>
                    <div className={styles.lesson__content__top}>
                        <img className={styles.lesson__content__top__bell} src={bell} alt="bell" />
                        <span
                            tabIndex="0"
                            role="button"
                            className={styles.lesson__content__top__title}
                            id={`lesson-title-${notification.id}`}
                            title={notification?.name ?? notification?.type?.name}>
                            {notification?.name ?? notification?.type?.name}
                        </span>
                        {lessonAvatar}
                    </div>

                    {!!notification?.subject?.name && (
                        <span
                            id={`lesson-subject-${notification.id}`}
                            className={styles.lesson__content__subtitle}
                            title={notification.subject.name}>
                            {notification.subject.name}
                        </span>
                    )}
                    <div className={styles.lesson__content__description}>
                        {!!notification.description && (
                            <span
                                id={`lesson-description-${notification.id}`}
                                className={styles.lesson__description__text}
                                title={notification.description}>
                                {notification.description}
                            </span>
                        )}
                    </div>
                </div>
                <span className={styles.lesson__time}>{time}</span>
            </div>
        </>
    );
};

export default View;

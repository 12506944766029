import React from "react";
import moment from "moment";
import { WEEK_DATE_FORMAT } from "../utils";
import View from "./View";

const Controller = (props) => {
    const { date } = props;

    moment.locale();
    const dayOfMonth = moment(date).format(WEEK_DATE_FORMAT);
    const dayOfWeek = moment(date).format("llll").split(",")[0];

    return <View dayOfMonth={dayOfMonth} dayOfWeek={dayOfWeek} />;
};

export default Controller;

/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { format } from "date-fns";
import "bootstrap/dist/css/bootstrap.css";
import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from "reactstrap";
import { FormCheck } from "react-bootstrap";
import lessonApi from "../../api/endPoints/lesson";
import deleteIcon from "../../assets/img/deleteIcon.svg";
import imgIcon from "../../assets/img/imgIcon.svg";
import * as style from "./style/EditLesson.module.scss";
import MakeLinks from "../Popup/MakeLinks";
import LeavePage from "../Popup/LeavePage";
import Preloader from "../Preloader/Preloader";
import { useStore } from "store";
import paths from "constants/paths";
import validation from "../CreateLesson/utils/validation";
import { toast } from "react-toastify";
import downloadLogo from "../../assets/img/downloadLogo.svg";
import CropperModal from "../Popup/CropperModal";
import { set } from "lodash";

function EditLesson() {
  const {
    modalWindowStore: {
      isCloseModalOpen,
      setIsCloseModalOpen,
      cropperModalOpen,
      seCropperModalOpen,
    },
  } = useStore();
  const params = useParams();
  const [data, setData] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const [toilet, setToilet] = useState(false);
  const [individual, setIndividual] = useState(false);
  const [teachers, setTeachers] = useState();
  const [minDate, setMinDate] = useState();
  const formNames = {
    name: "name",
    start_at: "start_at",
    button_1_name: "button_1_name",
    button_2_name: "button_2_name",
    button_toilet_enabled: "button_toilet_enabled",
    is_individual: "is_individual",
    teacher_id: "teacher_id",
  };
  let firstImgElem = document.getElementById("firstFile");
  let secondImgElem = document.getElementById("secondFile");
  const [firstChangeImg, setFirstChangeImg] = useState(false);
  const [secondChangeImg, setSecondChangeImg] = useState(false);
  const [startAt, setStartAt] = useState("");
  const [links, setLinks] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstImgFromServer, setFirstImgForServer] = useState(false);
  const [secondImgFromServer, setSecondImgForServer] = useState(false);
  const [errors, setErrors] = useState({});
  const [cropper, setCropper] = useState();
  const [cropPhoto, setCropPhoto] = useState();
  const [inputLableFor, setInputLableFor] = useState(null);
  const [image, setImage] = useState();

  const SIZE_LIMIT = 10485760;

  const [createLesson, setCreateLesson] = useState({
    name: "",
    start_at: "",
    button_toilet_enabled: false,
    is_individual: false,
    teacher_id: localStorage.getItem("id"),
    button_1_name: "",
    button_2_name: "",
  });

  const [editLesson, setEditLesson] = useState({});

  useEffect(() => {
    lessonApi.getLesson(params.id).then((response) => {
      setData(true);
      setCreateLesson({
        ...createLesson,
        ...response,
        teacher_id: response.teacher.id,
      });
    });
    lessonApi.getTeachers().then((response) => setTeachers(response));
    let date = new Date();
    date = format(date, "yyyy-MM-dd");
    setMinDate(date);
  }, []);

  useEffect(() => {
    if (createLesson.button_1_image_src) {
      setFirstImgForServer(createLesson.button_1_name);
    } else {
      setData(false);
    }

    if (createLesson.button_2_image_src) {
      setSecondImgForServer(createLesson.button_2_name);
    } else {
      setData(false);
    }
    setToilet(createLesson.button_toilet_enabled);
    setIndividual(createLesson.is_individual);
    let date = createLesson.start_at.split(".");
    setStartAt(date[0]);
  }, [data]);

  const toggleToilet = (event) => {
    const { name } = event.target;
    let values = { ...createLesson };
    values[name] = !toilet;
    let newValues = { ...editLesson };
    newValues[name] = !toilet;
    setEditLesson(newValues);
    setCreateLesson(values);
    setToilet(!toilet);
  };

  const toggleIndividual = (event) => {
    const { name } = event.target;
    let values = { ...createLesson };
    values[name] = !individual;
    let newValues = { ...editLesson };
    newValues[name] = !individual;
    setEditLesson(newValues);
    setCreateLesson(values);
    setIndividual(!individual);
  };

  const toggleCreate = () => {
    setModalCreate(!modalCreate);
  };

  const onBlur = async (e) => {
    const { name } = e.target;
    const validationErrors = await validation.clientValidateField(name, {
      ...createLesson,
    });

    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const clearErrField = (field) => {
    const checkError = { ...errors };

    if (checkError[field]) {
      delete checkError[field];
    }
    setErrors({ ...checkError });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    clearErrField(name);
    let values = { ...createLesson };
    let newValues = { ...editLesson };
    switch (name) {
      case formNames.name:
        values[name] = value;
        newValues[name] = value;
        break;
      case formNames.button_1_name:
        if (value === "" && createLesson.button_1_image_src === null) {
          setErrors({
            ...errors,
            button_1_name: "",
            button_2_name: "",
            button_1_image: "",
            button_2_image: "",
          });
        }
        values[name] = value;
        newValues[name] = value;
        break;
      case formNames.button_2_name:
        if (value === "" && createLesson.button_2_image_src === null) {
          setErrors({
            ...errors,
            button_1_name: "",
            button_2_name: "",
            button_1_image: "",
            button_2_image: "",
          });
        }
        values[name] = value;
        newValues[name] = value;
        break;
      case formNames.start_at:
        const setMinDate = `${minDate}T00:00`;
        setStartAt(value);
        if (setMinDate > value) {
          setErrors({
            ...errors,
            start_at: `Дата начала урока не может быть меньше ${minDate}`,
          });
          setCreateLesson({ ...createLesson, start_at: "" });
        } else {
          values[name] = value;
          newValues[name] = value;
        }
        break;
      case formNames.teacher_id:
        values[name] = Number(value);
        newValues[name] = Number(value);
        break;
    }
    setCreateLesson(values);
    setEditLesson(newValues);
  };

  const getCropData = (e) => {
    e.preventDefault();
    seCropperModalOpen(false);

    if (typeof cropper !== "undefined") {
      setCropPhoto(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
      fetch(cropper.getCroppedCanvas().toDataURL("image/jpeg"))
        .then((res) => res.blob())
        .then((file) => {
          if (inputLableFor === "firstFile") {
            setFirstChangeImg(file);
            delete errors.button_1_image;
          } else {
            setSecondChangeImg(file);
            delete errors.button_2_image;
          }
          setImage(null);
          setInputLableFor(null);
        });
    }
  };

  const handleChangeImg = (e) => {
    e.preventDefault();
    const { name } = e.target;
    clearErrField(name);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    switch (name) {
      case "firstFile":
        if (e.target.files[0].size <= SIZE_LIMIT) {
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(files[0]);
        } else {
          firstImgElem.value = null;
          toast.info("Вес файла больше 10 МБ");
        }
        break;
      case "secondFile":
        if (e.target.files[0].size <= SIZE_LIMIT) {
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(files[0]);
        } else {
          secondImgElem.value = null;
          toast.info("Вес файла больше 10 МБ");
        }
        break;
    }
  };

  const changeImgInput = (name) => {
    switch (name) {
      case "firstFile":
        firstImgElem.value = "";
        setFirstChangeImg(null);
        break;
      case "secondFile":
        secondImgElem.value = "";
        setSecondChangeImg(null);
        break;
    }
    setImage(null);
    seCropperModalOpen(false);
  };

  const submitButton = async () => {
    setLoading(true);
    if (
      createLesson.button_1_image_src === null &&
      editLesson.button_1_name === ""
    ) {
      delete editLesson.button_1_name;
    }
    if (
      createLesson.button_2_image_src === null &&
      editLesson.button_2_name === ""
    ) {
      delete editLesson.button_2_name;
    }
    let formData = new FormData();
    let jsonBody = JSON.stringify(editLesson);
    formData.append("jsonBody", jsonBody);

    firstImgElem = document.getElementById("firstFile");
    secondImgElem = document.getElementById("secondFile");

    if (firstChangeImg) {
      formData.append("button_1_image", firstChangeImg);
    }
    if (secondChangeImg) {
      formData.append("button_2_image", secondChangeImg);
    }
    formData.append("_method", "PUT");

    const validateErrors = await validation.clientValidateForm({
      ...createLesson,
    });

    if (validateErrors) {
      setErrors({ ...validateErrors });
      setLoading(false);
    } else {
      setErrors({});
      lessonApi
        .editLesson(params.id, formData)
        .then((response) => {
          setLoading(false);
          setLinks(response.hash);
          toggleCreate();
        })
        .catch((error) => {
          setLoading(false);
          setErrors(error.data.error.advanced);
        });
    }
  };

  const openCroperModal = (name) => {
    setInputLableFor(name);
    seCropperModalOpen(true);
  };

  return (
    <>
      {teachers && createLesson ? (
        <>
          <div className="row">
            <div className={`col-md-7 ${style.wrapper}`}>
              <Button
                className={style.btn_link}
                color="link"
                onClick={() => setIsCloseModalOpen(true)}
              >
                {"< Назад"}
              </Button>
              <p className={style.pageTitle}>Редактирование урока</p>
              <Form>
                <FormGroup>
                  <Label className={style.inputLable} for="nameOfLesson">
                    Тема урока
                  </Label>
                  <Input
                    className={style.inputImgName}
                    type="text"
                    name={formNames.name}
                    id="nameOfLesson"
                    placeholder="Введите тему урока"
                    value={createLesson.name}
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={(e) => onBlur(e)}
                    invalid={!!errors.name}
                  />
                  <FormFeedback>{errors?.name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label className={style.inputLable} for="Date">
                    Дата урока
                  </Label>
                  <Input
                    className={style.inputImgName}
                    type="datetime-local"
                    name={formNames.start_at}
                    id="Date"
                    value={startAt}
                    onChange={handleChange}
                    placeholder="date placeholder"
                    max="9999-12-31T00:00"
                    min={`${minDate}T00:00`}
                    autoComplete="off"
                    onBlur={(e) => onBlur(e)}
                    invalid={!!errors.start_at}
                  />
                  <FormFeedback>{errors?.start_at}</FormFeedback>
                </FormGroup>
                <div className={style.inputFileWrapper}>
                  <div className={style.inputImg}>
                    <div className={style.inputImgFirstImg}>
                      <p className={style.inputImgTxt}>Кнопка Тема урока №1</p>
                      <FormGroup>
                        <Label className={style.inputLable} for="nameOfImg1">
                          Название кнопки
                        </Label>
                        <Input
                          className={style.inputImgName}
                          type="text"
                          name={formNames.button_1_name}
                          id="nameOfImg1"
                          placeholder="Введите название кнопки"
                          value={createLesson.button_1_name}
                          onChange={handleChange}
                          onBlur={(e) => onBlur(e)}
                          invalid={!!errors?.button_1_name}
                          autoComplete="off"
                        />
                        <FormFeedback>{errors?.button_1_name}</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Input
                          className={style.fileInput}
                          type="file"
                          name="firstFile"
                          id="firstFile"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleChangeImg}
                          invalid={!!errors?.button_1_image}
                        />
                        {firstImgFromServer ? (
                          <div className={style.inputImgWrapper}>
                            <img
                              className={style.inputImgWrapperImg}
                              src={imgIcon}
                              alt="icon"
                            />
                            <a
                              href={createLesson.button_1_image_src}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {firstImgFromServer}
                            </a>
                            <Button
                              className={style.inputImgWrapperBtn}
                              outline
                              color="inherit"
                              name="firstImgDeleteBtn"
                              onClick={() => setFirstImgForServer(null)}
                            >
                              <img src={deleteIcon} alt="icon" />
                            </Button>
                          </div>
                        ) : (
                          <>
                            {firstChangeImg ? (
                              <div className={style.inputImgWrapper}>
                                <img
                                  className={style.inputImgWrapperImg}
                                  src={imgIcon}
                                  alt="icon"
                                />
                                <p className={style.inputImgWrapperTxt}>
                                  {firstImgElem?.files[0]?.name}
                                </p>
                                <Button
                                  className={style.inputImgWrapperBtn}
                                  outline
                                  color="inherit"
                                  name="firstFile"
                                  onClick={() => changeImgInput("firstFile")}
                                >
                                  <img src={deleteIcon} alt="icon" />
                                </Button>
                              </div>
                            ) : (
                              <Button
                                color="inherit"
                                className={style.fileLable}
                                onClick={() => openCroperModal("firstFile")}
                              >
                                <img
                                  className={style.fileImg}
                                  color="inherit"
                                  src={downloadLogo}
                                  alt=""
                                />
                                Прикрепить изображение
                              </Button>
                            )}
                          </>
                        )}
                        <FormFeedback>{errors?.button_1_image}</FormFeedback>
                      </FormGroup>
                    </div>

                    <div>
                      <p className={style.inputImgTxt}>Кнопка Тема урока №2</p>
                      <FormGroup>
                        <Label className={style.inputLable} for="nameOfImg2">
                          Название кнопки
                        </Label>
                        <Input
                          className={style.inputImgName}
                          type="text"
                          name={formNames.button_2_name}
                          id="nameOfImg2"
                          placeholder="Введите название кнопки"
                          value={createLesson.button_2_name}
                          onChange={handleChange}
                          invalid={!!errors?.button_2_name}
                          onBlur={(e) => onBlur(e)}
                          autoComplete="off"
                        />
                        <FormFeedback>{errors?.button_2_name}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        {secondImgFromServer ? (
                          <div className={style.inputImgWrapper}>
                            <img
                              className={style.inputImgWrapperImg}
                              src={imgIcon}
                              alt="icon"
                            />
                            <a
                              href={createLesson.button_2_image_src}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {secondImgFromServer}
                            </a>
                            <Button
                              className={style.inputImgWrapperBtn}
                              outline
                              color="inherit"
                              name="firstImgDeleteBtn"
                              onClick={() => setSecondImgForServer(false)}
                            >
                              <img src={deleteIcon} alt="icon" />
                            </Button>
                          </div>
                        ) : (
                          <>
                            {secondChangeImg ? (
                              <div className={style.inputImgWrapper}>
                                <img
                                  className={style.inputImgWrapperImg}
                                  src={imgIcon}
                                  alt="icon"
                                />
                                <p className={style.inputImgWrapperTxt}>
                                  {secondImgElem?.files[0]?.name}
                                </p>
                                <Button
                                  className={style.inputImgWrapperBtn}
                                  outline
                                  color="inherit"
                                  name="secondFile"
                                  onClick={() => changeImgInput("secondFile")}
                                >
                                  <img src={deleteIcon} alt="icon" />
                                </Button>
                              </div>
                            ) : (
                              <Button
                                color="inherit"
                                className={style.fileLable}
                                onClick={() => openCroperModal("secondFile")}
                              >
                                <img
                                  className={style.fileImg}
                                  color="inherit"
                                  src={downloadLogo}
                                  alt=""
                                />
                                Прикрепить изображение
                              </Button>
                            )}
                          </>
                        )}
                        <Input
                          className={style.fileInput}
                          type="file"
                          name="secondFile"
                          id="secondFile"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleChangeImg}
                          invalid={!!errors?.button_2_image}
                        />
                        <FormFeedback>{errors?.button_2_image}</FormFeedback>
                      </FormGroup>
                    </div>
                  </div>
                  <FormText color="muted">
                    Макс. допустимый вес изображения 10 МБ
                  </FormText>
                </div>
                <div className={style.toogleWrapper}>
                  <FormGroup className={style.toilet}>
                    <p className={style.inputImgTxt}>Кнопка Туалет</p>
                    <FormCheck
                      type="switch"
                      id="toilet"
                      name={formNames.button_toilet_enabled}
                      checked={createLesson.button_toilet_enabled}
                      onChange={toggleToilet}
                    />
                    {toilet ? (
                      <Label for="toilet" className={style.checkboxLable}>
                        On
                      </Label>
                    ) : (
                      <Label for="toilet" className={style.checkboxLable}>
                        Off
                      </Label>
                    )}
                  </FormGroup>
                  <FormGroup className={style.individualLesson}>
                    <p className={style.inputImgTxt}>Индивидуальный урок</p>
                    <FormCheck
                      type="switch"
                      id="individual"
                      name={formNames.is_individual}
                      checked={createLesson.is_individual}
                      onChange={toggleIndividual}
                    />
                    {individual ? (
                      <Label for="individual" className={style.checkboxLable}>
                        On
                      </Label>
                    ) : (
                      <Label for="individual" className={style.checkboxLable}>
                        Off
                      </Label>
                    )}
                  </FormGroup>
                </div>
                <FormGroup>
                  <Label className={style.inputLable} for="selectTeacher">
                    Выберите преподавателя
                  </Label>
                  <Input
                    type="select"
                    className={style.selectTeacher}
                    name={formNames.teacher_id}
                    id="selectTeacher"
                    aria-label="Выберите"
                    value={createLesson.teacher_id}
                    onChange={handleChange}
                    invalid={!!errors?.teacher_id}
                    onBlur={(e) => onBlur(e)}
                  >
                    <option>Выберите</option>
                    {teachers.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.last_name} {item.first_name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{errors?.teacher_id}</FormFeedback>
                </FormGroup>
                {loading ? (
                  <Button disabled color="primary" className={style.submitBtn}>
                    <div
                      className={`spinner-border text-light ${style.loading}`}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className={style.submitBtn}
                    onClick={() => submitButton()}
                    disabled={errors?.start_at}
                  >
                    Сохранить изменения
                  </Button>
                )}
              </Form>
            </div>
          </div>
          <div>
            {modalCreate && (
              <MakeLinks
                modal={modalCreate}
                toggle={toggleCreate}
                links={links}
              />
            )}
          </div>
          {isCloseModalOpen && <LeavePage page={paths.lessonList} />}
        </>
      ) : (
        <div className="row">
          <Preloader />
        </div>
      )}
      {cropperModalOpen && (
        <CropperModal
          image={image}
          setCropper={setCropper}
          getCropData={getCropData}
          cancelClick={changeImgInput}
          lableFor={inputLableFor}
          croppSize={150}
          hint="Выберите область, которая будет отображаться в плеере"
          ratio={1}
        />
      )}
    </>
  );
}

export default observer(EditLesson);

import BreadCrumbs from 'components/Breadcrumbs/Breadcrumbs';
import paths from 'constants/paths';
import React from 'react';
import history from 'utils/history/history';
import FoldersComponent from '../Folders/FoldersComponent';
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import * as style from './style/CatalogResources.module.scss';
import { Button } from 'reactstrap';

function CatalogResources() {
  return (
    <div>
      <Button
            className={style.btn_link}
            color="link"
            onClick={() => history.push(paths.adminPanel)}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
      <BreadCrumbs />
      <div>
        <h1 className={style.title}>Каталог ресурсов </h1>
      </div>
      <FoldersComponent />
    </div>
  );
}

export default CatalogResources;

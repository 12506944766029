import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import history from "../../utils/history/history";
import paths from "../../constants/paths";
import * as style from "./style/UsersList.module.scss";
import * as styles from "./style/CreateMaterial.module.scss";

import { Button } from "reactstrap";
import { useStore } from "store";
import TeachersList from "./TeachersList/TeachersList";
import StudentsList from "./StudentsList/StudentsList";
import role from "constants/role";
import { ReactComponent as ArrowBack } from "../../assets/img/arrowBack.svg";

function UsersList(props) {
    const {
        userStore: { setUserListTabs, userListTabs, setCreateUserTabs },
    } = useStore();

    const resid = props.match.params.id;

    function handleClick(e, tab) {
        e.preventDefault();
        setUserListTabs(tab);
    }

    const isAdmin = localStorage.getItem("role");

    const goToAddUsers = () => {
        if (userListTabs === "tab2") {
            setCreateUserTabs("tab2");
        } else {
            setCreateUserTabs("tab1");
        }
        history.push(paths.addUsers);
    };
    useEffect(() => {
        localStorage.removeItem("path");
    }, []);
    return (
        <div>
            <div className={`row ${style.wrapper}`}>
                <Button
                    className={style.btn_link}
                    color="link"
                    onClick={() => history.push(paths.adminPanel)}>
                    <ArrowBack />
                    <p className={style.btn_link_text}>Назад</p>
                </Button>
                <div className={style.listHeader}>
                    <p className={style.listTopTxt}>Управление пользователями</p>
                    {isAdmin === role.admin && (
                        <Button
                            color="primary"
                            className={style.createLesson}
                            onClick={goToAddUsers}>
                            Добавить
                        </Button>
                    )}
                </div>

                <div className={styles.tab_wrapper}>
                    <a
                        href="/"
                        onClick={(e) => handleClick(e, "tab1")}
                        className={
                            userListTabs === "tab1"
                                ? `${styles.tab_active} ${styles.tab}`
                                : styles.tab
                        }>
                        Ученики
                    </a>
                    {isAdmin === role.admin && (
                        <a
                            href="/"
                            onClick={(e) => handleClick(e, "tab2")}
                            className={
                                userListTabs === "tab2"
                                    ? `${styles.tab_active} ${styles.tab}`
                                    : styles.tab
                            }>
                            Педагоги
                        </a>
                    )}

                    <div className={styles.emptytab}></div>
                </div>

                {isAdmin === role.admin ? (
                    <div className={styles.tabs}>
                        {userListTabs === "tab2" ? (
                            <TeachersList resid={resid} />
                        ) : (
                            <StudentsList resid={resid} />
                        )}
                    </div>
                ) : (
                    <StudentsList resid={resid} />
                )}
            </div>
        </div>
    );
}
export default observer(UsersList);

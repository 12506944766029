import axiosInstance from "..";
import apiList from "../../constants/apiList";

export default {
  async createLesson(formData) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.createLesson,
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getTeachers() {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getTeachers,
      });
      return response.data.data.items;
    } catch (error) {
      throw error.response;
    }
  },
  async getLesson(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getLesson(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async editLesson(id, formData) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.getLesson(id),
        data: formData,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};

import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import * as style from "./style/FormDetail.module.scss";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import history from "utils/history/history";
import paths from "constants/paths";
import { ReactComponent as Pen } from "../assets/pen.svg";
import DetailMaterials from "../DetailMaterials/DetailMaterials";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import PublicFormModal from "components/Popup/PublicFormModal";
import EditFormModal from "components/Popup/EditFormModal";
import educationForms from "api/endPoints/educationForms";
import { useParams } from "react-router";
import Preloader from "components/Preloader/Preloader";

function FormDetail() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const {
    modalWindowStore: {
      isEditFormModal,
      isFormPublic,
      setIsEditFormModal,
      setIsFormPublic,
    },
  } = useStore();

  useEffect(() => {
    educationForms
      .getCurrentForm(params.id)
      .then((response) => setData(response));
  }, [loading]);

  const handleClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  return (
    <>
      {data ? (
        <>
          <div>
            <div className={style.header}>
              <div>
                <Button
                  className={style.btn_link}
                  color="link"
                  onClick={() => history.push(paths.educationForms)}
                >
                  <ArrowBack />
                  <p className={style.btn_link_text}>Назад</p>
                </Button>
              </div>
              <div className={style.title_wrapper}>
                <p className={style.title}>{data.name}</p>
                <div>
                  <Button
                    color="outline"
                    className={style.title_wrapper_editForm}
                    onClick={() => setIsEditFormModal(true)}
                  >
                    <Pen className={style.title_wrapper_editForm_pen} />
                    Редактировать шаблон
                  </Button>
                  {data.is_active ? (
                    <Button
                      color="outline"
                      className={style.title_wrapper_unpablish}
                      onClick={() => setIsFormPublic(true)}
                    >
                      Снять с публикации
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className={style.title_wrapper_publish}
                      onClick={() => setIsFormPublic(true)}
                    >
                      Опубликовать
                    </Button>
                  )}
                </div>
              </div>
              <p
                className={
                  data.is_active ? [style.title_active] : [style.title_unactive]
                }
              >
                {data.is_active ? "Опубликован" : "Снято с публикации"}
              </p>
            </div>
            <div>
              <p className={style.content_title}>Основная информация</p>
              <div className={style.content}>
                <div className={style.content_block}>
                  <p className={style.content_block_title}>Название:</p>
                  <p className={style.content_block_text}>{data.name}</p>
                </div>
                <div className={style.content_block}>
                  <p className={style.content_block_title}>Длительность:</p>
                  <p className={style.content_block_text}>
                    {data.duration ? data.duration.name : "-"}
                  </p>
                </div>
                <div className={style.content_block}>
                  <p className={style.content_block_title}>Цена:</p>
                  <p className={style.content_block_text}>
                    {Number.parseInt(data.price)}&nbsp;&#8381;
                  </p>
                </div>
                <div className={style.content_block}>
                  <p className={style.content_block_title}>Описание:</p>
                  <p className={style.content_block_text}>{data.description}</p>
                </div>
              </div>
            </div>
            <hr className={style.devider} />
            <div>
              <p className={style.content_title}>Доступно в шаблоне</p>
              <div className={style.tab_wrapper}>
                <a
                  href="/"
                  onClick={(e) => handleClick(e, "tab1")}
                  className={
                    activeTab === "tab1"
                      ? `${style.tab_active} ${style.tab}`
                      : style.tab
                  }
                >
                  Элементы
                </a>
                {data?.education_format_type?.name !== "FREE" && (
                  <a
                    href="/"
                    onClick={(e) => handleClick(e, "tab2")}
                    className={
                      activeTab === "tab2"
                        ? `${style.tab_active} ${style.tab}`
                        : style.tab
                    }
                  >
                    Материалы
                  </a>
                )}
                <div className={style.emptytab}></div>
              </div>
              {activeTab === "tab1" ? (
                <DetailMaterials
                  type="elements"
                  data={data.education_format_countable_elements}
                  setLoading={setLoading}
                />
              ) : (
                <>
                  {data?.education_format_type?.name !== "FREE" && (
                    <DetailMaterials
                      type="materials"
                      data={data.education_format_no_countable_elements}
                      setLoading={setLoading}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {isEditFormModal && (
            <EditFormModal
              data={data}
              setLoading={setLoading}
              loading={loading}
            />
          )}
          {isFormPublic && (
            <PublicFormModal
              data={data}
              setLoading={setLoading}
              loading={loading}
            />
          )}
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default observer(FormDetail);

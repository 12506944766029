import axiosInstance from "..";
import apiList from "../../constants/apiList";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getSubjectsForSelect(params, page) {
        const filterParams = { ...params };
        if (filterParams.name === "" || filterParams.name === null) {
            delete filterParams.name;
        }

        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getSubjectsForSelect,
                params: { name: filterParams.name, page },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },
};

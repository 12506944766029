import paths from "constants/paths";
import React from "react";
import { Button, Form, FormGroup } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import history from "utils/history/history";
import * as style from "./style/PurchaseHistory.module.scss";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import { ReactComponent as SearchIcon } from "../../../assets/img/search.svg";
import { MultiSelect } from "react-multi-select-component";
import { FormLabel } from "react-bootstrap";
import { useParams } from "react-router";

function PurchaseHistory() {
    const params = useParams();
  const data = [
    {
      id: 1,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
    {
      id: 2,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
    {
      id: 3,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
    {
      id: 4,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
    {
      id: 5,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
    {
      id: 6,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
    {
      id: 7,
      name: "Поддержка школьника",
      type: "Форма обучения",
      price: "5 000 р.",
      date: "27.08.2021",
    },
  ];
  const localization = {
    selectSomeItems: "Все",
    allItemsAreSelected: "Все",
    selectAll: "Все",
    search: "Поиск",
  };

  const options = ["aaaa", "bbbb"];
  return (
    <div>
      <Button
        className={style.btn_link}
        color="link"
        onClick={() => history.push(paths.purchasesDetail(params.id))}
      >
        <ArrowBack />
        <p className={style.btn_link_text}>Назад</p>
      </Button>
      <div>
      <p className={style.title}>История покупок</p>
        <div className={style.price_wrapper}>
          <p className={style.price_text}>Общая сумма расходов пользователя:</p>
          <p className={style.price_value}>15 000 р.</p>
        </div>
        <p className={style.search_lable}>Поиск по названию:</p>
        <div className={style.searhWrapper}> 
          <DebounceInput
          type="search"
            className={style.findUsers}
            placeholder="Введите ФИО пользователя"
          />
          <SearchIcon className={style.searchImg} />
        </div>
        <Form>
          <FormGroup>
            <FormLabel className={style.purchase_type}>
              Вид покупки:
            </FormLabel>
            <MultiSelect
              hasSelectAll={false}
              disableSearch
              options={options}
              className={style.purchase_type_input}
              overrideStrings={localization}
            />
          </FormGroup>
        </Form>

        <table className={style.table}>
          <thead>
            <tr className={style.table_header}>
              <td className={style.table_number_header}>Номер</td>
              <td className={style.table_name_header}>Название покупки</td>
              <td className={style.table_type_header}>Вид покупки</td>
              <td className={style.table_price_header}>Сумма</td>
              <td className={style.table_date_header}>Дата</td>
            </tr>
          </thead>
          <tbody>

              {data.map((item)=>(
                  <tr key={item.id} className={style.table_body}>
                      <td className={style.table_number}>{item.id}</td>
                      <td className={style.table_name}>{item.name}</td>
                      <td className={style.table_type}>{item.type}</td>
                      <td className={style.table_price}>{item.price}</td>
                      <td className={style.table_date}>{item.date}</td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PurchaseHistory;

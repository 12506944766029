import React, { useState } from "react";

import { observer } from "mobx-react-lite";

import { toast } from "react-toastify";
import { useStore } from "store";
import EditFileResource from "./ResourcesTab/EditFileResource";
import EditLinkResource from "./ResourcesTab/EditLinkResource";
import EditKinescopeResource from "./ResourcesTab/EditKinescopeResource";
import * as style from "./style/EditResources.module.scss";
import validationResources from "components/Resources/utils/validationResources";
import foldersApi from "../../../../api/endPoints/folders";

function EditResources({ modalLoading, setModalLoading }) {
    const {
        modalWindowStore: { setEditResource, editResourceOpen },
    } = useStore();

    const [item, setItem] = useState({
        ...editResourceOpen,
        file_name: editResourceOpen.type === "FILE" ? editResourceOpen.name : null,
        file: null,
        type: editResourceOpen.type,
    });

    const [activeTab, setActiveTab] = useState(editResourceOpen.type);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const onBlur = async (e) => {
        const validationErrors = await validationResources.clientValidateField(e.target.name, {
            ...item,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const onChange = (event) => {
        const { name, value } = event.target;
        clearErrField(name);
        setItem({ ...item, [name]: value });
    };

    function handleClick(e, tab) {
        e.preventDefault();
        setActiveTab(tab);
    }

    const onSubmit = (type) => {
        setModalLoading(true);
        if (type === "FILE") {
            foldersApi
                .editFileResource({ data: { name: item.name }, src_file: item.file }, item.id)
                .then(() => {
                    setEditResource(false);
                    setModalLoading(false);
                    toast.success("Ресурс успешно отредактирован");
                })
                .catch((error) => {
                    setErrors({ ...errors, ...error.data.error.advanced });
                    setLoading(false);
                    setModalLoading(false);
                });
        } else if (type === "KINESCOPE") {
            foldersApi
                .editKinescopeResource({ name: item.name, src_url: item.src_url }, item.id)
                .then(() => {
                    setEditResource(false);
                    setModalLoading(false);
                    toast.success("Ресурс успешно отредактирован");
                })
                .catch((error) => {
                    setErrors({ ...errors, ...error.data.error.advanced });
                    setLoading(false);
                    setModalLoading(false);
                });
        } else {
            foldersApi
                .editLinkResource({ name: item.name, src_url: item.src_url }, item.id)
                .then(() => {
                    setEditResource(false);
                    setModalLoading(false);
                    toast.success("Ресурс успешно отредактирован");
                })
                .catch((error) => {
                    setErrors({ ...errors, ...error.data.error.advanced });
                    setLoading(false);
                    setModalLoading(false);
                });
        }
    };

    const activeTabInput = (
        <div className={style.tabs}>
            {activeTab === "FILE" && <EditFileResource setModalLoading={setModalLoading} />}
            {activeTab === "LINK" && (
                <EditLinkResource
                    item={item}
                    onChange={onChange}
                    errors={errors}
                    onBlur={onBlur}
                    onSubmit={onSubmit}
                    loading={loading}
                    setEditResource={setEditResource}
                />
            )}
            {activeTab === "KINESCOPE" && (
                <EditKinescopeResource
                    item={item}
                    onChange={onChange}
                    errors={errors}
                    onBlur={onBlur}
                    onSubmit={onSubmit}
                    loading={loading}
                    setEditResource={setEditResource}
                />
            )}
        </div>
    );

    return (
        <div className={style.modal}>
            <div className={style.modalOverlay}>
                <div className={style.modalWindow}>
                    <div className={style.modalHeader}>
                        <p className={style.popUpTitle}>Редактировать ресурс</p>
                    </div>
                    <div>
                        <div className={style.tab_wrapper}>
                            <div
                                onClick={(e) => handleClick(e, "FILE")}
                                className={
                                    activeTab === "FILE"
                                        ? `${style.tab_active} ${style.tab}`
                                        : style.tab
                                }>
                                Загрузить файл
                            </div>

                            <div
                                onClick={(e) => handleClick(e, "LINK")}
                                className={
                                    activeTab === "LINK"
                                        ? `${style.tab_active} ${style.tab}`
                                        : style.tab
                                }>
                                Добавить ссылку
                            </div>
                            <div
                                onClick={(e) => handleClick(e, "KINESCOPE")}
                                className={
                                    activeTab === "KINESCOPE"
                                        ? `${style.tab_active} ${style.tab}`
                                        : style.tab
                                }>
                                Добавить kinescope
                            </div>
                            <div className={style.emptytab}></div>
                        </div>
                    </div>
                    {activeTabInput}
                </div>
            </div>
        </div>
    );
}

export default observer(EditResources);

import React, { useEffect, useMemo, useState } from "react";
import getTeacherEducationEvents from "../../../api/endPoints/educationEvents";
import View from "./View";

const Controller = (props) => {
    const { selectDate, students, statuses } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        getTeacherEducationEvents
            .getTeacherEducationEvents(selectDate[0], selectDate[6], students, statuses)
            .then((response) => {
                setData(response);
            })
            .finally(() => setIsLoading(false));
    }, [selectDate, students, statuses]);

    const tableData = useMemo(
        () =>
            data.map((item) => {
                const startDateTime = new Date(item.start_datetime);
                const endDateTime = new Date(item.end_datetime);
                return {
                    ...item,
                    startDateTime,
                    endDateTime,
                    startHours: startDateTime.getHours(),
                    startMinutes: startDateTime.getMinutes(),
                    endHours: endDateTime.getHours(),
                    endMinutes: endDateTime.getMinutes(),
                };
            }),
        [data],
    );

    const hours = useMemo(() => {
        if (!tableData.length) return [...new Array(5).keys()].map((i) => i + 8);

        let minHours = 23;
        let maxHours = 0;
        tableData.forEach((item) => {
            if (item.startHours < minHours) minHours = item.startHours;
            if (item.endHours > maxHours) maxHours = item.endHours;
        });

        let hoursCount = maxHours - minHours + 1;
        if (hoursCount < 5) {
            hoursCount = 5;
            const maxHoursDiff = 23 - (minHours + 4);
            if (maxHoursDiff < 0) minHours += maxHoursDiff;
        }

        return [...new Array(hoursCount).keys()].map((i) => i + minHours);
    }, [tableData]);

    const tableRows = useMemo(() => {
        const result = {};

        hours.forEach((hour) => {
            result[hour] = selectDate.reduce(
                (acc, date) => ({ ...acc, [date.toLocaleDateString()]: [] }),
                {},
            );
        });

        tableData.forEach((item) => {
            const hourKey = item.startHours;
            const dateKey = item.startDateTime.toLocaleDateString();
            result[hourKey]?.[dateKey]?.push(item);
        });

        return result;
    }, [tableData, hours, selectDate]);

    return <View selectDate={selectDate} tableRows={tableRows} isLoading={isLoading} />;
};

export default Controller;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import View from "./View";

const Controller = (props) => {
    const { deleteEvent, onClose } = props;

    const title = "Вы действительно хотите удалить событие?";

    const text = "Данные не сохранятся";

    return <View title={title} text={text} deleteEvent={() => deleteEvent()} onClose={onClose} />;
};

export default Controller;

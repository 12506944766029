export const compareData = (state = {}, prop = {}) => {
    const temp = {};
    const stateKeys = Object.keys(state);
    const propKeys = Object.keys(prop);
    const stateValues = Object.values(state);
    const propValues = Object.values(prop);

    /* eslint-disable no-plusplus */
    let i = 0;
    let j = 0;
    while (i < stateValues.length && j < propKeys.length) {
        if (stateKeys[i] === propKeys[j]) {
            if (JSON.stringify(stateValues[i]) !== JSON.stringify(propValues[j])) {
                temp[stateKeys[i]] = stateValues[i];
            }
            i++;
            j++;
        } else {
            temp[stateKeys[i]] = stateValues[i];
            i++;
        }
    }

    while (i < stateValues.length) {
        temp[stateKeys[i]] = stateValues[i];
        i++;
    }
    return temp;
};

import { object, string, number } from "yup";

const rulesMessage = {
    min: (number, field) =>
        `В поле "${field}" не должно быть меньше ${number} символов`,
    max: (number, field) =>
        `В поле "${field}" не должно быть больше ${number} символов`,
    required: "Поле обязательно для заполнения",
    email: 'Поле "Почта" введено некорректно',
};

const shema = object({
    name: string()
        .min(2, rulesMessage.min(2, "Название"))
        .max(150, rulesMessage.max(150, "Название"))
        .nullable()
        .required(rulesMessage.required),
    description: string()
        .min(2, rulesMessage.min(2, "Описание"))
        .max(300, rulesMessage.max(300, "Описание"))
        .nullable()
        .required(rulesMessage.required),
    price: number().required().integer(),
    duration: string().nullable(),
});

async function clientValidateForm(form) {
    let errors;
    try {
        await shema.validate(form, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

async function clientValidateField(field, value) {
    let errors;
    try {
        await shema.validateAt(field, value, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

export default { clientValidateForm, clientValidateField };
import React from "react";
import ModalAdd from "../ModalAdd";
import { ReactComponent as AddIcon } from "../assets/add.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const { title, modalTitle, modalButton, isOpenModal, onOpen, onClose } = props;

    const addModal = isOpenModal && (
        <ModalAdd onClose={onClose} title={modalTitle} buttonText={modalButton} />
    );

    return (
        <>
            <button
                type="button"
                className={styles.button}
                style={{ marginRight: "20px" }}
                onClick={onOpen}>
                {title}
                <AddIcon />
            </button>
            {addModal}
        </>
    );
};

export default View;

import React, { useState, useRef } from "react";
import { useHistory } from "react-router";

import { Button } from "reactstrap";

import { useClickOutside } from "../../utils/useClickOutside";
import * as style from "./style/Menu.module.scss";
import editDots from "../../assets/img/3dots.svg";

function Menu({ children }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  //   const {
  //     modalWindowStore: { setIsUnBlockModalOpen, setIsBlockModalOpen },
  //   } = useStore();

  const history = useHistory();
  const m = useRef();

  useClickOutside(m, () => {
    if (isMenuOpen) setMenuOpen(false);
  });
  const openMenu = (e) => {
    e.stopPropagation();
    setMenuOpen(true);
  };
  return (
    <div className={style.chat_bubble__menu_container} ref={m}>
      {isMenuOpen && (
        <div className={style.chat_bubble__menu_list}>{children}</div>
      )}
      <Button
        className={style.tableEditBtn}
        outline
        color="inherit"
        onClick={(e) => openMenu(e)}
      >
        <img className={style.tableEditBtnSvg} src={editDots} alt="Edit" />
      </Button>
    </div>
  );
}

export default Menu;

/* eslint-disable default-case */
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useStore } from "store";
import * as style from "./style/EditItem.module.scss";
import { ReactComponent as FileBlue } from "../../../assets/img/fileBlue.svg";
import validate from "./utils/validate";
import deleteIcon from "../../../assets/img/deleteIcon.svg";
import itemsManagement from "api/endPoints/itemsManagement";

function AddItem({ loading, setLoading }) {
  const {
    modalWindowStore: { setIsAddEditItemModalOpen, isAddEditItemModalOpen },
  } = useStore();
  const [data, setData] = useState({
    name: isAddEditItemModalOpen?.item?.name || "",
  });
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(
    isAddEditItemModalOpen?.item?.image_name
      ? { name: isAddEditItemModalOpen?.item?.image_name }
      : null
  );

  let imageElement = document.getElementById("file");

  const titleHint =
    isAddEditItemModalOpen.type === "edit"
      ? "Редактировать предмет"
      : "Создать предмет";

  const SIZE_LIMIT = 10485760;

  const clearErrField = (field) => {
    const checkError = { ...errors };

    if (checkError[field]) {
      delete checkError[field];
    }
    setErrors({ ...checkError });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    clearErrField(name);
    switch (name) {
      case "name":
        setData({ name: value });
        break;
      case "file":
        if (e.target.files[0].size <= SIZE_LIMIT) {
          setErrors({ ...errors, image_file: "" });
          setImage(e.target.files[0]);
        } else {
          toast.info("Размер файла не должен превышать 10 Мб.");
        }
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = await validate.clientValidateForm(data);
    if (errors) {
      setLoading(false);
      setErrors({ ...errors });
    } else {
      let formData = new FormData();
      let jsonBody = JSON.stringify(data);
      formData.append("jsonBody", jsonBody);

      if (isAddEditItemModalOpen.type === "edit") {
        if (isImageChanged) {
          formData.append("image_file", image);
        }
        itemsManagement
          .editSubject(isAddEditItemModalOpen.item.id, formData)
          .then(() => {
            toast.info("Изменения сохранены");
            setIsAddEditItemModalOpen(false);
            setLoading(false);
          })
          .catch((errors) => {
            setLoading(false);
            setErrors(errors.data.error.advanced);
          });
      } else {
        formData.append("image_file", image);
        itemsManagement
          .addSubject(formData)
          .then(() => {
            toast.info("Изменения сохранены");
            setIsAddEditItemModalOpen(false);
            setLoading(false);
          })
          .catch((errors) => {
            setLoading(false);
            setErrors(errors.data.error.advanced);
          });
      }
    }
  };

  const deleteImage = () => {
    if (Object.keys(image).length === 0) {
      imageElement.value = "";
    }
    setImage(null);
    setIsImageChanged(true)
  };

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>{titleHint}</p>
          </div>
          <div>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="name" className={style.first_lable}>
                  Название: *
                </Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Название предмета"
                  onChange={handleChange}
                  value={data.name}
                  invalid={!!errors?.name}
                />
                <FormFeedback>{errors?.name}</FormFeedback>
                <Label className={style.second_lable}>
                  Введите от 2 до 150 символов
                </Label>
              </FormGroup>
              <FormGroup className={style.file}>
                <Label className={style.first_lable}>
                  Загрузите изображение в формате .svg: *
                </Label>
                {image ? (
                  <div className={style.inputImgWrapper}>
                    <p className={style.inputImgWrapperTxt}>{image.name}</p>
                    <Button
                      className={style.inputImgWrapperBtn}
                      outline
                      color="inherit"
                      name="firstImgDeleteBtn"
                      onClick={() => deleteImage()}
                    >
                      <img src={deleteIcon} alt="icon" />
                    </Button>
                  </div>
                ) : (
                  <Label htmlFor="file" className={style.file_input}>
                    Добавить файл <FileBlue />
                  </Label>
                )}
                <Input
                  type="file"
                  id="file"
                  name="file"
                  style={{ display: "none" }}
                  onChange={handleChange}
                  accept=".svg"
                  invalid={!!errors?.image_file}
                />
                {!!errors?.image_file &&
                  errors?.image_file.map((item) => (
                    <FormFeedback>{item}</FormFeedback>
                  ))}
                {/* <Label className={style.second_lable}>
                  Файл должен быть формата .svg
                </Label> */}
                <Label className={style.second_lable}>
                  Размер файла не должен превышать 10 Мб.
                </Label>
              </FormGroup>
              <Button
                color="primary"
                outline
                className={style.deleteLesson}
                onClick={() => setIsAddEditItemModalOpen(false)}
              >
                Отменить
              </Button>
              {loading ? (
                <Button disabled color="primary" className={style.canselChange}>
                  <div
                    className={`spinner-border text-ligth ${style.loading}`}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </Button>
              ) : (
                <Button color="primary" className={style.canselChange}>
                  Сохранить
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(AddItem);

import axiosInstance from "..";
import Endpoints from "../../constants/api/programs";

const Programs = {
  async programs() {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: Endpoints.list
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },
}

export default Programs;
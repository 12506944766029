import React from "react";
import TimeTableDate from "../TimeTableDate";
import TimeTableHour from "../TimeTableHour";
import TimeTableBlock from "../TimeTableBlock";
import clockIcon from "../assets/clockIcon.png";
import Preloader from "components/Preloader/Preloader";
import styles from "./styles.module.scss";

const View = (props) => {
    const { selectDate, tableRows, isLoading } = props;

    const weekDates = selectDate.map((date) => <TimeTableDate key={date} date={date} />);
    const hoursList = Object.keys(tableRows).map((hour) => (
        <tr key={hour} style={{ height: "204px" }}>
            <TimeTableHour time={hour} />
            {Object.keys(tableRows[hour]).map((day) => (
                <TimeTableBlock key={day} lessons={tableRows[hour][day]} />
            ))}
        </tr>
    ));

    if (isLoading) return <Preloader />;

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <td title="Указано местное время">
                        <div className={styles.table__clock}>
                            <img src={clockIcon} alt="clock" />
                        </div>
                    </td>
                    {weekDates}
                </tr>
            </thead>
            <tbody>{hoursList}</tbody>
        </table>
    );
};

export default View;

import React, { useEffect, useState } from "react";
import history from "utils/history/history";
import firstImg from "../../../EducationForms/assets/firstImg.svg";
import secondImg from "../../../EducationForms/assets/secondImg.svg";
import * as style from "../../../EducationForms/DetailMaterials/style/DetailMaterials.module.scss";
import { useParams } from "react-router";

function PurchaseMaterial({ type, data, isClickable, educationFormat }) {
  const params = useParams();
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    setMaterials(data);
  }, [data]);

  const onCardClick = (path, id, item) => {
    if (item.name === "Видео-лекция" || item.name === "Видеокурс") {
      history.push(`${path}/material/${id}`);
    } else {
      history.push(`${path}/materials/${id}`);
    }
  };
  return (
    <div className={style.wrapper}>
      <div className={style.card_wrapper}>
        {materials?.map((item, index) => (
          <div
            className={style.card_item}
            key={item.id}
            style={isClickable && { cursor: "pointer" }}
            onClick={
              isClickable ? () => onCardClick(isClickable, item.id, item) : null
            }
          >
            <div className={style.header_wrapper}>
              <img
                src={
                  type === "materials"
                    ? item.image_src || secondImg
                    : item.image_src || firstImg
                }
                className={
                  item.count === 0 && !isClickable
                    ? style.card_item_img_opacity
                    : style.card_item_img
                }
                alt="Картинка"
              />
            </div>
            <p>{item.name}</p>

            {isClickable && (
              <>
                {item.template_count >= 0 && (
                  <p className={style.card_clickable_count}>
                    {item.template_count}шт.
                  </p>
                )}
                {item.count === 0 && (
                  <p className={style.card_clickable_zero}>
                    В этой папке нет активных ресурсов
                  </p>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PurchaseMaterial;

import React from "react";
import { Button } from "reactstrap";
import history from "utils/history/history";
import paths from "constants/paths";
import { useStore } from "store";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import styles from "./styles.module.scss";

const View = () => {
    const {
        createLessonStore: { setIsAdd },
    } = useStore();

    const link = localStorage.getItem("is_add") === "true" ? paths.lessonList : paths.adminPanel;

    return (
        <Button
            className={styles.button_link}
            color="link"
            onClick={() => {
                history.push(link);
                setIsAdd(false);
            }}>
            <ArrowBack />
            <p className={styles.button_link__text}>Назад</p>
        </Button>
    );
};

export default View;

import React, { useMemo, useState } from "react";
import { cardFormatTime } from "./helpers";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import View from "./View.jsx";

const Controller = (props) => {
    const { notification } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const time = useMemo(() => cardFormatTime(notification), [notification]);

    const styleTop = useMemo(() => `${time.split(" - ")[0].split(":")[1][0] * 34}px`, [time]);

    const colorsStyle = {
        top: styleTop,
    };

    const mentorActions = [
        {
            disabled: false,
            icon: DeleteIcon,
            name: "Удалить",
            onClick: () => setIsDeleteModalOpen(true),
        },
    ];

    return (
        <View
            notification={notification}
            colorsStyle={colorsStyle}
            time={time}
            mentorActions={mentorActions}
            isDeleteModalOpen={isDeleteModalOpen}
            closeDeleteModalOpen={() => setIsDeleteModalOpen(false)}
        />
    );
};

export default Controller;

import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { Button, Input } from "reactstrap";
import { useParams, useLocation } from "react-router";
import { observer } from "mobx-react-lite";
import history from "utils/history/history";
import paths from "constants/paths";
import users from "api/endPoints/users";
import educationForms from "api/endPoints/educationForms";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import { ReactComponent as Trash } from "../../../assets/img/deleteIcon.svg";
import { ReactComponent as Pencil } from "../../../assets/img/pencilDark.svg";
import DetailMaterials from "components/EducationForms/DetailMaterials/DetailMaterials";
import Preloader from "components/Preloader/Preloader";
import ChangeFormDuration from "components/Popup/ChangeFormDuration";
import DeleteForm from "./DeleteForm";
import ExitWithoutActivate from "./ExitWithoutActivate";
import { useStore } from "store";
import * as style from "./style/NewForm.module.scss";
import purchases from "api/endPoints/purchases";
import moment from "moment";
import BreadCrumbs from "components/Breadcrumbs/Breadcrumbs";

function NewForm() {
  const {
    modalWindowStore: {
      isChangeDurationModalOpen,
      setIsChangeDurationModalOpen,
      isDeleteUserFormOpen,
      setIsDeleteUserFormOpen,
      isExitWithoutActivateOpen,
      setIsExitWithoutActivateOpen,
    },
  } = useStore();
  const { pathname } = useLocation();
  const params = useParams();
  const [activeTab, setActiveTab] = useState("tab1");
  const [data, setData] = useState();
  const [educationFormsArray, setEducationFormsArray] = useState([]);
  const [isFormSave, setIsFormSave] = useState(params.formId ? true : false);
  const [currentFormData, setCurrentFormData] = useState({
    template: { name: null },
  });
  const [noCountableElements, setNoCountableElements] = useState();
  const [formSaved, setFormSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);

  const [breadcrumbs, setBreadcrumbs] = useState();

  const [educationData, setEducationData] = useState({
    template_id: null,
    user_id: params.id,
    created_by: localStorage.getItem("id"),
    order_id: null,
  });

  const startDate = currentFormData?.start_date
    ? new Date(currentFormData?.start_date).toLocaleDateString("ru-RU")
    : null;

  const endDate = currentFormData?.end_date
    ? new Date(currentFormData?.end_date).toLocaleDateString("ru-RU")
    : null;

  useEffect(() => {
    let templateName;
    educationForms.getEducationForms().then((response) => {
      const newArray = response.data.items.map((item) => {
        if (item.education_format_type.name !== "FREE") {
          return item;
        }
      });
      newArray.shift();
      setEducationFormsArray(newArray);
    });
    if (params.formId) {
      users
        .getNoCountableElementsForForm(params.formId)
        .then((response) => setNoCountableElements(response.data));
    } else {
      users
        .getNoCountableElementsForForm(currentFormData?.id)
        .then((response) => setNoCountableElements(response.data));
    }
    if (params.formId || currentFormData?.id) {
      let id = params.formId ? params.formId : currentFormData.id;
      educationForms.getFormForUser(id).then((response) => {
        templateName = response.template.name;
        setCurrentFormData(response);
      });
    }
    if (localStorage.getItem("order")) {
      purchases.getCurrentOrder(localStorage.getItem("order")).then((res) => {
        setOrderInfo(res);
        setIsFormSave(true);
        setEducationData({
          ...educationData,
          template_id: res.education_format_template_id,
        });
        const orderData = {
          template_id: res.education_format_template_id,
          user_id: params.id,
          created_by: localStorage.getItem("id"),
          order_id: null,
        };
        educationForms.createFormForUser(orderData).then((response) => {
          setCurrentFormData(response);
          templateName = response.template.name;
          setFormSaved(!formSaved);
          localStorage.removeItem("order");
        });
      });
    }
    users.getStudent(params.id).then((response) => {
      setBreadcrumbs([
        {
          name: `${response.user.last_name || "Неизвестный"} 
        ${response.user.first_name || "пользователь"}`,
          path: paths.userDetail(params.id),
        },
        {
          name: templateName || "Добавить форму обучения",
          path: params.formId
            ? `/user/${params.id}/edit-form/${params.formId}`
            : `/education-forms/${params.id}/create-form`,
        },
      ]);
      setData(response);
    });
  }, [loading, formSaved]);

  const handleChange = (e) => {
    const { value } = e.target;
    setEducationData({ ...educationData, template_id: value });
  };
  const handleClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const saveForm = () => {
    setIsFormSave(true);
    educationForms.createFormForUser(educationData).then((response) => {
      setCurrentFormData(response);
      setFormSaved(!formSaved);
    });
  };

  const deleteForm = () => {
    setIsFormSave(false);
    setCurrentFormData({ template: { name: null } });
    educationForms
      .deleteFormForUser(currentFormData.id)
      .then(() => {
        toast.info("Форма обучения сброшена");
        setEducationData({ ...educationData, template_id: null });
        setIsDeleteUserFormOpen(false);
      })
      .catch();
  };

  const form = useMemo(() => {
    return educationFormsArray.find(
      (item) => item.id === Number(educationData.template_id)
    );
  }, [educationData.template_id]);

  const publishForm = (status) => {
    if (status) {
      educationForms
        .deactivateFormForUser(currentFormData.id)
        .then(() => {
          setCurrentFormData({ ...currentFormData, is_active: false });
          toast.info(
            `Форма обучения ${currentFormData.template.name} деактивирована`
          );
        })
        .catch();
    } else {
      educationForms
        .activateFormForUser(currentFormData.id)
        .then(() => {
          history.push(paths.userDetail(params.id));
          toast.info(
            `Форма обучения ${currentFormData.template.name} активирована`
          );
          // setCurrentFormData({ ...currentFormData, is_active: true });
        })
        .catch();
    }
  };

  return (
    <>
      {data && currentFormData && educationData ? (
        <div>
          <div className={style.breadcrumbs_wrapper}>
            <BreadCrumbs breadCrumbs={breadcrumbs} pathname={pathname} />
          </div>
          <div className={style.header}>
            <p className={style.header_title}>
              {currentFormData?.template?.name
                ? `${currentFormData?.template?.name}`
                : "Добавить форму обучения"}
            </p>
            {currentFormData?.template?.type?.name !== "FREE" && (
              <Button
                color="primary"
                className={
                  currentFormData?.is_active
                    ? style.header_button_active
                    : style.header_button
                }
                onClick={() => publishForm(currentFormData?.is_active)}
                disabled={!isFormSave}
              >
                {currentFormData?.is_active
                  ? "Деактивировать"
                  : " Активировать"}
              </Button>
            )}
          </div>
          <div className={style.contact}>
            <p className={style.contact_title}>Контактные данные</p>
            <div className={style.contact_content}>
              <div className={style.contact_content_item}>
                <p className={style.contact_content_item_title}>
                  ФИ пользователя:
                </p>
                <p className={style.contact_content_item_data}>
                  {data.user.last_name || "Неизвестный"}&nbsp;
                  {data.user.first_name || "пользователь"}
                </p>
              </div>
              <div className={style.contact_content_item}>
                <p className={style.contact_content_item_title}>Телефон:</p>
                <p className={style.contact_content_item_data}>
                  {data.profile.student.phone || "-"}
                </p>
              </div>
              <div className={style.contact_content_item}>
                <p className={style.contact_content_item_title}>Почта:</p>
                <p className={style.contact_content_item_data}>
                  {data.user.email}
                </p>
              </div>
              <div className={style.contact_content_item}>
                <p className={style.contact_content_item_title}>Покупка:</p>
                <p className={style.contact_content_item_data}>
                  {orderInfo
                    ? `${orderInfo.name} ${moment(orderInfo.bought_at).format(
                        "L"
                      )}`
                    : "-"}
                </p>
              </div>
              {isFormSave && (
                <>
                  <div className={style.contact_content_item}>
                    <p className={style.contact_content_item_title}>
                      Шаблон формы обучения: *
                    </p>
                    <p className={style.contact_content_item_data}>
                      {currentFormData?.template?.name}
                      {currentFormData?.template?.type?.name !== "FREE" && (
                        <Button
                          color="outline"
                          outline
                          onClick={() => setIsDeleteUserFormOpen(true)}
                        >
                          <Trash />
                        </Button>
                      )}
                    </p>
                  </div>
                  {currentFormData?.template?.type?.name !== "FREE" && (
                    <div className={style.contact_content_item}>
                      <p className={style.contact_content_item_title}>
                        Длительность:
                      </p>
                      <p className={style.contact_content_item_data}>
                        {startDate}-{endDate}
                        <Button
                          color="outline"
                          outline
                          onClick={() =>
                            setIsChangeDurationModalOpen(true, currentFormData)
                          }
                        >
                          <Pencil />
                        </Button>
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <hr />
          {!isFormSave && (
            <>
              <div>
                <p className={style.form_title}>
                  Выберите шаблон формы обучения
                </p>
                <div className={style.form_wrapper}>
                  <Input
                    type="select"
                    className={style.form_add}
                    onChange={handleChange}
                    name="template_id"
                  >
                    <option>Выберите шаблон</option>
                    {educationFormsArray.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Input>
                  <Button
                    color="primary"
                    className={style.form_btn}
                    onClick={() => saveForm()}
                  >
                    Сохранить
                  </Button>
                </div>
              </div>
              {/* <hr /> */}
            </>
          )}
          <div>
            {isFormSave && currentFormData && (
              <>
                <p className={style.content_title}>
                  Доступные элементы и материалы
                </p>
                <div className={style.tab_wrapper}>
                  <a
                    href="/"
                    onClick={(e) => handleClick(e, "tab1")}
                    className={
                      activeTab === "tab1"
                        ? `${style.tab_active} ${style.tab}`
                        : style.tab
                    }
                  >
                    Элементы
                  </a>
                  <a
                    href="/"
                    onClick={(e) => handleClick(e, "tab2")}
                    className={
                      activeTab === "tab2"
                        ? `${style.tab_active} ${style.tab}`
                        : style.tab
                    }
                  >
                    Материалы
                  </a>
                  <div className={style.emptytab}></div>
                </div>

                {activeTab === "tab1" ? (
                  <DetailMaterials
                    type="form"
                    data={currentFormData?.countable_elements}
                    educationFormat={currentFormData?.id}
                  />
                ) : (
                  <DetailMaterials
                    type="materials"
                    data={noCountableElements}
                    isClickable={
                      params.formId
                        ? `/user/${params.id}/edit-form/${params.formId}`
                        : `/user/${params.id}/edit-form/${currentFormData.id}`
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <Preloader />
      )}
      {isChangeDurationModalOpen && (
        <ChangeFormDuration setLoading={setLoading} />
      )}
      {isDeleteUserFormOpen && (
        <DeleteForm setLoading={setLoading} deleteForm={deleteForm} />
      )}
      {isExitWithoutActivateOpen && <ExitWithoutActivate />}
    </>
  );
}

export default observer(NewForm);

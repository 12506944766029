import React from "react";
import { Form, FormGroup } from "reactstrap";
import DropdownSelect from "../Dropdown";
import closeIcon from "../assets/closeIcon.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const { title, text, deleteEvent, onClose } = props;

    return (
        <div className={styles.modal} onClick={(event) => event.stopPropagation()}>
            <div className={styles.modal__content}>
                <div className={styles.modal__content__top}>
                    <div className={styles.modal__content__top__title}>{title}</div>
                    <button
                        className={styles.modal__content__top__close}
                        date="button"
                        onClick={onClose}>
                        <img src={closeIcon} alt="closeIcon" />
                    </button>
                </div>
                <div className={styles.modal__content__body}>
                    <div className={styles.modal__content__body__text}>{text}</div>
                    <button
                        date="button"
                        className={styles.modal__content__body__create}
                        onClick={deleteEvent}>
                        Удалить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default View;

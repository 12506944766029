import React, { useState, useRef } from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import editDots from "../../../assets/img/3dots.svg";
import { useHistory } from "react-router";

import * as style from "./style/Menu.module.scss";
import { useClickOutside } from "utils/useClickOutside";

function Menu({ id, item }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const {
    modalWindowStore: {
      isAddEditItemModalOpen,
      isDeleteItemModalOpen,
      setIsAddEditItemModalOpen,
      setIsDeleteItemModalOpen,
    },
  } = useStore();

  const history = useHistory();
  const m = useRef();

  useClickOutside(m, () => {
    if (isMenuOpen) setMenuOpen(false);
  });

  const openDeleteModal = () => {
    setMenuOpen(false);
    setIsDeleteItemModalOpen(true, id,item.name);
  };

  const openEditModal = () => {
    const type = "edit";
    setMenuOpen(false);
    setIsAddEditItemModalOpen(true, id, type, item);
  };

  return (
    <div className={style.chat_bubble__menu_container} ref={m}>
      {isMenuOpen && (
        <>
          <div className={style.chat_bubble__menu_list}>
            <button
              className={style.chat_bubble__menu_list_item}
              onClick={() => openEditModal()}
            >
              Редактировать
            </button>
            <button
              className={style.chat_bubble__menu_list_item}
              onClick={() => openDeleteModal()}
            >
              Удалить
            </button>
          </div>
        </>
      )}

      <Button
        className={style.tableEditBtn}
        outline
        color="inherit"
        onClick={() => setMenuOpen(true)}
      >
        <img className={style.tableEditBtnSvg} src={editDots} alt="Edit" />
      </Button>
    </div>
  );
}

export default observer(Menu);

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import "bootstrap/dist/css/bootstrap.css";
import Pagination from "../Pagination/Pagination";
import { Button } from "reactstrap";
import lessonsApi from "../../api/endPoints/lessonList";
import deleteIcon from "../../assets/img/deleteIcon.svg";
import editDots from "../../assets/img/3dots.svg";
import * as style from "./style/LessonsList.module.scss";
import LessonStatus from "./LessonStatus";
import DeletePopUp from "../Popup/DeletePopUp";
import paths from "../../constants/paths";
import history from "../../utils/history/history";
import Preloader from "../Preloader/Preloader";
import Menu from "./Menu";
import { toast } from "react-toastify";

function LessonsList() {
  const [modal, setModal] = useState(0);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


  const toggle = (id) => {
    setModal(id);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  useEffect(() => {
    localStorage.removeItem("path");
    lessonsApi
      .lessons(page)
      .then((response) => {
        setData(response.items);
        if (response.current_page > response.last_page) {
          setPage(response.last_page);
        }
        setLastPage(response.last_page);
        setPaginationLoading(false);
        setLoading(false);
      })
      .catch();
  }, [page, modal]);

  const deleteLesson = (id) => {
    setLoading(true);
    apiDelete();
  };

  const apiDelete = () => {
    lessonsApi
      .deleteLesson(modal)
      .then()
      .catch()
      .finally(() => {
        toggle(0);
      });
  };

  const copyLink = (id, user) => {
    let text = document.getElementById(id).innerText;
    let elem = document.createElement("textarea");
    document.body.appendChild(elem);
    elem.value = text;
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
    if (user === "user") {
      toast.info("Ссылка на урок для ученика успешно скопирована");
    } else {
      toast.info("Ссылка на урок для учителя успешно скопирована");
    }
  };

  const prevPage = (number) => {
    setPaginationLoading(true);
    setPage(page - number);
  };

  const nextPage = (number) => {
    setPaginationLoading(true);
    setPage(page + number);
  };

  const lastPageFunction = (number) => {
    setPaginationLoading(true);
    setPage(number);
  };

  const formatDate = (value) => {
    let date = value.split("T");
    let rightDate = date[0].split("-");
    return rightDate[2] + "." + rightDate[1] + "." + rightDate[0];
  };

  const formatTime = (value) => {
    let time = value.split("T");
    let rightTime = time[1].split(".");
    return rightTime[0];
  };

  return (
    <>
      {data ? (
        <div>
          <div className={`row ${style.wrapper}`}>
            <a href="/" className={style.lesson_list_back_link}>
              &#8592; Назад
            </a>
            <div className={style.listHeader}>
              <p className={style.listTopTxt}>Таблица уроков</p>
              <Button
                color="primary"
                className={style.createLesson}
                onClick={() => history.push(paths.createLesson)}
              >
                Создать урок
              </Button>
            </div>
            {paginationLoading || loading ? (
              <>
                <table>
                  <thead>
                    <tr className={style.tableBorder}>
                      <th className={style.tableHeader}>Номер урока</th>
                      <th className={style.tableHeaderLessonName}>
                        Тема урока
                      </th>
                      <th className={style.tableHeader}>Дата урока</th>
                      <th className={style.tableHeader}>Время</th>
                      <th className={style.tableHeader}>Ссылка на урок</th>
                      <th className={style.tableHeader}>Педагог</th>
                      <th className={style.tableHeaderStatus}>Статус</th>
                      <th className={style.isIndividual}>Тип урока</th>
                      <th className={style.tableHeader}></th>
                    </tr>
                  </thead>
                </table>
                <div className={`row ${style.lessonsPreloader}`}>
                  <Preloader />
                </div>
              </>
            ) : (
              <table>
                <thead>
                  <tr className={style.tableBorder}>
                    <th className={style.tableHeader}>Номер урока</th>
                    <th className={style.tableHeaderLessonName}>Тема урока</th>
                    <th className={style.tableHeader}>Дата урока</th>
                    <th className={style.tableHeader}>Время</th>
                    <th className={style.tableHeader}>Ссылка на урок</th>
                    <th className={style.tableHeader}>Педагог</th>
                    <th className={style.tableHeaderStatus}>Статус</th>
                    <th className={style.isIndividual}>Тип урока</th>
                    <th className={style.tableHeader}></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id} className={style.tableBorder}>
                      <td>{item.id}</td>
                      <td
                        className={style.tableLessonName}
                        onClick={() => history.push(paths.editLesson(item.id))}
                      >
                        {item.name}
                      </td>
                      <td>
                        {item.start_at ? formatDate(item.start_at) : null}
                      </td>
                      <td>
                        {item.start_at ? formatTime(item.start_at) : null}
                      </td>
                      <td className={style.lessonBtnWrapper}>
                        <p id={`teacherLink${item.id}`} className={style.links}>
                          {process.env.REACT_APP_WEB_MORDA_URL}
                          {`/lesson?educationStakeholder=teacher&lessonHash=`}
                          {item.hash}
                        </p>
                        <Button
                          className={style.forTeacher}
                          outline
                          color="primary"
                          onClick={() =>
                            copyLink(`teacherLink${item.id}`, "teacher")
                          }
                        >
                          Для учителя
                        </Button>
                        <p id={`userLink${item.id}`} className={style.links}>
                          {process.env.REACT_APP_WEB_MORDA_URL}
                          {`/lesson?educationStakeholder=student&lessonHash=`}
                          {item.hash}
                        </p>
                        <Button
                          className={style.forStudent}
                          color="primary"
                          onClick={() => copyLink(`userLink${item.id}`, "user")}
                        >
                          Для ученика
                        </Button>
                      </td>
                      <td className={style.tableTeacher}>
                        {item.teacher?.fullname}
                      </td>
                      <td>
                        <LessonStatus status={item.status} />
                      </td>
                      {item.is_individual ? (
                        <td className={style.individualLesson}>
                          Индивидуальный
                        </td>
                      ) : (
                        <td className={style.individualLesson}>Групповой</td>
                      )}
                      <td>
                        {item.status === "active" ? (
                          <>
                            <Button
                              disabled
                              outline
                              color="inherit"
                              className={style.tableDeleteBtn}
                              onClick={() => {
                                toggle(item.id);
                              }}
                            >
                              <img src={deleteIcon} alt="Delete" />
                            </Button>
                            <Button
                              disabled
                              outline
                              color="inherit"
                              className={style.tableEditBtn}
                            >
                              <img
                                className={style.tableEditBtnSvg}
                                src={editDots}
                                alt="Edit"
                              />
                            </Button>
                          </>
                        ) : (
                          <>
                            {localStorage.getItem("role") === "ADMIN" ? (
                              <Button
                                outline
                                color="inherit"
                                className={style.tableDeleteBtn}
                                onClick={() => toggle(item.id)}
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </Button>
                            ) : (
                              <Button
                                disabled={
                                  localStorage.getItem("id") !==
                                  item.author.id.toString()
                                }
                                outline
                                color="inherit"
                                className={style.tableDeleteBtn}
                                onClick={() => toggle(item.id)}
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </Button>
                            )}

                            <Menu
                              id={item.id}
                              link={item.export_chat_link}
                              status={item.status}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {data.length === 0 && (
              <div>
                <p className={style.lessonsListNull}>
                  Нет созданных уроков, добавьте, нажав на кнопку "Создать урок"
                </p>
              </div>
            )}

            <div className={style.lessonPagination}>
              <Pagination
                page={page}
                nextPage={nextPage}
                prevPage={prevPage}
                lastPage={lastPage}
                lastPageFunction={lastPageFunction}
              />
            </div>
          </div>
          {isDeleteModalOpen && (
            <DeletePopUp
              modal={modal}
              toggle={() => toggle(0)}
              deleteLesson={deleteLesson}
              loading={loading}
            />
          )}
        </div>
      ) : (
        <div className="row">
          <Preloader />
        </div>
      )}
    </>
  );
}

export default observer(LessonsList);

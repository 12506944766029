import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FormGroup, Label } from "reactstrap";
import { FormCheck } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ReactComponent as Minus } from "../assets/minus.svg";
import { ReactComponent as Plus } from "../assets/plus.svg";
import firstImg from "../assets/firstImg.svg";
import secondImg from "../assets/secondImg.svg";
import educationForms from "../../../api/endPoints/educationForms";
import { ReactComponent as Pencil } from "../../../assets/img/pencil.svg";
import { observer } from "mobx-react-lite";
import users from "api/endPoints/users";
import { useStore } from "store";
import history from "utils/history/history";
import purchases from "api/endPoints/purchases";
import { toast } from "react-toastify";
import * as style from "./style/DetailMaterials.module.scss";

const debounceHandler = debounce(
  (itemId, count, type, id, educationFormat, name) => {
    if (type === "materials") {
      educationForms
        .unCountableElement(itemId, count, id)
        .then(
          toast.info(`Количество ${name} успешно сохранено в Шаблоне обучения`)
        );
    } else if (type === "user") {
      users
        .changeCountableElements(itemId, count, id)
        .then(toast.info(`Количество ${name} успешно сохранено`));
    } else if (type === "form") {
      educationForms
        .updateCountable(itemId, count, educationFormat)
        .then(
          toast.info(`Количество ${name} успешно сохранено в Форме обучения`)
        );
    } else {
      educationForms
        .countableElement(itemId, count, id)
        .then(
          toast.info(`Количество ${name} успешно сохранено в Шаблоне обучения`)
        );
    }
  },
  1000
);

function DetailMaterials({
  type,
  data,
  isEditable,
  isClickable,
  educationFormat,
}) {
  const {
    modalWindowStore: { setIsAdditionalPruchasePriceOpen },
  } = useStore();

  const params = useParams();
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    setMaterials(data);
  }, [data]);

  const debounceWrapper = (itemId, count, index, name, item, buttonType) => {
    let newMaterials = [...materials];
    if (item?.count - item?.reserved_count === 0 && buttonType === "minus") {
      toast.info(
        `Вы не можете поставить значение меньше ${item?.reserved_count}. События зарезервированы.`
      );
    } else {
      newMaterials[index].count = count;
      setMaterials(newMaterials);
      debounceHandler(itemId, count, type, params.id, educationFormat, name);
    }
  };

  const changeToggle = (index, id, status) => {
    let newMaterials = [...materials];
    newMaterials[index].is_active = !status;
    setMaterials(newMaterials);
    if (status) {
      purchases
        .unpublishCountableElements(id)
        .then(() => toast.info("Изменения сохранены"));
    } else {
      purchases
        .publishCountableElements(id)
        .then(() => toast.info("Изменения сохранены"));
    }
  };

  const onCardClick = (path, id) => {
    history.push(`${path}/materials/${id}`);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.card_wrapper}>
        {materials?.map((item, index) => (
          <div
            className={style.card_item}
            key={item.id}
            style={isClickable && { cursor: "pointer" }}
            onClick={
              isClickable ? () => onCardClick(isClickable, item.id) : null
            }
          >
            <div className={style.header_wrapper}>
              <img
                src={
                  type === "materials"
                    ? item.image_src || secondImg
                    : item.image_src || firstImg
                }
                className={
                  (item.count === 0 || item.is_active === false) && !isClickable
                    ? style.card_item_img_opacity
                    : style.card_item_img
                }
                alt="Картинка"
              />
              {isEditable && (
                <button
                  className={style.edit_btn}
                  onClick={() =>
                    setIsAdditionalPruchasePriceOpen(true, item.id, item.price)
                  }
                >
                  <Pencil />
                </button>
              )}
            </div>
            <p
              className={
                (item.count === 0 || item.is_active === false) &&
                style.card_item_name_opacity
              }
            >
              {item.name}
            </p>
            {item.price && (
              <p
                className={
                  (item.count === 0 || item.is_active === false) &&
                  style.card_item_name_opacity
                }
              >
                {Number.parseInt(item.price)}&#8381;
              </p>
            )}
            {isClickable && (
              <>
                {item.template_count >= 0 && (
                  <p className={style.card_clickable_count}>
                    {item.template_count}шт. согласно шаблону формы обучения
                  </p>
                )}
                {item.count === 0 ? (
                  <p className={style.card_clickable_zero}>
                    В этой папке нет активных ресурсов
                  </p>
                ) : (
                  <p className={style.card_clickable_zero}>
                    {item.count} материал добавлен
                  </p>
                )}
              </>
            )}
            {item.count !== undefined && !isClickable ? (
              <div className={style.card_count}>
                <button
                  className={style.card_count_btn}
                  onClick={() =>
                    debounceWrapper(
                      item.id,
                      item.count - 1,
                      index,
                      item.name,
                      item,
                      "minus"
                    )
                  }
                  disabled={item.count === 0}
                >
                  <Minus />
                </button>
                <p className={style.card_count_text}>{item.count}шт.</p>
                <button
                  className={style.card_count_btn}
                  onClick={() =>
                    debounceWrapper(
                      item.id,
                      item.count + 1,
                      index,
                      item.name,
                      item,
                      "plus"
                    )
                  }
                >
                  <Plus />
                </button>
              </div>
            ) : (
              <>
                {item.is_active !== undefined && (
                  <div className={style.card_count}>
                    <FormGroup className={style.toilet}>
                      <FormCheck
                        type="switch"
                        id="toilet"
                        name={`toggle`}
                        checked={item.is_active}
                        className={style.checkbox}
                        onChange={() =>
                          changeToggle(index, item.id, item.is_active)
                        }
                      />
                      {item.is_active ? (
                        <Label for="toilet" className={style.lable_active}>
                          Активен
                        </Label>
                      ) : (
                        <Label for="toilet" className={style.lable_unactive}>
                          Не активен
                        </Label>
                      )}
                    </FormGroup>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default observer(DetailMaterials);

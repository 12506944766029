import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import { useParams } from "react-router";
import { useStore } from "store";
import paths from "constants/paths";
import history from "utils/history/history";
import * as style from "./style/DeleteForm.module.scss";

function ExitWithoutActivate({}) {
  const params = useParams();
  const {
    modalWindowStore: { setIsExitWithoutActivateOpen },
  } = useStore();

  const closeForm = () => {
    history.push(paths.userDetail(params.id));
    setIsExitWithoutActivateOpen(false);
  };
  return (
    <div>
      <div className={style.modal}>
        <div className={style.modalOverlay}>
          <div className={style.modalWindow}>
            <div className={style.modalHeader}>
              <p className={style.popUpTitle}>
                Вы уверены, что хотите
                <br /> покинуть страницу
                <br /> без активации формы?
              </p>
            </div>
            <p className={style.popUpTxt}>
              Вы можете активировать форму позже.
            </p>
            <div>
              <Button
                outline
                color="primary"
                className={style.deleteLesson}
                onClick={() => setIsExitWithoutActivateOpen(false)}
              >
                Отменить
              </Button>
              <Button
                color="primary"
                className={style.canselChange}
                onClick={() => closeForm()}
              >
                Покинуть
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ExitWithoutActivate);

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useStore } from "store";
import * as style from "./style/ChangePrice.module.scss";
import { ReactComponent as Price } from "../../../assets/img/price.svg";
import purchases from "api/endPoints/purchases";
import { toast } from "react-toastify";

function ChangePrice({ loading, setLoading }) {
  const {
    modalWindowStore: {
      setIsAdditionalPruchasePriceOpen,
      isAdditionalPruchasePriceOpen,
    },
  } = useStore();
  const [errors, setErrors] = useState({});
  const [price, setPrice] = useState(
    Number.parseInt(isAdditionalPruchasePriceOpen.price)
  );
  const changePrice = (e) => {
    setErrors({});
    setPrice(Number(e.target.value.replace(/\D+/, "")));
  };

  const savePrice = () => {
    setLoading(true);
    purchases
      .editCountableElements(isAdditionalPruchasePriceOpen.id, price)
      .then(() => {
        setLoading(false);
        setIsAdditionalPruchasePriceOpen(false);
        toast.info("Изменения сохранены");
      })
      .catch((errors) => {
        setLoading(false);
        setErrors(errors.data.error.advanced);
      });
  };

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>Редактировать элемент</p>
          </div>

          <Form>
            <FormGroup>
              <Label className={style.form_first_lable}>Цена: *</Label>
              <div className={style.form_price_wrapper}>
                <Input
                  name="price"
                  placeholder="Введите цену"
                  value={price}
                  invalid={!!errors?.price}
                  onChange={(e) => changePrice(e)}
                />
                <Price className={style.form_price_img} />
                <FormFeedback>{errors?.price}</FormFeedback>
              </div>

              <Label className={style.form_second_lable}>
                Введите от 1 до 6 символов
              </Label>
            </FormGroup>
            <Button
              color="primary"
              outline
              className={style.deleteLesson}
              onClick={() => setIsAdditionalPruchasePriceOpen(false)}
            >
              Отмена
            </Button>
            {loading ? (
              <Button disabled color="primary" className={style.canselChange}>
                <div
                  className={`spinner-border text-light ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                className={style.canselChange}
                onClick={() => savePrice()}
              >
                Сохранить
              </Button>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default observer(ChangePrice);

import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { Button } from "reactstrap";
import Preloader from "../../Preloader/Preloader";
import * as style from "./style/DeleteStudent.module.scss";

function DeleteUsers({ deleteUser, loading }) {
  
  const {
    modalWindowStore: { setIsDeleteModalOpen },
  } = useStore();

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>
              Вы уверены, что хотите <br /> удалить профиль Пользователя?
            </p>
          </div>
          <div>
            {loading ? (
              <Button
                disabled
                color="primary"
                outline
                className={style.deleteLesson}
              >
                <div
                  className={`spinner-border text-primary ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                outline
                className={style.deleteLesson}
                onClick={() => deleteUser()}
              >
                Удалить пользователя
              </Button>
            )}
            <Button
              color="primary"
              className={style.canselChange}
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Отменить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DeleteUsers);

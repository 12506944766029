/* eslint-disable import/no-anonymous-default-export */
export default {
    signIn: `/api/v1/admin/login`,
    refresh: `/api/v1/admin/refresh`,
    logOut: `/api/v1/admin/logout`,
    lessons: `/api/v1/admin/lessons`,
    deleteLesson: (id) => `/api/v1/admin/lessons/${id}`,
    createLesson: `/api/v1/admin/lessons`,
    getLesson: (id) => `/api/v1/admin/lessons/${id}`,
    downloadFile: (id) => `/api/v1/admin/folders/${id}/materials/hash/download`,
    getTeachers: `/api/v1/admin/lessons/teachers`,
    createTeacher: `/api/v1/admin/mentors`,
    createStudent: `/api/v1/admin/students`,
    getTeachersList: `/api/v1/admin/mentors`,
    getStudentsList: `/api/v1/admin/students`,
    getStudent: (id) => `/api/v1/admin/students/${id}`,
    editStudent: (id) => `/api/v1/admin/students/${id}`,
    getStudentDevergency: `/api/v1/admin/students/divergencies`,
    getStudentClass: `/api/v1/admin/students/education-classes`,
    getStudentEducationForm: `/api/v1/admin/education-format-templates`,
    getAdultRole: `/api/v1/admin/students/adult-roles`,
    getCities: `/api/v1/admin/students/search_city`,
    deleteUser: (id) => `/api/v1/admin/users/${id}`,
    editTeacher: (id) => `/api/v1/admin/mentors/${id}`,
    getTeacher: (id) => `/api/v1/admin/mentors/${id}`,
    blockUser: (id) => `/api/v1/admin/users/${id}/block`,
    undlockUser: (id) => `/api/v1/admin/users/${id}/unblock`,
    changePassword: "/api/v1/admin/change-password",
    downloadStudents: "/api/v1/admin/students/download",
    downloadMentors: "/api/v1/admin/mentors/download",
    createFolder: `/api/v1/admin/folders`,
    editFolder: (id) => `/api/v1/admin/folders/${id}`,
    getFolders: "/api/v1/admin/folders",
    deleteFolder: (id) => `/api/v1/admin/folders/${id}`,
    getFolderId: (id) => `/api/v1/admin/folders/${id}`,
    getEducationEventStatuses: "/api/v1/education-events/statuses",
    getTeacherEducationEvents: "/api/v1/education-events/teacher",
    getEventList: "/api/v1/education-event-types",
    getResources: `/api/v1/admin/resources`,
    addResource: "/api/v1/admin/resources",
    editResource: (id) => `/api/v1/admin/resources/${id}`,
    deleteResource: (id) => `/api/v1/admin/resources/${id}`,
    downloadResource: (id) => `/api/v1/admin/resources/${id}/download`,
    educationForms: "/api/v1/admin/education-format-templates",
    currentEducationForm: (id) => `/api/v1/admin/education-format-templates/${id}`,
    publishForm: (id) => `/api/v1/admin/education-format-templates/${id}/publish`,
    unPublishForm: (id) => `/api/v1/admin/education-format-templates/${id}/unpublish`,
    duration: "/api/v1/admin/education-format-templates/durations",
    countableElements: (id) => `/api/v1/admin/education-format-templates/${id}/countable-elements`,
    noCountableElements: (id) =>
        `/api/v1/admin/education-format-templates/${id}/no-countable-elements`,
    orders: `/api/v1/admin/orders`,
    currentOrder: (id) => `/api/v1/admin/orders/${id}`,
    getSubjects: "/api/v1/admin/subjects",
    editSubject: (id) => `/api/v1/admin/subjects/${id}`,
    getNoCountableElementsForUser: "/api/v1/admin/education-format-no-countable-elements",
    changeCountableElements: (id) => `/api/v1/admin/students/${id}/countable-elements`,
    createFormForUser: "/api/v1/admin/education-formats",
    getFormForUser: (id) => `/api/v1/admin/education-formats/${id}`,
    deleteFormForUser: (id) => `/api/v1/admin/education-formats/${id}`,
    updateCountable: (id) => `/api/v1/admin/education-formats/${id}/update-countable`,
    activateFormForUser: (id) => `/api/v1/admin/education-formats/${id}/activate`,
    deactivateFormForUser: (id) => `/api/v1/admin/education-formats/${id}/deactivate`,
    getCountableElements: "/api/v1/admin/products/education-format-countable-elements",
    getCurrentProductList: "/api/v1/admin/products/education-format-no-countable-elements",
    getNoCountableElements: "/api/v1/admin/education-format-no-countable-elements",
    editCountableElements: (id) =>
        `/api/v1/admin/products/education-format-countable-elements/${id}`,
    publishCountableElements: (id) =>
        `/api/v1/admin/products/education-format-countable-elements/${id}/publish`,
    unpublishCountableElements: (id) =>
        `/api/v1/admin/products/education-format-countable-elements/${id}/unpublish`,
    publishNoCountableElements: (id) =>
        `/api/v1/admin/products/education-format-no-countable-elements/${id}/publish`,
    unpublishNoCountableElements: (id) =>
        `/api/v1/admin/products/education-format-no-countable-elements/${id}/unpublish`,
    getResourcesFile: (id) => `/api/v1/admin/resources/files/${id}`,
    getSubjectTree: (id) => `/api/v1/admin/education-formats/${id}/folder-resource-tree`,
    getNoCountableElementsForForm: (id) =>
        `/api/v1/admin/education-formats/${id}/no-countable-elements`,
    getProductsTree: `/api/v1/admin/products/education-format-no-countable-elements/tree`,
    addProduct: `/api/v1/admin/products/education-format-no-countable-elements`,
    editProduct: (id) => `/api/v1/admin/products/education-format-no-countable-elements/${id}`,
    getPurchasesTreeForUser: (id) => `/api/v1/admin/students/${id}/tree`,
    getSubjectsForSelect: "api/v1/subjects",
    educationClasses: "api/v1/student/education-classes",
    getEventTimeList: "api/v1/education-events/times",
    createEducationEvent: "api/v1/education-events",
    downloadTeacherEvents: "api/v1/education-events/teacher/download",
    editEducationEvent: (id) => `api/v1/education-events/${id}`,
    changeEventStatus: (id) => `api/v1/education-events/${id}/status`,
    deleteEvent: (id) => `api/v1/education-events/${id}`,
    timezoneAdmin: 'api/v1/admin/me',
    timezoneMentor: 'api/v1/mentor',
    searchCity: city => `api/v1/student/search-city?city_name=${city}`,
    searchTimezone: (city, latitude, longitude) =>
      `api/v1/student/search-timezone?city_name=${city}&city_latitude=${latitude}&city_longitude=${longitude}`,
};

import React, { useEffect, useState } from "react";
import { useStore } from "store";
import { DebounceInput } from "react-debounce-input";

import Preloader from "../../../../components/Preloader/Preloader";
import { ReactComponent as SearchIcon } from "../../../../assets/img/search.svg";
import * as style from "./style/ResourcesInFolder.module.scss";
import iconsUpload from "../../../../assets/img/iconsUpload.svg";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";
import pen from "../../../../assets/img/pen.svg";
import { Button } from "reactstrap";
import linkIcon from "../../../../assets/img/arrow-up.svg";
import fileIcon from "../../../../assets/img/file.svg";
import getResourcesFromFolder from "../../../../api/endPoints/folders";
import AddResources from "components/Resources/ResourcesComponent/AddResources/AddResources";
import DeleteResources from "components/Resources/ResourcesComponent/DeleteResources/DeleteResources";
import EditResources from "components/Resources/ResourcesComponent/EditResources/EditResources";
import { observer } from "mobx-react-lite";
import Pagination from "components/Pagination/Pagination";

function ResourcesInFolder({ id }) {
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [resourcesList, setResourcesList] = useState();
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [filter, setFilters] = useState({
        name: "",
    });
    const {
        modalWindowStore: {
            setAddResource,
            setEditResource,
            setDeleteResource,
            addResourceOpen,
            deleteResourceOpen,
            editResourceOpen,
        },
    } = useStore();

    useEffect(() => {
        setLoading(true);
        getResourcesFromFolder.getResources(filter, page, id).then((response) => {
            setResourcesList(response.items);
            setLastPage(response.last_page);
            setPaginationLoading(false);
            setLoading(false);
            if (response.current_page > response.last_page) {
                setPage(response.last_page);
            }
        });
    }, [id, page, filter, addResourceOpen, deleteResourceOpen, editResourceOpen]);

    const addResource = () => {
        setModalLoading(true);
    };

    const deleteResource = () => {
        setModalLoading(true);
    };

    const editResource = () => {
        setModalLoading(true);
    };

    const prevPage = (number) => {
        setPaginationLoading(true);
        setPage(page - number);
    };

    const nextPage = (number) => {
        setPaginationLoading(true);
        setPage(page + number);
    };

    const lastPageFunction = (number) => {
        setPaginationLoading(true);
        setPage(number);
    };

    const downloadResource = (hash) => {
        getResourcesFromFolder.downloadResource(hash).then((response) => {
            const link = document.createElement("a");
            link.href = response.url;
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <>
            {resourcesList ? (
                <div>
                    <div className={style.titleHead}>
                        <p>Ресурсы</p>
                        <Button
                            className={style.uploadResource}
                            outline
                            color="inherit"
                            onClick={() => setAddResource(true)}>
                            <img src={iconsUpload} alt="icon" />
                            Загрузить ресурс
                        </Button>
                    </div>
                    <div className={style.listHeaderInput}>
                        <p className={style.listHeaderInput_title}>Поиск по названию:</p>
                        <div className={style.searhWrapper}>
                            <DebounceInput
                                type="search"
                                name="name"
                                className={style.findMaterial}
                                minLength={1}
                                debounceTimeout={800}
                                onChange={(event) =>
                                    setFilters({ name: event.target.value.trim("-") })
                                }
                                placeholder="Введите название"
                            />
                            <div className={style.searchImg}>
                                <SearchIcon />
                            </div>
                        </div>
                    </div>
                    {paginationLoading || loading ? (
                        <div className={`row ${style.lessonsPreloader}`}>
                            <Preloader />
                        </div>
                    ) : (
                        <>
                            <div>
                                <table className={style.tableWidth}>
                                    {resourcesList && resourcesList.length === 0 ? (
                                        <div>
                                            <p className={style.lessonsListNull}>
                                                Здесь пока ничего нет
                                            </p>
                                        </div>
                                    ) : (
                                        <thead>
                                            <tr className={style.tableBorder}>
                                                <th className={style.tableHeader}>Номер </th>
                                                <th className={style.tableHeader}>Название</th>
                                                <th className={style.tableHeader}></th>
                                            </tr>
                                        </thead>
                                    )}
                                    <tbody>
                                        {resourcesList &&
                                            resourcesList.map((item, index) => (
                                                <tr key={item.id} className={style.tableBorder}>
                                                    <td className={style.itemNumber}>
                                                        {index + 1}
                                                    </td>
                                                    {item.type === "LINK" ||
                                                    item.type === "KINESCOPE" ? (
                                                        <td className={style.itemBlockName}>
                                                            <a
                                                                href={item.src_url}
                                                                className={style.itemLink}
                                                                target="_blanck">
                                                                {item.name}
                                                            </a>
                                                            <img src={linkIcon} alt="linkIcon" />
                                                        </td>
                                                    ) : (
                                                        <td
                                                            className={style.itemBlockName}
                                                            onClick={() =>
                                                                downloadResource(item.id)
                                                            }>
                                                            <a className={style.itemName}>
                                                                {item.name}
                                                            </a>
                                                            <img src={fileIcon} alt="fileIcon" />
                                                        </td>
                                                    )}

                                                    <td className={style.buttonDisplay}>
                                                        <Button
                                                            color="inherit"
                                                            className={style.tableDeleteBtn}
                                                            onClick={() =>
                                                                setEditResource(true, item)
                                                            }>
                                                            <img src={pen} alt="pen" />
                                                        </Button>
                                                        <Button
                                                            color="inherit"
                                                            className={style.tableDeleteBtn}>
                                                            <img
                                                                src={deleteIcon}
                                                                alt="Delete"
                                                                onClick={() =>
                                                                    setDeleteResource(true, item)
                                                                }
                                                            />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                                <div className={style.lessonPagination}></div>
                            </div>
                        </>
                    )}

                    <div className={style.foldersPagination}>
                        <Pagination
                            page={page}
                            nextPage={nextPage}
                            prevPage={prevPage}
                            lastPage={lastPage}
                            lastPageFunction={lastPageFunction}
                        />
                    </div>
                    {addResourceOpen && (
                        <AddResources
                            addResource={addResource}
                            loading={modalLoading}
                            setModalLoading={setModalLoading}
                        />
                    )}
                    {deleteResourceOpen && (
                        <DeleteResources
                            deleteResource={deleteResource}
                            loading={modalLoading}
                            setModalLoading={setModalLoading}
                        />
                    )}
                    {editResourceOpen && (
                        <EditResources
                            editResource={editResource}
                            loading={modalLoading}
                            setModalLoading={setModalLoading}
                        />
                    )}
                </div>
            ) : (
                <div className="row">
                    <Preloader />
                </div>
            )}
        </>
    );
}

export default observer(ResourcesInFolder);

import React, { useEffect, useState } from "react";
import { useStore } from "store";
import View from "./View";

const Controller = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const {
        createLessonStore: { is_add },
    } = useStore();

    const title = "Добавить событие";
    const onOpen = () => setIsOpenModal(true);
    const onClose = () => setIsOpenModal(false);
    const createEvent = () => {
        console.log("create");
        setIsOpenModal(false);
    };

    useEffect(() => {
        if (is_add) setIsOpenModal(true);
    }, []);

    const modalTitle = "Создать событие";
    const modalButton = "Создать событие";

    return (
        <View
            title={title}
            modalTitle={modalTitle}
            modalButton={modalButton}
            isOpenModal={isOpenModal}
            onOpen={onOpen}
            onClose={onClose}
            createEvent={createEvent}
        />
    );
};

export default Controller;

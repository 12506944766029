import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

import { Input, Button, Form, FormGroup, Label, FormText, FormFeedback } from "reactstrap";
import * as style from "./style/EditTeacher.module.scss";
import paths from "../../../../constants/paths";
import editTeacher from "../../../../api/endPoints/users";
import getTeacher from "../../../../api/endPoints/users";
import downloadLogo from "../../../../assets/img/downloadLogo.svg";
import imgIcon from "../../../../assets/img/imgIcon.svg";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";
import LeavePage from "components/Popup/LeavePage";
import { toast } from "react-toastify";
import Preloader from "components/Preloader/Preloader";
import validationTeacher from "components/UsersList/AddUsers/AddTeachers/utils/validationTeacher";
import { compareData } from "utils/compareData";
import { ReactComponent as ArrowBack } from "../../../../assets/img/arrowBack.svg";
import CropperModal from "components/Popup/CropperModal";

import AsyncSelect from "react-select/async";
import searchCity from "api/endPoints/profileEndpoint";
import searchTimezone from "api/endPoints/profileEndpoint";

function EditTeacher() {
    const {
        modalWindowStore: { isCloseModalOpen, setIsCloseModalOpen },
        modalWindowStore: { cropperModalOpen, seCropperModalOpen },
    } = useStore();
    const { id } = useParams();
    const history = useHistory();
    const [dataTeacherProfile, setDataTeacherProfile] = useState();
    const [editTeacherProfile, setEditTeacherProfile] = useState({});

    const [image, setImage] = useState();
    const [cropper, setCropper] = useState();
    const [buttonStatus, setButtonStatus] = useState(false);
    const [isCropperButtonPressed, setIsCropperButtonPressed] = useState(false);
    const imageElement = document.getElementById("avatar");
    const [deleteAvatar, setDeleteAvatar] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitBtnPressed, setIsSubmitBtnPressed] = useState(false);
    const [loading, setLoading] = useState(false);

    const [avatarName, setAvatarName] = useState("");

    const SIZE_LIMIT = 10485760;

    useEffect(() => {
        getTeacher.getTeacher(id).then((response) => {
            setDataTeacherProfile({
                ...response.profile.teacher,
                ...response.user,
            });
            setEditTeacherProfile({
                ...response.profile.teacher,
                ...response.user,
            });
            if (!!response?.profile?.teacher?.avatar) {
                setButtonStatus(true);
                setIsCropperButtonPressed(true);
                setImage(response?.profile.teacher.avatar);
                setAvatarName(response?.profile.teacher.avatar_name);
            }
        });
    }, []);

    const uploadPhoto = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        const reader = new FileReader();
        const avatar = e.target.files[0];
        if (avatar.size <= SIZE_LIMIT) {
            reader.onload = () => {
                setImage(reader.result);
                setAvatarName(avatar.name);
            };
            reader.readAsDataURL(files[0]);
        } else {
            toast.success("Размер файла не должен превышать 10 МБ", options);
        }
    };

    const getCropData = (e) => {
        e.preventDefault();
        setButtonStatus(true);
        setIsCropperButtonPressed(true);
        setDeleteAvatar(false);
        seCropperModalOpen(false);

        if (typeof cropper !== "undefined") {
            setImage(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
            fetch(cropper.getCroppedCanvas().toDataURL("image/jpeg"))
                .then((res) => res.blob())
                .then((file) => setEditTeacherProfile({ ...editTeacherProfile, avatar: file }));
        }
    };

    const formNames = {
        first_name: "first_name",
        last_name: "last_name",
        middle_name: "middle_name",
        email: "email",
        profession_info: "profession_info",
        education_info: "education_info",
        avatar: "avatar",
    };

    const onBlur = async (e) => {
        const validationErrors = await validationTeacher.clientValidateField(e.target.name, {
            ...editTeacherProfile,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        clearErrField(name);

        setEditTeacherProfile({ ...editTeacherProfile, [name]: value });
    };

    const checkForExitModal = () => {
        const prepareTeacher = { ...compareData(editTeacherProfile, dataTeacherProfile) };
        if (Object.keys(prepareTeacher).length === 0) {
            return false;
        } else {
            return true;
        }
    };

    const prepareData = () => {
        const prepareTeacher = {
            ...compareData(editTeacherProfile, dataTeacherProfile),
        };
        return { ...prepareTeacher };
    };

    const options = {
        style: { width: "400px" },
    };

    const deleteImage = () => {
        imageElement.value = "";
        setImage(null);
        setAvatarName(null);
        setButtonStatus(false);
        setIsCropperButtonPressed(false);
        setDeleteAvatar(true);
        seCropperModalOpen(false);
        setEditTeacherProfile({ ...editTeacherProfile, avatar: null });
    };

    const submitButton = () => {
        setIsSubmitBtnPressed(true);
        setLoading(true);
        editTeacher
            .editTeacher(prepareData(), id, deleteAvatar, avatarName)
            .then(() => {
                setLoading(false);
                setErrors("");
                history.push(paths.teacherDetail(id));
                toast.success("Пользователь успешно отредактирован", options);
            })
            .catch((error) => {
                setErrors({ ...errors, ...error });
                setLoading(false);
            });
    };

    const backButtonClick = () => {
        if (checkForExitModal()) {
            setIsCloseModalOpen(true);
        } else {
            history.push(paths.teacherDetail(id));
        }
    };

    let locationLoadTimeout;
    const [modeChangeLocation, setModeChangeLocation] = useState(false);

    const locationLoadOptions = (inputValue, callback) => {
        clearTimeout(locationLoadTimeout);
        locationLoadTimeout = setTimeout(() => {
            searchCity.searchCity(inputValue).then((response) => {
                const options = response.data.items.map((item) => ({
                    value: item,
                    label: item.name,
                }));
                callback(options);
            });
        }, 1000);
    };

    const handleAdminChange = (e) => {
        const { name, value } = e.target;
        clearErrField("teacher", name);
        setEditTeacherProfile({ ...editTeacherProfile, [name]: value });
    };

    const locationSelectOnChange = (selectedItem) => {
        const { data } = selectedItem.value;

        searchTimezone.searchTimezone(data.city, data.geo_lat, data.geo_lon).then((response) => {
            const timezone = response.data.items.pop();
            if (timezone) {
                const location = [data.city_type, data.city].join(" ");

                editTeacherProfile.timezone_id = timezone.id;
                editTeacherProfile.timezone = timezone;
                editTeacherProfile.location = location;
                handleAdminChange({ target: { name: "timezone_id", value: timezone.id } });
            }
        });

        setModeChangeLocation(false);
    };

    const defaultTimezone = useMemo(() => {
        if (editTeacherProfile?.timezone) {
            return `${editTeacherProfile.timezone.identifier} ${editTeacherProfile.timezone.offset}`;
        }
        if (dataTeacherProfile?.timezone) {
            return `${dataTeacherProfile.timezone.identifier} ${dataTeacherProfile.timezone.offset}`;
        }
        return null;
    }, [editTeacherProfile?.timezone, dataTeacherProfile?.timezone]);

    const defaultLocation = useMemo(() => {
        if (editTeacherProfile?.location) {
            return editTeacherProfile?.location;
        }
        if (dataTeacherProfile?.location) {
            return dataTeacherProfile?.location;
        }
        return null;
    }, [editTeacherProfile?.location, dataTeacherProfile?.location]);

    return (
        <div>
            <>
                {dataTeacherProfile ? (
                    <div>
                        <div className={style.wrapper}>
                            <Button
                                className={style.btn_link}
                                color="link"
                                onClick={backButtonClick}>
                                <ArrowBack />
                                <p className={style.btn_link_text}>Назад</p>
                            </Button>

                            <div className={style.listHeader}>
                                <p className={style.listTopTxt}>Редактировать пользователя</p>
                                <a
                                    className={style.linkToLk}
                                    href={`${process.env.REACT_APP_WEB_MORDA_URL}/edit-mentor/${id}`}
                                    target="_blank"
                                    rel="noreferrer">
                                    Перейти в личный кабинет
                                </a>
                            </div>

                            <Form className={style.addTeacherFields}>
                                <FormGroup>
                                    <Label for="firstName">Имя: *</Label>
                                    <Input
                                        type="text"
                                        id="firstName"
                                        name={formNames.first_name}
                                        value={editTeacherProfile?.first_name}
                                        onChange={handleChange}
                                        invalid={!!errors.first_name}
                                        onBlur={onBlur}
                                    />
                                    <FormFeedback>{errors.first_name}</FormFeedback>
                                    <FormText color="muted">Введите от 2 до 150 символов</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="lastName">Фамилия: *</Label>
                                    <Input
                                        type="text"
                                        id="lastName"
                                        name={formNames.last_name}
                                        value={editTeacherProfile?.last_name}
                                        onChange={handleChange}
                                        invalid={!!errors.last_name}
                                        onBlur={onBlur}
                                    />
                                    <FormFeedback>{errors.last_name}</FormFeedback>
                                    <FormText color="muted">Введите от 2 до 150 символов</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="middleName">Отчество: *</Label>
                                    <Input
                                        type="text"
                                        id="middleName"
                                        name={formNames.middle_name}
                                        value={editTeacherProfile?.middle_name}
                                        onChange={handleChange}
                                        invalid={!!errors.middle_name}
                                        onBlur={onBlur}
                                    />
                                    <FormFeedback>{errors.middle_name}</FormFeedback>
                                    <FormText color="muted">Введите от 2 до 150 символов</FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Почта: *</Label>
                                    <Input
                                        type="text"
                                        disabled={editTeacherProfile?.email}
                                        id="email"
                                        name={formNames.email}
                                        value={editTeacherProfile?.email}
                                        onChange={handleChange}
                                        invalid={!!errors.email}
                                        onBlur={onBlur}
                                    />
                                    <FormFeedback>{errors.email}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="professionInfo">
                                        Проф. информация (должность, стаж, специализация): *
                                    </Label>
                                    <Input
                                        type="textarea"
                                        className={style.textArea}
                                        id="professionInfo"
                                        name={formNames.profession_info}
                                        value={editTeacherProfile?.profession_info}
                                        onChange={handleChange}
                                        invalid={!!errors.profession_info}
                                        onBlur={onBlur}
                                    />
                                    <FormFeedback>{errors.profession_info}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="educationInfo">Образование: *</Label>
                                    <Input
                                        type="textarea"
                                        className={style.textArea}
                                        id="educationInfo"
                                        name={formNames.education_info}
                                        value={editTeacherProfile?.education_info}
                                        onChange={handleChange}
                                        invalid={!!errors.education_info}
                                        onBlur={onBlur}
                                    />
                                    <FormFeedback>{errors.education_info}</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="timezone">Место проживания: *</Label>
                                    {modeChangeLocation && (
                                        <div>
                                            <AsyncSelect
                                                placeholder=""
                                                loadOptions={locationLoadOptions}
                                                noOptionsMessage={() =>
                                                    "Начните вводить название города"
                                                }
                                                loadingMessage={() => "Загружается..."}
                                                onChange={locationSelectOnChange}
                                                onBlur={() => setModeChangeLocation(false)}
                                                autoFocus
                                                cacheOptions
                                            />
                                            <FormText>
                                                Введите название города и выберите из списка
                                            </FormText>
                                            <br />
                                        </div>
                                    )}
                                    {!modeChangeLocation && (
                                        <div>
                                            <Input
                                                id="timezone"
                                                name="timezone"
                                                value={defaultLocation}
                                                onFocus={() => setModeChangeLocation(true)}
                                                className={style.passwordInput}
                                                placeholder="Введите страну и город"
                                                invalid={
                                                    !!errors["teacher.timezone_id"] ||
                                                    !!errors?.teacher?.timezone_id
                                                }
                                            />
                                            {defaultTimezone && (
                                                <FormText>Часовой пояс: {defaultTimezone}</FormText>
                                            )}
                                        </div>
                                    )}
                                    <FormFeedback
                                        className={
                                            (!!errors["teacher.timezone_id"] ||
                                                !!errors?.teacher?.timezone_id) &&
                                            "d-block"
                                        }>
                                        {errors["teacher.timezone_id"] ||
                                            errors?.teacher?.timezone_id}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="photo">Фото:</Label>
                                    <div className={style.blockPhoto}>
                                        <input
                                            id="avatar"
                                            type="file"
                                            onChange={uploadPhoto}
                                            style={{ display: "none" }}
                                            accept="image/*"
                                        />
                                    </div>
                                    {buttonStatus ? (
                                        <>
                                            <div className={style.inputImgWrapper}>
                                                <img
                                                    className={style.inputImgWrapperImg}
                                                    src={imgIcon}
                                                    alt="icon"
                                                />
                                                <p className={style.inputImgWrapperTxt}>
                                                    {avatarName}
                                                </p>
                                                <Button
                                                    className={style.inputImgWrapperBtn}
                                                    outline
                                                    color="inherit"
                                                    name="firstImgDeleteBtn"
                                                    onClick={() => deleteImage()}>
                                                    <img src={deleteIcon} alt="icon" />
                                                </Button>
                                            </div>
                                            <img
                                                className={style.teacherPhotoFromServer}
                                                src={image}
                                                alt=""
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            color="inherit"
                                            className={style.fileLable}
                                            onClick={() => seCropperModalOpen(true)}>
                                            <img
                                                className={style.addImageButton}
                                                color="inherit"
                                                src={downloadLogo}
                                                alt=""
                                            />
                                            Прикрепить изображение
                                        </Button>
                                    )}

                                    <FormText color="muted">
                                        Размер изображения не должен превышать 10мб
                                    </FormText>
                                </FormGroup>
                                <div className={style.addButton}>
                                    {loading ? (
                                        <Button
                                            disabled
                                            color="primary"
                                            className={style.createLesson}
                                            onClick={() => submitButton()}>
                                            <div
                                                className={`spinner-border text-light ${style.loading}`}
                                                role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </Button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            className={style.createLesson}
                                            onClick={() => submitButton()}>
                                            Сохранить
                                        </Button>
                                    )}
                                </div>
                            </Form>
                        </div>
                        {isCloseModalOpen && <LeavePage page={paths.usersList} />}
                    </div>
                ) : (
                    <Preloader />
                )}
            </>
            {cropperModalOpen && (
                <CropperModal
                    image={image}
                    setCropper={setCropper}
                    getCropData={getCropData}
                    cancelClick={deleteImage}
                    lableFor={"avatar"}
                    croppSize={10}
                    hint="Выберите область, которая будет отображаться в вашем профиле"
                    ratio={1}
                />
            )}
        </div>
    );
}

export default observer(EditTeacher);

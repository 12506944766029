import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Label, FormGroup, FormText, Button } from "reactstrap";
import resApi from "api/endPoints/resources";
import Preloader from "components/Preloader/Preloader";
import paths from "constants/paths";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as style from "../style/CreateMaterial.module.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useStore } from "../../../../../store";

function TabLink({ resid }) {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const {
    userStore: { trainingNames },
    modalWindowStore: { setIsNotificationOpen, setNotification },
  } = useStore();
  const folderName = trainingNames.find((v) => v.id === Number(resid))?.name;

  const onSubmit = (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("jsonBody", JSON.stringify({ ...data, type: "LINK" }));
    resApi
      .createResource(resid, formData)
      .then((response) => {
        setIsNotificationOpen(true);
        setNotification("Материал успешно добавлен");
        let timer = setTimeout(() => {
          setIsNotificationOpen(false);
          clearTimeout(timer);
        }, 3000);
        history.push(paths.resource(resid));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        new Error(error);
      });
  };

  return (
    <>
      {!loading ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Введите название материала:</Label>

            <input
              className={
                errors.name
                  ? "form_control__input form_control__error"
                  : "form_control__input"
              }
              {...register("name", {
                required: true,
                minLength: 2,
                maxLength: 100,
              })}
              placeholder="Введите название"
            />

            {errors.name?.type === "required" && (
              <span style={{ color: "red" }}>
                Поле обязательно для заполнения
              </span>
            )}
            {errors.name?.type === "minLength" && (
              <span style={{ color: "red" }}>
                Введите корректное значение, должно быть больше 2 символов
              </span>
            )}
            {errors.name?.type === "maxLength" && (
              <span style={{ color: "red" }}>
                Введите корректное значение, должно быть меньше 100 символов
              </span>
            )}

            <FormText>Введите от 2 до 100 символов</FormText>
          </FormGroup>

          <FormGroup className="mt-5">
            <Label>Вставьте ссылку на ресурс:</Label>

            <input
              className={
                errors.src_url
                  ? "form_control__input form_control__error"
                  : "form_control__input"
              }
              {...register("src_url", {
                required: true,
                pattern:
                  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
              })}
              placeholder="Вставьте URL в виде https://google.com"
            />

            {errors.src_url?.type === "pattern" && (
              <span style={{ color: "red" }}>Введите корректное значение </span>
            )}
            {errors.src_url?.type === "required" && (
              <span style={{ color: "red" }}>
                Поле обязательно для заполнения
              </span>
            )}
          </FormGroup>
          <div className={style.material_block}>
            <span className={style.material_block__text}>
              Материал будет добавлен в раздел:
            </span>
            {folderName}
          </div>
          <Button
            className={style.button__create_material}
            type="submit"
            color="primary"
          >
            Добавить материал
          </Button>
        </Form>
      ) : (
        <div className="row">
          <Preloader />
        </div>
      )}
    </>
  );
}

export default observer(TabLink);

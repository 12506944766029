import React from "react";
import { ReactComponent as ArrowLeftIcon } from "../assets/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/arrow-right.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const { title, onClickArrow, isCurrentWeek, onCurrentWeek } = props;

    const backToCurrentWeek = !isCurrentWeek && (
        <span
            className={styles.wrapper__slider__current}
            role="button"
            tabIndex="0"
            onClick={() => onCurrentWeek()}
            onKeyPress={() => onCurrentWeek()}>
            Вернуться к текущей неделе
        </span>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapper__slider}>
                <button
                    className={styles.wrapper__slider__button}
                    type="button"
                    onClick={() => onClickArrow("left")}>
                    <ArrowLeftIcon />
                </button>
                <span className={styles.wrapper__slider__title}>{title}</span>
                <button
                    className={styles.wrapper__slider__button}
                    type="button"
                    onClick={() => onClickArrow("right")}>
                    <ArrowRightIcon />
                </button>
            </div>
            {backToCurrentWeek}
        </div>
    );
};

export default View;

import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import programsApi from "../../../../api/endPoints/programs";
import Modal from "../../Modal/Modal";
import * as style from "./style/CreateMaterial.module.scss";
import TabLink from "./TabLink/TabLink";
import TabFile from "./TabFile/TabFile";
import paths from "constants/paths";
import { useHistory } from "react-router";
import { useStore } from "../../../../store";

function CreateMaterial(props) {
  const [activeTab, setActiveTab] = useState("tab2");
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const resid = props.match.params.id;
  const {
    userStore: { setTrainingNames, trainingNames },
  } = useStore();

  useEffect(() => {
    programsApi
      .programs()
      .then((d) => {
        setTrainingNames(d.items);
      })
      .catch();
  }, []);

  function handleClick(e, tab) {
    e.preventDefault();
    setActiveTab(tab);
  }

  function handleBack(e) {
    e.preventDefault();
    setModal(true);
  }

  function goBack() {
    history.push(paths.resource(resid));
  }

	return (
		<>
			<div className={style.wrapper}>
				<a href="/" onClick={handleBack} className={style.back_link}>
					&#8592; Назад
				</a>
				<div className={style.header}>
					<h1 className="title-h1">Добавить материал</h1>
				</div>
				{/* Tab controls */}
				<div className={style.tab_wrapper}>
        <a
						href="/"
						onClick={(e) => handleClick(e, "tab2")}
						className={
							activeTab === "tab2"
								? `${style.tab_active} ${style.tab}`
								: style.tab
						}
					>
						Добавить файл
					</a>
					<a
						href="/"
						onClick={(e) => handleClick(e, "tab1")}
						className={
							activeTab === "tab1"
								? `${style.tab_active} ${style.tab}`
								: style.tab
						}
					>
						Добавить ссылку
					</a>
					
				</div>
				{/* Tabs */}
				<div className={style.tabs}>
					{activeTab === "tab1" && trainingNames ? (
						<TabLink resid={resid} />
					) : (
						<TabFile resid={resid} />
					)}
				</div>
			</div>
			{modal && (
				<Modal>
					<p className={style.modalTitle}>Вы собираетесь покинуть страницу</p>
					<div>Загруженные материалы не сохранятся</div>
					<div className={style.modal_buttons}>
						<Button className={style.modal_button} outline color="primary" onClick={goBack}>
							Покинуть страницу
						</Button>
						<Button className={style.modal_button} color="primary" onClick={() => setModal(false)}>
							Отменить
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
}

export default observer(CreateMaterial);

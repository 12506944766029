import React from "react";
import { useParams } from "react-router";
import * as style from "./style/TableData.module.scss";
import deleteIcon from "assets/img/deleteIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/img/file.svg";
import { ReactComponent as ArrowIcon } from "assets/img/arrow-up.svg";
import Resources from "api/endPoints/resources";

export default function TableData({ data, indx, del }) {
	const { id, name, src_url, type, hash } = data;

	const params = useParams();

	function handleSubmit(e) {
		e.preventDefault();
		del(id, name);
	}

	const download = (value) => {
		Resources.downloadResource(params.id, value).then((response) => {
			const link = document.createElement("a");
			link.href = response;
			document.body.appendChild(link);
			link.click();
		});
	};

	return (
		<div className={style.table__row}>
			
			<div>
				{type === "LINK" ? (
					<a
						href={src_url}
						className={style.table__link}
						target="_blank"
						rel="noreferrer"
					>
						{name}
						<ArrowIcon className={style.table__icon} />
					</a>
				) : (
					<button
						type="button"
						className={style.table__button}
						onClick={() => download(hash)}
					>
						{name}
						<DownloadIcon className={style.table__icon} />
					</button>
				)}
			</div>
			<div className={style.table__icon_delete}>
				<a href="/" onClick={handleSubmit}>
					<img src={deleteIcon} alt="icon" />
				</a>
			</div>
		</div>
	);
}

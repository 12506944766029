import React, { useState } from "react";
import { Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";

import * as style from "./style/Teacher.module.scss";
import TeacherComponent from "./TeacherComponent";
import users from "api/endPoints/users";
import FileSaver from "file-saver";
import {ReactComponent as SearchIcon} from "../../../assets/img/search.svg"
import {ReactComponent as Download} from "../../../assets/img/downloadBlue.svg"

function TeachersList() {
  const [filter, setFilters] = useState({
    name: "",
  });

  const downloadMentors = () => {
    users
      .downloadMentors(filter)
      .then((response) => FileSaver.saveAs(response, "Список педагогов.xlsx"));
  };

  return (
    <div>
      <div>
        <div className={style.listHeader}>
          <div className={style.listHeaderInput}>
            <p className={style.listHeaderInput_title}>Поиск по ФИО:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                name="name"
                className={style.findUsers}
                value={filter.name}
                minLength={1}
                debounceTimeout={800}
                onChange={(event) =>
                  setFilters({ name: event.target.value.trim("-") })
                }
                placeholder="Введите ФИО педагога "
              />
              <div className={style.searchImg}>
               <SearchIcon/>
              </div>
            </div>
          </div>
          <Button
            color="inherit"
            className={style.fileLable}
            onClick={downloadMentors}
          >
            <Download/>
            <p className={style.fileLable_text}>Скачать список</p>
          </Button>
        </div>
      </div>
      <TeacherComponent filter={filter} />
    </div>
  );
}

export default TeachersList;

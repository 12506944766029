/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import debounce from "debounce";
import { useStore } from "store";
import validate from "../utils/validate";
import { formatInputValue, getTimeZone, dateFormat } from "../helpers";
import { downloadFormat } from "./helpers";
import View from "./View";

const Controller = (props) => {
    const { downloadEvent, onClose } = props;

    const {
        downLoadTimeSheetStore: {
            start_week,
            end_week,
            start_month,
            end_month,
            students,
            statuses,
        },
    } = useStore();

    const [selectDate, setSelectDate] = useState([]);
    const [noSelectDate, setNoSelectDate] = useState([]);
    const [allSelectDate, setAllSelectDate] = useState([]);
    const [currentAllSelectDate, setCurrentAllSelectDate] = useState([]);
    const [loadingDate, setLoadingDate] = useState(false);

    const [errors, setErrors] = useState({});

    const [data, setData] = useState({
        date_from: downloadFormat(start_week),
        date_to: downloadFormat(end_week),
        statuses: statuses,
        students: students,
        teachers: [],
        timezone: getTimeZone(),
        type: "WEEK",
    });

    const title = "Выберите необходимый период, чтобы скачать расписание";

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const onSearch = (setCurrent, allSelect) =>
        debounce((value) => {
            setCurrent(
                allSelect.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())),
            );
        }, 300);

    useEffect(() => {
        setLoadingDate(true);
        const mas = [
            {
                name: `1 неделя ${dateFormat(start_week)}-${dateFormat(end_week)}`,
                selectedCode: "WEEK",
            },
            {
                name: `1 месяц ${dateFormat(start_month)}-${dateFormat(end_month)}`,
                selectedCode: "MONTH",
            },
        ];
        setAllSelectDate(mas);
        setCurrentAllSelectDate(mas);
        setSelectDate([mas[0]]);
        setNoSelectDate([mas[1]]);
        setLoadingDate(false);
    }, []);

    useEffect(() => {
        if (selectDate.length > 0) {
            if (selectDate[0].selectedCode === "WEEK") {
                data.date_from = downloadFormat(start_week);
                data.date_to = downloadFormat(end_week);
            } else {
                data.date_from = start_month;
                data.date_to = end_month;
            }
            setData({ ...data, type: selectDate[0].selectedCode });
            clearErrField("type");
        } else {
            setData({ ...data, type: "" });
        }
    }, [selectDate]);

    const onSearchDate = useMemo(
        () => onSearch(setCurrentAllSelectDate, allSelectDate),
        [allSelectDate],
    );

    const inputValueDate = selectDate.length > 0 ? formatInputValue(selectDate) : "Период";

    const dateData = {
        selectDate: selectDate,
        setSelectDate: setSelectDate,
        noSelectDate: noSelectDate,
        setNoSelectDate: setNoSelectDate,
        allSelectDate: currentAllSelectDate,
        loadingDate: loadingDate,
        onSearchDate: (value) => onSearchDate(value),
        inputValueDate: inputValueDate,
    };

    const checkDownloadEvent = async () => {
        const validateErrors = await validate.downloadClientValidateField("type", {
            ...data,
        });
        if (validateErrors) {
            setErrors({ ...validateErrors });
        } else {
            downloadEvent(data, setErrors);
        }
    };

    return (
        <View
            title={title}
            checkEvent={() => checkDownloadEvent()}
            onClose={onClose}
            errors={errors}
            {...dateData}
        />
    );
};

export default Controller;

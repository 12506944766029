/* eslint-disable import/no-anonymous-default-export */
import axiosInstance, { setBearerToken, removeBearerToken } from "..";
import apiList from "../../constants/apiList";

export default {
    async signIn(login, password) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.signIn,
                data: {
                    email: login,
                    password: password,
                },
            });
            const token = response.data.token.access_token;
            const role = response.data.user.roles;
            const id = response.data.user.id;
            setBearerToken(token);
            localStorage.setItem("access_token", token);
            localStorage.setItem("role", role);
            localStorage.setItem("id", id);
        } catch (error) {
            throw error.response;
        }
    },

    async refresh() {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.refresh,
            });
            const { token, user } = response.data;
            localStorage.setItem("access_token", token.access_token);
            localStorage.setItem("role", user.roles[0]);
            localStorage.setItem("id", user.id);
            setBearerToken(token.access_token);
            return response;
        } catch (error) {
            throw error.response;
        }
    },

    async logOut() {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.logOut,
            });
            localStorage.removeItem("access_token");
            localStorage.removeItem("role");
            localStorage.removeItem("id");
            removeBearerToken();
        } catch (error) {
            throw error.response;
        }
    },

    async changePassword(data) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.changePassword,
                data
            });
            localStorage.removeItem("access_token");
            localStorage.removeItem("role");
            localStorage.removeItem("id");
            removeBearerToken();
        } catch (error) {
            throw error.response;
        }
    },
};

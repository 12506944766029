import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router';
import { initSentry } from 'Sentry';
import history from './utils/history/history';
import App from './App';
import { createStore } from 'store';
import Preloader from 'components/Preloader/Preloader';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.scss';
import defaultRedirection from 'utils/history/defaultRedirection';

initSentry();
(async () => {
  const StoreProvider = createStore({});
  await defaultRedirection();
  ReactDOM.render(
    <StoreProvider>
      <Router history={history}>
        <ToastContainer
          autoClose={5000}
          style={{ zIndex: 1999 }}
          position="top-center"
          theme="dark"
          hideProgressBar
          icon={false}
        />
        <React.Suspense fallback={<Preloader />}>
          <App />
        </React.Suspense>
      </Router>
    </StoreProvider>,
    document.getElementById('root'),
  );
})();

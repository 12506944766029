import React from "react";
import { Card } from "reactstrap";
import { Link, useRouteMatch } from "react-router-dom";
import Menu from "../Menu/Menu";
import * as style from "../style/ItemList.module.scss";

function CardItem({ item, menu = true, isClickable }) {
  const { url } = useRouteMatch();
  return (
    <>
      {isClickable ? (
        <Link key={item.id} className={style.items} to={`${url}/subject/${item.id}`}>
          <div className={style.items_header}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/${item.image_src}`}
              alt="Картинка"
              className={style.items_header_image}
            />
            {menu && <Menu id={item.id} item={item} />}
          </div>
          <p className={style.items_name}>{item.name}</p>
        </Link>
      ) : (
        <Card key={item.id} className={style.items}>
          <div className={style.items_header}>
            <img
              src={`${process.env.REACT_APP_S3_URL}/${item.image_src}`}
              alt="Картинка"
              className={style.items_header_image}
            />
            {menu && <Menu id={item.id} item={item} />}
          </div>
          <p className={style.items_name}>{item.name}</p>
        </Card>
      )}
    </>
  );
}

export default CardItem;

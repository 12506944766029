import React, { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router";
import { CustomInput } from "reactstrap";
import { ReactComponent as FileIcon } from "../assets/FileIcon.svg";
import { ReactComponent as DownloadIcon } from "../assets/DownloadIcon.svg";
import { ReactComponent as LinkIcon } from "../assets/linkIcon.svg";
import itemsManagement from "api/endPoints/itemsManagement";
import folders from "api/endPoints/folders";
import LevelMargin from "../LevelMargin";
import * as style from "../style/folder-tree.module.scss";
import "../style/switcher.scss";
import "../style/radio.scss";

function File(props) {
  const {
    id,
    level,
    isFirstChild,
    isLastChild,
    isPrevFolderOpen,
    lastElementsList,
    name,
    checked,
    setChecked,
    fullData,
    radio,
    toggleChange,
    purchases,
    type,
    src_url,
    customId,
  } = props;
  const params = useParams();
  const [flag, setFlag] = useState(false);
  let array = [];
  let data = {};
  useEffect(() => {
    recursiveManage(fullData);
    data = {
      format_id: params.formId,
      material_id: params.materialId,
      subject_id: params.subjId,
      attachments: [...array],
    };
    if (flag) {
      itemsManagement.editItemInTree(params.formId, data).then(() => {
        setFlag(false);
        array = [];
        data = {};
      });
    }
  }, [fullData]);

  const recursiveManage = (data) => {
    for (let i = 0; i <= data.length; i++) {
      if (data[i]?.checked === 0.5) {
        recursiveManage(data[i].children);
      } else if (data[i]?.checked === 1) {
        let newItem;
        if (data[i].foreign_type === "FOLDER") {
          newItem = {
            attachment_id: data[i].id,
            attachment_type: "FOLDER",
          };
          array.push(newItem);
        } else {
          newItem = {
            attachment_id: data[i].id,
            attachment_type: "RESOURCE",
          };
          array.push(newItem);
        }
      }
    }
  };

  const handleSwitcherClick = (event) => {
    event.stopPropagation();
    setChecked("custom_id", customId, +!checked);
    setFlag(true);
  };

  const handleRadioClick = (event) => {
    event.stopPropagation();
    toggleChange(event, "RESOURCE");
  };

  const borderStyle = useMemo(() => {
    return `${!isPrevFolderOpen ? style.borderTop : ""} ${
      isLastChild ? style.borderBottom : ""
    }`;
  }, [isPrevFolderOpen, isLastChild]);

  const nameContainerStyle = useMemo(() => {
    return `${style.nameContainer} ${borderStyle}`;
  }, [borderStyle]);

  const resourceClick = () => {
    if (purchases) {
      if (type === "FILE") {
        folders.downloadResource(id).then((response) => {
          const link = document.createElement("a");
          link.href = response.url;
          document.body.appendChild(link);
          link.click();
        });
      } else {
        const link = document.createElement("a");
        link.href = src_url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      }
    }
  };

  return (
    <tr
      className={`${style.tableRow} ${
        purchases ? style.tableRowPurchases : null
      }`}
      onClick={resourceClick}
    >
      <td className={style.tableColumn}>
        <div className={style.marginLinesContainer}>
          <LevelMargin
            level={level}
            isFirstChild={isFirstChild}
            isLastChild={isLastChild}
            lastElementsList={lastElementsList}
          />
          <div className={nameContainerStyle}>
            <div className={style.nameIconContainer}>
              {!radio ? (
                <FileIcon />
              ) : (
                <div className="radio-button__wrapper">
                  <label className="radio-button">
                    <input
                      key={customId}
                      id={id}
                      type="radio"
                      name="tree"
                      value={customId}
                      onChange={handleRadioClick}
                      checked={checked === 1}
                    />
                  </label>
                </div>
              )}
            </div>
            {name}
          </div>
        </div>
      </td>
      <td className={style.tableColumn}>
        <div
          className={`${style.interactiveContainer} ${borderStyle} ${
            radio && style.interactiveContainerRadio
          } ${purchases && style.interactiveContainerPurchases}`}
        >
          {!radio && !purchases && (
            <CustomInput
              id={`file${id}`}
              type="switch"
              label={checked ? "Активен" : "Не активен"}
              checked={checked}
              onChange={handleSwitcherClick}
            />
          )}
          <div className={style.interactiveIconContainer}>
            {purchases && (
              <>
                {type === "FILE" ? (
                  <DownloadIcon className={style.interactiveIcon} />
                ) : (
                  <LinkIcon className={style.interactiveIcon} />
                )}
              </>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default File;

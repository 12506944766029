import joi from "joi";

const schema = joi.object({
    old_password: joi.string().required().messages({
        "string.empty": "Это поле обязательное",
    }),
    new_password: joi.string().min(6).required().messages({
        "string.empty": "Это поле обязательное",
        "string.min": "Значение не должно быть меньше 6",
    }),
    new_password_confirmation: joi.string().min(6).required().messages({
        "string.empty": "Это поле обязательное",
        "string.min": "Значение не должно быть меньше 6",
    })
})

export default function hasErrors(form) {
    const validate = schema.validate(form)
    return validate?.error
}
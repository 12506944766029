import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Button } from "reactstrap";
import * as style from "./style/PublicFormModal.module.scss";
import { useStore } from "store";
import educationForms from "api/endPoints/educationForms";
import { toast } from "react-toastify";

function PublicFormModal({ data, setLoading, loading }) {
  const { is_active, name } = data;
  const params = useParams();

  const cardInfo = is_active ? (
    <>
      Вы собираетесь снять с
      <br />публикации шаблон {name}
    </>
  ) : (
    <>
      Вы собираетесь опубликовать
      <br />шаблон формы обучения {name}
    </>
  );
  const {
    modalWindowStore: { setIsFormPublic },
  } = useStore();

  const submitClick = () => {
    setLoading(true);
    if (is_active) {
      educationForms.unPublishForm(params.id).then(() => {
        setIsFormPublic(false);
        toast.info(`Шаблон формы ${name} снят с публикации`);
        setLoading(false);
      });
    } else {
      educationForms.publishForm(params.id).then(() => {
        setIsFormPublic(false);
        toast.info(`Шаблон формы ${name} опубликован`);
        setLoading(false);
      });
    }
  };

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>{cardInfo}</p>
          </div>
          <p className={style.popUpTxt}>
            {is_active
              ? "Он станет недоступным к покупке пользователем"
              : "Он станет доступным для покупки пользователем"}
          </p>
          <div>
            <Button
              color="outline"
              className={style.canselChange}
              onClick={() => setIsFormPublic(false)}
            >
              Отменить
            </Button>
            {loading ? (
              <Button
                disabled
                color="primary"
                className={style.delete}
                onClick={() => submitClick()}
              >
                <div
                  className={`spinner-border text-light ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                className={style.delete}
                onClick={() => submitClick()}
              >
                {is_active ? "Снять с публикации" : "Опубликовать"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(PublicFormModal);

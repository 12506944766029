import React, { useEffect, useState } from "react";
import { useStore } from "store";
import { useParams, useLocation } from "react-router";
import { Button } from "reactstrap";
import Menu from "../../../Menu/Menu";
import history from "../../../../utils/history/history";
import paths from "../../../../constants/paths";
import Pagination from "../../../Pagination/Pagination";
import AddFolder from "../AddFolder/AddFolder";
import DeleteFolder from "../DeleteFolder/DeleteFolder";
import Preloader from "components/Preloader/Preloader";
import getFolderId from "../../../../api/endPoints/folders";
import * as style from "./style/FoldersResources.module.scss";
import plusIcon from "../../../../assets/img/plusIcon.svg";

import { observer } from "mobx-react-lite";

import ResourcesInFolder from "./ResourcesInFolder";
import EditFolder from "../EditFolder/EditFolder";
import BreadCrumbs from "components/Breadcrumbs/Breadcrumbs";

function FolderDetail() {
  const [foldersDetailInfo, setFoldersDetailInfo] = useState();
  const [resources, setResources] = useState();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [newFolderId, setNewFolderId] = useState(null);
  const { id } = useParams();
  const { pathname } = useLocation();

  const {
    modalWindowStore: {
      setAddFolder,
      addFolderOpen,
      setDeleteFolder,
      deleteFolderOpen,
      setEditFolder,
      editFolderOpen,
    },
  } = useStore();

  useEffect(() => {
    setLoading(true);
    getFolderId.getFolderId(id, page, newFolderId).then((response) => {
      setBreadCrumbs(response.breadcrumbs);
      setLastPage(response.last_page);
      setFoldersDetailInfo(response);
      setNewFolderId(null);
      setLoading(false);
      if (response.current_page > response.last_page) {
        setPage(response.last_page);
      }
    });
    getFolderId.getResources(id).then((response) => {
      setResources(response.items);
      setPaginationLoading(false);
      setLoading(false);
      if (response.current_page > response.last_page) {
        setPage(response.last_page);
      }
    });
  }, [id, page, addFolderOpen, deleteFolderOpen, editFolderOpen]);

  const breadcrumbsNew = breadCrumbs.map((item) => ({
    name: item.name,
    path: `/folder-detail/${item.id}`,
  }));

  breadcrumbsNew.unshift({ name: "Каталог ресурсов", path: "/resources" });

  const addFolder = (id) => {
    setModalLoading(true, id);
  };

  const deleteFolder = () => {
    setModalLoading(true);
  };

  const editFolder = () => {
    setModalLoading(true);
  };

  const prevPage = (number) => {
    setPaginationLoading(true);
    setPage(page - number);
    setNewFolderId(null);
  };

  const nextPage = (number) => {
    setPaginationLoading(true);
    setPage(page + number);
    setNewFolderId(null);
  };

  const lastPageFunction = (number) => {
    setPaginationLoading(true);
    setPage(number);
    setNewFolderId(null);
  };

  return (
    <>
      {foldersDetailInfo ? (
        <div className={style.headTitle}>
          <BreadCrumbs breadCrumbs={breadcrumbsNew} pathname={pathname} />
          <h3 className={style.titleNameFolder}>{foldersDetailInfo.name}</h3>
          <p className={style.foldersTitle}>Папки</p>
          <div>
            <div className={style.training_program_blocks}>
              <div className={style.training_program_blocks__block}>
                <div className={style.blocks__titles}>
                  <h3
                    className={`title-h3 ${style.training_program_blocks__title}`}
                  >
                    <Button
                      color="inherit"
                      className={style.addNewFolder}
                      onClick={() => setAddFolder(true, id)}
                    >
                      <img
                        className={style.plusIcon}
                        color="inherit"
                        src={plusIcon}
                        alt=""
                      />
                      Добавить папку
                    </Button>
                  </h3>
                </div>
              </div>

              {paginationLoading || loading ? (
                <div className={`row ${style.lessonsPreloader}`}>
                  <Preloader />
                </div>
              ) : (
                <>
                  {foldersDetailInfo &&
                    foldersDetailInfo.items.map((item) => (
                      <div
                        key={item.id}
                        className={style.training_program_blocks__link}
                        title={item.name}
                      >
                        <div className={style.training_program_blocks__block}>
                          <div
                            className={style.blocks__titles}
                            onClick={() =>
                              history.push(paths.folderDetail(item.id))
                            }
                          >
                            <p
                              className={`title-h3 ${style.training_program_blocks__title}`}
                            >
                              {item.name}
                            </p>
                          </div>
                          <div className={style.menuContainer}>
                            <Menu key={item.id}>
                              <Button
                                color="inherit"
                                className={style.menuButton}
                                onClick={() => setEditFolder(true, item)}
                              >
                                Редактировать
                              </Button>
                              <Button
                                color="inherit"
                                className={style.menuButton}
                                onClick={() => setDeleteFolder(true, item)}
                              >
                                Удалить
                              </Button>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
          <div className={style.foldersPagination}>
            <Pagination
              page={page}
              nextPage={nextPage}
              prevPage={prevPage}
              lastPage={lastPage}
              lastPageFunction={lastPageFunction}
            />
          </div>

          {addFolderOpen && (
            <AddFolder
              addFolder={addFolder}
              loading={modalLoading}
              setModalLoading={setModalLoading}
              setNewFolderId={setNewFolderId}
            />
          )}

          {deleteFolderOpen && (
            <DeleteFolder
              deleteFolder={deleteFolder}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}

          {editFolderOpen && (
            <EditFolder
              editFolder={editFolder}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}
        </div>
      ) : (
        <div className="row">
          <Preloader />
        </div>
      )}
      <hr />
      <ResourcesInFolder id={id} />
    </>
  );
}

export default observer(FolderDetail);

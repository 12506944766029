import { Route, Switch } from "react-router";
import Login from "./components/Login/Login";
import Header from "./components/Header/Header";
import LessonList from "./components/LessonsList/LessonsList";
import CreateLesson from "./components/CreateLesson/CreateLesson";
import EditLesson from "./components/EditLesson/EditLesson";
import AdminPanel from "./components/AdminPanel/AdminPanel";
import TrainingProgram from "./components/TrainingProgram/TrainingProgram";
import Resource from "./components/TrainingProgram/Resources/Resource/Resource";
import CreateMaterial from "./components/TrainingProgram/Resources/CreateMaterial/CreateMaterial";
import ChangePasswordPage from "./pages/ChangePassword/ChangePassword";
import ChangeTimezone from "./pages/ChangeTimezone";
import paths from "./constants/paths";
import "./App.css";
import UsersList from "components/UsersList/UsersList";
import AddUsers from "components/UsersList/AddUsers/AddUsers";
import EditTeacher from "components/UsersList/EditUser/EditTeacher/EditTeacher";
import EditStudent from "components/UsersList/EditUser/EditStudent/EditStudent";
import CatalogResources from "components/Resources/CatalogResources/CatalogResources";
import FolderDetail from "components/Resources/Folders/FoldersResources/FolderDetail";
import Purchases from "components/Purchases/Purchases";
import EducationForms from "components/EducationForms/EducationForms";
import FormDetail from "components/EducationForms/FormDetail/FormDetail";
import NewForm from "components/Purchases/NewForm/NewForm";
import PurchaseHistory from "components/Purchases/PurchaseHistory/PurchaseHistory";
import ItemList from "components/ItemManagement/ItemList";
import AdditionalPurchases from "components/Purchases/AdditionalPurchases/AdditionalPurchases";
import AddEditProduct from "components/Purchases/AdditionalPurchases/AddEditProduct/AddEditProduct";
import PurchaseMaterialDetail from "components/Purchases/AdditionalPurchases/PurchaseMaterialDetail/PurchaseMaterialDetail";
import UserDetail from "components/UsersList/UserDetail/UserDetail";
import Subjects from "components/Purchases/NewForm/Subjects";
import MaterialTree from "components/Purchases/NewForm/MaterialTree";
import UserMaterialPurchase from "components/UsersList/UserDetail/UserMaterialPurchase/UserMaterialPurchase";
import TeacherDetail from "components/UsersList/TeacherDetail/TeacherDetail";
import TimeSheet from "components/TimeSheet/TimeSheet";

function App() {
    return (
        <div className="container">
            <div className="row">
                <Header />
            </div>
            <Switch>
                <Route exact path={paths.login} component={Login} />
                <Route exact path={paths.adminPanel} component={AdminPanel} />
                <Route exact path={paths.lessonList} component={LessonList} />
                <Route exact path={paths.createLesson} component={CreateLesson} />
                <Route exact path={paths.training} component={TrainingProgram} />
                <Route exact path={paths.timeSheet} component={TimeSheet} />
                <Route exact path={paths.resource(":id")} component={Resource} />
                <Route exact path={paths.createMaterial(":id")} component={CreateMaterial} />
                <Route exact path={paths.editLesson(":id")} component={EditLesson} />
                <Route exact path={paths.changePassword} component={ChangePasswordPage} />
                <Route exact path={paths.changeTimezone} component={ChangeTimezone} />
                <Route exact path={paths.usersList} component={UsersList} />
                <Route exact path={paths.addUsers} component={AddUsers} />
                <Route exact path={paths.editTeacher(":id")} component={EditTeacher} />
                <Route exact path={paths.editStudent(":id")} component={EditStudent} />
                <Route exact path={paths.resources()} component={CatalogResources} />
                <Route exact path={paths.folderDetail(":id")} component={FolderDetail} />
                <Route exact path={paths.purchases} component={Purchases} />
                <Route exact path={"/user-detail/:id/material/:materialId"} component={Subjects} />
                <Route
                    exact
                    path={"/user-detail/:id/materials/:materialId"}
                    component={UserMaterialPurchase}
                />
                <Route
                    exact
                    path={"/user-detail/:id/material/:materialId/subject/:subjId"}
                    component={UserMaterialPurchase}
                />
                <Route exact path={paths.userDetail(":id")} component={UserDetail} />
                <Route exact path={paths.teacherDetail(":id")} component={TeacherDetail} />
                <Route exact path={paths.educationForms} component={EducationForms} />
                <Route exact path={paths.educationFormsDetail(":id")} component={FormDetail} />
                <Route
                    exact
                    path={"/user/:id/edit-form/:formId/materials/:materialId/subject/:subjId"}
                    component={MaterialTree}
                />
                <Route
                    exact
                    path={"/user/:id/edit-form/:formId/materials/:materialId"}
                    component={Subjects}
                />
                <Route exact path={"/user/:id/edit-form/:formId"} component={NewForm} />
                <Route exact path={paths.createNewForm(":id")} component={NewForm} />
                <Route exact path={paths.purchaseHistory(":id")} component={PurchaseHistory} />
                <Route exact path={paths.itemsManagement} component={ItemList} />
                <Route exact path={paths.additionalPurchases} component={AdditionalPurchases} />
                <Route exact path={paths.purchaseSubjects(":materialId")} component={Subjects} />
                <Route
                    exact
                    path={"/additional-purchases/material/:id/subject/:subjId"}
                    component={PurchaseMaterialDetail}
                />
                <Route
                    exact
                    path={paths.additionalPurchasesDetail(":id")}
                    component={PurchaseMaterialDetail}
                />
                <Route
                    exact
                    path={"/additional-purchases/material/:id/subject/:subjId/add-product"}
                    component={AddEditProduct}
                />
                <Route exact path={paths.addNewProduct(":id")} component={AddEditProduct} />
                <Route
                    exact
                    path={
                        "/additional-purchases/material/:id/subject/:subjId/edit-product/:productId"
                    }
                    component={AddEditProduct}
                />
                <Route
                    exact
                    path={"/additional-purchases/materials/:id/edit-product/:productId"}
                    component={AddEditProduct}
                />
            </Switch>
        </div>
    );
}

export default App;

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import history from "utils/history/history";
import * as style from "./style/ItemList.module.scss";
import { ReactComponent as ArrowBack } from "../../assets/img/arrowBack.svg";
import { ReactComponent as SearchIcon } from "../../assets/img/search.svg";
import { ReactComponent as Plus } from "../../assets/img/plus.svg";
import paths from "constants/paths";
import Pagination from "components/Pagination/Pagination";
import { useStore } from "store";
import DeleteItem from "./Modal/DeleteItem";
import AddItem from "./Modal/AddItem";
import itemsManagement from "api/endPoints/itemsManagement";
import Preloader from "components/Preloader/Preloader";
import CardItem from "./CardItem/CardItem";

function ItemList() {
  const [filter, setFilters] = useState({ name: "" });
  const {
    modalWindowStore: {
      isAddEditItemModalOpen,
      isDeleteItemModalOpen,
      setIsAddEditItemModalOpen,
    },
  } = useStore();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  useEffect(() => {
    setFilterLoading(true);
    itemsManagement.getSubjects(page, filter).then((response) => {
      if (response.data.current_page > response.data.last_page) {
        setPage(response.data.last_page);
      }
      setLastPage(response.data.last_page);
      setFilterLoading(false);
      setData(response.data.items);
    });
  }, [page, loading, filter]);

  const prevPage = (number) => {
    setFilterLoading(true);
    setPage(page - number);
  };

  const nextPage = (number) => {
    setFilterLoading(true);
    setPage(page + number);
  };

  const lastPageFunction = (number) => {
    setFilterLoading(true);
    setPage(number);
  };

  return (
    <>
      {data ? (
        <div>
          <Button
            className={style.btn_link}
            color="link"
            onClick={() => history.push(paths.adminPanel)}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
          <p className={style.title}>Управление предметами</p>
          <div className={style.debounce_wrapper}>
            <p className={style.title_debounce}>Поиск:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                className={style.findUsers}
                placeholder="Введите название"
                minLength={1}
                debounceTimeout={800}
                onChange={(event) => {
                  setFilters({ name: event.target.value });
                  setPage(1);
                }}
              />
              <SearchIcon className={style.searchImg} />
            </div>
          </div>
          <div className={style.items_wrapper}>
            {filterLoading ? (
              <div className={style.preloader_wrapper}>
                <Preloader />
              </div>
            ) : (
              <>
                {data.length === 0 && filter.name.length > 0 ? (
                  <p className={style.nothing_find}>Ничего не найдено</p>
                ) : (
                  <>
                    <button
                      className={style.add}
                      onClick={() => setIsAddEditItemModalOpen(true)}
                    >
                      <Plus />
                      Создать предмет
                    </button>
                    {data.map((item) => (
                      <CardItem key={item.id} item={item} />
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          <Pagination
            page={page}
            nextPage={nextPage}
            prevPage={prevPage}
            lastPage={lastPage}
            lastPageFunction={lastPageFunction}
          />
          {isDeleteItemModalOpen && (
            <DeleteItem setLoading={setLoading} loading={loading} />
          )}
          {isAddEditItemModalOpen && (
            <AddItem setLoading={setLoading} loading={loading} />
          )}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default observer(ItemList);

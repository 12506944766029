import { makeAutoObservable } from "mobx";

class CreateLessonStore {
    constructor() {
        this.teacher_id = localStorage.getItem("id");
        this.name = "";
        this.type_id = null;
        this.type_name = null;
        this.lesson_url = "";
        this.lesson_id = null;
        this.is_add = false;
        this.class_id = "";
        this.subject_id = "";
        this.students = null;
        this.start_at = "";
        this.start_time = "";
        this.is_update = false;
        makeAutoObservable(this);
    }

    setIsUpdate = (value) => {
        this.is_update = value;
    };

    setLessonId = (value) => {
        this.lesson_id = value;
    };

    setTeacherId = (value) => {
        this.teacher_id = value;
    };

    setName = (value) => {
        this.name = value;
    };

    setTypeId = (value) => {
        this.type_id = value;
    };

    setTypeName = (value) => {
        this.type_name = value;
    };

    setLessonUrl = (value) => {
        this.lesson_url = value;
    };

    setIsAdd = (value) => {
        this.is_add = value;
        localStorage.setItem("is_add", value);
    };

    setClearAll = () => {
        this.name = "";
        this.type_id = null;
        this.type_name = null;
        this.lesson_url = "";
        this.lesson_id = null;
        this.is_add = false;
        this.class_id = "";
        this.subject_id = "";
        this.students = null;
        this.start_at = "";
        this.start_time = "";
        this.is_update = false;
    };

    setClassId = (value) => {
        this.class_id = value;
    };

    setSubjectId = (value) => {
        this.subject_id = value;
    };
    setStudents = (value) => {
        this.students = value;
    };
    setStartAt = (value) => {
        this.start_at = value;
    };
    setStartTime = (value) => {
        this.start_time = value;
    };
}

export default CreateLessonStore;

import React from "react";
import { Form, FormGroup } from "reactstrap";
import DropdownSelect from "../Dropdown";
import closeIcon from "../assets/closeIcon.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const {
        title,
        checkEvent,
        onClose,
        errors,
        selectDate,
        setSelectDate,
        noSelectDate,
        setNoSelectDate,
        allSelectDate,
        loadingDate,
        onSearchDate,
        inputValueDate,
    } = props;

    const dropdownSelectDateData = {
        selectList: selectDate,
        setSelectList: setSelectDate,
        noSelectList: noSelectDate,
        setNoSelectList: setNoSelectDate,
        allSelectList: allSelectDate,
        loading: loadingDate,
        onSearch: onSearchDate,
        inputValue: inputValueDate,
        isError: errors.type,
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modal__content}>
                <div className={styles.modal__content__top}>
                    <div className={styles.modal__content__top__title}>{title}</div>
                    <button
                        className={styles.modal__content__top__close}
                        date="button"
                        onClick={onClose}>
                        <img src={closeIcon} alt="closeIcon" />
                    </button>
                </div>
                <div className={styles.modal__content__body}>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormGroup>
                            <DropdownSelect {...dropdownSelectDateData} />
                            {errors.type && (
                                <span className={styles.modal__content__body__message}>
                                    {errors.type}
                                </span>
                            )}
                        </FormGroup>

                        <button
                            date="button"
                            className={styles.modal__content__body__create}
                            onClick={checkEvent}>
                            Скачать
                        </button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default View;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import { ReactComponent as Pen } from "../../../assets/img/pen.svg";
import defaultUserImg from "../../../assets/img/defaultPerson.png";
import * as style from "./style/TeacherDetail.module.scss";

import paths from "constants/paths";
import history from "utils/history/history";
import users from "api/endPoints/users";
import Preloader from "components/Preloader/Preloader";

function TeacherDetail() {
  const params = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    users.getTeacher(params.id).then((response) => {
      setData(response);
    });
  }, []);

  return (
    <>
      {data ? (
        <div>
          <Button
            className={style.btn_link}
            color="link"
            onClick={() => history.push(paths.usersList)}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
          <div className={style.header}>
            <p className={style.header_title}>Личные данные педагога</p>
            <div className={style.header_btn_wrapper}>
              <Button
                color="outline"
                onClick={() => history.push(paths.editTeacher(params.id))}
              >
                <Pen className={style.pen} />
                Редактировать личные данные
              </Button>
              <a
                className={style.linkToLk}
                target="_blank"
                rel="noreferrer"
                href={`${process.env.REACT_APP_WEB_MORDA_URL}/office/time-sheet/mentor/${params.id}`}
              >
                Смотреть план обучения
              </a>
            </div>
          </div>
          <img
            src={data?.profile.teacher?.avatar || defaultUserImg}
            alt="Avatar"
            className={style.avatar}
          />
          <Row>
            <Col>
              <p className={style.colomn_title}>
                {data.user.last_name}&nbsp;
                {data.user.first_name}&nbsp;
                {data.user.middle_name}
              </p>
              <Row>
                <p className={style.colomn_content_title}>Почта:</p>
                <p className={style.colomn_content_text}>
                  {data?.user?.email || "-"}
                </p>
              </Row>
              <Row>
                <p className={style.colomn_content_title}>Образование: </p>
                <p className={style.colomn_content_text}>
                  {data?.profile?.teacher?.education_info || "-"}
                </p>
              </Row>
              <Row>
                <p className={style.colomn_content_title}>
                  Проф. информация (должность, стаж, специализация):
                </p>
                <p className={style.colomn_content_text}>
                  {data?.profile?.teacher?.profession_info || "-"}
                </p>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <>
          <Preloader />
        </>
      )}
    </>
  );
}

export default TeacherDetail;

import React, { useEffect, useState } from "react";
import * as style from "./style/Resource.module.scss";
import { Button } from "reactstrap";
import Modal from "../../Modal/Modal";
import history from "utils/history/history";
import paths from "constants/paths";
import Pagination from "components/Pagination/Pagination";
import TableData from "./TableData/TableData";
import Preloader from "components/Preloader/Preloader";
import resApi from "api/endPoints/resources";

export default function Resource(props) {
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({
		name: "",
		items: [],
		per_page: 20,
		last_page: null,
	});
	const [resourceName, setResourceName] = useState("");
	const [materialId, setMaterialId] = useState(0);
	const [page, setPage] = useState(1);
	const [paginationLoading, setPaginationLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const resid = props.match.params.id;

	const settingResp = (response) => {
		setData(response);
		setPaginationLoading(false);
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		resApi
			.resources(resid, page)
			.then((response) => {
				settingResp(response);
			})
			.catch((error) => new Error(error));
	}, [page, resid]);

	const deleteResourceModal = (id, resname) => {
		setModal(true);
		setResourceName(resname);
		setMaterialId(id);
	};

	const deleteResource = () => {
		setModal(false);
		setLoading(true);
		resApi
			.deleteResource(resid, materialId)
			.then(() => {
				resApi
					.resources(resid, page)
					.then((response) => {
						settingResp(response);
					})
					.catch((error) => new Error(error));
			})
			.catch();
	};

	const prevPage = (number) => {
		setPaginationLoading(true);
		setPage(page - number);
	};

	const nextPage = (number) => {
		setPaginationLoading(true);
		setPage(page + number);
	};

	const lastPageFunction = (number) => {
		setPaginationLoading(true);
		setPage(number);
	};

	const pre =
		loading || paginationLoading ? (
			<div className="row">
				<Preloader />
			</div>
		) : (
			<p style={{ color: "#BCBFC2" }}>Здесь пока ничего нет...</p>
		);

	return (
		<>
			<div className={style.resource_program}>
				{/* Header */}
				<a href="/training" className={style.resource_program__back_link}>
					&#8592; Назад
				</a>
				<div className={style.resource_program__header}>
					<h1 className="title-h1">{data.name}</h1>
					<Button
						color="primary"
						className={style.button__create_material}
						onClick={() => history.push(paths.createMaterial(resid))}
					>
						Добавить материалы
					</Button>
				</div>

				{/* Preloader for pagination */}
				{paginationLoading || loading || data.items.length === 0 ? (
					pre
				) : (
					<>
						<div className={style.resource_program__table}>
							{/* Materials */}
							<div className={style.table__header}>
								<p>Материалы</p>
							</div>
							{data.items.map((v, i) => (
								<div key={v.id}>
									<TableData
										data={v}
										indx={i + (page - 1) * data.per_page}
										del={(id, resname) => deleteResourceModal(id, resname)}
									/>
								</div>
							))}
						</div>

						{/* Pagination */}
						<div className={style.table__pagination}>
							<Pagination
								page={page}
								nextPage={nextPage}
								prevPage={prevPage}
								lastPage={data.last_page}
								lastPageFunction={lastPageFunction}
							/>
						</div>
					</>
				)}
			</div>
			{modal && (
				<Modal>
					<p className={style.modalTitle}>Вы уверены, что хотите удалить {resourceName}</p>
					<div>
						Если вы удалите материал, все сведения о нем будут недоступны
					</div>
					<div className={style.resource_program__modal_buttons}>
						<Button
							className={style.modal_button}
							outline
							color="primary"
							onClick={deleteResource}
						>
							Удалить материал
						</Button>
						<Button
							className={style.modal_button}
							color="primary"
							onClick={() => setModal(false)}
						>
							Отменить
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
}

import React from "react";
import downloadTeacherEvents from "../../../api/endPoints/educationEvents";
import FileSaver from "file-saver";
import Controller from "./Controller.jsx";

const Connector = (props) => {
    const { onClose } = props;

    const downloadEvent = (data, setLoading) => {
        downloadTeacherEvents
            .downloadTeacherEvents(data)
            .then((res) => {
                FileSaver.saveAs(res, "Расписание.pdf");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return <Controller downloadEvent={downloadEvent} onClose={onClose} />;
};

export default Connector;

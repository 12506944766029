import history from "./history";

async function defaultRedirection() {
    const lessonRegular = new RegExp(/^\/lessons/);
    const usersRegular = new RegExp(/^\/users/);

    const currentPath = history.location.pathname;

    if (lessonRegular.test(currentPath)) {
        if (!localStorage.getItem("access_token")) {
            localStorage.setItem("path", history.location.pathname);
            history.push("/");
        } else {
            history.push(history.location.pathname);
        }
    } else if (usersRegular.test(currentPath)) {
        if (!localStorage.getItem("access_token")) {
            localStorage.setItem("path", history.location.pathname);
            history.push("/");
        } else {
            history.push(history.location.pathname);
        }
    } else if (!localStorage.getItem("access_token")) {
        history.push("/");
    }
}

export default defaultRedirection;
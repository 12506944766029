const defaultTeacherData = {
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    profession_info: "",
    education_info: "",
    avatar: null,
};

const studentDefault = {
    first_name: "",
    last_name: "",
    email: "",
    birthday: "",
    phone: "",
    education_class: null,
    education_format: null,
    divergency: "",
    avatar: "",
    other_divergency: "",
};

const adultDefault = {
    fio: "",
    birthday: "",
    role: "",
    location: "",
};

export { defaultTeacherData, studentDefault, adultDefault };
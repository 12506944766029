import { object, string } from "yup";

const rulesMessage = {
  min: (number, field) =>
    `В поле "${field}" не должно быть меньше ${number} символов`,
  max: (number, field) =>
    `В поле "${field}" не должно быть больше ${number} символов`,
  required: "Поле обязательно для заполнения",
  email: 'Поле "Почта" введено некорректно',
};

const schemaStudent = object({
  student: object({
    first_name: string()
      .min(2, rulesMessage.min(2, "Имя"))
      .max(150, rulesMessage.max(150, "Имя"))
      .nullable()
      .required(rulesMessage.required),
    last_name: string()
      .min(2, rulesMessage.min(2, "Фамилия"))
      .max(150, rulesMessage.max(150, "Фамилия"))
      .nullable()
      .required(rulesMessage.required),
    birthday: string().nullable().required(rulesMessage.required),
    // phone: string()
    //   .min(11, rulesMessage.min(11, 'Телефон'))
    //   .nullable()
    //   .required(rulesMessage.required),
    email: string().email(rulesMessage.email).required(rulesMessage.required),
    divergency: string().nullable().required(rulesMessage.required),
    other_divergency: string()
      .nullable()
      .when("divergency", {
        is: (val) => val == 7,
        then: (schema) =>
          schema
            .required(rulesMessage.required)
            .min(2, rulesMessage.min(2, "Другая особенность"))
            .max(200, rulesMessage.max(200, "Другая особенность")),
        otherwise: (schema) => schema,
      }),
  }),
  adult: object({
    fio: string()
      .min(2, rulesMessage.min(2, "ФИО"))
      .max(150, rulesMessage.max(150, "ФИО"))
      .nullable(true)
      .required(rulesMessage.required),
    role: string().nullable(true).required(rulesMessage.required),
    location: string()
      .min(2, rulesMessage.min(2, "Место проживания"))
      .max(150, rulesMessage.max(150, "Место проживания"))
      .nullable(true)
      .required(rulesMessage.required),
  }),
});

async function clientValidateForm(form) {
  let errors;

  try {
    await schemaStudent.validate(form, { abortEarly: false });
  } catch (validationError) {
    errors = {};
    validationError.inner.forEach((error) => {
      const { path, message } = error;
      errors[path] = message;
    });
  }
  return errors;
}

async function clientValidateField(field, value) {
  let errors;
  try {
    await schemaStudent.validateAt(field, value, { abortEarly: false });
  } catch (validationError) {
    errors = {};
    validationError.inner.forEach((error) => {
      const { path, message } = error;
      errors[path] = message;
    });
  }
  return errors;
}

export default { clientValidateForm, clientValidateField };

import React, { useState } from "react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import * as style from "./style/DeleteResource.module.scss";
import { toast } from "react-toastify";
import foldersApi from "api/endPoints/folders";

function DeleteResources({ setModalLoading }) {
  const {
    modalWindowStore: { setDeleteResource, deleteResourceOpen },
  } = useStore();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const apiDeleteFolder = () => {
    setLoading(true);
    setModalLoading(true);
    foldersApi
      .deleteResource(deleteResourceOpen.item.id)
      .then(() => {
        setDeleteResource(false);
        setLoading(false);
        setModalLoading(false);
        toast.success("Ресурс успешно удален");
      })
      .catch((error) => {
        setErrors(error);
        setModalLoading(false);
        setLoading(false);
      });
  };

  return (
    <>
      {errors.status === 400 ? (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.error_title}>
                  Невозможно удалить ресурс <br />
                  {deleteResourceOpen.item.name}
                </p>
                <p className={style.error_text}>Ресурс содержится в материалах, купленных пользователями.</p>
              </div>
              <div className={style.displayButton}>
                <Button
                  color="primary"
                  className={style.deleteError}
                  onClick={() => setDeleteResource(false)}
                >
                  Ок
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.popUpTitle}>
                  Вы уверены что хотите <br />
                  удалить ресурс {deleteResourceOpen.item.name} ?
                </p>
              </div>
              <div className={style.displayButton}>
                <Button
                  color="primary"
                  outline
                  className={style.deleteLesson}
                  onClick={() => setDeleteResource(false)}
                >
                  Отмена
                </Button>

                {loading ? (
                  <Button
                    disabled
                    color="primary"
                    outline
                    className={style.deleteLesson}
                  >
                    <div
                      className={`spinner-border text-primary ${style.loading}`}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className={style.canselChange}
                    onClick={() => apiDeleteFolder(deleteResourceOpen.item.id)}
                  >
                    Удалить
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(DeleteResources);

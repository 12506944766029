import React from "react";
import { Button } from "reactstrap";
import { useStore } from "store";
import Preloader from "../../Preloader/Preloader";
import * as style from "./style/UnblockUser.module.scss";

function UnblockUser({ loading, apiUnBlockTeacher }) {
  const {
    modalWindowStore: { setIsUnBlockModalOpen, isUnBlockModalOpen },
  } = useStore();
  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>
              Вы уверены, что хотите <br /> разблокировать профиль
              <br /> Пользователя?
            </p>
          </div>

          <div>
            {loading ? (
              <Button
                disabled
                color="primary"
                outline
                className={style.deleteLesson}
              >
                <div
                  className={`spinner-border text-primary ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                outline
                className={style.deleteLesson}
                onClick={() => apiUnBlockTeacher(isUnBlockModalOpen.id)}
              >
                Разблокировать
              </Button>
            )}
            <Button
              color="primary"
              className={style.canselChange}
              onClick={() => setIsUnBlockModalOpen(false)}
            >
              Отменить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnblockUser;

/* eslint-disable default-case */
import axios from "axios";
import { toast } from "react-toastify";
import history from "../utils/history/history";
import authApi from "./endPoints/login";
import paths from "../constants/paths";
import apiList from "constants/apiList";

let refreshing = false;

const repeatInterval = () =>
    new Promise((resolve) => {
        const interval = setInterval(() => {
            if (!refreshing) {
                resolve();
                clearInterval(interval);
            }
        }, 100);
    });

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "access_token",
)}`;

axiosInstance.interceptors.response.use(resolvedInterceptor, rejectedInterceptor);

const repeatRequest = (originalRequest) => {
    originalRequest.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
    return new Promise((resolve, reject) => {
        axiosInstance(originalRequest)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const refresh = (originalRequest) => {
    return new Promise((resolve, reject) => {
        authApi
            .refresh()
            .then((res) => {
                refreshing = false;
                const { token } = res.data;
                originalRequest.headers.Authorization = `Bearer ${token.access_token}`;
                repeatRequest(originalRequest)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            })
            .catch(() => {
                refreshing = false;
            });
    });
};

function resolvedInterceptor(response) {
    return Promise.resolve(response);
}

async function rejectedInterceptor(error) {
    if (error.request.status === 0) {
        toast.error("Отсутствует подключение к интернету");
    }

    const { status } = error.response;
    switch (status) {
        case 401: {
            const originalRequest = error.config;
            const isRefreshTokenRequest = error.response.config.url === apiList.refresh;
            const isLoginRequest = error.response.config.url === apiList.signIn;

            if (!isRefreshTokenRequest) {
                if (!isLoginRequest) {
                    if (!refreshing) {
                        refreshing = true;
                        return refresh(originalRequest);
                    } else {
                        await repeatInterval();
                        return await repeatRequest(originalRequest);
                    }
                }
            } else {
                localStorage.removeItem("access_token");
                localStorage.removeItem("role");
                localStorage.removeItem("id");
                removeBearerToken();
                history.push("/");
            }
            break;
        }
        case 403:
            toast.error(`${error.response.data.error.message}`);

            if (error.response.data.error.code === "errors.blocked_user_access") {
                authApi.logOut().then(() => history.push(paths.login));
            }
            break;
        case 500:
            toast.error("Внутренняя ошибка сервера");
            break;
    }
    return Promise.reject(error);
}

function setBearerToken(token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

function removeBearerToken() {
    delete axiosInstance.defaults.headers.common.Authorization;
}

export default axiosInstance;
export { setBearerToken, removeBearerToken };

import React from "react";
import { Button } from "reactstrap";
import paths from "../../constants/paths";
import history from "../../utils/history/history";
import * as style from "./style/MakeLinks.module.scss";

export default function MakeLinks({ modal, toggle, links }) {
	return (
		<div className={style.modal}>
			<div className={style.modalOverlay}>
				<div className={style.modalWindow}>
					<div className={style.modalHeader}>
						<p className={style.popUpTitle}>Готово</p>
					</div>
					<p className={style.linksTitle}>Ссылка для учителя</p>
					<p className={style.links}>
						<a
							href={`${process.env.REACT_APP_WEB_MORDA_URL}/lesson?educationStakeholder=teacher&lessonHash=${links}`}
							target="_blank"
							rel="noreferrer"
						>
							{`${process.env.REACT_APP_WEB_MORDA_URL}/lesson?educationStakeholder=teacher&lessonHash=`}
							<wbr />
							{links}
						</a>
					</p>
					<p className={style.linksTitle}>Ссылка для ученика</p>
					<p className={style.links}>
						<a
							href={`${process.env.REACT_APP_WEB_MORDA_URL}/lesson?educationStakeholder=student&lessonHash=${links}`}
							target="_blank"
							rel="noreferrer"
						>
							{`${process.env.REACT_APP_WEB_MORDA_URL}/lesson?educationStakeholder=student&lessonHash=`}
							<wbr />
							{links}
						</a>
					</p>

					<Button
						className={style.modalBtn}
						color="primary"
						onClick={() => history.push(paths.lessonList)}
					>
						Перейти на список уроков
					</Button>
				</div>
			</div>
		</div>
	);
}

import axiosInstance from "..";
import apiList from "../../constants/apiList";

//eslint-disable-next-line import/no-anonymous-default-export
export default {
    async getFolders(page, folder) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getFolders,
                params: {
                    page: page,
                    per_page: "50",
                    new_folder_id: folder,
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async createFolder(data, id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.createFolder,
                data: data,
                params: {
                    parent: id,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editFolder(data, id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editFolder(id),
                data: data,
                params: {
                    _method: "PUT",
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async deleteFolder(id) {
        try {
            const response = await axiosInstance({
                method: "DELETE",
                url: apiList.deleteFolder(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getFolderId(id, page, folder) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getFolderId(id),
                params: {
                    page: page,
                    per_page: "50",
                    new_folder_id: folder,
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async getResources(filter, page, id) {
        const findName = { ...filter };

        if (findName.name === "") {
            delete findName.name;
        }

        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.getResources,
                params: {
                    name: findName.name,
                    page: page,
                    folder_id: id,
                    per_page: "50",
                },
            });
            return response.data.data;
        } catch (error) {
            throw error.response;
        }
    },

    async addFileResource(addFile, id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.addResource,
                data: addFile,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async addLinkResource(srcFile, id) {
        const data = { ...srcFile, folder_id: id };
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.addResource,
                data,
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editFileResource(data, id) {
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editResource(id),
                data,
                params: {
                    _method: "PUT",
                    resource: id,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editLinkResource(item, id) {
        let data = { ...item, type: "LINK" };
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editResource(id),
                data,
                params: {
                    _method: "PUT",
                    folder_id: id,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async editKinescopeResource(item, id) {
        let data = { ...item, type: "KINESCOPE" };
        try {
            const response = await axiosInstance({
                method: "POST",
                url: apiList.editResource(id),
                data,
                params: {
                    _method: "PUT",
                    folder_id: id,
                },
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async deleteResource(id) {
        try {
            const response = await axiosInstance({
                method: "DELETE",
                url: apiList.deleteResource(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },

    async downloadResource(id) {
        try {
            const response = await axiosInstance({
                method: "GET",
                url: apiList.downloadResource(id),
            });
            return response.data;
        } catch (error) {
            throw error.response;
        }
    },
};

import * as Sentry from "@sentry/react";

function initSentry() {
    const environment = process.env.REACT_APP_APPLICATION_ENVIRONMENT;
    const dsn = process.env.REACT_APP_SENTRY_DSN;

    if (dsn && environment) {
        Sentry.init({ environment, dsn });
    }
}

export { initSentry };
import React, { useState } from "react";
import { useStore } from "store";
import { observer } from "mobx-react-lite";

import {
  Button,
  FormText,
  Input,
  FormFeedback,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import folders from "api/endPoints/folders";
import { toast } from "react-toastify";

import * as style from "./style/EditFolder.module.scss";
import validationFolders from "components/Resources/utils/validationFolders";

function EditFolder({ setModalLoading }) {
  const {
    modalWindowStore: { setEditFolder, editFolderOpen },
  } = useStore();

  const [folder, setFolder] = useState({ name: editFolderOpen?.item?.name });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onBlur = async (e) => {
    const validationErrors = await validationFolders.clientValidateField(
      e.target.name,
      {
        ...folder,
      }
    );

    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const clearErrField = (field) => {
    const checkError = { ...errors };

    if (checkError[field]) {
      delete checkError[field];
    }
    setErrors({ ...checkError });
  };

  const changeInput = (event) => {
    const { name, value } = event.target;
    clearErrField(name);
    setFolder({ [name]: value });
  };

  const apiEditFolder = (folder) => {
    setModalLoading(true);
    setLoading(true);
    folders
      .editFolder(folder, editFolderOpen.item.id)
      .then(() => {
        setModalLoading(false);
        setEditFolder(false);
        setLoading(false);
        toast.success("Папка успешно отредактирована");
      })
      .catch(() => {
        setLoading(false);
        setModalLoading(false);
      });
  };

  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>
              Редактировать папку {editFolderOpen.item.name}
            </p>
          </div>
          <div>
            <Form>
              <FormGroup>
                <Label>Навзание:*</Label>
                <Input
                  type="text"
                  id="folderName"
                  name="name"
                  value={folder?.name}
                  onChange={changeInput}
                  invalid={!!errors.name}
                  onBlur={(e) => onBlur(e, folder.name)}
                  placeholder="Название папки"
                ></Input>
                <FormFeedback>{errors.name}</FormFeedback>
                <FormText color="muted" className={style.displayHint}>
                  Введите от 2 до 150 символов
                </FormText>
              </FormGroup>
            </Form>
          </div>
          <div className={style.displayButton}>
            <Button
              color="primary"
              outline
              className={style.deleteLesson}
              onClick={() => setEditFolder(false)}
            >
              Отмена
            </Button>

            {loading ? (
              <Button
                disabled
                color="primary"
                outline
                className={style.deleteLesson}
              >
                <div
                  className={`spinner-border text-primary ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                className={style.canselChange}
                onClick={() => apiEditFolder(folder, editFolderOpen.id)}
              >
                Сохранить
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(EditFolder);

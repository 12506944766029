/* eslint-disable import/no-anonymous-default-export */
import axiosInstance from "..";
import apiList from "../../constants/apiList";

export default {
  async getSubjects(page, filter, formId, materialId) {
    const formatterFilter = { ...filter };

    if (formatterFilter.name === "") {
      delete formatterFilter.name;
    }
    const params = {
      name: formatterFilter.name,
      page: page,
      per_page: "25",
      format_id: formId,
      material_id: materialId,
    };

    if (!formId) {
      delete params.format_id;
    }

    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getSubjects,
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async addSubject(data) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.getSubjects,
        data: data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async editSubject(id, data) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.editSubject(id),
        data: data,
        params: {
          _method: "PUT",
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async deleteSubject(id) {
    try {
      const response = await axiosInstance({
        method: "DELETE",
        url: apiList.editSubject(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getSubjectTree(formatId, subjectId, materialId) {
    const params = {
      subject_id: subjectId,
      format_id: formatId,
      material_id: materialId,
    };
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getSubjectTree(formatId),
        params,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async editItemInTree(id, data) {
    try {
      const response = await axiosInstance({
        method: "PUT",
        url: apiList.getSubjectTree(id),
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import debounce from "debounce";
import { useStore } from "store";
import { weekDates, formatInputValue } from "../helpers";
import View from "./View";

const Controller = (props) => {
    const { getStudentsFirstPage, getStudents, getStatus } = props;

    const {
        downLoadTimeSheetStore: { setStudents, setStatuses },
    } = useStore();

    const [selectDate, setSelectDate] = useState(weekDates(new Date()));

    const [studentsSelect, setStudentsSelect] = useState([]);
    const [noStudentsSelect, setNoStudentsSelect] = useState([]);
    const [allStudentsSelect, setAllStudentsSelect] = useState([]);
    const [studentsLoading, setStudentsLoading] = useState(false);
    const [studentsTotal, setStudentsTotal] = useState(0);
    const [studentsPage, setStudentsPage] = useState(1);
    const [studentsSearch, setStudentsSearch] = useState("");

    const [statusSelect, setStatusSelect] = useState([]);
    const [noStatusSelect, setNoStatusSelect] = useState([]);
    const [allStatusSelect, setAllStatusSelect] = useState([]);

    const onStudentsScroll = () => {
        if (allStudentsSelect.length < studentsTotal) {
            setStudentsPage(studentsPage + 1);
        }
    };

    const onStudentsSearch = useMemo(
        () =>
            debounce((value) => {
                setStudentsPage(1);
                setStudentsSearch(value);
            }, 300),
        [],
    );

    useEffect(() => {
        setStudentsLoading(true);
        if (studentsPage === 1) {
            getStudentsFirstPage(
                studentsSearch,
                studentsPage,
                setAllStudentsSelect,
                setStudentsTotal,
                setStudentsLoading,
            );
        } else {
            getStudents(
                studentsSearch,
                studentsPage,
                allStudentsSelect,
                setAllStudentsSelect,
                setStudentsTotal,
                setStudentsLoading,
            );
        }
    }, [studentsPage, studentsSearch]);

    useEffect(() => {
        getStatus(setAllStatusSelect);
    }, []);

    useEffect(() => {
        setStudents(studentsSelect);
    }, [studentsSelect]);

    useEffect(() => {
        setStatuses(statusSelect);
    }, [statusSelect]);

    const studentsInput =
        studentsSelect.length > 0 ? formatInputValue(studentsSelect) : "Все ученики";

    const statusInput = statusSelect.length > 0 ? formatInputValue(statusSelect) : "Все статусы";

    const studentsData = {
        studentsAllTitle: "Все ученики",
        studentsSelect: studentsSelect,
        setStudentsSelect: setStudentsSelect,
        noStudentsSelect: noStudentsSelect,
        setNoStudentsSelect: setNoStudentsSelect,
        allStudentsSelect: allStudentsSelect,
        studentsLoading: studentsLoading,
        onStudentsScroll: () => onStudentsScroll(),
        onStudentsSearch: (value) => onStudentsSearch(value),
        studentsInput: studentsInput,
    };

    const statusData = {
        statusAllTitle: "Все статусы",
        statusSelect: statusSelect,
        setStatusSelect: setStatusSelect,
        noStatusSelect: noStatusSelect,
        setNoStatusSelect: setNoStatusSelect,
        allStatusSelect: allStatusSelect,
        statusInput: statusInput,
    };

    return (
        <View
            selectDate={selectDate}
            setSelectDate={setSelectDate}
            {...studentsData}
            {...statusData}
        />
    );
};

export default Controller;

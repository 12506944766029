import React, { useEffect, useState } from "react";
import { Badge, Button } from "reactstrap";
import * as style from "./style/EducationForms.module.scss";
import { ReactComponent as Arrow } from "./assets/arrow.svg";
import paths from "constants/paths";
import history from "utils/history/history";
import { ReactComponent as ArrowBack } from "../../assets/img/arrowBack.svg";
import educationForms from "api/endPoints/educationForms";
import Preloader from "components/Preloader/Preloader";

function EducationForms() {
  const [data, setData] = useState();
  useEffect(() => {
    educationForms
      .getEducationForms()
      .then((response) => setData(response.data.items));
  }, []);

  return (
    <>
      {data ? (
        <div>
          <Button
            className={style.btn_link}
            color="link"
            onClick={() => history.push(paths.adminPanel)}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
          <p className={style.title}>Шаблоны форм обучения</p>
          <div className={style.formsBlock}>
            {data.map((item) => (
              <Badge
                href={paths.educationFormsDetail(item.id)}
                className={style.formsBlock_oneBlock}
                key={item.id}
                title={item.name}
              >
                <div className={style.formsBlock_oneBlock_wrapper}>
                  <div>
                    <div className={style.formsBlock_oneBlock_title_wrapper}>
                      <p className={style.formsBlock_oneBlock_title}>
                        {item.name}
                      </p>
                      <Arrow />
                    </div>
                    <p
                      className={
                        item.is_active
                          ? [style.formsBlock_oneBlock_active]
                          : [style.formsBlock_oneBlock_unactive]
                      }
                    >
                      {item.is_active ? "Опубликован" : "Снято с публикации"}
                    </p>
                  </div>
                  <p className={style.formsBlock_oneBlock_period}>
                    {item.duration?.name ? item.duration.name : null}
                  </p>
                  <p className={style.formsBlock_oneBlock_price}>
                    {Number.parseInt(item.price)}
                    &#8381;
                  </p>
                </div>
              </Badge>
            ))}
          </div>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default EducationForms;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CustomInput } from "reactstrap";
import { useParams } from "react-router";
import { ReactComponent as FolderIcon } from "../assets/FolderIcon.svg";
import { ReactComponent as ArrowUpIcon } from "../assets/ArrowUpIcon.svg";
import * as style from "../style/folder-tree.module.scss";
import "../style/switcher.scss";
import "../style/radio.scss";
import LevelMargin from "../LevelMargin";
import itemsManagement from "api/endPoints/itemsManagement";

function Folder(props) {
  const {
    id,
    level,
    isFirstChild,
    isLastChild,
    isPrevFolderOpen,
    hasChild,
    prevFolderHasChild,
    lastElementsList,
    name,
    foreign_type,
    checked,
    isOpen,
    setOpen,
    setChecked,
    children,
    fullData,
    radio,
    toggleChange,
    purchases,
    path,
    customId,
  } = props;
  const params = useParams();
  const [flag, setFlag] = useState(false);
  let array = [];
  let data = {};
  useEffect(() => {
    recursiveManage(fullData);
    data = {
      format_id: params.formId,
      material_id: params.materialId,
      subject_id: params.subjId,
      attachments: [...array],
    };
    if (flag) {
      itemsManagement.editItemInTree(params.formId, data).then(() => {
        setFlag(false);
        array = [];
        data = {};
      });
    }
  }, [fullData]);

  const handleNodeClick = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.target.type === "radio") return;
      else if (children.length > 0) {
        setOpen("custom_id", customId, +!isOpen);
      }
    },
    [isOpen, setOpen]
  );

  const recursiveManage = (data) => {
    for (let i = 0; i <= data.length; i++) {
      if (data[i]?.checked === 0.5) {
        recursiveManage(data[i].children);
      } else if (data[i]?.checked === 1) {
        let newItem;
        if (data[i].foreign_type === "FOLDER") {
          newItem = {
            attachment_id: data[i].id,
            attachment_type: "FOLDER",
          };
          array.push(newItem);
        } else {
          newItem = {
            attachment_id: data[i].id,
            attachment_type: "RESOURCE",
          };
          array.push(newItem);
        }
      }
    }
  };

  const handleSwitcherClick = useCallback(
    (event) => {
      event.stopPropagation();
      setChecked("custom_id", customId, +!checked);
      setFlag(true);
    },
    [checked, setChecked]
  );

  const handleRadioClick = useCallback(
    (event) => {
      event.stopPropagation();
      toggleChange(event, "FOLDER");
    },
    [toggleChange]
  );

  const tableRowStyle = useMemo(() => {
    const {
      tableRow,
      tableRowFolder,
      tableRowFolderOpened,
      tableRowFolderPointer,
    } = style;
    return `${tableRow} ${tableRowFolder} ${
      isOpen ? tableRowFolderOpened : ""
    } ${children.length > 0 && tableRowFolderPointer}`;
  }, [isOpen]);

  const iconContainerStyle = useMemo(() => {
    return `${style.interactiveIconContainer} ${
      !isOpen && checked === 0.5
        ? style.interactiveIconContainerHasSelected
        : ""
    }`;
  }, [isOpen, checked]);

  const interactiveIconStyle = useMemo(() => {
    return `${style.interactiveIcon} ${
      !isOpen ? style.interactiveIconClosed : ""
    }`;
  }, [isOpen]);

  const borderStyle = useMemo(() => {
    return `${
      !isPrevFolderOpen || !prevFolderHasChild ? style.borderTop : ""
    } ${
      (isLastChild && !isOpen) || (isLastChild && !hasChild)
        ? style.borderBottom
        : ""
    }`;
  }, [isPrevFolderOpen, isLastChild, prevFolderHasChild, isOpen]);

  const nameContainerStyle = useMemo(() => {
    return `${style.nameContainer} ${borderStyle}`;
  }, [borderStyle]);

  return (
    <>
      <tr className={tableRowStyle} onClick={handleNodeClick}>
        <td className={style.tableColumn}>
          <div className={style.marginLinesContainer}>
            <LevelMargin
              level={level}
              isFirstChild={isFirstChild}
              isLastChild={isLastChild}
              lastElementsList={lastElementsList}
              isOpen={isOpen}
              hasChild={hasChild}
            />
            <div className={nameContainerStyle}>
              <div className={style.nameIconContainer}>
                {!radio ? (
                  <FolderIcon />
                ) : (
                  <div className="radio-button__wrapper">
                    <label className="radio-button">
                      <input
                        key={customId}
                        id={id}
                        type="radio"
                        name="tree"
                        value={customId}
                        onChange={handleRadioClick}
                        checked={checked === 1}
                      />
                    </label>
                  </div>
                )}
              </div>
              {name}
            </div>
          </div>
        </td>
        <td className={style.tableColumn}>
          <div
            className={`${style.interactiveContainer} ${borderStyle} ${
              radio && style.interactiveContainerRadio
            } ${purchases && style.interactiveContainerPurchases}`}
          >
            {!radio && !purchases && (
              <CustomInput
                id={id}
                type="switch"
                label={!!Math.floor(checked) ? "Активен" : "Не активен"}
                checked={!!Math.floor(checked)}
                onChange={handleSwitcherClick}
              />
            )}
            {children.length > 0 && (
              <div className={iconContainerStyle}>
                <ArrowUpIcon className={interactiveIconStyle} />
              </div>
            )}
          </div>
        </td>
      </tr>
      {isOpen ? children : null}
    </>
  );
}

export default Folder;

import React, { useState } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback, InputGroup } from 'reactstrap';
import useChangePassword from './hooks/useChangePassword';
import { ReactComponent as EyeOn } from '../../assets/img/eyeOn.svg';
import { ReactComponent as EyeOff } from '../../assets/img/eyeOff.svg';

import * as style from './ChangePassword.module.scss';

function ChangePassword({ data, errors, onChange, onSubmit, loading }) {
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [newConfirmPassword, setNewConfirmPassword] = useState(false);

  return (
    <div className={style.wrapper}>
      <a href="/" className={style.back_link}>
        &#8592; Назад
      </a>
      <h1 className={`title-h1 ${style.head}`}>Сменить пароль</h1>
      <div className="row">
        <div className="col-6">
          <Form>
            <FormGroup>
              <Label for="old_password" className={style.input_label}>
                Введите текущий пароль: *
              </Label>
              <InputGroup className={style.inputWrapper}>
                <Input
                  id="old_password"
                  name="old_password"
                  placeholder="Текущий пароль"
                  type={oldPassword ? 'text' : 'password'}
                  className={style.passwordInput}
                  value={data.old_password}
                  onChange={onChange}
                  invalid={!!errors?.old_password}
                  autoComplete="off"
                />
                <Button
                  color="inline"
                  className={style.changeEye}
                  onClick={(e) => {
                    e.preventDefault();
                    setOldPassword(!oldPassword);
                  }}>
                  {oldPassword ? <EyeOn /> : <EyeOff />}
                </Button>
                <FormFeedback>{errors?.old_password}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <hr className={style.divider} />
            <FormGroup>
              <Label for="new_password" className={style.input_label}>
                Введите новый пароль: *
              </Label>
              <InputGroup className={style.inputWrapper}>
                <Input
                  id="new_password"
                  name="new_password"
                  placeholder="Новый пароль"
                  type={newPassword ? 'text' : 'password'}
                  className={style.passwordInput}
                  value={data.new_password}
                  onChange={onChange}
                  invalid={!!errors?.new_password}
                  autoComplete="off"
                />
                <Button
                  color="inline"
                  className={style.changeEye}
                  invalid={!!errors?.new_password}
                  onClick={(e) => {
                    e.preventDefault();
                    setNewPassword(!newPassword);
                  }}>
                  {newPassword ? <EyeOn /> : <EyeOff />}
                </Button>
                <FormFeedback>{errors?.new_password}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label for="new_password_confirmation" className={style.input_label}>
                Повторите новый пароль: *
              </Label>
              <InputGroup className={style.inputWrapper}>
                <Input
                  id="new_password_confirmation"
                  name="new_password_confirmation"
                  placeholder="Повторите пароль"
                  type={newConfirmPassword ? 'text' : 'password'}
                  className={style.passwordInput}
                  value={data.new_password_confirmation}
                  onChange={onChange}
                  invalid={!!errors?.new_password_confirmation}
                  autoComplete="off"
                />
                <Button
                  color="inline"
                  className={style.changeEye}
                  onClick={(e) => {
                    e.preventDefault();
                    setNewConfirmPassword(!newConfirmPassword);
                  }}>
                  {newConfirmPassword ? <EyeOn /> : <EyeOff />}
                </Button>
                <FormFeedback>{errors?.new_password_confirmation}</FormFeedback>
              </InputGroup>
            </FormGroup>
            <Button
              className={style.button_submit}
              color="primary"
              onClick={onSubmit}
              disabled={loading}>
              {loading ? (
                <div className={`spinner-border text-light ${style.loading}`} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                'Сменить пароль'
              )}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default function ChangePasswordPage() {
  return <ChangePassword {...useChangePassword()} />;
}

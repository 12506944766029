import React, { forwardRef } from "react";
import { ReactComponent as ArrowDownIcon } from "../assets/arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../assets/arrow-up.svg";
import { ReactComponent as CheckedIcon } from "../assets/checked.svg";
import Preloader from "components/Preloader/Preloader";
import styles from "./styles.module.scss";

const View = forwardRef((props, ref) => {
    const {
        selectAllTitle,
        isOnList,
        setShowList,
        setHideList,
        inputValue,
        setInputValue,
        selectList,
        setSelect,
        noSelectList,
        setNoSelect,
        selectAll,
        isAll,
        loading,
        noFound,
        isNotWork,
        isError,
    } = props;
    const { selectRef, lastItemRef } = ref;

    const icon = () => {
        if (isOnList)
            return (
                <ArrowUpIcon
                    className={styles.wrapper__container__icon}
                    onClick={() => setHideList()}
                />
            );
        return (
            <ArrowDownIcon
                className={styles.wrapper__container__icon}
                onClick={() => setShowList()}
            />
        );
    };

    const styleIsAll = isAll
        ? styles.wrapper__dropdown__itemSelect
        : styles.wrapper__dropdown__item;

    const selectListBlock = selectList.map((item) => (
        <div
            key={item.selectedCode}
            className={styles.wrapper__dropdown__itemSelect}
            onClick={() => {
                setNoSelect(item);
                if (!selectAllTitle) setHideList();
            }}>
            <div className={styles.wrapper__dropdown__item__checkbox}>
                <CheckedIcon className={styles.wrapper__dropdown__item__checkbox__checked} />
            </div>
            <div className={styles.wrapper__dropdown__item__label}>{item.name}</div>
        </div>
    ));

    const noSelectListBlock = noSelectList.map((item, id) => (
        <div
            key={item.selectedCode}
            className={styles.wrapper__dropdown__item}
            onClick={() => {
                setSelect(item);
                if (!selectAllTitle) setHideList();
            }}
            ref={id === noSelectList.length - 1 ? lastItemRef : null}>
            <div className={styles.wrapper__dropdown__item__checkbox} />
            <div className={styles.wrapper__dropdown__item__label}>{item.name}</div>
        </div>
    ));

    const isSelectList = selectList.length > 0 && (
        <div className={styles.wrapper__dropdown__divider} />
    );

    const topTitle = selectAllTitle && (
        <>
            <div className={styleIsAll} onClick={() => selectAll()}>
                <div className={styles.wrapper__dropdown__item__checkbox}>
                    {isAll && (
                        <CheckedIcon
                            className={styles.wrapper__dropdown__item__checkbox__checked}
                        />
                    )}
                </div>
                <div className={styles.wrapper__dropdown__item__label}>{selectAllTitle}</div>
            </div>
            <div className={styles.wrapper__dropdown__divider} />
        </>
    );

    const dropdown = isOnList && (
        <div className={styles.wrapper__dropdown}>
            {noFound ? (
                <div className={styles.wrapper__dropdown__item__label}>Ничего не найдено</div>
            ) : (
                <>
                    {topTitle}
                    {selectListBlock}
                    {isSelectList}
                    {noSelectListBlock}
                    {loading && <Preloader type="small" />}
                </>
            )}
        </div>
    );

    const errorStyle = isError ? styles.wrapper__error : styles.wrapper__container;

    return (
        <div className={styles.wrapper} id="select" ref={selectRef}>
            <div className={errorStyle}>
                {isNotWork ? (
                    <div className={styles.wrapper__container__input}>{inputValue}</div>
                ) : (
                    <>
                        <input
                            className={styles.wrapper__container__input}
                            type="text"
                            tabIndex="0"
                            onInput={(event) => setInputValue(event.target.value)}
                            onClick={() => setShowList()}
                            value={inputValue}
                        />
                        {icon()}
                    </>
                )}
            </div>
            {dropdown}
        </div>
    );
});

export default View;

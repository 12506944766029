import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Resumable from "resumablejs";

import foldersApi from "../../../../../api/endPoints/folders";
import { ReactComponent as FileBlue } from "../../../../../assets/img/fileBlue.svg";

import deleteIcon from "../../../../../assets/img/deleteIcon.svg";

import {
    Button,
    FormText,
    Input,
    Label,
    FormFeedback,
    Form,
    FormGroup,
    Progress,
} from "reactstrap";
import * as style from "./style/AddFileResources.module.scss";
import validationResources from "components/Resources/utils/validationResources";
import { v4 as uuidv4 } from "uuid";
import Resources from "api/endPoints/resources";
import login from "api/endPoints/login";

function AddFileResources({ setModalLoading }) {
    const { id } = useParams();
    const [progress, setProgress] = useState(0);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [addFile, setAddFile] = useState({
        name: "",
        type: "FILE",
        resource_file_id: "",
        folder_id: id,
    });
    const [fileComplete, setFileComplete] = useState(false);
    const [srcFileName, setSrcFileName] = useState("");
    const [srcFileId, setSrcFileId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const token = localStorage.getItem("access_token");
    const target = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/resources/files`;
    const [resourceId, setResourceId] = useState(null);
    const [deletedImg, setDeletedImg] = useState(false);
    const [saveFile, setSaveFile] = useState(null);
    const [submitBtnDisabled, setSubmitButtonDisabled] = useState(true);

    let fileId = uuidv4();
    const SIZE_LIMIT = 2147483648;
    let timerId;

    const getResurceInfo = (fileName, id) => {
        Resources.getResourcesFile(id)
            .then((res) => {
                if (res.resource_file.status === "SUCCESS") {
                    setIsFileUploading(false);
                    setSrcFileName(fileName);
                    clearInterval(timerId);
                    setSubmitButtonDisabled(false);
                    setSrcFileId(res.resource_file.id);
                }
            })
            .catch((error) => toast.info(error));
    };

    let chanck = 5;
    const config = {
        target: target,
        testChunks: false,
        headers: {
            Authorization: "Bearer " + token,
        },
        simultaneousUploads: 1,
        chunkSize: chanck * 1024 * 1024,
        // xhrTimeout: 1000,
        throttleProgressCallbacks: 1,
        chunkRetryInterval: 5000,
        generateUniqueIdentifier: function (file, event) {
            return fileId;
        },
        permanentErrors: [400, 404, 409, 415, 500, 501, 422],
    };

    let r = new Resumable(config);
    useEffect(() => {
        r.assignBrowse(document.getElementById("uploadBtn", false));
    }, [deletedImg]);

    r.on("fileSuccess", function (file, message) {
        console.log("fileSuccess", file, JSON.parse(message).resource_file.resource_file.id);
        setResourceId(JSON.parse(message).resource_file.resource_file.id);
        setFileComplete(true);
        timerId = setInterval(
            () => getResurceInfo(file.fileName, JSON.parse(message).resource_file.resource_file.id),
            1000,
        );
    });
    r.on("fileProgress", function (file, message) {
        setSaveFile(file);
        console.log("fileProgress", file);
        // setSrcFileName(file.fileName);
    });
    r.on("filesAdded", function (array) {
        if (array[0].file.size <= SIZE_LIMIT) {
            r.upload();
        } else {
            toast.info("Размер файла не должен превышать 2 Гб");
        }
        console.log("filesAdded", array);
    });
    r.on("fileRetry", function (file) {
        console.log("fileRetry", file);
        login.refresh().then((response) => {
            r.opts.headers = {
                Authorization: `Bearer ${response.data.token.access_token}`,
            };
        });
    });
    r.on("fileError", function (file, message) {
        console.log("fileError", file, message);
        setIsFileUploading(false);
        setSubmitButtonDisabled(true);
        setSrcFileName(null);
        setDeletedImg(!deletedImg);
        toast.info(JSON.parse(message).error.message);
    });
    r.on("uploadStart", function () {
        console.log("uploadStart");
        setIsFileUploading(true);
    });
    r.on("complete", function () {
        console.log("complete");
    });
    r.on("progress", function () {
        console.log("progress");
        setProgress(r.progress() * 100);
    });
    r.on("error", function (message, file) {
        console.log("error", message, file);
    });
    r.on("pause", function () {
        console.log("pause");
    });
    r.on("cancel", function () {
        if (chanck > 1) {
            chanck = chanck - 1;
        } else {
            toast.info("Слишком медленный интернет");
        }
        console.log("cancel");
    });

    const {
        modalWindowStore: { setAddResource },
    } = useStore();

    const onBlur = async (e) => {
        const validationErrors = await validationResources.clientValidateField(e.target.name, {
            ...addFile,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        clearErrField(name);
        setAddFile({ ...addFile, [name]: value });
    };

    const deleteFile = () => {
        Resources.deleteResourceFile(resourceId)
            .then(() => {
                toast.info("Файл успешно удален");
                setIsFileUploading(false);
                setSubmitButtonDisabled(true);
                setSrcFileName(null);
                setDeletedImg(!deletedImg);
            })
            .catch((error) => toast.info(error));
    };

    const apiAddResource = () => {
        setModalLoading(true);
        setLoading(true);
        const data = { ...addFile, resource_file_id: srcFileId };
        foldersApi
            .addFileResource(data, id)
            .then(() => {
                setAddResource(false);
                setModalLoading(false);
                toast.success("Ресурс успешно добавлен");
            })
            .catch((error) => {
                setErrors({ ...errors, ...error.data.error.advanced });
                setModalLoading(false);
                setLoading(false);
            });
    };

    const closeModal = () => {
        if (saveFile) {
            saveFile.cancel();
        }
        setAddResource(false);
    };

    return (
        <Form>
            <FormGroup>
                <div className={style.titleName}>
                    <Label>Название:*</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        value={addFile.name}
                        onChange={handleInputChange}
                        invalid={!!errors["name"] || !!errors?.name}
                        onBlur={(e) => onBlur(e, addFile.name)}
                        placeholder="Название материала"></Input>
                    <FormFeedback>{errors["name"] || errors?.name}</FormFeedback>
                    <FormText color="muted" className={style.displayHint}>
                        Введите от 2 до 150 символов
                    </FormText>
                </div>
            </FormGroup>

            <div>
                <Label color="muted" className={style.displayHint}>
                    Загрузите файл:*
                </Label>
                {srcFileName ? (
                    <div className={style.inputImgWrapper}>
                        <p className={style.inputImgWrapperTxt}>{srcFileName}</p>
                        <Button
                            className={style.inputImgWrapperBtn}
                            outline
                            color="inherit"
                            name="firstImgDeleteBtn"
                            onClick={() => deleteFile()}>
                            <img src={deleteIcon} alt="icon" />
                        </Button>
                    </div>
                ) : (
                    <>
                        {isFileUploading ? (
                            <>
                                <Progress animated color="primary" striped value={progress} />
                                <>
                                    {fileComplete && (
                                        <p className={style.progressText}>Файл сохраняется...</p>
                                    )}
                                </>
                            </>
                        ) : (
                            <button type="button" id="uploadBtn" className={style.uploadFile}>
                                Добавить файл
                                <FileBlue />
                            </button>
                        )}
                    </>
                )}
                <FormGroup>
                    <FormFeedback>{errors["src_file"] || errors?.src_file}</FormFeedback>

                    <FormText color="muted" className={style.displayHint}>
                        Размер файла не должен превышать 2ГБ
                    </FormText>
                </FormGroup>
            </div>

            <div className={style.displayButton}>
                <Button
                    color="primary"
                    outline
                    className={style.deleteLesson}
                    onClick={() => closeModal()}>
                    Отмена
                </Button>
                {loading ? (
                    <Button disabled color="primary" outline className={style.deleteLesson}>
                        <div
                            className={`spinner-border text-primary ${style.loading}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        disabled={submitBtnDisabled}
                        className={style.canselChange}
                        onClick={() => apiAddResource()}>
                        Добавить
                    </Button>
                )}
            </div>
        </Form>
    );
}

export default observer(AddFileResources);

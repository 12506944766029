const actions = {
  setValue: 'setValue',
  reset: 'reset',
  deletePhoto: 'deletePhoto',
  setValues: 'setValues',
};

const userTypes = {
  student: 'student',
  adult: 'adult',
};

const initialValue = {
  [userTypes.student]: {
    // first_name: '',
    // last_name: '',
    // email: '',
    // birthday: '',
    // phone: '',
    // education_class: 0,
    // education_format: 0,
    // divergency: 0,
    // avatar: '',
    // other_divergency: '',
  },
  [userTypes.adult]: {
    // fio: '',
    // birthday: '',
    // role: 0,
    // location: '',
  },
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.setValue: {
      const { name, value, userType } = payload;

      return {
        ...state,
        [userTypes[userType]]: {
          ...state[userTypes[userType]],
          [name]: value,
        },
      };
    }

    case actions.deletePhoto: {
      const { userType } = payload;
      return {
        ...state,
        [userTypes[userType]]: {
          ...state[userTypes[userType]],
          avatar: userTypes.student.avatar,
        },
      };
    }

    case actions.setValues: {
      return { ...state, ...payload };
    }

    case actions.reset:
      return initialValue;
    default:
      return state;
  }
}

export {
  userTypes,
  actions as userActions,
  reducer as userReducer,
  initialValue as userInitialValue,
};

import { formatTime } from "../helpers";

export const lessonCardColors = (item) => {
    switch (item.status.original_name) {
        case "COMPLETED":
            return {
                main: "#FFFFFF",
                mainDark: "#F1F3F5",
                border: "#BDCAD2",
                text: "#A5A8AB",
                studentName: "#A5A8AB",
                studentsCountBackground: "#E8EBEE",
                studentsCount: "#696e73",
                opacity: "60%",
            };
        case "CANCELLED":
            return {
                main: "#FFFFFF",
                mainDark: "#F1F3F5",
                border: "#FFA6A8",
                studentsCountBackground: "#E8EBEE",
                studentsCount: "#696e73",
            };
        case "PLANNED":
            switch (item.type.id) {
                case 1:
                    return {
                        main: "#E2FAFE",
                        mainDark: "#C9F1F7",
                    };
                case 2:
                    return {
                        main: "#E6F5E7",
                        mainDark: "#D1ECD2",
                    };
                case 3:
                    return {
                        main: "#EBF0FF",
                    };
                case 5:
                    return {
                        main: "#FFF9E7",
                    };
                case 4:
                    return {
                        main: "#FEF1F8",
                    };
                default:
                    throw Error("unknown type");
            }
        default:
            throw Error("unknown status");
    }
};

export const cardFormatTime = (time) => {
    return `${formatTime(time.startHours, time.startMinutes)} - ${formatTime(
        time.endHours,
        time.endMinutes,
    )}`;
};

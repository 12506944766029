/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from "reactstrap";
import { toast } from "react-toastify";
import { FormCheck } from "react-bootstrap";
import { useStore } from "store";
import lessonApi from "../../api/endPoints/lesson";
import downloadLogo from "../../assets/img/downloadLogo.svg";
import deleteIcon from "../../assets/img/deleteIcon.svg";
import imgIcon from "../../assets/img/imgIcon.svg";
import * as style from "./style/CreateLesson.module.scss";
import MakeLinks from "../Popup/MakeLinks";
import LeavePage from "../Popup/LeavePage";
import Preloader from "../Preloader/Preloader";
import paths from "constants/paths";
import validation from "./utils/validation";
import CropperModal from "../Popup/CropperModal";
import history from "utils/history/history";

function CreateLesson() {
    const [errors, setErrors] = useState({});
    const {
        modalWindowStore: {
            isCloseModalOpen,
            setIsCloseModalOpen,
            cropperModalOpen,
            seCropperModalOpen,
        },
    } = useStore();

    const {
        createLessonStore: { setTeacherId, setName, setTypeId, setLessonUrl, setIsAdd },
    } = useStore();

    const [modalCreate, setModalCreate] = useState(false);
    const [toilet, setToilet] = useState(false);
    const [individual, setIndividual] = useState(false);
    const [teachers, setTeachers] = useState();
    const [minDate, setMinDate] = useState();

    const formNames = {
        name: "name",
        start_at: "start_at",
        button_1_name: "button_1_name",
        button_2_name: "button_2_name",
        button_toilet_enabled: "button_toilet_enabled",
        is_individual: "is_individual",
        teacher_id: "teacher_id",
    };
    let firstImgElem = document.getElementById("firstFile");
    let secondImgElem = document.getElementById("secondFile");
    const [firstChangeImg, setFirstChangeImg] = useState(null);
    const [secondChangeImg, setSecondChangeImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cropper, setCropper] = useState();
    const [links, setLinks] = useState("");
    const [cropPhoto, setCropPhoto] = useState();
    const [inputLableFor, setInputLableFor] = useState(null);
    const [image, setImage] = useState();

    const SIZE_LIMIT = 10485760;

    const [createLesson, setCreateLesson] = useState({
        name: "",
        start_at: "",
        button_toilet_enabled: false,
        is_individual: individual,
        teacher_id: "",
    });

    useEffect(() => {
        lessonApi.getTeachers().then((response) => {
            setTeachers(response);
        });
        let date = new Date();
        date = format(date, "yyyy-MM-dd");
        setMinDate(date);
    }, []);

    const toggleToilet = (event) => {
        const { name } = event.target;
        let values = { ...createLesson };
        values[name] = !toilet;
        setCreateLesson(values);
        setToilet(!toilet);
    };

    const toggleIndividual = (event) => {
        const { name } = event.target;
        let values = { ...createLesson };
        values[name] = !individual;
        setCreateLesson(values);
        setIndividual(!individual);
    };

    const toggleCreate = () => {
        setModalCreate(!modalCreate);
    };

    const onBlur = async (e) => {
        const { name } = e.target;
        const validationErrors = await validation.clientValidateField(name, {
            ...createLesson,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        clearErrField(name);
        let values = { ...createLesson };
        switch (name) {
            case formNames.name:
                values[name] = value;
                break;
            case formNames.button_1_name:
                if (value === "" && firstChangeImg === null) {
                    setErrors({
                        ...errors,
                        button_1_name: "",
                        button_2_name: "",
                        button_1_image: "",
                        button_2_image: "",
                    });
                }
                values[name] = value;
                break;
            case formNames.button_2_name:
                if (value === "" && secondChangeImg === null) {
                    setErrors({
                        ...errors,
                        button_1_name: "",
                        button_2_name: "",
                        button_1_image: "",
                        button_2_image: "",
                    });
                }
                values[name] = value;
                break;
            case formNames.start_at:
                const setMinDate = `${minDate}T00:00`;
                if (setMinDate > value) {
                    setErrors({
                        ...errors,
                        start_at: `Дата начала урока не может быть меньше ${minDate}`,
                    });
                    setCreateLesson({ ...createLesson, start_at: "" });
                    values[name] = value;
                } else {
                    values[name] = value;
                }
                break;

            case formNames.teacher_id:
                values[name] = Number(value);
                break;
        }
        setCreateLesson(values);
    };

    const getCropData = (e) => {
        e.preventDefault();
        seCropperModalOpen(false);

        if (typeof cropper !== "undefined") {
            setCropPhoto(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
            fetch(cropper.getCroppedCanvas().toDataURL("image/jpeg"))
                .then((res) => res.blob())
                .then((file) => {
                    if (inputLableFor === "firstFile") {
                        setFirstChangeImg(file);
                        delete errors.button_1_image;
                    } else {
                        setSecondChangeImg(file);
                        delete errors.button_2_image;
                    }
                    setImage(null);
                    setInputLableFor(null);
                });
        }
    };

    const handleChangeImg = (e) => {
        e.preventDefault();
        const { name } = e.target;
        clearErrField(name);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        switch (name) {
            case "firstFile":
                if (e.target.files[0].size <= SIZE_LIMIT) {
                    reader.onload = () => {
                        setImage(reader.result);
                    };
                    reader.readAsDataURL(files[0]);
                    delete errors.button_1_image;
                } else {
                    firstImgElem.value = null;
                    toast.info("Вес файла больше 10 МБ");
                }
                break;
            case "secondFile":
                if (e.target.files[0].size <= SIZE_LIMIT) {
                    reader.onload = () => {
                        setImage(reader.result);
                    };
                    reader.readAsDataURL(files[0]);
                    delete errors.button_2_image;
                } else {
                    secondImgElem.value = null;
                    toast.info("Вес файла больше 10 МБ");
                }
                break;
        }
    };

    const changeImgInput = (name) => {
        switch (name) {
            case "firstFile":
                firstImgElem.value = "";
                setFirstChangeImg(null);
                break;
            case "secondFile":
                secondImgElem.value = "";
                setSecondChangeImg(null);
                break;
        }
        setImage(null);
        seCropperModalOpen(false);
    };

    const submitButton = async () => {
        setLoading(true);
        let formData = new FormData();
        let jsonBody = JSON.stringify(createLesson);
        formData.append("jsonBody", jsonBody);

        if (firstChangeImg) {
            formData.append("button_1_image", firstChangeImg);
        }
        if (secondChangeImg) {
            formData.append("button_2_image", secondChangeImg);
        }
        const validateErrors = await validation.clientValidateForm({
            ...createLesson,
        });

        if (validateErrors) {
            setErrors({ ...validateErrors });
            setLoading(false);
        } else {
            setErrors({});
            lessonApi
                .createLesson(formData)
                .then((response) => {
                    setTeacherId(createLesson.teacher_id);
                    setName(createLesson.name);
                    setTypeId(createLesson.is_individual ? 1 : 2);
                    setLessonUrl(
                        `${process.env.REACT_APP_WEB_MORDA_URL}/lesson?educationStakeholder=student&lessonHash=${response.hash}`,
                    );
                    setLinks(response.hash);
                    setLoading(false);
                    setLoading(false);
                    setIsAdd(true);
                })
                .catch((error) => {
                    setLoading(false);
                    setErrors(error.data.error.advanced);
                })
                .finally(() =>
                    localStorage.getItem("role") === "MENTOR"
                        ? history.push(paths.timeSheet)
                        : toggleCreate(),
                );
        }
    };

    const openCroperModal = (name) => {
        setInputLableFor(name);
        seCropperModalOpen(true);
    };

    return (
        <>
            {teachers ? (
                <>
                    <div className="row">
                        <div className={`col-md-7 ${style.wrapper}`}>
                            <Button
                                className={style.btn_link}
                                color="link"
                                onClick={() => setIsCloseModalOpen(true)}>
                                {"< Назад"}
                            </Button>
                            <p className={style.pageTitle}>Создание урока</p>
                            <Form>
                                <FormGroup>
                                    <Label className={style.inputLable} for="nameOfLesson">
                                        Тема урока
                                    </Label>
                                    <Input
                                        className={style.inputImgName}
                                        type="text"
                                        name={formNames.name}
                                        id="nameOfLesson"
                                        placeholder="Введите тему урока"
                                        value={createLesson.name}
                                        onChange={handleChange}
                                        invalid={!!errors.name}
                                        autoComplete="off"
                                        onBlur={(e) => onBlur(e)}
                                    />
                                    <FormFeedback>{errors?.name}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label className={style.inputLable} for="Date">
                                        Дата урока
                                    </Label>
                                    <Input
                                        className={style.inputImgName}
                                        type="datetime-local"
                                        name={formNames.start_at}
                                        id="Date"
                                        value={createLesson.start_at}
                                        onChange={handleChange}
                                        placeholder="date placeholder"
                                        max="9999-12-31T00:00"
                                        min={`${minDate}T00:00`}
                                        autoComplete="off"
                                        onBlur={(e) => onBlur(e)}
                                        invalid={!!errors.start_at}
                                    />
                                    <FormFeedback>{errors?.start_at}</FormFeedback>
                                </FormGroup>

                                <div className={style.inputFileWrapper}>
                                    <div className={style.inputImg}>
                                        <div className={style.inputImgFirstImg}>
                                            <p className={style.inputImgTxt}>
                                                Кнопка Тема урока №1
                                            </p>
                                            <FormGroup>
                                                <Label
                                                    className={style.inputLable}
                                                    for="nameOfImg1">
                                                    Название кнопки
                                                </Label>
                                                <Input
                                                    className={style.inputImgName}
                                                    type="text"
                                                    name={formNames.button_1_name}
                                                    id="nameOfImg1"
                                                    placeholder="Введите название кнопки"
                                                    value={createLesson.button_1_name}
                                                    autoComplete="off"
                                                    onChange={handleChange}
                                                    onBlur={(e) => onBlur(e)}
                                                    invalid={!!errors?.button_1_name}
                                                />
                                                <FormFeedback>{errors?.button_1_name}</FormFeedback>
                                            </FormGroup>
                                            <FormGroup>
                                                <Input
                                                    className={style.fileInput}
                                                    type="file"
                                                    name="firstFile"
                                                    id="firstFile"
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={handleChangeImg}
                                                    invalid={!!errors?.button_1_image}
                                                />
                                                {firstChangeImg ? (
                                                    <div className={style.inputImgWrapper}>
                                                        <img
                                                            className={style.inputImgWrapperImg}
                                                            src={imgIcon}
                                                            alt="icon"
                                                        />
                                                        <p className={style.inputImgWrapperTxt}>
                                                            {firstImgElem?.files[0]?.name}
                                                        </p>
                                                        <Button
                                                            className={style.inputImgWrapperBtn}
                                                            outline
                                                            color="inherit"
                                                            name="firstFile"
                                                            onClick={() =>
                                                                changeImgInput("firstFile")
                                                            }>
                                                            <img src={deleteIcon} alt="icon" />
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        color="inherit"
                                                        className={style.fileLable}
                                                        onClick={() =>
                                                            openCroperModal("firstFile")
                                                        }>
                                                        <img
                                                            className={style.fileImg}
                                                            color="inherit"
                                                            src={downloadLogo}
                                                            alt=""
                                                        />
                                                        Прикрепить изображение
                                                    </Button>
                                                )}
                                                <FormFeedback>
                                                    {errors?.button_1_image}
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div>
                                            <p className={style.inputImgTxt}>
                                                Кнопка Тема урока №2
                                            </p>
                                            <FormGroup>
                                                <Label
                                                    className={style.inputLable}
                                                    for="nameOfImg2">
                                                    Название кнопки
                                                </Label>
                                                <Input
                                                    className={style.inputImgName}
                                                    type="text"
                                                    name={formNames.button_2_name}
                                                    id="nameOfImg2"
                                                    placeholder="Введите название кнопки"
                                                    value={createLesson.button_2_name}
                                                    onChange={handleChange}
                                                    autoComplete="off"
                                                    invalid={!!errors?.button_2_name}
                                                    onBlur={(e) => onBlur(e)}
                                                />
                                                <FormFeedback>{errors?.button_2_name}</FormFeedback>
                                            </FormGroup>
                                            <FormGroup>
                                                <Input
                                                    className={style.fileInput}
                                                    type="file"
                                                    name="secondFile"
                                                    id="secondFile"
                                                    accept=".jpg,.jpeg,.png"
                                                    onChange={handleChangeImg}
                                                    invalid={!!errors?.button_2_image}
                                                />
                                                {secondChangeImg ? (
                                                    <div className={style.inputImgWrapper}>
                                                        <img
                                                            className={style.inputImgWrapperImg}
                                                            src={imgIcon}
                                                            alt="icon"
                                                        />
                                                        <p className={style.inputImgWrapperTxt}>
                                                            {secondImgElem?.files[0]?.name}
                                                        </p>
                                                        <Button
                                                            className={style.inputImgWrapperBtn}
                                                            outline
                                                            color="inherit"
                                                            name="secondFile"
                                                            onClick={() =>
                                                                changeImgInput("secondFile")
                                                            }>
                                                            <img src={deleteIcon} alt="icon" />
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <Button
                                                        color="inherit"
                                                        className={style.fileLable}
                                                        onClick={() =>
                                                            openCroperModal("secondFile")
                                                        }>
                                                        <img
                                                            className={style.fileImg}
                                                            color="inherit"
                                                            src={downloadLogo}
                                                            alt=""
                                                        />
                                                        Прикрепить изображение
                                                    </Button>
                                                )}
                                                <FormFeedback>
                                                    {errors?.button_2_image}
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormText color="muted">
                                        Макс. допустимый вес изображения 10 МБ
                                    </FormText>
                                </div>
                                <div className={style.toogleWrapper}>
                                    <FormGroup className={style.toilet}>
                                        <p className={style.inputImgTxt}>Кнопка Туалет</p>
                                        <FormCheck
                                            type="switch"
                                            id="toilet"
                                            name={formNames.button_toilet_enabled}
                                            checked={toilet}
                                            onChange={toggleToilet}
                                        />
                                        {toilet ? (
                                            <Label for="toilet" className={style.checkboxLable}>
                                                On
                                            </Label>
                                        ) : (
                                            <Label for="toilet" className={style.checkboxLable}>
                                                Off
                                            </Label>
                                        )}
                                    </FormGroup>
                                    <FormGroup className={style.individualLesson}>
                                        <p className={style.inputImgTxt}>Индивидуальный урок</p>
                                        <FormCheck
                                            type="switch"
                                            id="individual"
                                            name={formNames.is_individual}
                                            checked={individual}
                                            onChange={toggleIndividual}
                                        />
                                        {individual ? (
                                            <Label for="individual" className={style.checkboxLable}>
                                                On
                                            </Label>
                                        ) : (
                                            <Label for="individual" className={style.checkboxLable}>
                                                Off
                                            </Label>
                                        )}
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <Label className={style.inputLable} for="selectTeacher">
                                        Выберите преподавателя
                                    </Label>
                                    <Input
                                        type="select"
                                        className={style.selectTeacher}
                                        name={formNames.teacher_id}
                                        id="selectTeacher"
                                        aria-label="Выберите"
                                        value={createLesson.teacher_id}
                                        onChange={handleChange}
                                        invalid={!!errors?.teacher_id}
                                        onBlur={(e) => onBlur(e)}>
                                        <option>Выберите</option>
                                        {teachers.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.last_name} {item.first_name}
                                            </option>
                                        ))}
                                    </Input>
                                    <FormFeedback>{errors?.teacher_id}</FormFeedback>
                                </FormGroup>
                                {loading ? (
                                    <Button disabled color="primary" className={style.submitBtn}>
                                        <div
                                            className={`spinner-border text-light ${style.loading}`}
                                            role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        className={style.submitBtn}
                                        onClick={() => submitButton()}
                                        disabled={errors?.start_at}>
                                        Создать урок
                                    </Button>
                                )}
                            </Form>
                        </div>
                    </div>
                    <div>
                        {modalCreate && (
                            <MakeLinks modal={modalCreate} toggle={toggleCreate} links={links} />
                        )}
                    </div>
                    {isCloseModalOpen && <LeavePage page={paths.lessonList} />}
                </>
            ) : (
                <div className="row">
                    <Preloader />
                </div>
            )}
            {cropperModalOpen && (
                <CropperModal
                    image={image}
                    setCropper={setCropper}
                    getCropData={getCropData}
                    cancelClick={changeImgInput}
                    lableFor={inputLableFor}
                    croppSize={150}
                    hint="Выберите область, которая будет отображаться в плеере"
                    ratio={1}
                />
            )}
        </>
    );
}

export default observer(CreateLesson);

import React from 'react';
import * as style from "./styles/UserLinkWrapper.module.scss";

function UserLinkWrapper({ blocked, link, children }){
    if (!blocked) {
        return <a className={style.link} style={{width:'100%'}} href={link} target="_blank">{children}</a>;
    } else {
        return children;
    }
}

export default UserLinkWrapper;
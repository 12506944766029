import { object, string, number, array } from "yup";

const rulesMessage = {
    min: (symbol, field) => `В поле "${field}" не должно быть меньше ${symbol} символов`,
    max: (symbol, field) => `В поле "${field}" не должно быть больше ${symbol} символов`,
    required: "Поле обязательно для заполнения",
};

const URL = new RegExp(
    "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?",
);

const schema = object({
    name: string()
        .nullable()
        .when("type_id", {
            is: (val) => val === 1 || val === 2 || val === 6,
            then: (shema) =>
                shema
                    .min(2, rulesMessage.min(2, "Название"))
                    .max(150, rulesMessage.max(150, "Название"))
                    .required(rulesMessage.required),
        }),
    type_id: number().typeError(rulesMessage.required).nullable().required(rulesMessage.required),
    start_at: string().nullable(true).required(rulesMessage.required),
    start_time: string().nullable(true).required(rulesMessage.required),
    teacher_id: string().nullable(true).required(rulesMessage.required),
    students: array()
        .nullable(true)
        .required(rulesMessage.required)
        .min(1, rulesMessage.required)
        .when("type_id", {
            is: (val) => val === 2,
            then: (shema) => shema.min(2, "Должно быть не меньше 2 студентов"),
        }),
    lesson_url: string().nullable(true).matches(URL, `Это поле должно быть ссылкой`),
    description: string()
        .nullable()
        .min(2, rulesMessage.min(2, "Описание"))
        .max(150, rulesMessage.max(150, "Описание")),
});

async function clientValidateForm(form) {
    let errors;

    try {
        await schema.validate(form, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

async function clientValidateField(field, value) {
    let errors;
    try {
        await schema.validateAt(field, value, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

const statusSchema = object({
    start_at: string()
        .nullable(true)
        .when("status", {
            is: (val) => val === "PLANNED",
            then: (shema) => shema.required(rulesMessage.required),
        }),
    start_time: string()
        .nullable(true)
        .when("status", {
            is: (val) => val === "PLANNED",
            then: (shema) => shema.required(rulesMessage.required),
        }),
});

async function statusClientValidateForm(form) {
    let errors;

    try {
        await statusSchema.validate(form, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

async function statusClientValidateField(field, value) {
    let errors;
    try {
        await statusSchema.validateAt(field, value, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

const downloadShema = object({
    type: string().nullable().required(rulesMessage.required),
});

async function downloadClientValidateField(field, value) {
    let errors;
    try {
        await downloadShema.validateAt(field, value, { abortEarly: false });
    } catch (validationError) {
        errors = {};
        validationError.inner.forEach((error) => {
            const { path, message } = error;
            errors[path] = message;
        });
    }
    return errors;
}

export default {
    clientValidateField,
    clientValidateForm,
    statusClientValidateForm,
    statusClientValidateField,
    downloadClientValidateField,
};

import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "reactstrap";
import { useStore } from "store";
import * as style from "./style/DeleteForm.module.scss";

function DeleteForm({ loading, deleteForm, setLoading }) {
  const {
    modalWindowStore: { setIsDeleteUserFormOpen },
  } = useStore();
  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>
              Вы уверены, что хотите <br />
              cбросить шаблон формы
              <br /> обучения?
            </p>
          </div>
          <p className={style.popUpTxt}>
          Данная форма обучения будет удалена без возможности восстановления.
          </p>
          <div>
            <Button
              outline
              color="primary"
              className={style.deleteLesson}
              onClick={() => setIsDeleteUserFormOpen(false)}
            >
              Отменить
            </Button>
            {loading ? (
              <Button disabled color="primary" className={style.canselChange}>
                <div
                  className={`spinner-border text-light ${style.loading}`}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                className={style.canselChange}
                onClick={() => deleteForm()}
              >
                Сбросить шаблон
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DeleteForm);

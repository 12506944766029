import React from "react";
import { observer } from "mobx-react-lite";

import { ReactComponent as FileBlue } from "../../../../../assets/img/fileBlue.svg";
import deleteIcon from "../../../../../assets/img/deleteIcon.svg";

import {
    Button,
    FormText,
    Input,
    Label,
    Form,
    FormGroup,
    FormFeedback,
    Progress,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import * as style from "./style/EditFileResource.module.scss";
import { useStore } from "store";
import { useState } from "react";
import foldersApi from "../../../../../api/endPoints/folders";
import validationResources from "components/Resources/utils/validationResources";
import { toast } from "react-toastify";
import Resources from "api/endPoints/resources";
import { useEffect } from "react";
import Resumable from "resumablejs";
import { useMemo } from "react";
import login from "api/endPoints/login";

function EditFileResource({ setModalLoading }) {
    const {
        modalWindowStore: { setEditResource, editResourceOpen },
    } = useStore();
    let fileId = uuidv4();
    const [progress, setProgress] = useState(0);
    const SIZE_LIMIT = 2147483648;
    let timerId;
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({
        name: editResourceOpen.name,
        type: "FILE",
    });
    const token = localStorage.getItem("access_token");
    const target = `${process.env.REACT_APP_BASE_URL}/api/v1/admin/resources/files`;
    const [resourceId, setResourceId] = useState(null);
    const [fileComplete, setFileComplete] = useState(false);
    const [deletedImg, setDeletedImg] = useState(false);
    const [saveFile, setSaveFile] = useState(null);
    const [submitBtnDisabled, setSubmitButtonDisabled] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [srcFileName, setSrcFileName] = useState(
        editResourceOpen.type === "FILE" ? editResourceOpen.name : null,
    );
    const [srcFileId, setSrcFileId] = useState(editResourceOpen?.file?.resource_file?.id);
    const itemId = editResourceOpen.id;
    const [addButton, setAddButton] = useState(editResourceOpen.type === "FILE" ? false : true);

    //Uploader files

    const getResurceInfo = (fileName, id) => {
        Resources.getResourcesFile(id)
            .then((res) => {
                if (res.resource_file.status === "SUCCESS") {
                    setIsFileUploading(false);
                    setSrcFileName(fileName);
                    clearInterval(timerId);
                    setSubmitButtonDisabled(false);
                    setSrcFileId(res.resource_file.id);
                }
            })
            .catch((error) => toast.info(error));
    };

    let chanck = 5;
    const config = {
        target: target,
        testChunks: false,
        headers: {
            Authorization: "Bearer " + token,
        },
        simultaneousUploads: 1,
        chunkSize: chanck * 1024 * 1024,
        // xhrTimeout: 1000,
        throttleProgressCallbacks: 1,
        chunkRetryInterval: 5000,
        generateUniqueIdentifier: function (file, event) {
            return fileId;
        },
        permanentErrors: [400, 404, 409, 415, 500, 501, 422],
    };
    let r = new Resumable(config);
    useEffect(() => {
        if (addButton) {
            r.assignBrowse(document.getElementById("uploadBtn", false));
        }
    }, [deletedImg]);

    r.on("fileSuccess", function (file, message) {
        console.log("fileSuccess", file, JSON.parse(message).resource_file.resource_file.id);
        setResourceId(JSON.parse(message).resource_file.resource_file.id);
        setFileComplete(true);
        timerId = setInterval(
            () => getResurceInfo(file.fileName, JSON.parse(message).resource_file.resource_file.id),
            1000,
        );
    });
    r.on("fileProgress", function (file) {
        setSaveFile(file);
        console.log("fileProgress", file, file.fileName);
        // setSrcFileName(file.fileName);
    });
    r.on("filesAdded", function (array) {
        if (array[0].file.size <= SIZE_LIMIT) {
            r.upload();
        } else {
            toast.info("Размер файла не должен превышать 2 Гб");
        }
        console.log("filesAdded", array);
    });
    r.on("fileRetry", function (file) {
        console.log("fileRetry", file);
        login.refresh().then((response) => {
            r.opts.headers = {
                Authorization: `Bearer ${response.data.token.access_token}`,
            };
        });
    });
    r.on("fileError", function (file, message) {
        console.log("fileError", file, message);
        setIsFileUploading(false);
        setSubmitButtonDisabled(true);
        setSrcFileName(null);
        setDeletedImg(!deletedImg);
        toast.info(JSON.parse(message).error.message);
    });
    r.on("uploadStart", function () {
        console.log("uploadStart");
        setIsFileUploading(true);
    });
    r.on("complete", function () {
        console.log("complete");
    });
    r.on("progress", function () {
        console.log("progress");
        setProgress(r.progress() * 100);
    });
    r.on("error", function (message, file) {
        console.log("error", message, file);
    });
    r.on("pause", function () {
        console.log("pause");
    });
    r.on("cancel", function () {
        if (chanck > 1) {
            chanck = chanck - 1;
        } else {
            toast.info("Слишком медленный интернет");
        }
        console.log("cancel");
    });

    //Main functional

    const onBlur = async (e) => {
        const validationErrors = await validationResources.clientValidateField(e.target.name, {
            ...item,
        });

        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors,
            });
        }
    };

    const clearErrField = (field) => {
        const checkError = { ...errors };

        if (checkError[field]) {
            delete checkError[field];
        }
        setErrors({ ...checkError });
    };
    const onChange = (event) => {
        const { name, value } = event.target;
        clearErrField(name);
        setItem({ ...item, [name]: value });
    };
    const deleteImage = (id) => {
        if (id) {
            Resources.deleteResourceFile(id)
                .then(() => {
                    toast.info("Файл успешно удален");
                    setAddButton(true);
                    setIsFileUploading(false);
                    setSubmitButtonDisabled(true);
                    setSrcFileName(null);
                    setResourceId(null);
                    setDeletedImg(!deletedImg);
                })
                .catch((error) => toast.info(error));
        } else {
            setAddButton(true);
            setIsFileUploading(false);
            setSubmitButtonDisabled(true);
            setSrcFileName(null);
            setDeletedImg(!deletedImg);
        }
    };

    const onSubmit = () => {
        setModalLoading(true);
        const data = { ...item, resource_file_id: srcFileId };
        foldersApi
            .editFileResource(data, itemId)
            .then(() => {
                setEditResource(false);
                setModalLoading(false);
                toast.success("Ресурс успешно отредактирован");
            })
            .catch((error) => {
                setErrors({ ...errors, ...error.data.error.advanced });
                setModalLoading(false);
                setLoading(false);
            });
    };

    const closeModal = () => {
        if (saveFile) {
            saveFile.cancel();
        }
        setEditResource(false);
    };

    return (
        <Form>
            <FormGroup>
                <div className={style.titleName}>
                    <Label>Название:*</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        value={item.name}
                        invalid={!!errors["name"] || !!errors?.name}
                        onBlur={(e) => onBlur(e, item.name)}
                        onChange={onChange}
                        placeholder="Название папки"></Input>
                    <FormFeedback>{errors["name"] || errors?.name}</FormFeedback>
                    <FormText color="muted" className={style.displayHint}>
                        Введите от 2 до 150 символов
                    </FormText>
                </div>
            </FormGroup>
            <FormGroup>
                <div>
                    <Label color="muted" className={style.displayHint}>
                        Загрузите файл:*
                    </Label>
                    {srcFileName ? (
                        <div className={style.inputImgWrapper}>
                            <p className={style.inputImgWrapperTxt}>{srcFileName}</p>
                            <Button
                                className={style.inputImgWrapperBtn}
                                outline
                                color="inherit"
                                name="firstImgDeleteBtn"
                                onClick={() => deleteImage(srcFileId)}>
                                <img src={deleteIcon} alt="icon" />
                            </Button>
                        </div>
                    ) : (
                        <>
                            {isFileUploading ? (
                                <>
                                    <Progress animated color="primary" striped value={progress} />
                                    <>
                                        {fileComplete && (
                                            <p className={style.progressText}>
                                                Файл сохраняется...
                                            </p>
                                        )}
                                    </>
                                </>
                            ) : (
                                <button type="button" id="uploadBtn" className={style.uploadFile}>
                                    Добавить файл
                                    <FileBlue />
                                </button>
                            )}
                        </>
                    )}
                    <FormText color="muted" className={style.displayHint}>
                        Размер файла не должен превышать 2ГБ
                    </FormText>
                    {resourceId && editResourceOpen.type !== "FILE" && (
                        <Label className={style.textFile}>
                            После сохранения ранее загруженная ссылка удалится
                        </Label>
                    )}
                </div>
            </FormGroup>
            <div className={style.displayButton}>
                <Button
                    color="primary"
                    outline
                    className={style.deleteLesson}
                    onClick={() => closeModal()}>
                    Отмена
                </Button>

                {loading ? (
                    <Button disabled color="primary" outline className={style.deleteLesson}>
                        <div
                            className={`spinner-border text-primary ${style.loading}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        disabled={submitBtnDisabled}
                        className={style.canselChange}
                        onClick={() => onSubmit("FILE")}>
                        Сохранить
                    </Button>
                )}
            </div>
        </Form>
    );
}

export default observer(EditFileResource);

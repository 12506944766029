import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { ReactComponent as SearchIcon } from "../../../assets/img/search.svg";
import BreadCrumbs from "components/Breadcrumbs/Breadcrumbs";
import * as style from "./style/Subjects.module.scss";
import itemsManagement from "api/endPoints/itemsManagement";
import CardItem from "components/ItemManagement/CardItem/CardItem";
import Preloader from "components/Preloader/Preloader";
import Pagination from "components/Pagination/Pagination";
import { useLocation, useParams } from "react-router";
import users from "api/endPoints/users";

function Subjects() {
  const params = useParams();
  const [filter, setFilters] = useState();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const { pathname } = useLocation();

  const [userName, setUserName] = useState();

  useEffect(() => {
    setFilterLoading(true);
    itemsManagement
      .getSubjects(page, filter, params.formId, params.materialId)
      .then((response) => {
        if (response.data.current_page > response.data.last_page) {
          setPage(response.data.last_page);
        }
        setBreadCrumbs(response.data.breadcrumbs);
        setLastPage(response.data.last_page);
        setFilterLoading(false);
        setData(response.data.items);
      });
  }, [page, filter]);

  const shopRegular = new RegExp(/\/additional-purchases\/material/);

  useEffect(() => {
    if (!shopRegular.test(pathname)) {
      users.getStudent(params.id).then((response) => {
        setUserName(`${response.user.last_name || "Неизвестный"} 
      ${response.user.first_name || "пользователь"}`);
      });
    }
  }, []);

  const prevPage = (number) => {
    setFilterLoading(true);
    setPage(page - number);
  };

  const nextPage = (number) => {
    setFilterLoading(true);
    setPage(page + number);
  };

  const lastPageFunction = (number) => {
    setFilterLoading(true);
    setPage(number);
  };
  const breadcrumbsNew = () => {
    if (params.formId) {
      let firstItem = {
        name: userName,
        path: `/user-detail/${params.id}`,
      };
      let secondItem = {
        name: breadCrumbs[0].name,
        path: `/user/${params.id}/edit-form/${breadCrumbs[0].id}`,
      };
      let thirdItem = {
        name: breadCrumbs[1].name,
        path: `/user/${params.id}/edit-form/${breadCrumbs[0].id}/materials/${breadCrumbs[1].id}`,
      };

      return [firstItem, secondItem, thirdItem];
    } else if (params.id) {
      let firstItem = {
        name: "Личные данные ученика",
        path: `/user-detail/${params.id}`,
      };
      let secondItem = {
        name: breadCrumbs[0].name,
        path: `/user-detail/${params.id}/material/${params.materialId}`,
      };

      return [firstItem, secondItem];
    } else {
      let firstItem = {
        name: "Магазин дополнительных продуктов",
        path: `/additional-purchases`,
      };
      let secondItem = {
        name: breadCrumbs[0].name,
        path: `/additional-purchases/material/${params.materialId}`,
      };

      return [firstItem, secondItem];
    }
  };

  return (
    <>
      {data ? (
        <div>
          <div className={style.header}>
            <BreadCrumbs breadCrumbs={breadcrumbsNew()} pathname={pathname} />
            <p className={style.title}>
              {params.formId ? breadCrumbs[1].name : breadCrumbs[0].name}
            </p>
          </div>
          <div className={style.debounce_wrapper}>
            <p className={style.title_debounce}>Поиск:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                className={style.findUsers}
                placeholder="Введите название"
                minLength={1}
                debounceTimeout={800}
                onChange={(event) => setFilters({ name: event.target.value })}
              />
              <SearchIcon className={style.searchImg} />
            </div>
          </div>
          <div className={style.items_wrapper}>
            {filterLoading ? (
              <div className={style.preloader_wrapper}>
                <Preloader />
              </div>
            ) : (
              <>
                {data.length > 0 ? (
                  <>
                    {data.map((item) => (
                      <CardItem
                        key={item.id}
                        item={item}
                        menu={false}
                        isClickable="/subject"
                      />
                    ))}
                  </>
                ) : (
                  <p className={style.lessonsListNull}>Ничего не найдено</p>
                )}
              </>
            )}
          </div>
          <Pagination
            page={page}
            nextPage={nextPage}
            prevPage={prevPage}
            lastPage={lastPage}
            lastPageFunction={lastPageFunction}
          />
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default Subjects;

import React from 'react';
import { Button } from 'reactstrap';
import Preloader from '../Preloader/Preloader';
import * as style from './style/DeletePopUp.module.scss';

export default function DeletePopUp({ toggle, deleteLesson, id, loading }) {
  return (
    <div className={style.modal}>
      <div className={style.modalOverlay}>
        <div className={style.modalWindow}>
          <div className={style.modalHeader}>
            <p className={style.popUpTitle}>
              Вы уверены, что хотите <br /> удалить урок?
            </p>
          </div>
          <p className={style.popUpTxt}>
            Если вы удалите урок, все сведения о нем будут недоступны.
          </p>
          <div>
            {loading ? (
              <Button disabled color="primary" outline className={style.deleteLesson}>
                <div className={`spinner-border text-primary ${style.loading}`} role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Button>
            ) : (
              <Button
                color="primary"
                outline
                className={style.deleteLesson}
                onClick={() => deleteLesson()}>
                Удалить урок
              </Button>
            )}
            <Button color="primary" className={style.canselChange} onClick={toggle}>
              Отменить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

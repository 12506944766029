const actions = {
    setErrors: 'setErrors',
    resetErrors: 'resetErrors',
};

const initialValue = {};

function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case actions.setErrors:
            return { ...payload};
        case actions.resetErrors:
            return initialValue;
        default:
            return state;
    }
}

export {
    actions as errorActions,
    reducer as errorReducer,
    initialValue as errorInitialValue,
};

/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import FolderTree from "components/FolderTree/FolderTree";
import { useParams } from "react-router";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { ReactComponent as Price } from "../../../../assets/img/price.svg";
import { useStore } from "store";
import validation from "components/Purchases/AdditionalPurchases/AddEditProduct/utils/validate";
import * as style from "./style/AddEditProduct.module.scss";
import purchases from "api/endPoints/purchases";
import Preloader from "components/Preloader/Preloader";
import { toast } from "react-toastify";
import history from "utils/history/history";
import { ReactComponent as ArrowBack } from "../../../../assets/img/arrowBack.svg";
import paths from "constants/paths";
import { mapRadioTree } from "components/FolderTree/utils/mapRadioTree";
import downloadLogo from "../../../../assets/img/downloadLogo.svg";
import deleteIcon from "../../../../assets/img/deleteIcon.svg";
import imgIcon from "../../../../assets/img/imgIcon.svg";
import CropperModal from "components/Popup/CropperModal";

const recursiveAddCustomId = (data) => {
  data.forEach((el) => {
    el.custom_id = el.id + el.foreign_type;
    if (el.children.length > 0) {
      return recursiveAddCustomId(el.children);
    }
  });

  return data;
};
function AddEditProduct() {
  const params = useParams();
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    subject_id: params.subjId,
    attachable_id: null,
    description: "",
    materials_count: null,
    delete_image: false,
    education_format_no_countable_element_id: params.id,
  });
  const [treeData, setTreeData] = useState();
  const [cropper, setCropper] = useState();
  const [image, setImage] = useState();
  const [cropPhoto, setCropPhoto] = useState();
  const [changeImg, setChangeImg] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);

  let imgElem = document.getElementById("cropper");

  const {
    modalWindowStore: { cropperModalOpen, seCropperModalOpen },
  } = useStore();

  const SIZE_LIMIT = 10485760;

  useEffect(() => {
    if (params.productId) {
      purchases
        .getCurrentProduct(params.productId)
        .then((res) => {
          setForm({
            name: res.name,
            price: Number.parseInt(res.price),
            description: res.description,
            materials_count: res.materials_count,
          });
          setChangeImg(res.product_image);
          return res.attachement?.id + res.attachement_type;
        })
        .then((attachmentCustomId) =>
          purchases
            .getProductsTree(params.productId)
            .then((res) =>
              setTreeData(
                mapRadioTree(attachmentCustomId, recursiveAddCustomId(res.data))
              )
            )
        );
    } else {
      purchases
        .getProductsTree()
        .then((res) => setTreeData(recursiveAddCustomId(res.data)));
    }
  }, []);

  const getCropData = (e) => {
    e.preventDefault();
    seCropperModalOpen(false);

    if (typeof cropper !== "undefined") {
      setCropPhoto(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
      fetch(cropper.getCroppedCanvas().toDataURL("image/jpeg"))
        .then((res) => res.blob())
        .then((file) => {
          setChangeImg(file);
          setForm({ ...form, delete_image: false });
        });
    }
  };

  const handleChangeImg = (e) => {
    e.preventDefault();
    const { name } = e.target;
    clearErrField(name);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    if (e.target.files[0].size <= SIZE_LIMIT) {
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(files[0]);
      delete errors.button_1_image;
    } else {
      imgElem.value = null;
      toast.info("Вес файла больше 10 МБ");
    }
  };

  const changeImgInput = () => {
    if (cropPhoto) {
      imgElem.value = "";
    }
    setChangeImg(null);
    setImage(null);
    seCropperModalOpen(false);
    setForm({ ...form, delete_image: true });
  };

  const clearErrField = (field) => {
    const checkError = { ...errors };

    if (checkError[field]) {
      delete checkError[field];
    }
    setErrors({ ...checkError });
  };

  const handleChange = (e, type) => {
    e.stopPropagation();
    const { name, value, id } = e.target;

    clearErrField(name);
    switch (name) {
      case "name":
        setForm({ ...form, name: value });
        break;
      case "price":
        setForm({ ...form, price: Number(value.replace(/\D+/, "")) });
        break;
      case "tree":
        setForm({
          ...form,
          attachable_type: type,
          attachable_id: e.target.id,
          education_format_no_countable_element_id: params.id,
        });
        setDisableSubmit(false);
        break;
      case "description":
        setForm({ ...form, description: value });
        break;
      case "count":
        setForm({ ...form, materials_count: value.replace(/\D+/, "") || null });
        break;
    }
  };

  const onBlur = async (e) => {
    const { name } = e.target;
    const validationErrors = await validation.clientValidateField(name, {
      ...form,
    });
    if (validationErrors) {
      setErrors({
        ...errors,
        ...validationErrors,
      });
    }
  };

  const saveProduct = async () => {
    let formData = new FormData();
    let jsonBody = JSON.stringify(form);
    formData.append("jsonBody", jsonBody);
    formData.append("product_image", changeImg);
    const validateErrors = await validation.clientValidateForm({
      ...form,
    });
    if (validateErrors) {
      setErrors({ ...validateErrors });
    } else {
      if (params.productId) {
        purchases
          .editProduct(params.productId, formData)
          .then(() => {
            toast.info("Изменения сохранены");
            if (params.subjId) {
              history.push(
                `/additional-purchases/material/${params.id}/subject/${params.subjId}`
              );
            } else {
              history.push(paths.additionalPurchasesDetail(params.id));
            }
          })
          .catch((error) => {
            setErrors(error.data.error.advanced);
          });
      } else {
        purchases
          .addProduct(formData)
          .then(() => {
            toast.info("Изменения сохранены");
            if (params.subjId) {
              history.push(
                `/additional-purchases/material/${params.id}/subject/${params.subjId}`
              );
            } else {
              history.push(paths.additionalPurchasesDetail(params.id));
            }
          })
          .catch((error) => {
            setErrors(error.data.error.advanced);
          });
      }
    }
  };

  const backClick = () => {
    if (params.subjId) {
      history.push(
        `/additional-purchases/material/${params.id}/subject/${params.subjId}`
      );
    } else {
      history.push(`/additional-purchases/materials/${params.id}`);
    }
  };

  return (
    <>
      {treeData ? (
        <div>
          <div className={style.header}>
            <Button
              className={style.btn_link}
              color="link"
              onClick={() => backClick()}
            >
              <ArrowBack />
              <p className={style.btn_link_text}>Назад</p>
            </Button>
            <p className={style.title}>
              {params.productId ? "Редактировать продукт" : "Добавить продукт"}
            </p>

            <div className={style.cropper_wrapper}>
              <p className={style.cropper_title}>Фото:</p>
              {changeImg ? (
                <div className={style.inputImgWrapper}>
                  <img
                    className={style.inputImgWrapperImg}
                    src={imgIcon}
                    alt="icon"
                  />
                  <p className={style.inputImgWrapperTxt}>
                    {imgElem?.files[0]?.name || changeImg}
                  </p>
                  <Button
                    className={style.inputImgWrapperBtn}
                    outline
                    color="inherit"
                    name="firstFile"
                    onClick={() => changeImgInput("firstFile")}
                  >
                    <img src={deleteIcon} alt="icon" />
                  </Button>
                </div>
              ) : (
                <>
                  <button
                    className={style.cropper_btn}
                    onClick={() => seCropperModalOpen(true)}
                  >
                    <img
                      className={style.cropper_img}
                      color="inherit"
                      src={downloadLogo}
                      alt=""
                    />
                    <span>Прикрепить изображение</span>
                  </button>
                  <Label className={style.form_lable_second}>
                    Размер файла не должен превышать 10 МБ.
                    <br />
                    Допустимый формат файла: JPG, JPEG, PNG
                  </Label>
                </>
              )}
              <Input
                style={{ display: "none" }}
                type="file"
                name="cropper"
                id="cropper"
                accept=".jpg,.jpeg,.png"
                onChange={handleChangeImg}
                invalid={!!errors?.file}
              />
            </div>
            <Form>
              <FormGroup>
                <Label className={style.form_lable_first} htmlFor="name">
                  Название: *
                </Label>
                <Input
                  name="name"
                  id="name"
                  value={form.name}
                  placeholder="Введите название"
                  className={style.form_input}
                  onChange={handleChange}
                  onBlur={onBlur}
                  invalid={!!errors?.name}
                />
                <FormFeedback>{errors?.name}</FormFeedback>
                <Label className={style.form_lable_second}>
                  Введите от 2 до 150 символов
                </Label>
              </FormGroup>
              <FormGroup>
                <Label className={style.form_lable_first} htmlFor="description">
                  Описание: *
                </Label>
                <Input
                  name="description"
                  id="description"
                  value={form.description}
                  placeholder="Введите описание"
                  className={style.form_input}
                  onChange={handleChange}
                  onBlur={onBlur}
                  invalid={!!errors?.description}
                />
                <FormFeedback>{errors?.description}</FormFeedback>
                <Label className={style.form_lable_second}>
                  Введите от 2 до 300 символов
                </Label>
              </FormGroup>
              <div className={style.form_bottom_wrapper}>
                <FormGroup className={style.form_group}>
                  <Label htmlFor="price" className={style.form_lable_first}>
                    Цена: *
                  </Label>
                  <div className={style.form_price_wrapper}>
                    <Input
                      // type="number"
                      id="price"
                      name="price"
                      min={0}
                      value={form.price}
                      pattern="^[0-9]"
                      placeholder="Введите цену"
                      className={style.form_price}
                      onChange={handleChange}
                      onBlur={onBlur}
                      invalid={!!errors?.price}
                    />
                    <Price className={style.form_price_img} />
                    <FormFeedback>{errors?.price}</FormFeedback>
                  </div>

                  <Label className={style.form_lable_second}>
                    Введите от 1 до 6 символов
                  </Label>
                </FormGroup>
                <FormGroup className={style.form_group}>
                  <Label htmlFor="count" className={style.form_lable_first}>
                    Количество материалов:
                  </Label>
                  <Input
                    id="count"
                    name="count"
                    min={0}
                    value={form.materials_count}
                    pattern="^[0-9]"
                    placeholder="Введите количество"
                    className={style.form_price}
                    onChange={handleChange}
                  />
                  <Price className={style.form_price_img} />
                  <FormFeedback>{errors?.price}</FormFeedback>
                </FormGroup>
              </div>
            </Form>
            <p className={style.tree_title}>Добавьте ресурс или папку *</p>
            <div className={style.tree_wrapper}>
              <FolderTree data={treeData} radio toggleChange={handleChange} />
            </div>
            <Button
              // disabled={disableSubmit}
              className={style.form_submit}
              color="primary"
              onClick={() => saveProduct()}
            >
              {params.productId ? "Сохранить" : "Добавить"}
            </Button>
          </div>
        </div>
      ) : (
        <Preloader />
      )}
      {cropperModalOpen && (
        <CropperModal
          image={image}
          setCropper={setCropper}
          getCropData={getCropData}
          cancelClick={changeImgInput}
          lableFor="cropper"
          hint="Выберите область, которая будет отображаться в магазине"
        />
      )}
    </>
  );
}

export default observer(AddEditProduct);

const actions = {
    setValue: 'setValue',
    reset: 'reset',
};

const initialValue = { old_password: '', new_password: '', new_password_confirmation: ''};

function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case actions.setValue:
            return {
                ...state,
                [payload.name]: payload.value,
            };
        case actions.reset:
            return initialValue;
        default:
            return state;
    }
}

export {
    actions as dataActions,
    reducer as dataReducer,
    initialValue as dataInitialValue,
};

import ModalWindowStore from "./modalWindowStore";
import UserStore from "./userStore";
import CreateLessonStore from "./createLessonStore";
import DownLoadTimeSheetStore from "./DownLoadTimeSheetStore";

class RootStore {
    constructor(context) {
        this.userStore = new UserStore(context);
        this.modalWindowStore = new ModalWindowStore(context);
        this.createLessonStore = new CreateLessonStore(context);
        this.downLoadTimeSheetStore = new DownLoadTimeSheetStore(context);
    }
    getStore = (storeName) => this[storeName];
}
export default RootStore;

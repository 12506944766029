import {useReducer, useState} from "react";
import {dataActions, dataReducer, dataInitialValue} from '../reducers/dataReducer';
import {errorActions, errorReducer, errorInitialValue} from '../reducers/errorsReducer';
import hasErrors from "../utils/validate";
import authApi from "../../../api/endPoints/login";
import history from "../../../utils/history/history";
import {toast} from "react-toastify";

export default function useChangePassword() {
    const [loading, setLoading] = useState(false)

    const [errors, errorsDispatch] = useReducer(errorReducer, errorInitialValue)
    const [data, dataDispatch] = useReducer(dataReducer, dataInitialValue);

    const onChange = (event) => {
        const {name, value} = event.target;
        dataDispatch({type: dataActions.setValue, payload: {name, value}});
    }

    const onSubmit = () => {
        setLoading(true)
        const errors = hasErrors(data)
        if (errors) {
            setLoading(false)
            let errorsField = {}
            errors.details.forEach((error) => {
                errorsField[error.context.label] = error.message
            })
            errorsDispatch({
                type: errorActions.setErrors,
                payload: errorsField
            });
        } else {
            errorsDispatch({
                type: errorActions.resetErrors
            });
            authApi.changePassword(data)
                .then((res) => {
                    toast.success("Пароль успешно сменен");
                    history.push("/");
                })
                .catch((errors) => {
                    errorsDispatch({
                        type: errorActions.setErrors,
                        payload: errors.data.error.advanced
                    });
                })
                .finally(() => setLoading(false))
        }
    }

    return {
        data,
        errors,
        onSubmit,
        onChange,
        loading
    }
}

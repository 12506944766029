/* eslint-disable import/no-anonymous-default-export */
import axiosInstance from "..";
import apiList from "../../constants/apiList";

export default {
  async getEducationForms() {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.educationForms,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async getCurrentForm(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.currentEducationForm(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async publishForm(id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.publishForm(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async unPublishForm(id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.unPublishForm(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async duration() {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.duration,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async editForm(id, data, type) {
    const updateData = { ...data };

    if (type === "FREE") {
      delete updateData.price;
    }

    if (updateData.duration === null) {
      delete updateData.duration;
    }

    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.currentEducationForm(id),
        data: updateData,
        params: {
          _method: "PUT",
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async countableElement(itemId, count, id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.countableElements(id),
        data: {
          countable_element_id: itemId,
          count,
        },
        params: {
          _method: "PUT",
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async unCountableElement(itemId, count, id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.noCountableElements(id),
        data: {
          no_countable_element_id: itemId,
          count,
        },
        params: {
          _method: "PUT",
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async createFormForUser(data) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.createFormForUser,
        data,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getAllFormsForUser(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.createFormForUser,
        params: { user_id: id },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getFormForUser(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getFormForUser(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async activateFormForUser(id) {
    try {
      const response = await axiosInstance({
        method: "PUT",
        url: apiList.activateFormForUser(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deactivateFormForUser(id) {
    try {
      const response = await axiosInstance({
        method: "PUT",
        url: apiList.deactivateFormForUser(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deleteFormForUser(id) {
    try {
      const response = await axiosInstance({
        method: "DELETE",
        url: apiList.deleteFormForUser(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async updateCountable(itemId, count, id) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.updateCountable(id),
        data: {
          countable_id: itemId,
          count,
        },
        params: {
          _method: "PUT",
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async editFormForUser(id, data) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: apiList.deleteFormForUser(id),
        params: {
          _method: "PUT",
        },
        data: {
          start_date: data[0],
          end_date: data[1],
        },
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};

import { makeAutoObservable } from "mobx";

class ModalWindowStore {
    constructor() {
        this.isNotificationOpen = false;
        this.notification = null;
        this.isDeleteModalOpen = false;
        this.isBlockModalOpen = false;
        this.isUnBlockModalOpen = false;
        this.isCloseModalOpen = false;
        this.isChangeTabsModal = false;
        this.cropperModalOpen = false;
        this.addFolderOpen = false;
        this.deleteFolderOpen = false;
        this.editFolderOpen = false;
        this.addResourceOpen = false;
        this.deleteResourceOpen = false;
        this.editResourceOpen = false;
        this.isEditFormModal = false;
        this.isFormPublic = false;
        this.isAddEditItemModalOpen = false;
        this.isDeleteItemModalOpen = false;
        this.isAdditionalPruchasePriceOpen = false;
        this.isActivationFormModalOpen = false;
        this.isDeleteFormModalOpen = false;
        this.isChangeDurationModalOpen = false;
        this.isDeleteUserFormOpen = false;
        this.isExitWithoutActivateOpen = false;
        this.isDeleteProductModalOpen = false;
        this.isTimeSheetCreateModalOpen = false;
        this.isTimeSheetDeleteModalOpen = false;
        this.isChangeEventStatusModalOpen = false;
        this.isDownloadTimeSheetModalOpen = false;
        makeAutoObservable(this);
    }

    setIsNotificationOpen = (value) => {
        this.isNotificationOpen = value;
    };

    setNotification = (value) => {
        this.notification = value;
    };

    setIsDeleteModalOpen = (value, id) => {
        this.isDeleteModalOpen = value ? { id } : null;
    };

    setIsBlockModalOpen = (value, id) => {
        this.isBlockModalOpen = value ? { id } : null;
    };

    setIsDeleteItemModalOpen = (value, id, name) => {
        this.isDeleteItemModalOpen = value ? { id, name } : null;
    };

    setIsAdditionalPruchasePriceOpen = (value, id, price) => {
        this.isAdditionalPruchasePriceOpen = value ? { id, price } : null;
    };
    setIsUnBlockModalOpen = (value, id) => {
        this.isUnBlockModalOpen = value ? { id } : null;
    };

    setIsCloseModalOpen = (value) => {
        this.isCloseModalOpen = value;
    };

    setIsChangeTabsModal = (value, page) => {
        this.isChangeTabsModal = value ? { page } : null;
    };

    seCropperModalOpen = (value) => {
        this.cropperModalOpen = value;
    };

    setAddFolder = (value, id) => {
        this.addFolderOpen = value ? { id } : null;
    };

    setDeleteFolder = (value, item) => {
        this.deleteFolderOpen = value ? { item } : null;
    };

    setEditFolder = (value, item) => {
        this.editFolderOpen = value ? { item } : null;
    };

    setAddResource = (value, id) => {
        this.addResourceOpen = value ? { id } : null;
    };

    setDeleteResource = (value, item) => {
        this.deleteResourceOpen = value ? { item } : null;
    };

    setEditResource = (value, item) => {
        this.editResourceOpen = value ? item : null;
    };

    setFileResource = (value, id, name) => {
        this.addFileResource = value ? { id, name } : null;
    };
    setIsEditFormModal = (value) => {
        this.isEditFormModal = value;
    };

    setIsFormPublic = (value, type) => {
        this.isFormPublic = value ? { type } : null;
    };

    setIsAddEditItemModalOpen = (value, id, type, item) => {
        this.isAddEditItemModalOpen = value ? { id, type, item } : null;
    };

    setIsDeleteItemModalOpen = (value, id, name) => {
        this.isDeleteItemModalOpen = value ? { id, name } : null;
    };

    setIsActivationFormModalOpen = (value, id, status, name) => {
        this.isActivationFormModalOpen = value ? { id, status, name } : null;
    };

    setIsDeleteFormModalOpen = (value, id, name) => {
        this.isDeleteFormModalOpen = value ? { id, name } : null;
    };

    setIsChangeDurationModalOpen = (value, date) => {
        this.isChangeDurationModalOpen = value ? { date } : null;
    };

    setIsDeleteUserFormOpen = (value) => {
        this.isDeleteUserFormOpen = value;
    };

    setIsExitWithoutActivateOpen = (value) => {
        this.isExitWithoutActivateOpen = value;
    };

    setIsDeleteProductModalOpen = (value, id, name) => {
        this.isDeleteProductModalOpen = value ? { id, name } : null;
    };

    setIsTimeSheetCreateModalOpen = (value, item) => {
        this.isTimeSheetCreateModalOpen = value ? { item } : null;
    };

    setIsTimeSheetDeleteModalOpen = (value, id) => {
        this.isTimeSheetDeleteModalOpen = value ? { id } : null;
    };

    setIsChangeEventStatusModalOpen = (value, item) => {
        this.isChangeEventStatusModalOpen = value ? { item } : null;
    };

    setIsDownloadTimeSheetModalOpen = (value) => {
        this.isDownloadTimeSheetModalOpen = value;
    };

    setIsLeavePageModalOpen = (value) => {
        this.isLeavePageModalOpen = value;
    };
}

export default ModalWindowStore;

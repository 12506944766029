import React from "react";
import LessonCard from "../LessonCard";
import NotificationCard from "../NotificationCard";

const View = (props) => {
    const { lessons } = props;

    return (
        <td key={lessons} style={{ position: "relative" }}>
            {lessons.map((lesson) =>
                lesson.type.id === 6 ? (
                    <NotificationCard key={lesson} notification={lesson} />
                ) : (
                    <LessonCard key={lesson} lesson={lesson} />
                ),
            )}
        </td>
    );
};

export default View;

import React, { useState, useEffect } from "react";
import RootNode from "./RootNode";
import * as style from "./style/folder-tree.module.scss";
import { NODE_TYPES } from "./state/constants";
import { mapRadioTree } from "./utils/mapRadioTree";

function FolderTree(props) {
  const { data, onChange, radio, toggleChange, purchases } = props;
  const [folderTree, setFolderTree] = useState([]);

  useEffect(() => {
    setFolderTree(data);
  }, []);

  const onTreeChange = (id, tree, event) => {
    if (event.type !== "initialization") {
      const newFolderTree = folderTree.map((item) => {
        return item.custom_id === id ? tree : item;
      });
      setFolderTree(newFolderTree);
      onChange?.(newFolderTree);
    }
  };

  const handleToggleChange = (e, type) => {
    const newFolderTree = mapRadioTree(e.target.value, folderTree);
    setFolderTree(newFolderTree);
    onChange?.(newFolderTree);
    toggleChange?.(e, type);
  };
  return (
    <div className={style.tableContainer}>
      <table className={style.table}>
        <tbody>
          {folderTree
            ? folderTree.map((folderTreeItem, index) => {
                let isPrevFolderOpen = false;
                const isFirstElement = index === 0;
                const isLastElement = index === folderTree.length - 1;
                let hasChild = false;
                let prevFolderHasChild = false;

                if (folderTreeItem.type === NODE_TYPES.FOLDER) {
                  hasChild = !!folderTreeItem.children.length;
                }

                if (index > 0) {
                  const prevFolderTreeItem = folderTree[index - 1];
                  if (prevFolderTreeItem.type === NODE_TYPES.FOLDER) {
                    isPrevFolderOpen = prevFolderTreeItem.isOpen;
                    prevFolderHasChild = !!prevFolderTreeItem.children.length;
                  }
                }
                return (
                  <RootNode
                    key={
                      folderTreeItem.type
                        ? `${folderTreeItem.foreign_type}${folderTreeItem.id}${folderTreeItem.type}`
                        : `${folderTreeItem.foreign_type}${folderTreeItem.id}`
                    }
                    data={folderTreeItem}
                    isFirstElement={isFirstElement}
                    isLastElement={isLastElement}
                    isPrevFolderOpen={isPrevFolderOpen}
                    hasChild={hasChild}
                    prevFolderHasChild={prevFolderHasChild}
                    onTreeChange={onTreeChange}
                    fullData={folderTree}
                    radio={radio}
                    toggleChange={handleToggleChange}
                    purchases={purchases}
                  />
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default FolderTree;

import apiList from "constants/apiList";
import axiosInstance from "..";
import Endpoints from "../../constants/api/resources";

const Resources = {
  async resources(id, page) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: Endpoints.list(id),
        params: {
          page: page,
          per_page: "20",
        },
      });
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deleteResource(res, id) {
    try {
      await axiosInstance({
        method: "delete",
        url: Endpoints.delete(res, id),
      });
    } catch (error) {
      throw error.response;
    }
  },
  async createResource(id, FormData) {
    try {
      const response = await axiosInstance({
        method: "POST",
        url: Endpoints.create(id),
        data: FormData,
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async downloadResource(id, hash) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.downloadFile(id),
        params: { hash },
      });
      return response.data.url;
    } catch (error) {
      throw error.response;
    }
  },

  async getResourcesFile(id) {
    try {
      const response = await axiosInstance({
        method: "GET",
        url: apiList.getResourcesFile(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
  async deleteResourceFile(id) {
    try {
      const response = await axiosInstance({
        method: "DELETE",
        url: apiList.getResourcesFile(id),
      });
      return response.data;
    } catch (error) {
      throw error.response;
    }
  },
};

export default Resources;

import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Label, FormText, FormGroup, Button } from "reactstrap";
import resApi from "api/endPoints/resources";
import paths from "constants/paths";
import { useForm } from "react-hook-form";
import Preloader from "components/Preloader/Preloader";
import * as style from "../style/CreateMaterial.module.scss";
import * as stylefile from "./TabFile.module.scss";
import { ReactComponent as DownloadLogo } from "assets/img/downloadLogo.svg";
import deleteIcon from "assets/img/deleteIcon.svg";
import imgIcon from "../../../../../assets/img/file.svg";
import { useHistory } from "react-router";
import { useStore } from "../../../../../store";

function TabFile({ resid }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [formFile, setFormFile] = useState({});
	const [fileErrors, setFileErrors] = useState("");
	const [fileObj, setFileObj] = useState({});
	const {
		userStore: { trainingNames },
		modalWindowStore: { setIsNotificationOpen, setNotification },
	} = useStore();
	const history = useHistory();
	const folderName = trainingNames.find((v) => v.id === Number(resid))?.name;
	let downloadFile = document.getElementById("download");

	const handleChange = (event) => {
		setFileObj(event.target.files[0]);
		const file = event.target.files[0];
		if (file != null && file.size <= 50000000) {
			setFormFile(file);
			setIsLoaded(true);
			setFileErrors("");
		} else {
			setFileErrors("Ошибка, размер файла должен быть меньше 50 МБ");
		}
	};

	const onSubmit = (data) => {
		setLoading(true);
		setIsLoaded(false);
		let formData = new FormData();
		formData.append(
			"jsonBody",
			JSON.stringify({ ...data, type: "FILE", src_url: "" })
		);
		formData.append("src_file", formFile);
		resApi
			.createResource(resid, formData)
			.then((response) => {
				setIsNotificationOpen(true);
				setNotification("Материал успешно добавлен");
				let timer = setTimeout(() => {
					setIsNotificationOpen(false);
					clearTimeout(timer);
				}, 3000);
				history.push(paths.resource(resid));
			})
			.catch((error) => {
				setLoading(false);
				new Error(error);
			});
	};

	const cancelFile = () => {
		fileObj.value = "";
    downloadFile.value=""
		setFormFile({});
		setIsLoaded(false);
	};
	

	return (
		<>
			{!loading ? (
				<Form onSubmit={handleSubmit(onSubmit)}>
					<FormGroup>
						<Label>Введите название материала:</Label>

						<input
							className={
								errors.name
									? "form_control__input form_control__error"
									: "form_control__input"
							}
							{...register("name", {
								required: true,
								minLength: 2,
								maxLength: 100,
							})}
							placeholder="Введите название"
						/>

						{errors.name?.type === "required" && (
							<span style={{ color: "red" }}>
								Поле обязательно для заполнения
							</span>
						)}
						{errors.name?.type === "minLength" && (
							<span style={{ color: "red" }}>
								Введите корректное значение, должно быть больше 2 символов
							</span>
						)}
						{errors.name?.type === "maxLength" && (
							<span style={{ color: "red" }}>
								Введите корректное значение, должно быть меньше 100 символов
							</span>
						)}

						<FormText>Введите от 2 до 100 символов</FormText>
					</FormGroup>
					<FormGroup className="mt-5">
						{isLoaded ? (
							<div className={stylefile.loaded_info}>
								<img
									className={style.inputImgWrapperImg}
									src={imgIcon}
									alt="icon"
								/>
								<div className={style.inputImgWrapperTxt}>{formFile?.name}</div>
								<Button
									className={style.inputImgWrapperBtn}
									outline
									color="inherit"
									name="firstImgDeleteBtn"
									onClick={cancelFile}
								>
									<img src={deleteIcon} alt="icon" />
								</Button>
							</div>
						) : (
							<>
								<Label>Загрузите файл:</Label>
								<Label className={stylefile.fileLable} for="download">
									<DownloadLogo className={stylefile.fileImg} />
									Прикрепить файл
								</Label>
								<input
									className={stylefile.fileInput}
									type="file"
									id="download"
									{...register("file", {
										required: true,
										onChange: handleChange,
									})}
								/>

								{errors.file?.type === "required" && (
									<span style={{ color: "red" }}>
										Поле обязательно для заполнения
									</span>
								)}
							</>
						)}

						<div style={{ color: "red" }}>{fileErrors}</div>
						<FormText>Размер файла не должен превышать 50 МБ</FormText>
					</FormGroup>
					<div className={style.material_block}>
						<span className={style.material_block__text}>
							Материал будет добавлен в раздел:
						</span>
						{folderName}
					</div>
					<Button
						className={style.button__create_material}
						color="primary"
						type="submit"
					>
						Добавить материал
					</Button>
				</Form>
			) : (
				<div className="row">
					<Preloader />
				</div>
			)}
		</>
	);
}

export default observer(TabFile);

import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import { DebounceInput } from 'react-debounce-input';

import Preloader from '../../../components/Preloader/Preloader';
import Pagination from '../../Pagination/Pagination';

import getFolders from '../../../api/endPoints/folders';

import { Button } from 'reactstrap';

import * as style from './ResourcesComponent.module.scss';
import iconsUpload from '../../../assets/img/iconsUpload.svg';
import deleteIcon from '../../../assets/img/deleteIcon.svg';
import linkIcon from '../../../assets/img/arrow-up.svg';
import fileIcon from '../../../assets/img/file.svg';
import { ReactComponent as SearchIcon } from '../../../assets/img/search.svg';
import pen from '../../../assets/img/pen.svg';
import AddResources from './AddResources/AddResources';
import { observer } from 'mobx-react-lite';
import DeleteResources from './DeleteResources/DeleteResources';
import EditResources from './EditResources/EditResources';

function ResourcesComponent({ id, resources }) {
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [resourcesList, setResourcesList] = useState();
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [filter, setFilters] = useState({
    name: '',
  });

  const [currentNumber, setCurrentNumber] = useState();

  const {
    modalWindowStore: {
      setAddResource,
      setDeleteResource,
      setEditResource,
      addResourceOpen,
      deleteResourceOpen,
      editResourceOpen,
    },
  } = useStore();

  useEffect(() => {
    setLoading(true);
    getFolders.getResources(filter, page).then((response) => {
      setCurrentNumber((response.current_page - 1) * response.per_page);
      setResourcesList(response.items);
      setPaginationLoading(false);
      setLoading(false);
      if (response.current_page > response.last_page) {
        setPage(response.last_page);
      }
      setLastPage(response.last_page);
    });
  }, [page, lastPage, filter, resources, modalLoading]);

  const addResource = () => {
    setModalLoading(true);
  };

  const deleteResource = () => {
    setModalLoading(true);
  };

  const editResource = () => {
    setModalLoading(true);
  };

  const prevPage = (number) => {
    setPaginationLoading(true);
    setPage(page - number);
  };

  const nextPage = (number) => {
    setPaginationLoading(true);
    setPage(page + number);
  };

  const lastPageFunction = (number) => {
    setPaginationLoading(true);
    setPage(number);
  };

  const downloadResource = (id) => {
    getFolders.downloadResource(id).then((response) => {
      const link = document.createElement('a');
      link.href = response.url;
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      {resourcesList ? (
        <div>
          <div className={style.titleHead}>
            <p>Ресурсы</p>
            <Button
              className={style.uploadResource}
              outline
              color="inherit"
              onClick={() => setAddResource(true)}>
              <img src={iconsUpload} alt="icon" />
              Загрузить ресурс
            </Button>
          </div>

          <div className={style.listHeaderInput}>
            <p className={style.listHeaderInput_title}>Поиск по названию:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                name="name"
                className={style.findMaterial}
                minLength={1}
                debounceTimeout={800}
                onChange={(event) => setFilters({ name: event.target.value.trim('-') })}
                placeholder="Введите название"
              />
              <div className={style.searchImg}>
                <SearchIcon />
              </div>
            </div>
          </div>

          {loading ? (
            <div className={`row ${style.lessonsPreloader}`}>
              <Preloader />
            </div>
          ) : (
            <>
              <table className={style.tableWidth}>
                {resourcesList && resourcesList.length === 0 ? (
                  <div>
                    <p className={style.lessonsListNull}>Ничего не найдено</p>
                  </div>
                ) : (
                  <thead>
                    <tr className={style.tableBorder}>
                      <th className={style.tableHeader_number}>Номер </th>
                      <th className={style.tableHeader_name}>Название</th>
                      <th className={style.tableHeader}></th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {resourcesList &&
                    resourcesList.map((item, index) => (
                      <tr key={item.id} className={style.tableBorder}>
                        <td>{index + 1 + currentNumber}</td>
                        {item.type === 'LINK' ? (
                          <td className={style.itemBlockName}>
                            <a href={item.src_url} className={style.itemLink} target="_blanck">
                              {item.name}
                            </a>
                            <img src={linkIcon} alt="linkIcon" />
                          </td>
                        ) : (
                          <td
                            className={style.itemBlockName}
                            onClick={() => downloadResource(item.id)}>
                            <a className={style.itemName}>{item.name}</a>
                            <img src={fileIcon} alt="fileIcon" />
                          </td>
                        )}
                        <td className={style.buttonDisplay}>
                          <Button
                            color="inherit"
                            className={style.tableDeleteBtn}
                            onClick={() => setEditResource(true, item)}>
                            <img src={pen} alt="pen" />
                          </Button>
                          <Button
                            color="inherit"
                            className={style.tableDeleteBtn}
                            onClick={() => setDeleteResource(true, item)}>
                            <img src={deleteIcon} alt="Delete" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className={style.pagination}>
                <Pagination
                  page={page}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  lastPage={lastPage}
                  lastPageFunction={lastPageFunction}
                />
              </div>
            </>
          )}

          {addResourceOpen && (
            <AddResources
              addResource={addResource}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}
          {deleteResourceOpen && (
            <DeleteResources
              deleteResource={deleteResource}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}
          {editResourceOpen && (
            <EditResources
              editResource={editResource}
              loading={modalLoading}
              setModalLoading={setModalLoading}
            />
          )}
        </div>
      ) : (
        <div className="row">
          <Preloader />
        </div>
      )}
    </>
  );
}

export default observer(ResourcesComponent);

import React from "react";
import deleteEvent from "../../../api/endPoints/educationEvents";
import { toast } from "react-toastify";
import Controller from "./Controller.jsx";

const Connector = (props) => {
    const { eventId, onClose } = props;

    const deleteTeacherEvent = () => {
        deleteEvent.deleteEvent(eventId).then(() => {
            onClose();
            window.location.reload();
            toast.info("Изменения сохранены");
        });
    };

    return <Controller deleteEvent={deleteTeacherEvent} onClose={onClose} />;
};

export default Connector;

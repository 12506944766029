import React, { useMemo } from "react";

import ModalDeleteEvent from "../ModalDeleteEvent";
import PopoverMenu from "./PopoverMenu";
import ModalAdd from "../ModalAdd";
import styles from "./styles.module.scss";

const View = (props) => {
    const {
        lesson,
        colorsStyle,
        childName,
        visibleStudent,
        lessonType,
        srcImg,
        time,
        mentorActions,
        closeDeleteModalOpen,
        isDeleteModalOpen,
        isEditModalOpen,
        closeEditModalOpen,
        modalTitle,
        modalButton,
    } = props;

    const lessonAvatar = useMemo(() => {
        if (lessonType)
            return (
                <div className={styles.lesson__content__top__icon}>
                    <img src={srcImg} alt="lessonAvatar" />
                    <PopoverMenu target={`lesson-${lesson.id}`} actions={mentorActions} />
                </div>
            );
        return null;
    }, [lessonType, srcImg]);

    const addModal = isEditModalOpen && (
        <ModalAdd
            onClose={closeEditModalOpen}
            isUpdate
            title={modalTitle}
            buttonText={modalButton}
        />
    );

    const deleteModal = isDeleteModalOpen && (
        <ModalDeleteEvent onClose={closeDeleteModalOpen} eventId={lesson.id} />
    );

    return (
        <>
            {deleteModal}
            {addModal}
            <div className={styles.lesson} style={colorsStyle} id={`lesson-${lesson.id}`}>
                <div className={styles.lesson__content}>
                    <div className={styles.lesson__content__top}>
                        <span
                            tabIndex="0"
                            role="button"
                            className={styles.lesson__content__top__title}
                            id={`lesson-title-${lesson.id}`}
                            style={
                                lesson.lesson_url
                                    ? { color: "#0000EE", cursor: "pointer" }
                                    : { cursor: "unset" }
                            }
                            title={lesson?.name ?? lesson?.type?.name}>
                            {lesson?.name ?? lesson?.type?.name}
                        </span>

                        {lessonAvatar}
                    </div>

                    {!!lesson?.subject?.name && (
                        <span
                            id={`lesson-subject-${lesson.id}`}
                            className={styles.lesson__content__subtitle}
                            title={lesson.subject.name}>
                            {lesson.subject.name}
                        </span>
                    )}
                    {!!visibleStudent && (
                        <span className={styles.lesson__content__student__name}>{childName}</span>
                    )}
                    <div className={styles.lesson__content__description}>
                        {!!lesson.description && (
                            <span
                                id={`lesson-description-${lesson.id}`}
                                className={styles.lesson__description__text}
                                title={lesson.description}>
                                {lesson.description}
                            </span>
                        )}
                    </div>
                </div>
                <span className={styles.lesson__time}>{time}</span>
            </div>
        </>
    );
};

export default View;

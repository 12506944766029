import React, { useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { DebounceInput } from "react-debounce-input";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { ReactComponent as SearchIcon } from "../../../../assets/img/search.svg";
import { ReactComponent as Pencil } from "../../../../assets/img/pen.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/img/deleteIcon.svg";
import { ReactComponent as FileBlue } from "../../../../assets/img/fileBlue.svg";
import { ReactComponent as File } from "../../../../assets/img/file.svg";
import { ReactComponent as Folder } from "../../../../assets/img/folder.svg";
import history from "../../../../utils/history/history";
import * as style from "./style/PurchaseMaterialDetail.module.scss";
import { useLocation, useParams } from "react-router";
import purchases from "api/endPoints/purchases";
import { toast } from "react-toastify";
import Preloader from "components/Preloader/Preloader";
import Pagination from "components/Pagination/Pagination";
import { useStore } from "store";
import DeleteProduct from "../Modal/DeleteProduct";
import { observer } from "mobx-react-lite";
import BreadCrumbs from "components/Breadcrumbs/Breadcrumbs";
import numberOfProduct from "utils/numberOfProduct";

function PurchaseMaterialDetail() {
  const {
    modalWindowStore: { isDeleteProductModalOpen, setIsDeleteProductModalOpen },
  } = useStore();
  const params = useParams();
  const [data, setData] = useState();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [filter, setFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    setFilterLoading(true);
    purchases
      .getCurrentProductsList(filter, params.id, page, params.subjId)
      .then((res) => {
        setBreadCrumbs(res.data.breadcrumbs);
        setData(res.data.items);
        setFilterLoading(false);
        setLastPage(res.data.last_page);
      });
  }, [filter, page, loading]);

  const changeToggle = (index, id, status) => {
    let newMaterials = [...data];
    newMaterials[index].is_active = !status;
    setData(newMaterials);
    if (status) {
      purchases
        .unpublishNoCountableElements(id)
        .then(() => toast.info("Изменения сохранены"));
    } else {
      purchases
        .publishNoCountableElements(id)
        .then(() => toast.info("Изменения сохранены"));
    }
  };

  const prevPage = (number) => {
    setPage(page - number);
  };

  const nextPage = (number) => {
    setPage(page + number);
  };

  const lastPageFunction = (number) => {
    setPage(number);
  };

  const breadcrumbsNew = () => {
    if (params.subjId) {
      let firstItem = {
        name: "Магазин дополнительных продуктов",
        path: `/additional-purchases`,
      };
      let secondItem = {
        name: breadCrumbs[0].name,
        path: `/additional-purchases/material/${params.id}`,
      };
      let thirdItem = {
        name: breadCrumbs[1].name,
        path: `/additional-purchases/material/${params.id}/subject/${params.subjId}`,
      };

      return [firstItem, secondItem, thirdItem];
    } else {
      let firstItem = {
        name: "Магазин дополнительных продуктов",
        path: `/additional-purchases`,
      };
      let secondItem = {
        name: breadCrumbs[0].name,
        path: `/additional-purchases/materials/${params.id}`,
      };
      return [firstItem, secondItem];
    }
  };

  const filtersChange = (event) => {
    setPage(1);
    setFilter({ ...filter, name: event.target.value });
  };

  return (
    <>
      {data ? (
        <div>
          <div className={style.title_header}>
            <BreadCrumbs breadCrumbs={breadcrumbsNew()} pathname={pathname} />
            <p className={style.title}>{breadCrumbs[0]?.name}</p>
          </div>
          <div className={style.header_wrapper}>
            <p className={style.header_title}>Продукты</p>
            <Button
              outline
              color="link"
              className={style.header_add_button}
              onClick={() => history.push(`${pathname}/add-product`)}
            >
              <FileBlue /> Добавить из каталога
            </Button>
          </div>
          <div>
            <p className={style.title_debounce}>Поиск по названию:</p>
            <div className={style.searhWrapper}>
              <DebounceInput
                type="search"
                className={style.findUsers}
                placeholder="Введите название"
                value={filter?.name}
                minLength={1}
                debounceTimeout={800}
                onChange={(event) => filtersChange(event)}
              />
              <SearchIcon className={style.searchImg} />
            </div>
          </div>
          <div>
            <Label htmlFor="status" className={style.title_debounce}>
              Активность
            </Label>
            <Input
              id="status"
              name="status"
              type="select"
              className={style.select}
              onChange={(event) => {
                setPage(1);
                setFilter({ ...filter, is_active: event.target.value });
              }}
            >
              <option value="">Все</option>
              <option value="true">Активен</option>
              <option value="false">Не активен</option>
            </Input>
          </div>
          {filterLoading ? (
            <Preloader />
          ) : (
            <>
              <table className={style.table_width}>
                {data.length === 0 ? (
                  <div>
                    <p className={style.lessons_Null}>Ничего не найдено...</p>
                  </div>
                ) : (
                  <>
                    <thead>
                      <tr className={style.table_header}>
                        <td className={style.table_number_header}>Номер</td>
                        <td className={style.table_name_header}>Название</td>
                        <td className={style.table_price_header}>Цена</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={item.id} className={style.table_body}>
                          <td className={style.table_number}>
                            {numberOfProduct(index, page)}
                          </td>
                          <td className={style.table_name}>
                            {item.name}
                            {item.attachement_type === "FOLDER" ? (
                              <Folder />
                            ) : (
                              <File />
                            )}
                          </td>
                          <td className={style.table_price}>
                            {Number.parseInt(item.price)}р./1шт.
                          </td>
                          <td>
                            <FormGroup className={style.checkbox}>
                              <FormCheck
                                type="switch"
                                id={`toggle${item.id}`}
                                name={`toggle${item.id}`}
                                checked={item.is_active}
                                onChange={() =>
                                  changeToggle(index, item.id, item.is_active)
                                }
                              />
                              {item.is_active ? (
                                <Label
                                  for={`toggle${item.id}`}
                                  className={style.lable_active}
                                >
                                  Активен
                                </Label>
                              ) : (
                                <Label
                                  for={`toggle${item.id}`}
                                  className={style.lable_unactive}
                                >
                                  Не активен
                                </Label>
                              )}
                            </FormGroup>
                          </td>
                          <td>
                            <Button
                              color="link"
                              outline
                              className="mr-5"
                              onClick={() =>
                                history.push(
                                  `${pathname}/edit-product/${item.id}`
                                )
                              }
                            >
                              <Pencil />
                            </Button>
                            <Button
                              color="link"
                              outline
                              onClick={() =>
                                setIsDeleteProductModalOpen(
                                  true,
                                  item.id,
                                  item.name
                                )
                              }
                            >
                              <DeleteIcon />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                )}
              </table>
              <div className={style.lessonPagination}>
                <Pagination
                  page={page}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  lastPage={lastPage}
                  lastPageFunction={lastPageFunction}
                />
              </div>
              {isDeleteProductModalOpen && (
                <DeleteProduct loading={loading} setLoading={setLoading} />
              )}
            </>
          )}
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
}

export default observer(PurchaseMaterialDetail);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { weekDates, shiftWeekDates, dateFormat, isCurrentWeek, monthDates } from "../helpers";
import { useStore } from "store";
import View from "./View";

const Controller = (props) => {
    const { selectDate, setSelectDate } = props;

    const {
        downLoadTimeSheetStore: { setWeekDate, setMonthDate },
    } = useStore();

    const title = useMemo(() => {
        setWeekDate({ start: selectDate[0], end: selectDate[6] });
        const selectMonth = monthDates(selectDate[0]);
        setMonthDate({
            start: selectMonth[0],
            end: selectMonth[selectMonth.length - 1],
        });

        if (isCurrentWeek(selectDate)) return "Текущая неделя";
        const start = dateFormat(selectDate[0]);
        const end = dateFormat(selectDate[6]);
        return `${start} - ${end}`;
    }, [selectDate]);

    const onClickArrow = (direction) => setSelectDate(shiftWeekDates(direction, selectDate));
    const onCurrentWeek = () => setSelectDate(weekDates(new Date()));

    return (
        <View
            title={title}
            onClickArrow={onClickArrow}
            isCurrentWeek={isCurrentWeek(selectDate)}
            onCurrentWeek={onCurrentWeek}
        />
    );
};

export default Controller;

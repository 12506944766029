import React from "react";
import { formatTime } from "../helpers";
import View from "./View.jsx";

const Controller = (props) => {
    const { time } = props;

    const newTime = formatTime(time);

    return <View time={newTime} />;
};

export default Controller;

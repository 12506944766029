import React from "react";
import { observer } from "mobx-react-lite";

import { Button, Form, FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import * as style from "./style/EditLinkResource.module.scss";

function EditLinkResource({ item, onChange, errors, onBlur, onSubmit, loading, setEditResource }) {
    return (
        <Form>
            <FormGroup>
                <div className={style.titleName}>
                    <Label>Название:*</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        value={item.name}
                        invalid={!!errors["name"] || !!errors?.name}
                        onBlur={(e) => onBlur(e, item.name)}
                        onChange={onChange}
                        placeholder="Название папки"></Input>
                    <FormFeedback>{errors["name"] || errors?.name}</FormFeedback>
                    <FormText color="muted" className={style.displayHint}>
                        Введите от 2 до 150 символов
                    </FormText>
                </div>
            </FormGroup>
            <FormGroup>
                <div className={style.resourceLink}>
                    <Label>Ссылка на ресурс:*</Label>
                    <Input
                        type="text"
                        id="src_url"
                        name="src_url"
                        value={item.src_url}
                        invalid={!!errors["src_url"] || !!errors?.src_url}
                        onBlur={(e) => onBlur(e, item.src_url)}
                        onChange={onChange}
                        placeholder="Вставьте URL"></Input>

                    <FormFeedback>{errors["src_url"] || errors?.src_url}</FormFeedback>
                </div>
                {item.src_url && item.type === "FILE" && (
                    <Label className={style.textFile}>
                        После сохранения загруженный ранее файл удалится
                    </Label>
                )}
                {item.src_url && item.type === "KINESCOPE" && (
                    <Label className={style.textFile}>
                        После сохранения ранее загруженная ссылка удалится
                    </Label>
                )}
            </FormGroup>

            <div className={style.displayButton}>
                <Button
                    color="primary"
                    outline
                    className={style.deleteLesson}
                    onClick={() => setEditResource(false)}>
                    Отмена
                </Button>

                {loading ? (
                    <Button disabled color="primary" outline className={style.deleteLesson}>
                        <div
                            className={`spinner-border text-primary ${style.loading}`}
                            role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Button>
                ) : (
                    <Button
                        color="primary"
                        className={style.canselChange}
                        onClick={() => onSubmit("LINK")}>
                        Сохранить
                    </Button>
                )}
            </div>
        </Form>
    );
}

export default observer(EditLinkResource);

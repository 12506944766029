function createNodeTypes (name) {
    return {
        CREATE: `${name}_CREATE`,
        EDIT: `${name}_EDIT`,
        DELETE: `${name}_DELETE`,
        SELECT: `${name}_SELECT`,
        OPEN: `${name}_OPEN`
    }
}

function createDataTypes () {
    return {
        SET: 'SET_DATA',
        DELETE: 'DELETE_DATA',
    }
}
const NODE_TYPES = {
    FILE: 'RESOURCE',
    FOLDER: 'FOLDER',
}
const FILE = createNodeTypes(NODE_TYPES.FILE);
const FOLDER = createNodeTypes(NODE_TYPES.FOLDER);
const DATA = createDataTypes();

export { FILE, FOLDER, DATA, NODE_TYPES };
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";
import { ReactComponent as Pen } from "../../../assets/img/pen.svg";
import { ReactComponent as Plus } from "../../../assets/img/plus.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/img/arrow-up.svg";
import defaultUserImg from "../../../assets/img/defaultPerson.png";
import DetailMaterials from "../../EducationForms/DetailMaterials/DetailMaterials";
import editDots from "../../../assets/img/3dots.svg";
import history from "utils/history/history";
import paths from "constants/paths";
import Menu from "../../Menu/Menu";

import * as style from "./style/UserDetail.module.scss";
import users from "api/endPoints/users";
import Preloader from "components/Preloader/Preloader";
import educationForms from "api/endPoints/educationForms";
import ActivationEducationForm from "components/Popup/ActivationEducationForm";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import DeleteEducationForm from "components/Popup/DeleteEducationForm";
import moment from "moment";
import PurchaseMaterial from "components/Purchases/AdditionalPurchases/PurchaseMaterial/PurchaseMaterial";
import role from "constants/role";

function UserDetail() {
  const isAdmin = localStorage.getItem("role");
  const [activeTab, setActiveTab] = useState("tab1");
  const [data, setData] = useState();
  const [noCountableElements, setNoCountableElements] = useState();
  const [allEducationDorms, setAllEducationDorms] = useState();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const {
    modalWindowStore: {
      setIsActivationFormModalOpen,
      isActivationFormModalOpen,
      isDeleteFormModalOpen,
      setIsDeleteFormModalOpen,
    },
  } = useStore();

  const handleClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  useEffect(() => {
    users.getStudent(params.id).then((response) => setData(response));
    users
      .getNoCountableElementsForUser(params.id)
      .then((response) => setNoCountableElements(response.data.items));
    educationForms
      .getAllFormsForUser(params.id)
      .then((response) => setAllEducationDorms(response.data.items));
  }, [loading]);

  const formStatus = (type, status) => {
    if (type === "FREE") {
      return style.education_forms_free;
    } else if (status) {
      return style.education_forms_active;
    } else {
      return style.education_forms_item;
    }
  };
  const goBack = () => {
    if (localStorage.getItem("purchase")) {
      history.push(paths.purchases);
      localStorage.removeItem("purchase");
    } else {
      history.push(paths.usersList);
    }
  };

  return (
    <>
      {data && allEducationDorms ? (
        <div>
          <Button
            className={style.btn_link}
            color="link"
            onClick={() => goBack()}
          >
            <ArrowBack />
            <p className={style.btn_link_text}>Назад</p>
          </Button>
          <div className={style.header}>
            <p className={style.header_title}>Личные данные ученика</p>
            <div className={style.header_btn_wrapper}>
              <Button
                color="outline"
                onClick={() => history.push(paths.editStudent(params.id))}
              >
                <Pen className={style.pen} />
                Редактировать личные данные
              </Button>
              {isAdmin === role.admin && (
                <a
                  className={style.linkToLk}
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_WEB_MORDA_URL}/office/time-sheet/student/${params.id}`}
                >
                  Личный кабинет ученика
                </a>
              )}
            </div>
          </div>
          <img
            src={data.profile.student?.avatar || defaultUserImg}
            alt="Avatar"
            className={style.avatar}
          />
          <Row>
            <Col md={5}>
              <p className={style.colomn_title}>
                {data.user?.last_name || "Неизвестный"}&nbsp;
                {data.user?.first_name || "пользователь"}
              </p>
              <Row>
                <Col>
                  <p className={style.colomn_content_title}>Дата рождения:</p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.student?.birthday
                      ? moment(data?.profile?.student?.birthday).format("L")
                      : "-"}
                  </p>
                </Col>
                <Col>
                  <p className={style.colomn_content_title}>Телефон:</p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.student?.phone || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className={style.colomn_content_title}>Группа обучения:</p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.student?.education_class?.name || "-"}
                  </p>
                </Col>
                <Col>
                  <p className={style.colomn_content_title}>Почта:</p>
                  <p className={style.colomn_content_text}>
                    {data?.user?.email || "-"}
                  </p>
                </Col>
              </Row>
              <Row className="mt-4">
                <p className={style.colomn_content_title}>Особенность:</p>
                <p className={style.colomn_content_text}>
                  {data?.profile?.student?.other_divergency
                    ? data?.profile?.student?.other_divergency
                    : data?.profile?.student?.divergency?.name}
                </p>
              </Row>
            </Col>
            <Col md={5} className="offset-md-1 ">
              <p className={style.colomn_title}>Взрослый</p>
              <Row>
                <Col>
                  <p className={style.colomn_content_title}>ФИО взрослого:</p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.adult?.fio || "-"}
                  </p>
                </Col>
                <Col>
                  <p className={style.colomn_content_title}>
                    Место проживания:
                  </p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.adult?.location || "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className={style.colomn_content_title}>Роль:</p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.adult?.role?.name || "-"}
                  </p>
                </Col>
                <Col>
                  <p className={style.colomn_content_title}>Дата рождения:</p>
                  <p className={style.colomn_content_text}>
                    {data?.profile?.adult?.birthday
                      ? moment(data?.profile?.adult?.birthday).format("L")
                      : "-"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className={style.devaider} />
          <div className={style.education_forms}>
            <div className={style.education_forms_header}>
              <p className={style.education_forms_title}>Формы обучения</p>
              {/* <Button
                color="outline"
                className={style.education_forms_history}
                onClick={() => history.push(paths.purchaseHistory(params.id))}
              >
                Смотреть историю покупок <ArrowIcon />
              </Button> */}
            </div>
            <div className={style.education_forms_wrapper}>
              <button
                className={style.education_forms_add}
                onClick={() => history.push(paths.createNewForm(params.id))}
              >
                <Plus />
                Добавить форму обучения
              </button>
              {allEducationDorms.map((item) => (
                <div
                  key={item.id}
                  className={formStatus(item.type, item.is_active)}
                  onClick={() =>
                    history.push(paths.editForm(params.id, item.id))
                  }
                >
                  <div className={style.education_forms_item_name_wrapper}>
                    <p
                      className={
                        !item.is_active && item.type !== "FREE"
                          ? style.education_forms_item_name_unactive
                          : style.education_forms_item_name
                      }
                      title={item.name}
                    >
                      {item.name}
                    </p>
                    {item.type !== "FREE" ? (
                      <Menu>
                        <button
                          className={style.menu_btn}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push(paths.editForm(params.id, item.id));
                          }}
                        >
                          Редактировать
                        </button>
                        <button
                          className={style.menu_btn}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsActivationFormModalOpen(
                              true,
                              item.id,
                              item.is_active,
                              item.name
                            );
                          }}
                        >
                          {item.is_active ? "Деактивировать" : " Активировать"}
                        </button>
                        {!item.is_active && (
                          <button
                            className={style.menu_btn}
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsDeleteFormModalOpen(
                                true,
                                item.id,
                                item.name
                              );
                            }}
                          >
                            Удалить
                          </button>
                        )}
                      </Menu>
                    ) : (
                      <Menu>
                        <button
                          className={style.menu_btn}
                          onClick={() =>
                            history.push(paths.editForm(params.id, item.id))
                          }
                        >
                          Редактировать
                        </button>
                      </Menu>
                    )}
                  </div>
                  <p
                    className={
                      !item.is_active && item.type !== "FREE"
                        ? style.education_forms_item_duration_unactive
                        : style.education_forms_item_duration
                    }
                  >
                    {item.type === "FREE"
                      ? "Бессрочно"
                      : ` ${moment(item.start_date).format("L")} -
                        ${moment(item.end_date).format("L")}`}
                  </p>
                  {item.type !== "FREE" && (
                    <p
                      className={
                        item.is_active
                          ? [style.education_forms_item_status_active]
                          : [style.education_forms_item_status_unactive]
                      }
                    >
                      {item.is_active ? "Активна" : "Не активна"}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div>
            <p className={style.content_title}>Дополнительные покупки</p>
            <div className={style.tab_wrapper}>
              <a
                href="/"
                onClick={(e) => handleClick(e, "tab1")}
                className={`${style.tab} ${activeTab === "tab1" && style.tab_active}`}
              >
                Элементы
              </a>
              <a
                href="/"
                onClick={(e) => handleClick(e, "tab2")}
                className={`${style.tab} ${activeTab === "tab2" && style.tab_active}`}
              >
                Материалы
              </a>
              <div className={style.emptytab}></div>
            </div>
            {activeTab === "tab1" ? (
              <DetailMaterials
                type="user"
                data={data.user.education_format_countable_elements}
              />
            ) : (
              <PurchaseMaterial
                type="materials"
                data={noCountableElements}
                isClickable={`/user-detail/${params.id}`}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <Preloader />
        </>
      )}
      {isActivationFormModalOpen && (
        <ActivationEducationForm setLoading={setLoading} loading={loading} />
      )}
      {isDeleteFormModalOpen && (
        <DeleteEducationForm setLoading={setLoading} loading={loading} />
      )}
    </>
  );
}

export default observer(UserDetail);

import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormText,
  FormFeedback,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import styles from './styles.module.scss';
/* eslint-disable import/no-extraneous-dependencies */
import 'cropperjs/dist/cropper.css';

import searchCity from "api/endPoints/profileEndpoint";
import searchTimezone from "api/endPoints/profileEndpoint";
import validation from './utils/validation';

const View = ({ onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [admin, setAdmin] = useState({});
  const [loading, setLoading] = useState(false);


  const clearErrField = (obj, field) => {
    const checkError = { ...errors };
    if (obj === 'teacher') {
      delete checkError[field];
    } else {
      if (checkError[`${obj}.${field}`]) {
        delete checkError[`${obj}.${field}`];
      }
      if (checkError[obj] && checkError[obj][field]) {
        delete checkError[obj][field];
      }
    }
    setErrors({ ...checkError });
  };

  const handleAdminChange = e => {
    const { name, value } = e.target;
    clearErrField('teacher', name);
    setAdmin({ ...admin, [name]: value });
  };

  let locationLoadTimeout;
  const [modeChangeLocation, setModeChangeLocation] = useState(false);

  const locationLoadOptions = (inputValue, callback) => {
    clearTimeout(locationLoadTimeout);
    locationLoadTimeout = setTimeout(() => {
      searchCity.searchCity(inputValue).then(response => {
        const options = response.data.items.map(item => ({
          value: item,
          label: item.name,
        }));
        callback(options);
      });
    }, 1000);
  };

  const locationSelectOnChange = selectedItem => {
    const { data } = selectedItem.value;
    // const location = [data.city_type, data.city].join(' ');

    searchTimezone.searchTimezone(data.city, data.geo_lat, data.geo_lon).then(response => {
      const timezone = response.data.items.pop();
      if (timezone) {
        admin.timezone = timezone;
        admin.timezone_id = timezone.id;
        handleAdminChange({ target: { name: 'timezone_id', value: timezone.id } });
      }
    });

    setModeChangeLocation(false);
  };

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      const validateErrors = await validation.clientSideValidation({ ...admin });
      if (validateErrors) {
        setErrors({ ...validateErrors });
      } else {
        setErrors({});
        onSubmit(admin, setErrors, setLoading);
      }
    },
    [admin, onSubmit],
  );

  const defaultTimezone = useMemo(() => {
    if(admin?.timezone)
      return `${admin.timezone.identifier} ${admin.timezone.offset}`;
    if (localStorage?.timezone){
      const valTimezone = JSON.parse(localStorage.timezone);
    return `${valTimezone.identifier} ${valTimezone.offset}`;}
    return null;
  }, [admin?.timezone]);


  return (
    <>
<div className={styles.wrapper}>
      <a href="/" className={styles.back_link}>
        &#8592; Назад
      </a>
      <h1 className={`title-h1 ${styles.head}`}>Сменить часовой пояс</h1>
      <div className="row">
        <div className="col-6">
        <Form onSubmit={handleSubmit} noValidate>
            <FormGroup>
              <Label htmlFor="timezone" className={styles.input_label}>Часовой пояс: *</Label>
              {modeChangeLocation && (
                <div>
                  <AsyncSelect
                    placeholder=""
                    loadOptions={locationLoadOptions}
                    noOptionsMessage={() => 'Начните вводить название города'}
                    loadingMessage={() => 'Загружается...'}
                    onChange={locationSelectOnChange}
                    onBlur={() => setModeChangeLocation(false)}
                    autoFocus
                    cacheOptions
                  />
                  <FormText>Введите название города и выберите из списка</FormText>
                  <br />
                </div>
              )}
              {!modeChangeLocation && (
                <div>
                  <Input
                    id="timezone"
                    name="timezone"
                    value={defaultTimezone}
                    onFocus={() => setModeChangeLocation(true)}
                    className={styles.passwordInput}
                    placeholder="Введите страну и город"
                    invalid={!!errors['teacher.timezone_id'] || !!errors?.teacher?.timezone_id}
                  />
                  <br />
                </div>
              )}
              <FormFeedback
                className={
                  (!!errors['teacher.timezone_id'] || !!errors?.teacher?.timezone_id) &&
                  'd-block'
                }
              >
                {errors['teacher.timezone_id'] || errors?.teacher?.timezone_id}
              </FormFeedback>
            </FormGroup>
            {loading ? (
              <Button color="primary" className={styles.button_submit} disabled>
                <div className="spinner-border text-white btn-preloader" role="status">
                  <span className="sr-only" />
                </div>
              </Button>
            ) : (
              <Button color="primary"  className={styles.button_submit}>Сохранить часовой пояс</Button>
            )}
          </Form>
        </div>
      </div>
    </div>


    </>
  );
};

View.propTypes = {
  onSubmit: PropTypes.func,
};

View.defaultProps = {
  onSubmit: Function.prototype,
};

export default View;

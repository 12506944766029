import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";
import * as style from "./style/AddUser.module.scss";

import AddTeacher from "./AddTeachers/AddTeacher";
import AddStudent from "./AddStudent/AddStudent";

import role from "../../../constants/role";
import { useStore } from "store";
import LeavePage from "components/Popup/LeavePage";
import paths from "constants/paths";
import ChangeCreateUsersTabs from "components/Popup/ChangeCreateUsersTabs";
import history from "utils/history/history";
import { defaultTeacherData, studentDefault, adultDefault } from "constants/defaultCreateField";

import { ReactComponent as ArrowBack } from "../../../assets/img/arrowBack.svg";

function AddUsers(props) {
    const resid = props.match.params.id;
    const [teacherData, setTeacherData] = useState({});
    const [createStudentData, setCreateStudentData] = useState({
        student: studentDefault,
        adult: adultDefault,
    });
    const {
        modalWindowStore: {
            isCloseModalOpen,
            setIsCloseModalOpen,
            isChangeTabsModal,
            setIsChangeTabsModal,
        },
        userStore: {
            setCreateUserTabs,
            creacteUserTabs,
            isOpenLeaveModal,
            checkForOpenModalTeacher,
            checkForOpenModalStudent,
        },
    } = useStore();

    function handleClick(e, tab) {
        e.preventDefault();
        if (tab === "tab1") {
            if (checkForOpenModalTeacher(teacherData, defaultTeacherData)) {
                setIsChangeTabsModal(true, tab);
            } else {
                setCreateUserTabs(tab);
            }
        } else {
            if (
                checkForOpenModalStudent(
                    createStudentData.student,
                    studentDefault,
                    createStudentData.adult,
                    adultDefault,
                )
            ) {
                setIsChangeTabsModal(true, tab);
            } else {
                setCreateUserTabs(tab);
            }
        }
    }

    const leavePage = () => {
        if (creacteUserTabs === "tab2") {
            if (checkForOpenModalTeacher(teacherData, defaultTeacherData)) {
                setIsCloseModalOpen(true);
            } else {
                history.push(paths.usersList);
            }
        } else {
            if (
                checkForOpenModalStudent(
                    createStudentData.student,
                    studentDefault,
                    createStudentData.adult,
                    adultDefault,
                )
            ) {
                setIsCloseModalOpen(true);
            } else {
                history.push(paths.usersList);
            }
        }
    };

    return (
        <div>
            <div className={`row ${style.wrapper}`}>
                <Button className={style.btn_link} color="link" onClick={() => leavePage()}>
                    <ArrowBack />
                    <p className={style.btn_link_text}>Назад</p>
                </Button>

                <div className={style.listHeader}>
                    <p className={style.listTopTxt}>Добавить нового пользователя</p>
                </div>

                <div className={style.tab_wrapper}>
                    <a
                        href="/"
                        onClick={(e) => handleClick(e, "tab1")}
                        className={
                            creacteUserTabs === "tab1"
                                ? `${style.tab_active} ${style.tab}`
                                : style.tab
                        }>
                        Ученики
                    </a>
                    <a
                        href="/"
                        onClick={(e) => handleClick(e, "tab2")}
                        className={
                            creacteUserTabs === "tab2"
                                ? `${style.tab_active} ${style.tab}`
                                : style.tab
                        }>
                        Педагоги
                    </a>
                </div>

                <div className={style.tabs}>
                    {creacteUserTabs === "tab2" ? (
                        <AddTeacher resid={resid} setTeacherData={setTeacherData} />
                    ) : (
                        <AddStudent
                            resid={resid}
                            setCreateStudentData={setCreateStudentData}
                            createStudentData={createStudentData}
                        />
                    )}
                </div>
            </div>
            {isCloseModalOpen && <LeavePage page={paths.usersList} />}
            {isChangeTabsModal && (
                <ChangeCreateUsersTabs
                    setTeacherData={setTeacherData}
                    setCreateStudentData={setCreateStudentData}
                />
            )}
        </div>
    );
}

export default observer(AddUsers);

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import getStudentsFilter from "../../../api/endPoints/users";
import getEducationEventStatuses from "../../../api/endPoints/educationEvents";
import { formatUserName } from "../helpers";
import Controller from "./Controller";

const Connector = () => {
    const formatItem = (item) => ({
        name: formatUserName(item?.user),
        selectedCode: item?.user?.id,
    });

    const getStudentsFirstPage = (search, page, setAll, setTotal, setLoading) => {
        getStudentsFilter
            .getStudentsFilter({ name: search }, page)
            .then((response) => {
                setAll([...response.items.map((item) => formatItem(item))]);
                setTotal(response.total);
            })
            .finally(() => setLoading(false));
    };

    const getStudents = (search, page, all, setAll, setTotal, setLoading) => {
        getStudentsFilter
            .getStudentsFilter({ name: search }, page)
            .then((response) => {
                setAll([...all, ...response.items.map((item) => formatItem(item))]);
                setTotal(response.total);
            })
            .finally(() => setLoading(false));
    };

    const getStatus = (setAll) => {
        getEducationEventStatuses.getEducationEventStatuses().then((response) => {
            setAll(response.map((el) => ({ name: el.name, selectedCode: el.original_name })));
        });
    };

    return (
        <Controller
            getStudentsFirstPage={getStudentsFirstPage}
            getStudents={getStudents}
            getStatus={getStatus}
        />
    );
};

export default Connector;

import React, { useMemo } from 'react';
import * as style from "./style/folder-tree.module.scss";

function LevelMargin (props) {
    const { level, isFirstChild, isLastChild, lastElementsList, isOpen = false, hasChild = true } = props;

    return useMemo(() => {
        const range = {startIndex: 0, endIndex: lastElementsList.length - 1};

        if (isLastChild) {
            for (let i = lastElementsList.length - 1; i >= 0; i -= 1) {
                if (!lastElementsList[i]) {
                    range.startIndex = i + 1;
                    break;
                }
            }
        }

        return lastElementsList.map((isLast, index) => {
            if (index === 0) {
                return null;
            }

            let classname = `${style.marginLine}`;

            const isIndexInRange = index >= range.startIndex && index <= range.endIndex;
            if (isLastChild && isIndexInRange && (!isOpen || !hasChild)) {
                classname = `${classname} ${isLast ? style.marginLineEnd : ''}`;
            }

            if (isFirstChild && index === level) {
                classname = `${classname} ${style.marginLineStart}`;
            }

            return <div key={index} className={classname}/>
        })


    }, [level, isFirstChild, isLastChild, isOpen, hasChild, lastElementsList]);
}

export default LevelMargin;
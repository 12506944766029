import React from "react";
import { Form, FormFeedback, FormGroup, FormText, Input } from "reactstrap";
import DropdownSelect from "../Dropdown";
import closeIcon from "../assets/closeIcon.svg";
import { ReactComponent as InfoIcon } from "../assets/infoIcon.svg";
import styles from "./styles.module.scss";

const View = (props) => {
    const {
        title,
        buttonText,
        createEvent,
        onClose,
        clearErrField,
        data,
        setData,
        errors,
        onBlur,
        selectType,
        setSelectType,
        noSelectType,
        setNoSelectType,
        allSelectType,
        loadingType,
        onSearchType,
        inputValueType,
        selectSubject,
        setSelectSubject,
        noSelectSubject,
        setNoSelectSubject,
        allSelectSubject,
        loadingSubject,
        inputValueSubject,
        onSubjectSearch,
        onSubjectScroll,
        selectClass,
        setSelectClass,
        noSelectClass,
        setNoSelectClass,
        allSelectClass,
        loadingClass,
        onSearchClass,
        inputValueClass,
        studentSelect,
        setStudentSelect,
        noStudentSelect,
        setNoStudentSelect,
        allStudentSelect,
        studentLoading,
        onStudentScroll,
        onStudentSearch,
        studentInput,
        studentAllTitle,
        minDate,
        selectTime,
        setSelectTime,
        noSelectTime,
        setNoSelectTime,
        allSelectTime,
        loadingTime,
        onSearchTime,
        inputValueTime,
    } = props;

    const dropdownSelectTypeData = {
        selectList: selectType,
        setSelectList: setSelectType,
        noSelectList: noSelectType,
        setNoSelectList: setNoSelectType,
        allSelectList: allSelectType,
        loading: loadingType,
        onSearch: onSearchType,
        inputValue: inputValueType,
        isError: errors.type_id,
    };

    const dropdownSelectSubjectData = {
        selectList: selectSubject,
        setSelectList: setSelectSubject,
        noSelectList: noSelectSubject,
        setNoSelectList: setNoSelectSubject,
        allSelectList: allSelectSubject,
        loading: loadingSubject,
        onSearch: onSubjectSearch,
        onScroll: onSubjectScroll,
        inputValue: inputValueSubject,
    };

    const dropdownSelectClassData = {
        selectList: selectClass,
        setSelectList: setSelectClass,
        noSelectList: noSelectClass,
        setNoSelectList: setNoSelectClass,
        allSelectList: allSelectClass,
        loading: loadingClass,
        onSearch: onSearchClass,
        inputValue: inputValueClass,
    };

    const dropdownSelectStudentData = {
        selectAllTitle: (data.type_id === 2 || data.type_id === 6) && studentAllTitle,
        selectList: studentSelect,
        setSelectList: setStudentSelect,
        noSelectList: noStudentSelect,
        setNoSelectList: setNoStudentSelect,
        allSelectList: allStudentSelect,
        loading: studentLoading,
        onScroll: onStudentScroll,
        onSearch: onStudentSearch,
        inputValue: studentInput,
        isNotWork: !data.type_id,
        isError: errors.students,
    };

    const dropdownSelectTimeData = {
        selectList: selectTime,
        setSelectList: setSelectTime,
        noSelectList: noSelectTime,
        setNoSelectList: setNoSelectTime,
        allSelectList: allSelectTime,
        loading: loadingTime,
        onSearch: onSearchTime,
        inputValue: inputValueTime,
        isNotWork: !(data.teacher_id && data.type_id && data.students && data.start_at),
        isError: errors.start_time,
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modal__content}>
                <div className={styles.modal__content__top}>
                    <div className={styles.modal__content__top__title}>{title}</div>
                    <button
                        className={styles.modal__content__top__close}
                        type="button"
                        onClick={onClose}>
                        <img src={closeIcon} alt="closeIcon" />
                    </button>
                </div>
                <div className={styles.modal__content__body}>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormGroup>
                            <DropdownSelect {...dropdownSelectTypeData} />
                            {errors.type_id && (
                                <span className={styles.modal__content__body__message}>
                                    {errors.type_id}
                                </span>
                            )}
                        </FormGroup>

                        {(data.type_id === 1 || data.type_id === 2 || data.type_id === 6) && (
                            <FormGroup>
                                <Input
                                    type="text"
                                    placeholder="Название события"
                                    name="name"
                                    value={data.name}
                                    onChange={(e) => {
                                        setData({ ...data, name: e.target.value });
                                        clearErrField(e.target.name);
                                    }}
                                    onBlur={onBlur}
                                    invalid={!!errors.name}
                                />
                                {errors.name ? (
                                    <FormFeedback>{errors.name}</FormFeedback>
                                ) : (
                                    <FormText>
                                        <InfoIcon className={styles.modal__content__body__icon} />
                                        Введите от 2 до 150 символов
                                    </FormText>
                                )}
                            </FormGroup>
                        )}

                        <FormGroup>
                            <Input
                                type="text"
                                placeholder="Ссылка"
                                name="lesson_url"
                                value={data.lesson_url}
                                onChange={(e) => {
                                    if (e.target.value.length > 0) {
                                        setData({ ...data, lesson_url: e.target.value });
                                    } else {
                                        setData({ ...data, lesson_url: null });
                                    }
                                    clearErrField(e.target.name);
                                }}
                                onBlur={onBlur}
                                invalid={!!errors.lesson_url}
                            />
                            {errors.lesson_url && <FormFeedback>{errors.lesson_url}</FormFeedback>}
                        </FormGroup>

                        {data?.type_id !== 6 && (
                            <FormGroup>
                                <Input
                                    type="text"
                                    placeholder="Описание"
                                    name="description"
                                    value={data.description}
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setData({ ...data, description: e.target.value });
                                        } else {
                                            setData({ ...data, description: null });
                                        }
                                        clearErrField(e.target.name);
                                    }}
                                    onBlur={onBlur}
                                    invalid={!!errors.description}
                                />
                                {errors.description && (
                                    <FormFeedback>{errors.description}</FormFeedback>
                                )}
                            </FormGroup>
                        )}

                        {(data.type_id === 1 || data.type_id === 2) && (
                            <FormGroup>
                                <DropdownSelect {...dropdownSelectSubjectData} />
                            </FormGroup>
                        )}

                        {(data.type_id === 6 || data.type_id === 2) && (
                            <FormGroup>
                                <DropdownSelect {...dropdownSelectClassData} />
                            </FormGroup>
                        )}

                        <FormGroup>
                            <DropdownSelect {...dropdownSelectStudentData} />
                            {errors.students && (
                                <span className={styles.modal__content__body__message}>
                                    {errors.students}
                                </span>
                            )}
                        </FormGroup>

                        <FormGroup>
                            <Input
                                type="date"
                                placeholder="Дата"
                                max="9999-12-31"
                                name="start_at"
                                value={`${data.start_at.split("T")[0]}`}
                                className={styles.modal__content__body__date}
                                min={minDate}
                                onChange={(event) => {
                                    setData({
                                        ...data,
                                        start_at: event.target.value
                                            ? `${event.target.value}T00:00`
                                            : "",
                                        start_time: "",
                                    });
                                    clearErrField(event.target.name);
                                }}
                                invalid={!!errors.start_at}
                                onBlur={onBlur}
                                disabled={data.students?.length < 1}
                            />
                            {errors.start_at ? (
                                <FormFeedback>{errors.start_at}</FormFeedback>
                            ) : (
                                <FormText>
                                    <InfoIcon className={styles.modal__content__body__icon} />
                                    Обязательно выберите{" "}
                                    {data.type_id === 4 || data.type_id === 5
                                        ? "Взрослого"
                                        : "Ученика"}
                                </FormText>
                            )}
                        </FormGroup>

                        <FormGroup>
                            <DropdownSelect {...dropdownSelectTimeData} />
                            {errors.start_time ? (
                                <span className={styles.modal__content__body__message}>
                                    {errors.start_time}
                                </span>
                            ) : (
                                <FormText>
                                    <InfoIcon className={styles.modal__content__body__icon} />
                                    Обязательно выберите дату
                                </FormText>
                            )}
                        </FormGroup>

                        <button
                            type="button"
                            className={styles.modal__content__body__create}
                            onClick={createEvent}>
                            {buttonText}
                        </button>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default View;

import React, { useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import "flatpickr/dist/themes/material_blue.css";
import * as style from "./style/ChangeFormDuration.module.scss";
import DatePicker from "utils/datePicker";
import { useStore } from "store";
import educationForms from "api/endPoints/educationForms";
import { toast } from "react-toastify";

function ChangeFormDuration({ setLoading }) {
  const params = useParams();
  const {
    modalWindowStore: {
      isChangeDurationModalOpen,
      setIsChangeDurationModalOpen,
    },
  } = useStore();
  const [date, setDate] = useState([
    new Date(isChangeDurationModalOpen.date.start_date),
    new Date(isChangeDurationModalOpen.date.end_date),
  ]);
  const options = {
    mode: "range",
    allowInput: false,
    minDate: "today",
    dateFormat: "d.m.Y",
    defaultDate: ["2016-10-10", "2016-10-20"],
    onChange: function (selectedDates, dateStr, instance) {
      let start = selectedDates[0].setHours(selectedDates[0].getHours() + 4);
      let end = selectedDates[1].setHours(selectedDates[1].getHours() + 4);
      setDate([new Date(start), new Date(end)]);
    },
    locale: Russian,
  };

  const changeDuration = (type) => {
    if (type === "week") {
      let week = date[1].getDate();
      let newDate = [date[0], new Date(date[1].setDate(week + 7))];
      setDate(newDate);
    } else {
      let month = date[1].getMonth();
      let newDate = [date[0], new Date(date[1].setMonth(month + 1))];
      setDate(newDate);
    }
  };

  const saveChanges = () => {
    setLoading(true);
    educationForms
      .editFormForUser(isChangeDurationModalOpen.date.id, date)
      .then(() => {
        setLoading(false);
        toast.info("Изменения сохранены");
        setIsChangeDurationModalOpen(false);
      })
      .catch(() => setLoading(false));
  };


  return (
    <>
      {date && (
        <div className={style.modal}>
          <div className={style.modalOverlay}>
            <div className={style.modalWindow}>
              <div className={style.modalHeader}>
                <p className={style.modal_title}>
                  Редактировать длительность
                  <br /> формы обучения
                </p>
              </div>
              <div>
                <button
                  className={style.button_week}
                  onClick={() => changeDuration("week")}
                >
                  + 1 неделя
                </button>
                <button
                  className={style.button_month}
                  onClick={() => changeDuration("month")}
                >
                  + 1 Месяц
                </button>
              </div>

              <div className={style.input_wrapper}>
                <p className={style.input_duration_lable}>
                  Введите дату начала и окончания:
                </p>
                <Flatpickr
                  data
                  value={date}
                  options={options}
                  placeholder="Дата начала"
                  className={style.input_duration}
                />
                {/* &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
          <input id="DashboardEndDatePickera" placeholder="Дата окончания" /> */}
              </div>
              <div className={style.modal_button_wrapper}>
                <Button
                  color="primary"
                  outline
                  className={style.modal_cancel}
                  onClick={() => setIsChangeDurationModalOpen(false)}
                >
                  Отмена
                </Button>
                <Button
                  color="primary"
                  className={style.modal_save}
                  onClick={() => saveChanges()}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(ChangeFormDuration);

import { object, number } from 'yup';

const rulesMessage = {
  required: 'Поле обязательно для заполнения',
};

const schema = object({
  timezone_id: number().required(rulesMessage.required),
});

async function clientSideValidation(form) {
  let errors;
  try {
    await schema.validate(form, { abortEarly: false });
  } catch (validationError) {
    errors = {};
    validationError.inner.forEach(error => {
      const { path, message } = error;
      errors[path] = message;
    });
  }
  return errors;
}

export default {
  clientSideValidation,
};
